import axios from "axios";
import { urlAPI } from "./config";

export default {
  state: {
    Partner: {},
    AllPartners: [],
  },
  mutations: {
    SetPartner(state, payload) {
      state.Partner = payload;
    },
    SetAllPartners(state, payload) {
      state.AllPartners = payload;
    },
  },
  actions: {
    async LoadPartner({ commit }, payload) {
      let data = await axios.get(urlAPI + `/agent/${payload}`);
      commit("SetPartner", data?.data?.data);
    },
    async LoadAllPartners({ commit }) {
      let responce = await axios.get(urlAPI + "/agent");
      commit("SetAllPartners", responce?.data?.data);
      return responce?.data?.data;
    },
  },
  getters: {
    getPartner: (state) => state.Partner,
    getAllPartners: (state) => state.AllPartners,
  },
};
