<template>
  <v-app id="app">
    <router-view />
  </v-app>
  <metainfo>
    <!-- <template v-slot:title="{ content }">{{ content }} - Yay!</template> -->
  </metainfo>
</template>

<script>
export default {
  name: "App",
  metaInfo() {
    let obj = {
      title: 'G Partners',
    }
    return obj
  },
  async mounted() {
    await this.$store.dispatch('csrfCookie');
  },
};
</script>
<style lang="scss">
@import "./assets/Helvetica/stylesheet.css";

* {
  font-family: "HelveticaNeueCyr", sans-serif !important;
}

.text-h1,
.text-h2,
.text-h3,
.text-h4,
.text-h5,
.text-h6 {
  font-family: "HelveticaNeueCyr", sans-serif !important;
}

html {
  font-size: 15px !important;
  scroll-behavior: smooth;
  scroll-padding-top: 100px;
}

html,
#app {
  background: #111213;
  overflow-x: hidden;
}

/* @media screen and (min-width: 600px) and (max-width: 980px) {
  #app {
    zoom: .85;
  }
} */

@media (min-width: 1440px) {
  .v-container {
    max-width: 1400px !important;
  }
}

@media screen and (max-width: 321px) {
  #app {
    zoom: .85;
  }
}
</style>
