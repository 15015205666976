<template>
    <v-container :fluid="$vuetify.display.mdAndDown ? true : false">
        <v-row justify="center">
            <v-col cols="12" sm="11" md="11">
                <v-row justify="space-between">
                    <v-col cols="12">
                        <v-list class="list" v-model:selected="choosePage">

                            <v-list-item value="terms" color="#fff" rounded="shaped" class="list-item"
                                @click="$router.push({ params: { name: 'terms' } })">
                                <v-list-item-title class="text-title">
                                    Terms and condition
                                </v-list-item-title>
                            </v-list-item>

                            <v-list-item value="cookies" color="#fff" rounded="shaped" class="list-item"
                                @click="$router.push({ params: { name: 'cookies' } })">
                                <v-list-item-title class="text-title">
                                    Cookies
                                </v-list-item-title>
                            </v-list-item>

                            <v-list-item value="copyrights" color="#fff" rounded="shaped" class="list-item"
                                @click="$router.push({ params: { name: 'copyrights' } })">
                                <v-list-item-title class="text-title">
                                    Copyrights
                                </v-list-item-title>
                            </v-list-item>

                            <v-list-item value="legal" color="#fff" rounded="shaped" class="list-item"
                                @click="$router.push({ params: { name: 'legal' } })">
                                <v-list-item-title class="text-title">
                                    Legal person
                                </v-list-item-title>
                            </v-list-item>

                        </v-list>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12">
                        <v-fade-transition>
                            <v-card class="cards-content pa-4" flat v-show="choosePage == 'terms'">
                                <p>
                                    Добро пожаловать на сайт агентства недвижимости GPartners Invest SRL!
                                </p>

                                <p>
                                    Прежде чем начать использование нашего сайта, пожалуйста, внимательно ознакомьтесь с
                                    нашими
                                    условиями использования и пользовательским соглашением. В случае использования
                                    нашего
                                    сайта,
                                    вы
                                    автоматически соглашаетесь с нижеперечисленными условиями.
                                </p>

                                <br />

                                <p>
                                    При совершении запросов, звонков, скачивании медиаматериалов и получении информации
                                    с нашего сайта, вы предоставляете согласие на обработку ваших персональных данных.
                                    Это включает в себя ваше имя, контактные данные и другую информацию, предоставленную
                                    вами. Вы также соглашаетесь получать предложения о сотрудничестве и маркетинговые
                                    предложения от GPartners Invest SRL.
                                </p>

                                <h2>
                                    Сбор данных.
                                </h2>

                                <p>
                                    Наш сайт использует сервисы Google Analytics 4 и Google Tag Manager для сбора
                                    анонимной информации о поведении пользователей на сайте. Эта информация помогает нам
                                    улучшать качество предоставляемых услуг. Пожалуйста, обратите внимание, что
                                    использование нашего сайта подразумевает ваше согласие на сбор и обработку данных
                                    через указанные сервисы.
                                </p>

                                <h2>
                                    Защита данных и право на удаление.
                                </h2>

                                <p>
                                    Мы ценим вашу конфиденциальность и соблюдаем положения GDPR (Общего регламента о
                                    защите данных). Если у вас есть какие-либо вопросы или запросы относительно удаления
                                    ваших персональных данных, пожалуйста, свяжитесь с нами по адресу
                                    contact@gpartners.md. Мы готовы предоставить вам всю необходимую информацию и
                                    выполнить ваш запрос в соответствии с действующим законодательством.
                                </p>

                                <p>
                                    Используя наш сайт, вы подтверждаете свое согласие с вышеуказанными условиями и
                                    обязуетесь соблюдать их. При возникновении вопросов или несогласий, пожалуйста,
                                    свяжитесь с нами для получения дополнительной информации.
                                </p>

                                <br />

                                <p>
                                    С уважением, <br />
                                    Команда GPartners Invest SRL</p>

                            </v-card>
                        </v-fade-transition>
                        <v-fade-transition>
                            <v-card class="cards-content pa-4" flatv v-show="choosePage == 'cookies'">
                                <p>Добро пожаловать на сайт агентства недвижимости GPartners Invest SRL!</p>

                                <p>Прежде чем начать использование нашего сайта, пожалуйста, внимательно ознакомьтесь с
                                    нашими
                                    условиями использования и пользовательским соглашением. В случае использования
                                    нашего
                                    сайта,
                                    вы
                                    автоматически соглашаетесь с нижеперечисленными условиями.</p>
                            </v-card>
                        </v-fade-transition>
                        <v-fade-transition>
                            <v-card class="cards-content pa-4" flatv v-show="choosePage == 'copyrights'">
                                <p>Добро пожаловать на сайт агентства недвижимости GPartners Invest SRL!</p>

                                <p>Прежде чем начать использование нашего сайта, пожалуйста, внимательно ознакомьтесь с
                                    нашими
                                    условиями использования и пользовательским соглашением. В случае использования
                                    нашего
                                    сайта,
                                    вы
                                    автоматически соглашаетесь с нижеперечисленными условиями.</p>
                            </v-card>
                        </v-fade-transition>
                        <v-fade-transition>
                            <v-card class="cards-content pa-4" flatv v-show="choosePage == 'legal'">
                                <p>Добро пожаловать на сайт агентства недвижимости GPartners Invest SRL!</p>

                                <p>Прежде чем начать использование нашего сайта, пожалуйста, внимательно ознакомьтесь с
                                    нашими
                                    условиями использования и пользовательским соглашением. В случае использования
                                    нашего
                                    сайта,
                                    вы
                                    автоматически соглашаетесь с нижеперечисленными условиями.</p>
                            </v-card>
                        </v-fade-transition>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { useLocale } from "vuetify";

export default {
    name: "LegalContent",
    setup() {
        const { t } = useLocale();
        return {
            t,
        };
    },
    data: () => ({
        choosePage: ["terms"]
    }),
    mounted() {
        this.choosePage = this.$route.params.name ? [this.$route.params.name] : ["terms"];
    }
};
</script>

<style scoped>
.cards-content>h2 {
    font-size: 2.1rem;
    margin-top: 3rem;
    margin-bottom: 1rem;
}

.cards-content>p {
    font-size: 1.15rem;
    margin-top: 1.1rem;
    margin-bottom: 1.1rem;
}

.cards-content {
    color: #C4C4C4;
    background: transparent;
}


.list {
    background: transparent;
}

.list-item {
    background: transparent;
}

.text-title {
    color: #9A9A9B;
    font-size: 1.6rem;
    font-weight: 400;
}

.btn-allp {
    text-transform: inherit;
    letter-spacing: -0.02rem;
    font-weight: 400;
    font-size: 1.2rem;
}

.container-main {
    padding-top: 15vh;
}

.row-line {
    width: 100%;
}

.title-small2 {
    color: #c4c4c4;
    font-weight: 400;
    letter-spacing: -0.035rem !important;
}

.title-small {
    color: #c4c4c4;
    font-weight: 400;
    letter-spacing: -0.04rem !important;
    text-shadow: 8px 13px 35px rgba(0, 0, 0, 0.5);
}

.mh-90 {
    min-height: 69px;
}

.title-big {
    letter-spacing: -0.04rem !important;
    color: #f6f2e6;
    text-shadow: rgba(0, 0, 0, 0.87) 0px 4px 30px;
    font-size: 6rem;
    font-weight: 400;
    line-height: 6rem;
}

.divider-partner {
    border-width: 1px;
    opacity: 1;
    border-image-slice: 1;
    border-image-source: linear-gradient(to right, rgba(246, 242, 230, 0.36), rgba(255, 255, 255, 0));
}

@media screen and (max-width: 600px) {
    .title-big {
        font-size: 3rem;
        line-height: 3rem;
    }

    .title-small,
    .title-small2 {
        font-size: 0.85rem !important;
        line-height: 1.1rem !important;
    }

    .row-line {
        height: unset !important;
    }

    .btn-allp {
        font-size: 1rem;
    }

    .mh-90 {
        min-height: 55px;
    }

}

@media screen and (min-width: 600px) and (max-width: 960px) {
    .title-big {
        zoom: 0.8;
    }

    .title-small,
    .title-small2 {
        zoom: 0.8;
    }
}
</style>