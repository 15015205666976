import { createRouter, createWebHistory } from "vue-router";
import crm_router from "./CRM.js";
import web_router from "./Web.js";

/* const routes = [
  {
    path: `/:lang?`,
    name: "home",
    component: Home,
  },
  {
    path: `/:lang?/about`,
    name: "about-en",
    component: About,
    beforeEnter: (to, from, next) => {
      const lang = to.params.lang;
      if (Object.keys(locale.messages).includes(lang)) {
        locale.locale = lang;
        localStorage.setItem("language", lang.toLowerCase());
        if (lang != "en") {
          switch (lang) {
            case "ro":
              next("/ro/despre-noi");
              break;
            case "ru":
              next("/ru/o-nas");
              break;
          }
        } else {
          next();
        }
      } else {
        next("/en/");
      }
    },
  },
  {
    path: `/:lang?/despre-noi`,
    name: "about-ro",
    component: About,
    beforeEnter: (to, from, next) => {
      const lang = to.params.lang;
      if (Object.keys(locale.messages).includes(lang)) {
        locale.locale = lang;
        localStorage.setItem("language", lang.toLowerCase());
        if (lang != "ro") {
          switch (lang) {
            case "en":
              next("/en/about");
              break;
            case "ru":
              next("/ru/o-nas");
              break;
          }
        } else {
          next();
        }
      } else {
        next("/en/");
      }
    },
  },
  {
    path: `/:lang?/o-nas`,
    name: "about-ru",
    component: About,
    beforeEnter: (to, from, next) => {
      const lang = to.params.lang;
      if (Object.keys(locale.messages).includes(lang)) {
        locale.locale = lang;
        localStorage.setItem("language", lang.toLowerCase());
        if (lang != "ru") {
          switch (lang) {
            case "en":
              next("/en/about");
              break;
            case "ro":
              next("/ro/despre-noi");
              break;
          }
        } else {
          next();
        }
      } else {
        next("/en/");
      }
    },
  },
]; */

const routes = [...web_router, ...crm_router];

const router = createRouter({
  history: createWebHistory(),
  mode: "history",
  routes: routes,
  scrollBehavior(to, from) {
    return new Promise((resolve) => {
      if (
        from.name != to.name &&
        to.name == "property" &&
        from.name != undefined
      ) {
        setTimeout(() => {
          resolve({
            top: 0,
            el: "#ProductImages",
            behavior: "instant",
          });
        }, 250);
      } else if (from.name != to.name && from.name != undefined) {
        resolve({ top: 0, behavior: "instant" });
      } else if (from.name == to.name && to.name == "property") {
        let obj = {
          behavior: "instant",
        };

        if (from.params.propertyID != to.params.propertyID) {
          obj.el = "#ProductImages";
          obj.top = 0;
        } else if(to.hash != null && to.hash != ""){
          obj.el = to.hash;
          obj.top = 100;
        }
        resolve(obj);
      } else {
        resolve();
      }
    });
  },
});

export default router;

/*  else if (
        from.name == to.name &&
        to.name == "real-estate" &&
        from.params.categorie != to.params.categorie
      ) {

        let data = { top: 0, behavior: "instant" };
        if (to.hash) {
          data.el = to.hash;
        }
        resolve(data);

      }  */
