import { ro } from "vuetify/lib/locale";

export default {
  $vuetify: { ...ro },
  ...ro,
  route: {
    home: "",
    about: "despre-noi",
    real_estate: "real-estate",
    real_estate_single: "real-estate-single",
    real_estate_single_gallary: "real-estate-single-gallary",
  },
  header: {
    home: "Acasă",
    real_estate: "Imobil",
    about: "Despre",
    learn: "Academia",
    partners: "Partnenerii",
    contact: "Contacte",
    consultation: "Consultation",
    current_lang: "Ro",
    legal: "Legal",
  },
  home: {
    parallax: {
      invest: "Investește",
      best_choice: "în cea mai bună opțiune",
      with_the: "că",
      work_with: "lucrați cu",
      experts: "Experții",
      în_domeniul:
        "în imobiliare, investiții, construcții de mai mult de 10 ani",
      passion: "pasionați în",
      btn_1: "Imobiliare",
      btn_2: "Învață în Hub",
      btn_3: "Contactează-ne",
      honest: "Integritate",
      as_principe: "în lucrul cu clienții",
    },
    real_estate: {
      title:
        "Parteneri imobiliari de încredere, implicați în creșterea activelor dvs",
      btn_cards_action: "Află mai multe",
    },
    HelpWithYou: {
      title: "Vă putem ajuta cu...",
      btn_action: "Consultație gratuită",
      items: [
        {
          title: "Investiții",
          description:
            "Un serviciu de investiții bazat pe trei calități: 1.Localizare, 2.Lichiditate, 3.ROI (eficiența investiției).",
        },
        {
          title: "Vânzare & cumpărare",
          description:
            "Serviciu de suport pentru tranzacții din momentul în care produsul este împachetat până când tranzacția este închisă și obiectul este transferat noului proprietar. Asistență în evaluarea pieței imobiliare, verificarea stării fizice și juridice a proprietății, sprijin în tranzacție până la obținerea dreptului de proprietate asupra proprietății.",
        },
        {
          title: "Imobil de inchiriat",
          description:
            "Asistență în selectarea chiriașilor și proprietarilor de calitate, precum și în administrarea proprietății.",
        },
        {
          title: "Schimb de proprietate",
          description:
            "Schimb eficient de bunuri imobiliare fără riscuri și pierderi.",
        },
        {
          title: "Evaluarea proprietății",
          description:
            "Efectuăm analize profesionale de piață pentru a identifica prețul de piață al proprietății.",
        },
        {
          title: "Servicii de brokeraj ipotecare",
          description:
            "Sprijinul deplin al împrumutatului asupra împrumutului până la primirea unui credit ipotecar pentru proprietatea dorită.",
        },
        {
          title: "Constructii si reparatii",
          description:
            "Servicii de constructii si reparatii de inalta calitate in Chisinau. De la renovări la construcție la sol, oferim soluții de calitate pentru a vă îmbunătăți proprietatea.",
        },
        {
          title: "Serviciu de design interior si exterior",
          description:
            "Serviciul include: 1. Evaluarea proiectului, 2. Lucrări de înaltă calitate, 3. Garanția calității lucrărilor.",
        },
        {
          title: "Lucrări de arhitectură.",
          description:
            "Elaborare proiecte de arhitectura la solicitare in Chisinau.",
        },
        {
          title: "Privatizarea",
          description:
            "Serviciu complet de asistență juridică, de la colectarea documentelor până la obținerea bunurilor imobiliare în proprietate privată.",
        },
      ],
    },
    AboutG: {
      subtitle: "Cine stă în spatele G Partners?",
      title: {
        part_first: "Experții",
        part_white: "imobiliari",
        part_last: "cu peste 10 ani de experienta",
      },
      person_1: {
        line_1_name: "Ivanov Maxim",
        line_2: "Partener general",
        line_3: "Proprietate comerciala",
        line_4: "Investiții",
      },
      person_2: {
        line_1_name: "Gheorghies Florentin",
        line_2: "Partener general",
        line_3: "Proprietatea industrială",
        line_4: "Expertiza juridica",
      },
      about_text: {
        part_1:
          "G Partners este un butic imobiliar din Chișinău, fondat ca urmare a cooperării organice a profesioniștilor imobiliari de succes. În activitatea sa, G Partners Invest este ghidată de atenția acordată confidențialității informațiilor, tranzacțiilor transparente și îndeplinirii așteptărilor clienților.",
        part_2:
          "Salutăm toți clienții potențiali care ar putea pune întrebări sau au un interes în cooperare. Vom fi bucuroși să vă ajutăm.",
        part_3: " ",
      },
      btn_learn_more: "Află mai multe",
    },
    OurMission: {
      subtitle: "Cooperare cu G Partners Invest.",
      title: "Experți imobiliari de încredere",
      text: {
        part_1:
          "G Partners oferă clienților cel mai înalt nivel de consultanță imobiliară și reprezentare superioară în închirieri imobiliare, achiziții și vânzări imobiliare, schimburi și alte tranzacții.",
        part_2:
          "Clienții noștri primesc o abordare transparentă și prețuiesc contribuția noastră atunci când furnizează servicii imobiliare în Moldova și în întreaga lume. Ne străduim să oferim clienților noștri cele mai bune condiții în tranzacțiile imobiliare. Când se confruntă cu o provocare dificilă, agenții noștri imobiliari sunt creativi, strategici și rezolvă probleme.",
        part_3: "Servicii de proiectare, constructii si reparatii in Chisinau.",
        part_4:
          "G Partners Invest este gata să devină partenerul dumneavoastră de încredere în domeniul renovării, proiectării și construcțiilor în Moldova.",
        part_5: "Fă primul pas",
      },
    },
    realEstateWorld: {
      title: "Activitățile noastre și cele mai bune oferte.",
      subtitle:
        "Imobile comerciale, rezidentiale, apartamente si case cu diferite optiuni in orasul Chisinau.",
      items: [
        {
          title: "Imobiliare în Dubai, Emiratele Arabe Unite",
          btn_text: "În curând",
        },
        {
          title: "Imobile comerciale în Moldova",
          btn_text: "Află mai multe",
        },
        {
          title: "Deveniți un expert imobiliar în G Partners™ Hub",
          btn_text: "Află mai multe",
        },
      ],
    },
  },
  cookieModal: {
    title:
      "Folosim cookie-uri pentru a vă îmbunătăți experiența, pentru a analiza traficul pe site și pentru a oferi conținut personalizat. Citiți-ne",
    title_Cookie_Policy: "Politica",
    btn_reject: "Respinge",
    btn_accept: "Accept",
  },
  getConsultation: {
    btn_title: "Consultanță gratuită",
    name: "OFFICE MANAGER",
    list_item_telegram: "Prin Telegram",
    list_item_whatts: "Prin Whatts App",
    list_item_phone: "Prin telefonul mobil",
  },
  real_estate: {
    Categories: {
      page_title: "Imobiliare",
      page_subtitle: "care merită atenția dumneavoastră",
      btn_1: "Calculator ipotecar",
      btn_2: "Selecție personalizată",
    },
    Products: {
      filter: {
        type: "Tip",
        offer: "Ofertă",
        object_price: "Prețul obiectului",
        location: "Locație",
        condition: "Stare",
        reparation_type: "Tipul de reparație",
        land_type: "Tip de pământ",
        industrial_purpose_type: "Tip de destinație industrială",
        rooms: "Camere",
        remove_filters: "Ștergeți filtrele",
        show: "Afișați",
        location_placeholder: "Neselectat",
        area: "Pătrat m²",
      },
      filter_input: "Numele proprietății, districtului sau dezvoltatorului",
      btn_new_search: "O nouă căutare",
      btn_change_filter: "Schimbați filtrele",
      modal: {
        btn_reset_filter: "Resetați filtrele",
        btn_see_result: "Caută",
      },
      btn_remove_filter: "Ștergeți filtre",
      btn_we_found: {
        part1: "Am gasit",
        part2: "rezultate",
      },
      btn_load_more: "Mai mult",
    },
    MaibCalculator: {
      title_left: "Calculați opțiunea imobiliară",
      btn_right_1: "Credit ipotecar",
      btn_right_2: "Alte",
      btn_new_calculation: "Calcul nou",
    },
    GetInTouch: {
      text_need_offer: "Căutați o ofertă specială?",
      text_title: {
        part1: "Hai să",
        part2: "ne auzim",
      },
      text_small_right:
        "Nu poți găsi ceva anume? Vom fi bucuroși să vă ajutăm!",
      btn_get_in_touch: "Contactează-ne",
    },
  },
  footer: {
    working_worldwide: "Operăm * în toată lumea.",
    based_in_chisinau: "Chișinău, Moldova",
    copyrights: "Copyrights",
    legal_person: "Entitate juridică",
    terms_and_condition: "Termeni și condiții",
    sitemap: "Harta site-ului XML",
  },
  real_estate_single: {
    Parallax: {
      subtitle_right: "merită atenția dumneavoastră",
      btn_1: "Calculator ipotecar",
      btn_2: "Solicitare specială",
    },
    Product_images: {
      btn_open_gallary: "Deschideți galeria",
    },
    Product_details: {
      building_company: "Constructor",
      responsible_agent: "Agent responsabil",
      btn_contact: "Contactați pentru detalii",
      btn_see_presentation: "Descărcați prezentarea",
      highlights: "Avantajele obiectului",
      about_product: "Descriere",
    },
    OtherProducts: {
      block_title: "Vezi alte obiecte:",
    },
  },
  real_estate_single_gallary: {
    btn_go_back_to_details: "Înapoi la detalii",
    btn_go_back: "Înapoi",
  },
  modals_real_estate: {
    contact: {
      title: "Contactați un expert pentru consultanță",
      subtitle:
        "Nu pierdeți mult timp! Un specialist imobiliar va lua legătura cu dvs. și va răspunde la toate întrebările dvs.",
      input_1_label: "Număr de telefon",
      input_2_label: "Nume și Prenume",
      text_reCAPTCHA:
        "Site-ul nostru este protejat de reCAPTCHA și respectă Politica de confidențialitate și Condițiile de utilizare Google.",
      btn_request: "Consultanță gratuită",
      text_terms: {
        part_1: "Apăsând acest buton, sunteți de acord automat cu",
        part_2: "termenii și condițiile site-ului.",
      },
    },
    see_presentation_pdf: {
      title: "Descărcați prezentarea despre acest obiect",
      subtitle:
        "Mai multe informații despre planul de proprietate, locație și altele în această prezentare.",
      input_1_label: "Număr de telefon",
      input_2_label: "Nume și Prenume",
      text_reCAPTCHA:
        "Site-ul nostru este protejat de reCAPTCHA și respectă Politica de confidențialitate și Condițiile de utilizare Google.",
      btn_request: "Descărcați catalogul",
      btn_download: "Gata de descărcare",
      text_terms: {
        part_1: "Apăsând acest buton, sunteți de acord automat cu",
        part_2: "termenii și condițiile.",
      },
    },
  },
  about_page: {
    Text_animation_1: "Academia G Partners",
    Text_animation_2: "G Partners Hub",
    Text_animation_3: "Consultanță imobiliară",
    Parallax: {
      title: "Activitatea noastră",
      subtitle_right:
        "Servicii imobiliare complexe. Cu noi este usor si sigur.",
    },
    Learn_ihub_one: {
      title:
        "Ajutăm experții să-și crească nivelul de competență și să învețe perspectivele pieței.",
      subtitle_text_right:
        "Învățați informații de clasă înaltă de la mentori cu o experiență de 10 ani pe piața imobiliară.",
      btn_learn: "Învățați în hub",
    },
    AllActionOne: {
      items: [
        {
          title: "Tot ce aveți nevoie",
          description:
            "Am creat condiții confortabile de învățare pentru ca voi să vă puteți concentra cât mai mult pe procesul de învățare. Sălile noastre de clasă sunt echipate cu echipamente de ultimă generație, inclusiv panouri smart 4K de înaltă calitate.",
        },
        {
          title: "Locație centrală",
          description:
            "Academia G Partners este situată în inima orașului, oferind acces ușor și confort pentru voi. Acest lucru vă permite să ajungeți rapid și convenabil la cursurile noastre fără a pierde timp în călătorii lungi. Sunt și locuri de parcare!",
        },
        {
          title: "Beneficiile cursului de inițiere",
          description:
            "Cunoștințe profunde în domeniu, abilități practice, recunoaștere profesională, networking și dezvoltare profesională continuă.",
        },
      ],
      btn: "Începeți să învățați",
    },
    Learn_ihub_two: {
      title:
        "Păstrați 100% din comision, fiți parte dintr-o comunitate de experți profesioniști, beneficiați de un birou modern într-o locație ultra-centrală și de locuri de parcare garantate.",
      subtitle_text_right:
        "Interacționați cu alți profesioniști din industrie, inclusiv agenți imobiliari colegi, brokeri, investitori, dezvoltatori și furnizori de servicii. Participarea la această rețea poate duce la recomandări potențiale, parteneriate și oportunități de colaborare, extinzând în cele din urmă baza de clienți a expertului individual și oportunitățile de afaceri.",
      btn_request: "Solicitați detalii despre hub",
    },
    AllActionTwo: {
      items: [
        {
          title: "Hub G Partners",
          description:
            "Oportunități unice de colaborare și dezvoltare continuă. Scopul nostru este să promovăm și să cultivăm o cultură a experților imobiliari încrezători și calificați din Republica Moldova.",
        },
        {
          title: "Serviciile noastre",
          description:
            "Spațiu de coworking; Cursuri continue de formare profesională; Evenimente și sesiuni de networking; Suport legal, informațional și documentar; Acces la baza de date a companiei și oferte exclusive.",
        },
        {
          title: "Beneficii",
          description:
            "100% din tranzacții; Utilizarea brandului companiei; Baza de date a clienților și partenerilor companiei; Spațiu de lucru modern, bine echipat, cu locație convenabilă; Pagină proprie de profil pe site-ul web.",
        },
      ],
      btn: "Alăturați-vă Hubului",
    },
    Learn_ihub_three: {
      title:
        "Suntem convinși că onestitatea este baza relațiilor pe termen lung.",
      subtitle_text_right:
        "Toate acțiunile cu noi sunt efectuate transparent și corect față de client. Experții noștri au un nivel înalt de cunoștințe și experiență, asigurând tranzacții de succes pentru achiziționarea, vânzarea, intermedierea și consultanța imobiliară. Ne asumăm responsabilitatea pentru fiecare tranzacție, căutând să asigurăm satisfacția maximă și siguranța pentru clienții noștri.",
      btn_request: "Solicitați acces la hub",
    },
    AllActionThree: {
      items: [
        {
          title: "Experiență și Expertiză",
          description:
            "Experiența vastă în domeniu facilitează stabilirea soluțiilor personalizate și eficiente pentru fiecare client.",
        },
        {
          title: "Portofoliu Diversificat",
          description:
            "Solicitările pentru apartamente, case de lux, terenuri și spații comerciale sunt ușor satisfăcute prin varietatea de oferte prezentate de companie.",
        },
        {
          title: "Transparență și Profesionalism",
          description:
            "Comunicarea deschisă și transparența asigură echilibrul și alegerea corectă și oportună pentru clienți.",
        },
      ],
      btn: "Contactați-ne",
    },
    Our_services: {
      title: "În prezent, oferim aceste servicii",
    },
    FAQs: {
      title: "Întrebări frecvente",
      items: {
        item1: {
          title:
            "De ce este mai bine și mai sigur să cumperi imobiliare prin intermediul unei agenții?",
          description:
            "Cumpărarea de imobiliare prin intermediul unei agenții are multe avantaje care contribuie la minimizarea riscurilor și optimizarea procesului de cumpărare: Securitate juridică, Consultanță profesională, Economie de timp, Suport în toate etapele tranzacției, Optimizare costuri, Protecție împotriva fraudelor, Selecție extinsă de proprietăți, Protecție juridică a tranzacției, Metode sigure de plată, Confidențialitate.",
        },
        item2: {
          title:
            "Ce documente sunt necesare pentru achiziționarea de imobiliare?",
          description:
            "Pentru achiziționarea de imobiliare sunt necesare următoarele documente: • Pașaportul cumpărătorului. • Consimțământul soțului/soției pentru achiziție: dacă achiziția se face în cadrul căsătoriei. • Contract preliminar de vânzare-cumpărare: cu termeni și perioade specificate pentru tranzacție. • Documente pentru imobil: extras care confirmă drepturile de proprietate ale vânzătorului și absența sarcinilor. • Documente de confirmare a plății: chitanțe sau extrase bancare care confirmă transferul de bani către vânzător.",
        },
        item3: {
          title: "Care sunt etapele principale ale vânzării de imobile?",
          description:
            "Etaplele principale ale vânzării de imobile includ: 1. Pregătirea documentelor: colectarea tuturor documentelor necesare proprietății. 2. Evaluarea imobilului: determinarea valorii de piață a proprietății. 3. Găsirea unui cumpărător și prezentarea imobilului: plasarea anunțurilor și organizarea vizionărilor. 4. Semnarea unui contract preliminar de vânzare-cumpărare: acord cu depunerea unui avans de către cumpărător. 5. Semnarea contractului principal de vânzare-cumpărare: autentificarea tranzacției și transferul banilor.",
        },
        item4: {
          title:
            "Cum să alegi un dezvoltator de încredere în momentul achiziției de imobile noi?",
          description:
            "În momentul alegerii unui dezvoltator, este important să acorzi atenție următoarelor aspecte: 1. Experiență și reputație: studiază istoricul companiei, numărul de proiecte finalizate și feedback-ul clienților. 2. Documente și permise: verifică disponibilitatea tuturor autorizațiilor de construcție necesare și documentele pentru teren. 3. Stabilitate financiară: evaluează poziția financiară a dezvoltatorului, inclusiv împrumuturile și obligațiile. 4. Ritmul construcției: observă cât de bine respectă dezvoltatorul termenele și calitatea lucrărilor la proprietățile deja construite. 5. Termenii contractului: examinează cu atenție contractul, inclusiv termenele de livrare, obligațiile de garanție și posibilele penalități pentru întârzieri.",
        },
        item5: {
          title:
            "Ce este un avans la achiziționarea de imobiliare și cum să-l formalizezi corect?",
          description:
            "Un avans este o sumă de bani transferată de cumpărător către vânzător ca confirmare a intenției de a cumpăra imobile. Este formalizat printr-un acord separat sau specificat într-un contract preliminar de vânzare-cumpărare. Avansul servește ca o garanție a executării tranzacției: dacă cumpărătorul renunță la achiziție, avansul rămâne la vânzător; dacă vânzătorul renunță, trebuie să returneze avansul în dublu exemplar. Este important ca acordul să specifice clar condițiile de restituire a avansului și circumstanțele în care acesta nu este returnat.",
        },
      },
    },
  },
  partners_page: {
    Parallax: {
      title: "Cunoașteți echipa noastră",
      subtitle_right:
        "Aflați mai multe despre echipa noastră de agenți imobiliari și parteneri.",
    },
    general_partners: {
      title: "Parteneri Generale",
      content: {
        part_1:
          "La G Partners, angajamentul nostru constant pentru inovație ne ajută să rămânem pe prima linie pieței imobiliare. Cu două decenii de experiență în industrie, am dobândit competențe în gestionarea proprietăților, serviciul pentru clienți și construcții.",
        part_2:
          "Am jucat un rol semnificativ în crearea identităților distinctive ale proprietăților, transformarea spațiilor și dezvoltarea de soluții remarcabile pentru numeroase proiecte imobiliare în Moldova și alte țări.",
        part_3: " ",
      },
    },
    our_team: {
      subtitle_text: "Cunoașteți echipa noastră",
      title_text: "Experți în imobiliare",
    },
  },
  learn_page: {
    Parallax: {
      title: "Învață cu noi",
      subtitle_right:
        "Pe baza experienței noastre, am dezvoltat propria noastră academie pentru ca tu să poți deveni expert în imobiliare.",
    },
    about_the_project: {
      text_before_title: "Despre ce este vorba?",
      text_title: "Noi a dezvoltat cel mai practic curs",
      text_after_title: "pentru a deveni expert în imobiliare în Moldova.",
      block_1: {
        about_project_title: "Despre proiect.",
        text: {
          part1:
            "Anumite tendințe în piața imobiliară din Moldova ne-au determinat să creăm o nouă direcție în compania noastră - o academie de formare în domeniul imobiliar.",
          part2:
            "Materialul de formare a fost dezvoltat de noi pe o perioadă lungă de timp și își propune să transmită 10 ani de experiență în afacerile imobiliare, dinamica pieței în Republica Moldova, metodele de comunicare cu clienții și principiile de operare.",
        },
      },
      block_2: {
        you_will_master_title: "Vei studia",
        blocks: [
          {
            title: "Caracteristicile pieței imobiliare",
            text: "Învățați să distingeți între categorii, tipuri, intervale de preț și factori ai proprietăților imobiliare.",
          },
          {
            title: "Cunoștințe aprofundate în domeniu",
            text: "Aspecte esențiale ale pieței imobiliare; Baza legislativă a domeniului imobiliar; Finanțare și Credite; Cadastru și infomatiie cadastrală.",
          },
          {
            title: "Abilități Practice",
            text: "Instruire practica prin studii de caz și simulări; Abilități de comunicare și interacțiune cu clienții; Tehnici de negociere; Pregătirea de tranzacții.",
          },
          {
            title: "Recunoaștere Profesională",
            text: "Angajarea în cadrul companiei imobiliare “G Partners”; Recunoaștere la nivel Național și Internațional.",
          },
          {
            title: "Networking",
            text: "Interacțiune cu alți profesioniști și formatori din domeniul imobiliar; Comunitate Profesională și Receptivă.",
          },
          {
            title: "Dezvoltare Profesională continuă",
            text: "Cursurile sunt actualizate constant pentru a reflecta cele mai recente tendințe și evoluții din sectorul imobiliar.",
          },
        ],
        btns: {
          smart_board: "Tablă Smart 4K",
          mini_groups: "Grupuri mici",
          graduated_students: "+500 de studenți absolvenți",
          opportunity_to_work: "Oportunitate de a lucra cu noi",
        },
      },
    },
    material_schedule: {
      title_text: "Programul materialului de învățare",
      text_right: {
        title: "Program",
        subtitle:
          "Toate informațiile despre cursurile academiei vor fi structurate și colectate în blocuri pentru a facilita memorarea și coerența. Familiarizați-vă cu blocurile principale.",
      },
      week_1: {
        week_text_number: "Modul 01",
        topic_name: "Domeniul impbiliar si rolul expertului.",
      },
      week_2: {
        week_text_number: "Modul 02",
        topic_name:
          "Analiza si evaluarea pietii, tipurile de imobile, formarea pretului.",
      },
      week_3: {
        week_text_number: "Modul 03",
        topic_name: "Categoriile de beneficiari domeniului imobiliar.",
      },
      week_4: {
        week_text_number: "Modul 04",
        topic_name: "Tipurile de credit.",
      },
      week_5: {
        week_text_number: "Modul 05",
        topic_name: "Baza legislative domeniului imobiliar.",
      },
      week_6: {
        week_text_number: "Modul 06",
        topic_name:
          "Instrumente si strategiile de lucru, planul de activitatea expertului imobiliar.",
      },
    },
    our_mission: {
      text_subtitle: "ce înseamnă să faci parte din G Partners",

      text_title: "Misiunea noastră principală",

      text_content_part_1_bold: "Imobiliare în Moldova și în lume.",

      text_content_part_2:
        "La G Partners, pasiunea noastră neclintită pentru inovație alimentează căutarea noastră constantă de a rămâne în fruntea tendințelor imobiliare. Cu o experiență colectivă de peste două decenii în industrie, ne-am perfecționat un set diversificat de abilități în dezvoltarea imobiliară, design și servicii pentru clienți.",

      text_content_part_3_bold:
        "Competențe în construcții și reparații în Moldova.",

      text_content_part_4:
        "Am jucat un rol cheie în crearea de proprietăți, spații unice și în dezvoltarea de soluții pentru proiecte imobiliare în Moldova.",

      text_content_part_5: "Investiți cu G Partners.",
    },
  },
  contact_page: {
    Parallax: {
      title: "Contactați-ne",
    },
    get_in_touch: {
      title: "Hai să ne auzim",
      text_right:
        "Suntem online pentru a fi în legătură cu dumneavoastră între 9 dimineața și 20 seara în zona GMT+3.",
      btn_text: "Contactați-ne",
    },
    map_location: {
      title_text: "Locația sediului central",
      text_right: "Bulevardul Ștefan cel Mare și Sfînt 6, MD-2012, Chișinău",
    },
  },
  single_partner: {
    about_title: "",
    all_partners_btn: "Toți partenerii",
    g_partners_agent: "Partener",
    responsible_for: "Obiectele partenerului",
    contact_btn: " Contactați pentru detalii",
  },
};
