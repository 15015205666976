import axios from "axios";
import { axiosInstance, urlAPI } from "./config";

export default {
  state: {
    Product: {},
    AllProducts: [],
    TOPProducts: [],
    RealEstateByAgent: [],
  },
  mutations: {
    SetProduct(state, payload) {
      state.Product = payload;
    },
    SetAllProducts(state, payload) {
      state.AllProducts = payload;
    },
    SetTOPProducts(state, payload) {
      state.TOPProducts = payload;
    },
    SetRealEstateByAgent(state, payload) {
      state.RealEstateByAgent = payload;
    },
  },
  actions: {
    async LoadProduct({ commit }, payload) {
      if (!payload) {
        commit("SetProduct", {});
        return;
      }

      return await axiosInstance
        .get(
          urlAPI +
            `/property/${payload.type ? payload.type + "/" : ""}${
              payload.value
            }`
        )
        .then(function (data) {
          commit("SetProduct", data?.data?.data);
          return data?.data?.data;
        })
        .catch(function (error) {
          commit("SetProduct", {});
          return {
            error: error.response.data.message,
          };
        });
    },
    async LoadAllProducts({ commit }, payload) {
      let categ_id = payload ? "/category/" + payload : "";
      let responce = await axios.get(urlAPI + "/property" + categ_id);

      if (responce.data.success) {
        commit("SetAllProducts", responce?.data?.data);
      }
    },
    async LoadTOPProducts({ commit }) {
      let responce = await axios.get(urlAPI + "/property/pin");

      if (responce.data.success) {
        commit("SetTOPProducts", responce?.data?.data);
      }
    },
    async LoadRealEstateByAgent({ commit }, payload) {
      let AllAgents = await axios.get(urlAPI + "/agent");

      let agent = AllAgents.data.data.filter((a) => {
        return a[`name_ro`] == payload;
      });

      let agent_id = "/agent/" + agent[0].ID;
      let responce = await axios.get(urlAPI + "/property" + agent_id);
      if (responce.data.success) {
        commit("SetRealEstateByAgent", responce?.data?.data);
      }
    },
  },
  getters: {
    getProduct: (state) => state.Product,
    getAllProducts: (state) => state.AllProducts,
    getTOPProducts: (state) => state.TOPProducts,
    getRealEstateByAgent: (state) => state.RealEstateByAgent,
  },
};
