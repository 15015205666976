<template>
  <div class="py-4 py-md-12 px-0">
    <v-container fluid class="container-main px-0">
      <p class="first-slide">
        <span>&nbsp;-&nbsp;{{ text }}&nbsp;-&nbsp;{{ text }}</span>
      </p>
      <p class="words words--first">
        <span>&nbsp;-&nbsp;{{ text }}&nbsp;-&nbsp;{{ text }}</span>
      </p>
      <p class="words words--second">
        <span>&nbsp;-&nbsp;{{ text }}&nbsp;-&nbsp;{{ text }}</span>
      </p>
    </v-container>
  </div>
</template>

<script>
import { useLocale } from "vuetify";

export default {
  name: "TextAnimation",
  setup() {
    const { t } = useLocale();
    return {
      t,
    };
  },
  props: {
    text: String
  },
  computed: {
    height_image_paralax() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return "100vh";
        case "lg":
          return "100vh";
        case "xl":
          return "100vh";
        default:
          return "100vh";
      }
    },
  },
};
</script>

<style scoped>
.first-slide {
  font-size: 17rem;
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
}

.first-slide span {
  display: inline-block;
  animation-name: slide-then-hide;
  animation-duration: 20s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  color: rgba(63, 63, 63, 1);
}

.words {
  font-size: 17rem;
  margin: 0 auto;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
}

.words span {
  display: inline-block;
  padding-left: 100%;
  animation-name: slide;
  animation-duration: 40s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  color: rgba(63, 63, 63, 1);
  font-size: 17rem;
}

.words--second span {
  animation-delay: 20s;
  color: rgba(63, 63, 63, 1);

}

@keyframes slide-then-hide {
  0% {
    transform: translate(0, 0);
  }

  100% {
    display: none;
    transform: translate(-100%, 0);
  }
}

@keyframes slide {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-100%, 0);
  }
}

.container-main {
  justify-content: center;
  display: flex;
  overflow: hidden;
  width: 100%;
  max-width: 100% !important;
  height: 383px;
}


@media screen and (max-width: 600px) {
  .words {
    font-size: 5.5rem;
  }

  .words span {
    font-size: 5.5rem;
  }

  .first-slide {
    font-size: 5.5rem;
  }

  .container-main {
    height: 130px;
  }


}

@media screen and (min-width: 600px) and (max-width: 960px) {
  .words {
    font-size: 10rem;
  }

  .words span {
    font-size: 10rem;
  }

  .first-slide {
    font-size: 10rem;
  }

  .container-main {
    height: 250px;
  }

}
</style>
