<template>
    <v-dialog v-model="dialog" min-width="65%" width="auto" class="PDFdialog">
        <v-card class="PDFModal pa-2 py-sm-8 pa-sm-10">
            <v-btn icon class="btn-close-dialog" color="#F6F2E6" size="x-large" @click="dialog = !dialog">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-row :justify="$vuetify.display.smAndDown ? 'center' : 'space-between'" class="ma-0">
                <v-col cols="12" sm="6" lg="5" align-self="center">
                    <h2 class="modal-title">
                        {{ t("$vuetify.modals_real_estate.contact.title") }}
                    </h2>
                    <p class="modal-text mt-2">
                        {{ t("$vuetify.modals_real_estate.contact.subtitle") }}
                    </p>
                    <v-form v-model="valid" ref="form">

                        <v-text-field :label="t('$vuetify.modals_real_estate.contact.input_1_label')"
                            variant="underlined" class="inputs-data-contact mt-1" base-color="#9A9A9B" v-model="phone"
                            :rules="phoneRules"></v-text-field>
                        <v-text-field :label="t('$vuetify.modals_real_estate.contact.input_2_label')"
                            variant="underlined" class="inputs-data-contact mt-1" base-color="#9A9A9B" v-model="name"
                            :rules="nameRules"></v-text-field>
                        <p class="modal-text-reCAPTCHA mt-3">
                            {{ t("$vuetify.modals_real_estate.contact.text_reCAPTCHA") }}
                        </p>
                        <template v-if="$vuetify.display.smAndUp">
                            <v-btn block class="btn-download mt-5" :disabled="!valid" size="large" color="#F6F2E6"
                                @click="RequestConsult()">
                                {{ t("$vuetify.modals_real_estate.contact.btn_request") }}
                            </v-btn>
                            <p class="modal-text-terms mt-2">
                                {{ t("$vuetify.modals_real_estate.contact.text_terms.part_1") }}
                                <a @click="GoToTerms()" class="link-terms">
                                    {{ t("$vuetify.modals_real_estate.contact.text_terms.part_2") }}
                                </a>
                            </p>
                        </template>
                    </v-form>
                </v-col>
                <v-col cols="7" sm="5" md="5" lg="5" xl="4" align-self="center">
                    <v-img width="100%" src="../../assets/img/real_estate/modalContactUs.png" lazy-src="../../assets/img/real_estate/lazy/modalContactUs.png"></v-img>
                </v-col>
                <v-col cols="12" v-if="$vuetify.display.xs">
                    <v-btn block class="btn-download mt-5" :disabled="!valid" size="large" color="#F6F2E6"
                        @click="RequestConsult()">
                        Request free consultation
                    </v-btn>
                    <p class="modal-text-terms mt-2">By clicking this button you are automatically agree with our
                        <a href="#" class="link-terms">terms and conditions.
                        </a>
                    </p>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>
</template>
<script>
import { useLocale } from "vuetify";
import { load } from 'recaptcha-v3'

export default {
    name: "PdfPresentation",
    setup() {
        const { t } = useLocale();
        return {
            t,
        };
    },
    data() {
        return {
            valid: false,
            dialog: false,
            property: {},
            agent: {},
            services: {},
            phone: "",
            name: "",
            phoneRules: [
                v => !!v || 'Phone is required',
                v => v.length >= 7 || 'Name must be > 8 characters',
                (value) => {
                    const regex = /^[\\+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,6}$/;
                    if (regex.test(value)) {
                        return true;
                    }
                    return 'Phone number is invalid';
                }
            ],
            nameRules: [
                v => !!v || 'Name is required',
                v => v.length >= 3 || 'Name must be > 3 characters',
            ],
        }
    },
    methods: {
        async RequestConsult() {
            this.$refs.form.validate();
            if (this.valid) {
                load('6LeROM8pAAAAAH4GSY5Sw0ZQ8nv0eWWxtcmFDi9M', {
                    autoHideBadge: true,
                }).then((recaptcha) => {
                    recaptcha.execute('login').then(async (token) => {
                        if (token) {
                            this.dialog = false;
                            let data_lead = {
                                name: this.name,
                                phone: this.phone
                            }
                            let create_lead = await this.$store.dispatch('create_lead', data_lead);

                            this.name = "";
                            this.phone = "";

                            let data_lead_origin = {
                                ID_lead: create_lead.ID,
                                language: this.$vuetify.locale.current,
                                url: this.$route.fullPath,
                            }

                            if (this.property.ID) {
                                data_lead_origin.ID_property = this.property.ID;
                            } else if (this.agent.ID) {
                                data_lead_origin.ID_agent = this.agent.ID;
                            } else if (this.services.title) {
                                data_lead_origin.source_name = this.services.title;
                            }
                            await this.$store.dispatch('create_lead_origin', data_lead_origin);
                        }
                    })
                })
            }
        },
        GoToTerms() {
            this.$router.push({
                name: 'legal',
                params: {
                    lang: this.$vuetify.locale.current
                }
            })
        }
    }
}
</script>

<style scoped>
.link-terms {
    color: #404040;
}

.link-terms:hover {
    cursor: pointer;
    color: #5f5f5f;
}

.modal-text-terms {
    color: #404040;
    font-size: 0.6rem;
    text-align: center;
}

.btn-download {
    text-transform: inherit;
    color: #111213;
    border-radius: 41px;
    font-weight: 400;
    letter-spacing: normal;
    height: 38px;
}

.modal-text-reCAPTCHA {
    color: rgba(154, 154, 155, 1);
    font-size: 0.7rem;
}

.btn-close-dialog {
    position: absolute;
    right: 0%;
    top: 0%;
    transform: translate(40%, -40%);
}

.modal-text {
    color: rgba(154, 154, 155, 1);
    font-size: 1rem;
    letter-spacing: -0.01rem;
}

.modal-title {
    font-size: 2rem;
    line-height: 2.8rem;
    letter-spacing: -0.02rem;
    background: -webkit-linear-gradient(348deg, rgba(246, 242, 230, 1), rgba(144, 142, 135, 1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 400;
    text-shadow: 0 4px 50px rgba(0, 0, 0, 0.7);
}

.PDFModal {
    min-height: 65vh;
    overflow: visible !important;
    background: linear-gradient(30deg, rgba(17, 18, 19, 1) 50%, rgb(47, 51, 54) 150%);
}

@media screen and (max-width: 600px) {
    .modal-title {
        text-align: center;
        font-size: 1.8rem;
        line-height: 2.5rem;
    }

    .modal-text {
        text-align: center;
        font-size: .9rem;
    }

    .modal-text-reCAPTCHA {
        font-size: 0.6rem;
        text-align: center;
    }

}
</style>
<style>
.PDFdialog .v-overlay__scrim {
    opacity: .52 !important;
}

.inputs-data-contact .v-label {
    color: #9A9A9B !important;
}

.inputs-data-contact .v-input__prepend {
    color: #F6F2E6 !important;
}

.inputs-data-contact .v-field__input {
    color: #F6F2E6 !important;
}

.inputs-data-contact .v-field__outline::after {
    color: #949495 !important;
}
</style>