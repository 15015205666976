import { axiosInstance } from "../config";

export default {
  state: {
    token: window.localStorage.getItem("token"),
    role: "",
    priority: "",
  },
  mutations: {
    SetToken(state, payload) {
      state.token = payload;
    },
    SetRole(state, payload) {
      state.role = payload;
    },
    SetPriority(state, payload) {
      state.priority = payload;
    },
  },
  actions: {
    async AdminCkeck({ commit }) {
      if (!window.localStorage.getItem("token")) return;

      let data = await axiosInstance.get("/admin/role").catch(function () {
        return;
      });
      if (
        data?.data?.success == true &&
        data?.data?.data?.access_level_priority
      ) {
        commit("SetRole", data?.data?.data.access_level_name);
        commit("SetPriority", data?.data?.data.access_level_priority);
        return true;
      } else {
        return;
      }
    },
    async Logout() {
      return await axiosInstance
        .post("/admin/logout")
        .then(function (data) {
          return data?.data;
        })
        .catch(function (error) {
          return {
            error: error.response.data.message,
          };
        });
    },
    async LogoutTerminate() {
      return await axiosInstance
        .post("/admin/terminate")
        .then(function (data) {
          return data?.data;
        })
        .catch(function (error) {
          return {
            error: error.response.data.message,
          };
        });
    },
    async SignIn({ commit }, payload) {
      let body = {
        username: payload.username,
        password: payload.password,
      };
      /* , {
          headers: {
            "X-XSRF-TOKEN":
              "eyJpdiI6Im4xd0l5WWo1SVZlNmw4aWh1K2ZacUE9PSIsInZhbHVlIjoiSG1EcFhva1YvUGg2QU5UYjVqc041Mi8rZFlVSmxMUlhrM1JvVjJzWVgxUXI5Y0dLdFQ3ZTZheFhhd01HYXM5T1pMSkR4R282RHZEMWZoZHk3dHdPVlE3cDNHVkV6Wk5EaVdzaytqZjRJSzAwLzgvQldZRzVWdFY5dHBXdXlqdUMiLCJtYWMiOiI2MWJjZDc0N2Y3M2Q5MzU2ZGZlMWY0ZTBkOGUzOTFmMzlhMThlZWE4NjQ4MDMxZWEzNTliNDJlNjlhMzViNTRmIiwidGFnIjoiIn0%3D",
          },
        } */
      let data = await axiosInstance
        .post("/admin/login", body)
        .catch(function (error) {
          return {
            data: {
              data: {
                error: error.response.data.message,
              },
            },
          };
        });
      if (data?.data?.success == true) {
        window.localStorage.setItem("token", data?.data?.data.token);
        commit("SetToken", data?.data?.data.token);
        commit("SetRole", data?.data?.data.role);
        commit("SetPriority", data?.data?.data.priority);
        return {
          success: true,
        };
      } else {
        return data?.data?.data;
      }
    },
  },
  getters: {
    getToken: (state) => state.token,
    getRole: (state) => state.role,
    getPriority: (state) => state.priority,
  },
};
