<template>
  <v-container :fluid="$vuetify.display.mdAndDown ? true : false">
    <v-row justify="center">
      <v-col cols="11" md="11" lg="10" style="z-index: 1;">
        <v-row class="">
          <v-col cols="12" sm="4" lg="3" class="px-sm-0">
            <h5 class="title-about">
              {{ t("$vuetify.learn_page.about_the_project.block_2.you_will_master_title") }}
            </h5>
          </v-col>
          <v-col cols="12" sm="8" lg="9" class="px-0">
            <v-row justify="center">
              <v-col cols="12" sm="6" class="cols-master">
                <v-card color="#0C0C0C" class="pa-5 card-master">
                  <svg width="22" height="22" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1198_2108)">
                      <path
                        d="M11.2907 0.0636614C11.1065 -0.0212205 10.8935 -0.0212205 10.7093 0.0636614L0.396768 4.81366C0.148382 4.92807 -0.00741818 5.17717 0.000272242 5.44761C0.00796262 5.71804 0.177674 5.95813 0.43217 6.05861L10.7447 10.13C10.9086 10.1947 11.0914 10.1947 11.2553 10.13L19.25 6.9737V14.9286C18.4906 14.9286 17.875 15.5362 17.875 16.2857V19H22V16.2857C22 15.5362 21.3844 14.9286 20.625 14.9286V6.43084L21.5678 6.05861C21.8223 5.95813 21.992 5.71804 21.9997 5.44761C22.0074 5.17717 21.8516 4.92807 21.6032 4.81366L11.2907 0.0636614Z"
                        fill="#F6F2E6" />
                      <path
                        d="M5.7414 9.5432C5.56255 9.47701 5.36385 9.48723 5.19295 9.57144C5.02204 9.65564 4.89433 9.80623 4.84044 9.9871L4.15294 12.2942C4.05289 12.63 4.22795 12.9858 4.55717 13.1157L10.7447 15.5586C10.9086 15.6233 11.0914 15.6233 11.2553 15.5586L17.4428 13.1157C17.772 12.9858 17.9471 12.63 17.8471 12.2942L17.1596 9.9871C17.1057 9.80623 16.978 9.65564 16.8071 9.57144C16.6361 9.48723 16.4375 9.47701 16.2586 9.5432L11 11.4896L5.7414 9.5432Z"
                        fill="#F6F2E6" />
                    </g>
                    <defs>
                      <clipPath id="clip0_1198_2108">
                        <rect width="22" height="19" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>

                  <v-card-title class="card-master-title px-0">
                    {{ t("$vuetify.learn_page.about_the_project.block_2.blocks[0].title") }}
                  </v-card-title>
                  <v-card-text class="card-master-text pa-0 mt-2 mt-sm-4">
                    {{ t("$vuetify.learn_page.about_the_project.block_2.blocks[0].text") }}
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" class="cols-master">
                <v-card color="#0C0C0C" class="pa-5 card-master">
                  <svg width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M8 0L10.3394 1.18841L12.9443 1.57561L14.1247 3.88569L16 5.70061L15.5705 8.25L16 10.7994L14.1247 12.6143L12.9443 14.9244L10.3394 15.3116L8 16.5L5.66058 15.3116L3.05573 14.9244L1.87531 12.6143L0 10.7994L0.429472 8.25L0 5.70061L1.87531 3.88569L3.05573 1.57561L5.66058 1.18841L8 0Z"
                      fill="#F6F2E6" />
                    <path
                      d="M2.3922 16.2165V22L8 20.625L13.6078 22V16.2165L10.7785 16.637L7.99999 18.0485L5.22146 16.637L2.3922 16.2165Z"
                      fill="#F6F2E6" />
                  </svg>


                  <v-card-title class="card-master-title px-0">
                    {{ t("$vuetify.learn_page.about_the_project.block_2.blocks[1].title") }}
                  </v-card-title>
                  <v-card-text class="card-master-text pa-0 mt-2 mt-sm-4">
                    {{ t("$vuetify.learn_page.about_the_project.block_2.blocks[1].text") }}
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" class="cols-master">
                <v-card color="#0C0C0C" class="pa-5 card-master">
                  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22ZM9.62499 8.9375C9.62499 10.0766 9.00938 11 8.24999 11C7.4906 11 6.87499 10.0766 6.87499 8.9375C6.87499 7.79841 7.4906 6.875 8.24999 6.875C9.00938 6.875 9.62499 7.79841 9.62499 8.9375ZM5.89158 13.1549C6.22025 12.9648 6.64081 13.0771 6.83093 13.4058C7.66407 14.846 9.21959 15.8125 11 15.8125C12.7805 15.8125 14.336 14.846 15.1691 13.4058C15.3592 13.0771 15.7798 12.9648 16.1085 13.1549C16.4371 13.345 16.5494 13.7656 16.3593 14.0942C15.2905 15.9419 13.2911 17.1875 11 17.1875C8.70891 17.1875 6.70953 15.9419 5.64072 14.0942C5.4506 13.7656 5.56291 13.345 5.89158 13.1549ZM13.75 11C12.9906 11 12.375 10.0766 12.375 8.9375C12.375 7.79841 12.9906 6.875 13.75 6.875C14.5094 6.875 15.125 7.79841 15.125 8.9375C15.125 10.0766 14.5094 11 13.75 11Z"
                      fill="#F6F2E6" />
                  </svg>



                  <v-card-title class="card-master-title px-0">
                    {{ t("$vuetify.learn_page.about_the_project.block_2.blocks[2].title") }}
                  </v-card-title>
                  <v-card-text class="card-master-text pa-0 mt-2 mt-sm-4">
                    {{ t("$vuetify.learn_page.about_the_project.block_2.blocks[2].text") }}
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" class="cols-master">
                <v-card color="#0C0C0C" class="pa-5 card-master">
                  <svg width="21" height="23" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.25 0C4.00736 0 3 1.02974 3 2.3V3.06667H9V2.3C9 1.02975 7.99264 0 6.75 0H5.25Z"
                      fill="#F6F2E6" />
                    <path
                      d="M9 4.6V6.13333H12V4.6H18V5.95235C18 6.24274 18.1605 6.50821 18.4146 6.63808L19.7562 7.3238C20.5185 7.7134 21 8.50981 21 9.38099V18.4H12V16.1C12 15.8967 12.079 15.7017 12.2197 15.5579L13.5 14.2491V13.0333C13.5 12.6099 13.1642 12.2667 12.75 12.2667H8.25C7.83579 12.2667 7.5 12.6099 7.5 13.0333V14.2491L8.78033 15.5579C8.92098 15.7017 9 15.8967 9 16.1V18.4H0V9.38099C0 8.50981 0.481505 7.7134 1.24377 7.3238L2.58541 6.63808C2.8395 6.50821 3 6.24274 3 5.95235V4.6H9Z"
                      fill="#F6F2E6" />
                    <path d="M0 19.9333V20.7C0 21.9703 1.00736 23 2.25 23H6.75C7.99264 23 9 21.9703 9 20.7V19.9333H0Z"
                      fill="#F6F2E6" />
                    <path
                      d="M12 19.9333V20.7C12 21.9703 13.0074 23 14.25 23H18.75C19.9926 23 21 21.9703 21 20.7V19.9333H12Z"
                      fill="#F6F2E6" />
                    <path d="M18 3.06667H12V2.3C12 1.02974 13.0074 0 14.25 0H15.75C16.9926 0 18 1.02975 18 2.3V3.06667Z"
                      fill="#F6F2E6" />
                  </svg>


                  <v-card-title class="card-master-title px-0">
                    {{ t("$vuetify.learn_page.about_the_project.block_2.blocks[3].title") }}
                  </v-card-title>
                  <v-card-text class="card-master-text pa-0 mt-2 mt-sm-4">
                    {{ t("$vuetify.learn_page.about_the_project.block_2.blocks[3].text") }}
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" class="cols-master">
                <v-card color="#0C0C0C" class="pa-5 card-master">
                  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22ZM9.62499 8.9375C9.62499 10.0766 9.00938 11 8.24999 11C7.4906 11 6.87499 10.0766 6.87499 8.9375C6.87499 7.79841 7.4906 6.875 8.24999 6.875C9.00938 6.875 9.62499 7.79841 9.62499 8.9375ZM5.89158 13.1549C6.22025 12.9648 6.64081 13.0771 6.83093 13.4058C7.66407 14.846 9.21959 15.8125 11 15.8125C12.7805 15.8125 14.336 14.846 15.1691 13.4058C15.3592 13.0771 15.7798 12.9648 16.1085 13.1549C16.4371 13.345 16.5494 13.7656 16.3593 14.0942C15.2905 15.9419 13.2911 17.1875 11 17.1875C8.70891 17.1875 6.70953 15.9419 5.64072 14.0942C5.4506 13.7656 5.56291 13.345 5.89158 13.1549ZM13.75 11C12.9906 11 12.375 10.0766 12.375 8.9375C12.375 7.79841 12.9906 6.875 13.75 6.875C14.5094 6.875 15.125 7.79841 15.125 8.9375C15.125 10.0766 14.5094 11 13.75 11Z"
                      fill="#F6F2E6" />
                  </svg>

                  <v-card-title class="card-master-title px-0">
                    {{ t("$vuetify.learn_page.about_the_project.block_2.blocks[4].title") }}
                  </v-card-title>
                  <v-card-text class="card-master-text pa-0 mt-2 mt-sm-4">
                    {{ t("$vuetify.learn_page.about_the_project.block_2.blocks[4].text") }}
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" class="cols-master">
                <v-card color="#0C0C0C" class="pa-5 card-master">
                  <svg width="21" height="23" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.25 0C4.00736 0 3 1.02974 3 2.3V3.06667H9V2.3C9 1.02975 7.99264 0 6.75 0H5.25Z"
                      fill="#F6F2E6" />
                    <path
                      d="M9 4.6V6.13333H12V4.6H18V5.95235C18 6.24274 18.1605 6.50821 18.4146 6.63808L19.7562 7.3238C20.5185 7.7134 21 8.50981 21 9.38099V18.4H12V16.1C12 15.8967 12.079 15.7017 12.2197 15.5579L13.5 14.2491V13.0333C13.5 12.6099 13.1642 12.2667 12.75 12.2667H8.25C7.83579 12.2667 7.5 12.6099 7.5 13.0333V14.2491L8.78033 15.5579C8.92098 15.7017 9 15.8967 9 16.1V18.4H0V9.38099C0 8.50981 0.481505 7.7134 1.24377 7.3238L2.58541 6.63808C2.8395 6.50821 3 6.24274 3 5.95235V4.6H9Z"
                      fill="#F6F2E6" />
                    <path d="M0 19.9333V20.7C0 21.9703 1.00736 23 2.25 23H6.75C7.99264 23 9 21.9703 9 20.7V19.9333H0Z"
                      fill="#F6F2E6" />
                    <path
                      d="M12 19.9333V20.7C12 21.9703 13.0074 23 14.25 23H18.75C19.9926 23 21 21.9703 21 20.7V19.9333H12Z"
                      fill="#F6F2E6" />
                    <path d="M18 3.06667H12V2.3C12 1.02974 13.0074 0 14.25 0H15.75C16.9926 0 18 1.02975 18 2.3V3.06667Z"
                      fill="#F6F2E6" />
                  </svg>


                  <v-card-title class="card-master-title px-0">
                    {{ t("$vuetify.learn_page.about_the_project.block_2.blocks[5].title") }}
                  </v-card-title>
                  <v-card-text class="card-master-text pa-0 mt-2 mt-sm-4">
                    {{ t("$vuetify.learn_page.about_the_project.block_2.blocks[5].text") }}
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-divider class="divider-bottom mb-8 mt-15" color="info"></v-divider>

        <v-row justify="space-around" class="btns-row">

          <v-btn variant="outlined" class="btn-row-1 rounded-pill px-5 order-2 order-sm-1" size="large">
            {{ t("$vuetify.learn_page.about_the_project.block_2.btns.smart_board") }}
          </v-btn>

          <v-btn variant="flat" class="btn-row-2 rounded-pill order-4 order-sm-2" icon="mdi-star">
          </v-btn>

          <v-btn variant="flat" class="btn-row-3 rounded-pill px-5 order-5 order-sm-3" size="large">
            {{ t("$vuetify.learn_page.about_the_project.block_2.btns.mini_groups") }}
          </v-btn>

          <v-btn variant="flat" class="btn-row-4 rounded-pill order-7 order-sm-4" icon="mdi-account-multiple">
          </v-btn>

          <v-btn variant="flat" class="btn-row-5 rounded-pill px-5 order-3 order-sm-5" size="large">
            {{ t("$vuetify.learn_page.about_the_project.block_2.btns.graduated_students") }}
          </v-btn>

          <v-btn variant="outlined" class="btn-row-6 rounded-pill order-1 order-sm-6" icon="mdi-briefcase">
          </v-btn>

          <v-btn variant="outlined" class="btn-row-7 rounded-pill px-5 order-6 order-sm-7" size="large">
            {{ t("$vuetify.learn_page.about_the_project.block_2.btns.opportunity_to_work") }}
          </v-btn>

        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useLocale } from "vuetify";

export default {
  name: "YouMaster",
  setup() {
    const { t } = useLocale();
    return {
      t,
    };
  },
  data: () => ({
  }),
  mounted() {
  },
  computed: {
  },
};
</script>

<style scoped>
.btns-row {
  row-gap: 15px;
  column-gap: 15px;
  flex-wrap: wrap;
}

.btns-row>button {
  min-height: 48px;
}

.btn-row-7 {
  color: #F6F2E6;
  text-transform: inherit;
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: -0.03rem;
  transition: 0.7s;
}

.btn-row-7:hover {
  color: #111213;
  background: #f6f2e6;
}

.btn-row-6 {
  color: #F6F2E6;
  text-transform: inherit;
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: -0.03rem;
  transition: 0.7s;
}

.btn-row-6:hover {
  color: #111213;
  background: #f6f2e6;
}

.btn-row-5 {
  color: #111213;
  background: #F6F2E6;
  text-transform: inherit;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: -0.03rem;
  transition: 0.7s;
}

.btn-row-5:hover {
  color: #F6F2E6;
  background: #111213;
}

.btn-row-4 {
  color: #F6F2E6;
  background: #000000;
  text-transform: inherit;
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: -0.03rem;
  transition: 0.7s;
}

.btn-row-4:hover {
  color: #111213;
  background: #f6f2e6;
}

.btn-row-3 {
  color: #F6F2E6;
  background: #000000;
  text-transform: inherit;
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: -0.03rem;
  transition: 0.7s;
}

.btn-row-3:hover {
  color: #111213;
  background: #f6f2e6;
}

.btn-row-2 {
  color: #111213;
  background: #F6F2E6;
  text-transform: inherit;
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: -0.03rem;
  transition: 0.7s;
}

.btn-row-2:hover {
  color: #F6F2E6;
  background: #111213;
}

.btn-row-1 {
  color: #F6F2E6;
  text-transform: inherit;
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: -0.03rem;
  transition: 0.7s;
}

.btn-row-1:hover {
  color: #111213;
  background: #f6f2e6;
}

.divider-bottom {
  border-width: 1px;
  border-color: rgba(154, 154, 155, 0.36);
  opacity: 1;
}

.cols-master {
  align-items: stretch;
  display: flex;
}

.card-master {
  border-top-left-radius: 40px;
}

.card-master-text {
  color: #9A9A9B;
  font-size: 1.2rem;
  line-height: 1.7rem;
  font-weight: 400;
  min-height: 96px;
}

.card-master-title {
  color: #F6F2E6;
  font-size: 2rem;
  font-weight: 400;
  letter-spacing: -0.02rem;
  text-wrap: balance;
  white-space: normal;
  line-height: 2.5rem;
}

.title-about {
  color: #F6F2E6;
  font-size: 2rem;
  letter-spacing: -0.02rem;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  z-index: 1;
}

@media screen and (max-width: 600px) {
  .card-master-text {
    font-size: 0.9rem;
    line-height: 1.45rem;
    min-height: 65px;
  }

  .card-master-title {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .btns-row>button {
    zoom: .7;
    font-size: 1.1rem;
  }

  .btns-row {
    column-gap: 2px;
  }
}

@media screen and (min-width: 600px) and (max-width: 1140px) {

  .card-master-text {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .card-master-title {
    font-size: 1.8rem;
    line-height: 2.3rem;
  }

  .btns-row>button {
    zoom: .8;
  }
}
</style>
