<template>
    <v-main id="sign_in_page">
        <v-navigation-drawer v-model="drawer" color="#111213" :rail="rail" permanent
            :class="!rail ? 'drawer' : 'drawer_rail'">
            <v-list-item>
                <v-btn :icon="rail ? 'mdi-chevron-right' : 'mdi-chevron-left'" variant="text" size="small"
                    @click.stop="rail = !rail"></v-btn>
            </v-list-item>
            <v-list-item>
                <v-img width="100%" max-height="55px"
                    :src="!rail ? require('../../assets/img/crm/logo_admin.png') : require('../../assets/img/crm/logo_xs.png')"></v-img>
            </v-list-item>

            <v-divider class="my-6"></v-divider>

            <v-list v-model="$route.name">
                <v-list-item v-if="getPriority != 3" :prepend-icon="rail ? 'mdi-account-supervisor-circle' : null" title="Лиды и клиенты"
                    value="leads" class="drawer-list-item" @click="OpenComponent('leads')" rounded="lg"
                    :active="$route.name == 'leads'"></v-list-item>
                <v-list-item v-if="getPriority < 4" :prepend-icon="rail ? 'mdi-home-group' : null" title="База недвижимости"
                    value="real-estate-crm" class="drawer-list-item" @click="OpenComponent('real-estate-crm')"
                    :active="$route.name == 'real-estate-crm'" rounded="lg"></v-list-item>
                <v-list-item v-if="getPriority < 4" :prepend-icon="rail ? 'mdi-apps' : null" title="Категории" value="categories-crm"
                    class="drawer-list-item" @click="OpenComponent('categories-crm')"
                    :active="$route.name == 'categories-crm'" rounded="lg"></v-list-item>
                <v-list-item v-if="getPriority < 4" :prepend-icon="rail ? 'mdi-account-box' : null" title="Агенты" value="agents"
                    class="drawer-list-item" @click="OpenComponent('agents-crm')" :active="$route.name == 'agents'"
                    rounded="lg"></v-list-item>
                <v-list-item v-if="getPriority == 1" :prepend-icon="rail ? 'mdi-cog' : null" title="Менеджмент"
                    value="settings" class="drawer-list-item" @click="OpenComponent('admin-settings-crm')"
                    :active="$route.name == 'settings'" rounded="lg"></v-list-item>
                <v-list-item v-if="getPriority <= 2" :prepend-icon="rail ? 'mdi-filter' : null" title="Параметры фильтров"
                    value="settings" class="drawer-list-item" @click="OpenComponent('filters-settings-crm')"
                    :active="$route.name == 'settings'" rounded="lg"></v-list-item>
                <v-list-item :prepend-icon="rail ? 'mdi-web' : null" title="Перейти на сайт" value="home"
                    class="drawer-list-item" href="/" target="_blank" :active="$route.name == 'index'"
                    rounded="lg"></v-list-item>
                <v-list-item id="activator-Logout" :prepend-icon="rail ? 'mdi-logout' : null" title="Выйти"
                    class="drawer-list-item" link :active="false"></v-list-item>
                <v-dialog activator="#activator-Logout" max-width="700">
                    <template v-slot:default="{ isActive }">
                        <v-card class="pa-6 py-md-10 text-center" rounded="lg">
                            <h2 class="mb-3">Вы уверены что хотите выйти?</h2>
                            <h4>Внимание: </h4>
                            <v-card-text class="pt-0">Это действие не подлежат возврату!</v-card-text>
                            <v-card-actions>
                                <v-row justify="center">
                                    <v-col cols="4">
                                        <v-btn color="#E05757" variant="flat" flat rounded="lg" block text="Выйти"
                                            @click="Logout()" class="btn-text"></v-btn>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-btn color="#E05757" variant="flat" flat rounded="lg" block
                                            text="Закрыть все сессии" @click="LogoutTerminate()"
                                            class="btn-text"></v-btn>
                                    </v-col>
                                    <v-col cols="4">
                                        <v-btn color="#C6C6C6" variant="flat" flat rounded="lg" block
                                            text="Вернуться назад" @click="isActive.value = false"
                                            class="btn-text"></v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-actions>
                            <v-card-text>
                                <i>
                                    при нажатии выйти, вы вернетесь на страницу входа в CRM
                                </i>
                            </v-card-text>
                        </v-card>
                    </template>
                </v-dialog>
            </v-list>
        </v-navigation-drawer>
        <router-view></router-view>
    </v-main>
</template>
<script>
import { defineComponent } from "vue";
import { mapGetters } from 'vuex'
import { useLocale } from "vuetify";

export default defineComponent({
    name: "DashboardPage",
    setup() {
        const { t } = useLocale();
        return {
            t,
        };
    },
    data: () => ({
        drawer: true,
        rail: false,
    }),
    computed: {
        ...mapGetters([
            'getPriority',
        ]),
    },
    methods: {
        OpenComponent(name) {
            this.$router.push({
                path: `/admin/${name}`,
                name: name
            })
        },
        async Logout() {
            let res = await this.$store.dispatch('Logout');
            if (res.success) {
                window.localStorage.setItem("token", undefined);
                window.location.reload();
            }
        },
        async LogoutTerminate() {
            let res = await this.$store.dispatch('LogoutTerminate');
            if (res.success) {
                window.localStorage.setItem("token", undefined);
                window.location.reload();
            }
        }
    },
    metaInfo() {
        return {
            link: [
                { rel: 'icon', href: "/favicon_admin.ico" }
            ]
        }
    },
})
</script>
<style scoped>
.drawer-list-item {
    border-radius: 10px;
    font-weight: 500;
}

.drawer {
    padding-top: 10vh;
    padding-left: 20px;
    padding-right: 20px;
}

.btn-text {
    text-transform: inherit;
}

.drawer_rail {
    padding-top: 10vh;
}

#sign_in_page {
    background-color: #F4F4F4;
}

@media screen and (max-width: 600px) {}
</style>