<template>
  <v-navigation-drawer v-model="drawer" temporary scrim="true" location="right" class="pt-4 px-3" color="#111213"
    mobile-breakpoint="xs" :width="$vuetify.display.xs ? $vuetify.display.width : drawer ? '400px' : ''">
    <v-list nav width="100%" size="compact">
      <v-list-item class="mb-0 pb-0">
        <template v-slot:append>
          <v-btn icon :class="drawer ? 'btn-close-drawer' : ''" color="#F6F2E6" size="x-large"
            v-if="$vuetify.display.smAndDown" @click.stop="drawer = !drawer">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-list-item>

      <v-list-subheader class="subheader">Navigation</v-list-subheader>

      <v-list-item>
        <v-btn variant="tonal" :color="$vuetify.locale.current == 'en' ? '#CC8702' : '#9A9A9B'
          " class="drawer-langs pa-1" @click="changeLocale('en')">
          <v-row justify="center" class="ma-0" style="padding-top: 4px;">
            EN
          </v-row>
        </v-btn>
        <v-btn variant="tonal" :color="$vuetify.locale.current == 'ro' ? '#CC8702' : '#9A9A9B'
          " class="drawer-langs pa-1 mx-2" @click="changeLocale('ro')">
          <v-row justify="center" class="ma-0" style="padding-top: 4px;">
            RO
          </v-row>
        </v-btn>
        <v-btn variant="tonal" :color="$vuetify.locale.current == 'ru' ? '#CC8702' : '#9A9A9B'
          " class="drawer-langs pa-1" @click="changeLocale('ru')">
          <v-row justify="center" class="ma-0" style="padding-top: 4px;">
            RU
          </v-row>
        </v-btn>
      </v-list-item>

      <div class="px-2">
        <v-divider class="mt-3 mb-8 px-2"></v-divider>
      </div>

      <v-list-item value="home" @click="$router.push(GetPath('home'))">
        <v-list-item-title class="drawer-list-items">
          {{ t("$vuetify.header.home") }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item value="real_estate" @click="$router.push(GetPath('real-estate'))">
        <v-list-item-title class="drawer-list-items">
          {{ t("$vuetify.header.real_estate") }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item value="about" @click="$router.push(GetPath('about'))">
        <v-list-item-title class="drawer-list-items">
          {{ t("$vuetify.header.about") }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item value="learn" @click="$router.push(GetPath('learn'))">
        <v-list-item-title class="drawer-list-items">
          {{ t("$vuetify.header.learn") }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item value="partners" @click="$router.push(GetPath('partners'))">
        <v-list-item-title class="drawer-list-items">
          {{ t("$vuetify.header.partners") }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item value="contact" @click="$router.push(GetPath('contact'))">
        <v-list-item-title class="drawer-list-items">
          {{ t("$vuetify.header.contact") }}
        </v-list-item-title>
      </v-list-item>

      <v-list-subheader class="subheader mt-3">Social Media</v-list-subheader>

      <div class="px-2">
        <v-divider class="mt-2 mb-4"></v-divider>
      </div>

      <v-row justify="space-between" class="ma-0 px-2">
        <v-btn variant="text" class="drawer-btn-links px-2" href="https://www.instagram.com/gpartnersco/" target="_blank">
          INSTAGRAM
        </v-btn>
        <v-btn variant="text" class="drawer-btn-links px-2" href="https://www.linkedin.com/company/gpartnersco/about/" target="_blank">
          Linkedin
        </v-btn>
        <v-btn variant="text" class="drawer-btn-links px-2" href="https://www.facebook.com/Gpartners.invest/" target="_blank">
          FACebook
        </v-btn>
        <v-btn variant="text" class="drawer-btn-links px-2" href="https://www.rabota.md/ru/companies/g-partners" target="_blank">
          Rabota.md
        </v-btn>
      </v-row>
    </v-list>
  </v-navigation-drawer>

  <v-app-bar class="appBarHeader" :elevation="0" scroll-behavior="elevate" extended height="100px" density="compact"
    extension-height="0px">
    <v-container class="py-1 px-2" :fluid="$vuetify.display.mdAndDown ? true : false">
      <v-row justify="space-between">
        <v-col cols="auto" sm="5" md="3" lg="4" xl="5" align-self="center">
          <v-img @click="$router.push(GetPath('home'))" min-width="180px" max-width="190px" class="mt-3 img-logo-big"
            src="../assets/img/header/logoText.png"></v-img>
        </v-col>
        <v-col cols="1" sm="2" md="1" align-self="center" class="pr-0" v-if="!$vuetify.display.xs">
          <v-img @click="$router.push(GetPath('home'))" absolute min-width="40px" max-width="40px" height="40px"
            src="../assets/img/header/logo.png" class="img-logo ml-auto"></v-img>
        </v-col>
        <v-col offset="0" offset-sm="3" offset-md="0" cols="auto" sm="auto" md="8" lg="7" xl="6" align-self="center">
          <div class="appbarBtns py-1 px-3">

            <v-btn text @click="$router.push(GetPath('home'))" class="px-3" v-if="$vuetify.display.mdAndUp"
              :class="$route.name == 'home' ? 'btn-appbar-active' : 'btn-appbar'">
              {{ t("$vuetify.header.home") }}
            </v-btn>
            <v-btn text @click="$router.push(GetPath('real-estate'))" class="px-3" v-if="$vuetify.display.mdAndUp"
              :class="$route.name == 'real-estate' || $route.name == 'property' || $route.name == 'property_gallary' ? 'btn-appbar-active' : 'btn-appbar'">
              {{ t("$vuetify.header.real_estate") }}
            </v-btn>
            <v-btn text @click="$router.push(GetPath('about'))" class="px-3" v-if="$vuetify.display.mdAndUp"
              :class="$route.name == 'about' ? 'btn-appbar-active' : 'btn-appbar'">
              {{ t("$vuetify.header.about") }}
            </v-btn>
            <v-btn text @click="$router.push(GetPath('learn'))" class="px-3" v-if="$vuetify.display.mdAndUp"
              :class="$route.name == 'learn' ? 'btn-appbar-active' : 'btn-appbar'">
              {{ t("$vuetify.header.learn") }}
            </v-btn>
            <v-btn text @click="$router.push(GetPath('partners'))" class="px-3" v-if="$vuetify.display.mdAndUp"
              :class="$route.name == 'partners' || $route.name == 'partner-single' ? 'btn-appbar-active' : 'btn-appbar'">
              {{ t("$vuetify.header.partners") }}
            </v-btn>
            <v-btn text @click="$router.push(GetPath('contact'))" class="px-3" v-if="$vuetify.display.mdAndUp"
              :class="$route.name == 'contact' ? 'btn-appbar-active' : 'btn-appbar'">
              {{ t("$vuetify.header.contact") }}
            </v-btn>
            <v-menu transition="scale-transition" location="bottom right" origin="auto" v-if="$vuetify.display.mdAndUp">
              <template v-slot:activator="{ props }">
                <v-btn class="btn-appbar btn-lang" slim v-bind="props">
                  {{ t("$vuetify.header.current_lang") }}
                </v-btn>
              </template>

              <v-list class="list-lang">
                <v-list-item density="compact" v-for="(item, i) in languages" :key="i" @click="changeLocale(item.code)"
                  class="pl-8 pr-12">
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn icon class="btn-appbar px-3" v-if="$vuetify.display.smAndDown" @click.stop="drawer = !drawer">
              <v-icon>mdi-menu</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
import { useLocale } from "vuetify";

export default {
  name: "HeaderComponent",
  setup() {
    const { t } = useLocale();
    return {
      t,
    };
  },
  data: () => ({
    drawer: null,
    languages: [
      { title: "English", code: "en" },
      { title: "Romănâ", code: "ro" },
      { title: "Русский", code: "ru" },
    ],

  }),
  methods: {
    GetPath(name) {
      switch (name) {
        case "home":
          return {
            name: 'home',
            params: { lang: this.$vuetify.locale.current }
          };
        case "real-estate":
          return {
            name: 'real-estate',
            params: {
              lang: this.$vuetify.locale.current,
              categorie: ""
            }
          };
        case "about":
          return {
            name: 'about',
            params: {
              lang: this.$vuetify.locale.current,
            }
          }
        case "learn":
          return {
            name: 'learn',
            params: {
              lang: this.$vuetify.locale.current,
            }
          };
        case "partners":
          return {
            name: 'partners',
            params: {
              lang: this.$vuetify.locale.current,
            }
          };
        case "contact":
          return {
            name: 'contact',
            params: {
              lang: this.$vuetify.locale.current,
            }
          }
        default:
          return {
            name: 'home',
            params: { lang: this.$vuetify.locale.current }
          };
      }
    },
    changeLocale(lang) {
      this.drawer = false;
      this.$vuetify.locale.current = lang;
      localStorage.setItem("language", lang.toLowerCase());
      this.$router.push({ params: { lang: lang } })
      /* if(this.$router.currentRoute.value.name.includes("real-estate"))
      this.$router.push({
        name: this.$router.currentRoute.value.name,
        params: {
          lang: lang,
          //path: this.$vuetify.locale.t(`$vuetify.route.${this.$router.currentRoute.value.name}`)
        }
      }); */
    },
  },
};
</script>
<style scoped>
.drawer-btn-links {
  color: #c4c4c4;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.005rem;
}

.drawer-item-consult {
  background: rgba(47, 47, 47, 0.43);
  font-size: 20px;
  color: #9a9a9b;
  text-transform: inherit;
  border-radius: 9px;
  font-weight: 400;
  letter-spacing: -0.01rem;
}

.drawer-list-items {
  color: #f6f2e6;
  font-size: 40px;
  line-height: 50px;
  font-weight: 400;
  letter-spacing: -0.01rem;
}

.drawer-langs {
  min-width: 50px;
  background: rgba(47, 47, 47, 0.43);
  border-radius: 9px;
  font-size: 19px;
  font-weight: 400;
  letter-spacing: -0.008rem;
}

.subheader {
  color: #373737;
  font-size: 12px;
  text-transform: uppercase;
  min-height: 25px;
}

/* .drawer-class {
  animation-name: drawer_anim;
  animation-duration: 1s;
  animation-direction: alternate;
  transform: translateX(0%) matrix(1, 0, 0, 1, 0, 0) skew(0deg, 0deg);
}
@keyframes drawer_anim {
  from {
    transform: translateX(100%) matrix(1, 0, -0.2, 1.2, 0, 0) skew(24deg, 0deg);
  }
  to {
    transform: translateX(0%) matrix(1, 0, 0, 1, 0, 0) skew(0deg, 0deg);
  }
} */
.btn-close-drawer {
  /* animation-name: Btn_close_drawer_anim;
  animation-duration: 1.1s;
  animation-direction: normal;
  animation-timing-function: linear;
  animation-fill-mode: forwards; */
  /* animation: Btn_close_drawer_anim 1.1s ease-in-out 0s normal forwards;
  -o-animation: Btn_close_drawer_anim 1.1s ease-in-out 0s normal forwards;
  -ms-animation: Btn_close_drawer_anim 1.1s ease-in-out 0s normal forwards;
  -moz-animation: Btn_close_drawer_anim 1.1s ease-in-out 0s normal forwards;
  -webkit-animation: Btn_close_drawer_anim 1.1s ease-in-out 0s normal forwards;
  transform: rotate(-360deg) scale(0.4); */
}

/* @keyframes Btn_close_drawer_anim {
  from {
    transform: rotate(-360deg) scale(0.4);
  }

  to {
    transform: rotate(0deg) scale(1);
  }
} */

.block-right {
  max-width: 50%;
}

.btn-consult {
  background: #2f2f2f54;
  border-radius: 9px;
}

.list-lang {
  background: #1717179f !important;
  border-radius: 15px !important;
  color: #f6f2e6;
  font: 15px;
  backdrop-filter: blur(7px);
}

.btn-lang {
  min-width: 45px;
  background: #2f2f2f54;
  padding: 2px !important;
  border-radius: 9px;
}

.appBarHeader {
  max-height: 100px;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
}

.appbarBtns {
  background: #1717179f;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  backdrop-filter: blur(7px);
  align-items: center;
}

.btn-appbar {
  text-transform: inherit;
  color: #9a9a9b;
  font-weight: 400;
  letter-spacing: 0px;
  /*font-size: 15px;*/
  font-size: 1rem;
}

.btn-appbar-active {
  text-transform: inherit;
  color: #9a9a9b;
  background-color: rgba(47, 47, 47, 0.43);
  font-weight: 400;
  letter-spacing: 0px;
  border-radius: 9px;
  /*font-size: 15px;*/
  font-size: 1rem;
}

.img-logo {
  margin-right: auto;
  transition: .3s;
}

.img-logo:hover {
  cursor: pointer;
  filter: brightness(4);
}

.img-logo-big {
  transition: .3s;
}

.img-logo-big:hover {
  filter: brightness(4);
  cursor: pointer;
}


@media screen and (max-width: 600px) {
  .appBarHeader {
    background: linear-gradient(rgba(17, 18, 19, 1), rgba(0, 0, 0, 0));
  }
}
</style>