<template>
  <headerTop />
  <cookiesModal />
  <getConsultation />
  <v-main>
    <CategoriesParallax :Categories="getAllCategories" />
    <ProductsPage :Categories="getAllCategories" :Products="getAllProducts.filter(p => !p.is_archived)" class="mt-15" />
    <MaibCalculator id="MaibCalculator" class="mt-200" />
    <GetInTouch id="GetInTouch" class="mt-200" />
    <realEstateWorld class="mt-200" />
  </v-main>
  <footerComponent />
  <Preloader :is-loading="isLoading" />
</template>

<script>
import headerTop from "../components/header.vue";
import cookiesModal from "../components/cookiesModal.vue";
import Preloader from "../components/Preloader.vue";
import getConsultation from "../components/getConsultation.vue";
import footerComponent from "../components/footer.vue";
import CategoriesParallax from "../components/RealEstatePage/1.Categories.vue";
import ProductsPage from "../components/RealEstatePage/2.Products.vue";
import MaibCalculator from "../components/RealEstatePage/3.MaibCalculator.vue";
import GetInTouch from "../components/RealEstatePage/4.GetInTouch.vue";
import realEstateWorld from "../components/realEstateWorld.vue";
import { defineComponent } from "vue";
import { mapGetters } from 'vuex'
import { useLocale } from "vuetify";

export default defineComponent({
  name: "RealEstate",
  components: {
    headerTop,
    Preloader,
    cookiesModal,
    getConsultation,
    CategoriesParallax,
    ProductsPage,
    MaibCalculator,
    GetInTouch,
    realEstateWorld,
    footerComponent,
  },
  data: () => ({
    isLoading: true,
  }),
  metaInfo() {
    if (this.$route.params.categorie && this.getCategorie) {
      if (!this.getCategorie.ID) return {}
      if (!this.getCategorie[`meta_title_${this.$vuetify.locale.current}`] || !this.getCategorie[`meta_description_${this.$vuetify.locale.current}`]) return {}
      return {
        htmlAttrs: {
          lang: this.$vuetify.locale.current,
        },
        title: this.getCategorie[`meta_title_${this.$vuetify.locale.current}`],
        description: this.getCategorie[`meta_description_${this.$vuetify.locale.current}`],
        meta: [
          { vmid: 'description', name: 'description', content: this.getCategorie[`meta_description_${this.$vuetify.locale.current}`] }
        ]
      }
    } else {
      return {}
    }
  },
  async mounted() {
    await this.$store.dispatch('LoadAllCategories');
    if (this.$route.params.categorie) {
      await this.$store.dispatch('LoadCategorieLink_name', this.$route.params.categorie);
      /* let category = this.getAllCategories.filter((c) => {
        return c[`link_${this.$vuetify.locale.current}`] == this.$route.params.categorie;
      })
      await this.$store.dispatch('LoadAllProducts', category[0] ? category[0].ID : null); */
      await this.$store.dispatch('LoadAllProducts', this.getCategorie.ID ?? null);
      
    } else {
      await this.$store.dispatch('LoadAllProducts');
    }

    let vue = this;
    if (document.readyState == "complete") {
      vue.isLoading = false;
    } else {
      window.addEventListener("load", () => {
        vue.isLoading = false;
      });
    }
  },
  async updated() {
    if (this.$route.params.categorie) {
      await this.$store.dispatch('LoadCategorieLink_name', this.$route.params.categorie);
      await this.$store.dispatch('LoadAllProducts', this.getCategorie.ID ?? null);

      /* let category = this.getAllCategories.filter((c) => {
        return c[`link_${this.$vuetify.locale.current}`] == this.$route.params.categorie;
      })
      await this.$store.dispatch('LoadAllProducts', category[0] ? category[0].ID : null); */
    } else {
      await this.$store.dispatch('LoadAllProducts');
    }
  },
  setup() {
    const { t } = useLocale();
    return {
      t,
    };
  },
  computed: {
    ...mapGetters([
      'getAllCategories',
      'getAllProducts',
      'getCategorie',
    ])
  },
});
</script>

<style scoped>
.mt-200 {
  margin-top: 200px;
}

.mt-100 {
  margin-top: 100px;
}

@media screen and (max-width: 768px) {
  .mt-200 {
    margin-top: 100px;
  }
}
</style>