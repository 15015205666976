<template>
  <v-menu activator="#menu-activator" class="menu-block">
    <v-list class="menu-list">
      <v-list-subheader class="list-subheader">
        {{ t("$vuetify.getConsultation.name") }}
        <svg class="ml-2" width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="4" cy="4.5" r="4" fill="#19D442" />
        </svg>
      </v-list-subheader>
      <v-list-item class="menu-list-item px-6" v-for="item in items" :key="item.title" :href="item.href" target="_blank">
        <template v-slot:prepend>
          <v-icon>
            <svg :width="item.icon.width" :viewBox="item.icon.viewBox" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path :d="item.icon.d" fill="#F6F2E6" />
            </svg>
          </v-icon>
        </template>
        <v-list-item-title class="list-title">
          {{ t(`$vuetify.${item.title}`) }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
  <v-card id="menu-activator" class="py-1 pl-4 pl-sm-8 pr-2 pr-sm-3">
    <v-card-text class="menu-title py-0 py-sm-1">
      {{ t("$vuetify.getConsultation.btn_title") }}
    </v-card-text>
    <v-img class="card-img" cover :width="$vuetify.display.xs ? '35px' : '45px'"
      src="../assets/img/icons/getConsult.jpg"></v-img>
  </v-card>
</template>

<script>
import { useLocale } from "vuetify";

export default {
  name: "GetConsultation",
  setup() {
    const { t } = useLocale();
    return {
      t,
    };
  },
  data: () => ({
    loaded: false,
    items: [
      {
        href: "https://t.me/gpartnersco",
        icon: {
          d: "M16 8.5C16 12.9183 12.4183 16.5 8 16.5C3.58172 16.5 0 12.9183 0 8.5C0 4.08172 3.58172 0.5 8 0.5C12.4183 0.5 16 4.08172 16 8.5ZM8.28672 6.40599C7.50861 6.72963 5.95346 7.3995 3.6213 8.41558C3.2426 8.56618 3.04421 8.71351 3.02615 8.85757C2.99563 9.10103 3.30052 9.1969 3.71569 9.32745C3.77216 9.3452 3.83068 9.3636 3.89067 9.3831C4.29913 9.51588 4.84859 9.67121 5.13423 9.67738C5.39334 9.68298 5.68253 9.57616 6.0018 9.35692C8.18081 7.88603 9.30563 7.14258 9.37625 7.12655C9.42607 7.11524 9.49511 7.10102 9.54188 7.1426C9.58866 7.18418 9.58406 7.26291 9.57911 7.28404C9.5489 7.4128 8.35212 8.52543 7.73279 9.10121C7.53971 9.28071 7.40276 9.40804 7.37476 9.43712C7.31204 9.50226 7.24812 9.56388 7.18669 9.6231C6.80722 9.98891 6.52265 10.2632 7.20245 10.7112C7.52913 10.9265 7.79055 11.1045 8.05134 11.2821C8.33616 11.4761 8.62023 11.6695 8.98778 11.9105C9.08142 11.9718 9.17086 12.0356 9.25796 12.0977C9.58942 12.334 9.88721 12.5463 10.2551 12.5124C10.4689 12.4928 10.6897 12.2918 10.8018 11.6923C11.0668 10.2755 11.5878 7.20567 11.7082 5.94069C11.7187 5.82986 11.7055 5.68802 11.6948 5.62576C11.6842 5.56349 11.6619 5.47478 11.581 5.40911C11.4851 5.33133 11.3371 5.31493 11.2709 5.3161C10.9701 5.3214 10.5084 5.48192 8.28672 6.40599Z",
          width: 16,
          viewBox: "0 0 16 17"

        },
        title: "getConsultation.list_item_telegram"
      },
      {
        href: "https://wa.me/message/E4HQMMGFCACOB1",
        icon: {
          d: "M13.6013 2.82644C12.1074 1.3249 10.1154 0.5 7.99428 0.5C3.62651 0.5 0.0684247 4.05809 0.0646233 8.42587C0.0646233 9.82478 0.429555 11.1857 1.1214 12.3907L0 16.5L4.20432 15.3976C5.36373 16.0286 6.6676 16.3632 7.99428 16.3632H7.99808C12.3659 16.3632 15.9239 12.8051 15.9277 8.43348C15.9239 6.31611 15.099 4.32419 13.6013 2.82644ZM7.99428 15.0213C6.80825 15.0213 5.64884 14.7019 4.63767 14.1013L4.39819 13.9569L1.90449 14.6107L2.56973 12.1778L2.41387 11.9269C1.75243 10.8778 1.40651 9.66512 1.40651 8.42207C1.40651 4.79556 4.36397 1.83809 7.99808 1.83809C9.75812 1.83809 11.4117 2.52613 12.6586 3.76919C13.9016 5.01604 14.5859 6.66964 14.5859 8.42968C14.5821 12.0676 11.6246 15.0213 7.99428 15.0213ZM11.6094 10.0871C11.4117 9.98824 10.4386 9.50927 10.2561 9.44084C10.0736 9.37622 9.94059 9.34201 9.81134 9.53968C9.67829 9.73735 9.29815 10.1859 9.18411 10.3152C9.07007 10.4482 8.95223 10.4634 8.75456 10.3646C8.55689 10.2657 7.91826 10.0567 7.16178 9.38002C6.57257 8.85543 6.17723 8.20539 6.05938 8.00772C5.94534 7.81005 6.04798 7.70361 6.14682 7.60478C6.23425 7.51734 6.34449 7.37289 6.44332 7.25885C6.54216 7.14481 6.57637 7.06118 6.64099 6.92813C6.70562 6.79508 6.67521 6.68104 6.62579 6.58221C6.57637 6.48337 6.18103 5.50642 6.01377 5.11107C5.85411 4.72333 5.69065 4.77655 5.56901 4.77275C5.45497 4.76515 5.32192 4.76515 5.18887 4.76515C5.05582 4.76515 4.84295 4.81456 4.66048 5.01224C4.47801 5.20991 3.96863 5.68888 3.96863 6.66584C3.96863 7.64279 4.67949 8.58173 4.77832 8.71478C4.87716 8.84783 6.17343 10.8474 8.16154 11.7065C8.63291 11.9117 9.00165 12.0334 9.29055 12.1246C9.76572 12.2767 10.1953 12.2539 10.5374 12.2044C10.9175 12.1474 11.7082 11.7255 11.8755 11.2617C12.0389 10.7979 12.0389 10.4026 11.9895 10.319C11.9401 10.2353 11.8071 10.1859 11.6094 10.0871Z",
          width: 16,
          viewBox: "0 0 16 17"

        },
        title: "getConsultation.list_item_whatts"
      },
      {
        href: "tel:+37378338338",
        icon: {
          d: "M0 2.5C0 1.39543 0.895431 0.5 2 0.5H8C9.10457 0.5 10 1.39543 10 2.5V14.5C10 15.6046 9.10457 16.5 8 16.5H2C0.89543 16.5 0 15.6046 0 14.5V2.5ZM6 13.5C6 12.9477 5.55228 12.5 5 12.5C4.44772 12.5 4 12.9477 4 13.5C4 14.0523 4.44772 14.5 5 14.5C5.55228 14.5 6 14.0523 6 13.5Z",
          width: 10,
          viewBox: "0 0 10 17"
        },
        title: "getConsultation.list_item_phone"
      },
    ]
  }),
};
</script>
<style scoped>
.list-subheader {
  color: #646464;
  font-size: 1.1rem;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  padding-left: 24px !important;
}

.menu-list-item {
  min-height: 42px;
}

.menu-list-item:hover {
  background-color: rgba(246, 242, 230, 0.1);
}

.list-title {
  color: #F6F2E6;
  font-weight: 500;
  font-size: 1.1rem;
}

.menu-list {
  background: #070707 !important;
  border-top-left-radius: 35px !important;
  border-top-right-radius: 35px !important;
  border: 1px solid rgba(154, 154, 155, 0.7);
  padding-bottom: 28px;
  bottom: -28px;
}

.card-img {
  border-radius: 100%;
  border: 1px solid #19D442;
}

.menu-title {
  color: #F6F2E6;
  font-size: 1.1rem;
  letter-spacing: -0.02rem;
  font-weight: 400;
}

#menu-activator {
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 3000;
  background: rgba(0, 0, 0, 1);
  border: 1px solid rgba(154, 154, 155, 0.7);
  border-radius: 41px;
  display: flex;
  align-items: center;
  user-select: none;
  transition: .3s;
}

#menu-activator:hover {
  background: #F6F2E6;
  cursor: pointer;
}

#menu-activator:hover .menu-title {
  color: #111213;
}

@media screen and (max-width: 600px) {
  .menu-title {
    font-size: 1.0rem;
  }
}
@media screen and (min-width: 600px) and (max-width: 960px) {}
</style>