<template>
  <v-fade-transition>
    <div class="preloader" v-if="loading">
      <h3 class="text-h3">{{ selected }}</h3>
      <div style="opacity: 0; width: 0px;">
        مرحبًا
      </div>
    </div>
  </v-fade-transition>
</template>

<script>
export default {
  name: "PreloaderPage",
  props: {
    isLoading: Boolean,
  },
  data: () => ({
    words: ["Hello", "Привет", "Salut", "مرحبًا", "Привіт"],
    selected: "",
    lap: false,
  }),
  mounted() {
    const PreloaderFinish = new Event("PreloaderFinish");
    let vue = this;
    let i = 0;
    if (document.readyState == "complete") {
      this.lap = true;
      document.dispatchEvent(PreloaderFinish);
      return
    }
    let intervalP = setInterval(() => {
      vue.selected = vue.words[i];
      i++;
      if (i > vue.words.length) {
        this.lap = true;
        document.dispatchEvent(PreloaderFinish);
        clearInterval(intervalP);
        i = 0;
      }
    }, 330);
  },
  computed: {
    loading() {
      if (this.isLoading == true) {
        return true;
      } else if (this.lap == false) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(20, 21, 23, 0.9);
  backdrop-filter: blur(10px);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(246, 242, 230, 1);
}
</style>