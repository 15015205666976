<template>
  <v-container v-if="AllProducts.length > 0" class="container-main" :fluid="$vuetify.display.mdAndDown ? true : false">
    <v-row justify="center">
      <v-col cols="12" md="11" lg="10">
        <v-row justify="center">
          <span class="block-title">
            {{ t("$vuetify.real_estate_single.OtherProducts.block_title") }}
            {{ Categorie['name_' + $vuetify.locale.current] }}
          </span>
        </v-row>
        <v-row justify="center" class="mt-12">
          <v-col cols="6" sm="4" md="3" lg="3" v-for="(card, i) in AllProducts.slice(0, 4)" :key="i">
            <cardRealEstate :card="card" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import cardRealEstate from "../cardRealEstate.vue";
import { useLocale } from "vuetify";

export default {
  name: "OtherProducts",
  setup() {
    const { t } = useLocale();
    return {
      t,
    };
  },
  props: {
    Product: Object,
    Categorie: Object,
  },
  components: {
    cardRealEstate
  },
  updated() {
    this.$store.dispatch('LoadAllProducts', this.Product.ID_category);

  },
  computed: {
    AllProducts() {
      return this.$store.state.Product.AllProducts.filter(p => p['link_' + this.$vuetify.locale.current] != this.$route.params.propertyID && p.is_archived == 0 )
    },
  },
};
</script>

<style scoped>
.block-title {
  color: #9A9A9B;
  font-size: 1.1rem;
}

@media screen and (max-width: 600px) {}

@media screen and (min-width: 600px) and (max-width: 960px) {}
</style>