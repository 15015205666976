<template>
  <v-scroll-x-transition>
    <v-card class="modal-card" v-if="loaded && modal">
      <v-card-text class="modal-title">
        {{ t("$vuetify.cookieModal.title") }}
        <a href="#" class="title-href">
          {{ t("$vuetify.cookieModal.title_Cookie_Policy") }}
        </a>.
      </v-card-text>
      <v-card-actions>
        <v-btn @click="setCookie(false)" class="btn-reject px-12">
          {{ t("$vuetify.cookieModal.btn_reject") }}
        </v-btn>
        <v-btn @click="setCookie(true)" class="btn-accept px-12">
          {{ t("$vuetify.cookieModal.btn_accept") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-scroll-x-transition>
</template>

<script>
import { useLocale } from "vuetify";

export default {
  name: "cookiesModal",
  setup() {
    const { t } = useLocale();
    return {
      t,
    };
  },
  data: () => ({
    loaded: false,
    modal: !localStorage.getItem("Cookie") ? true : false,
  }),
  mounted() {
    let vue = this;
    document.addEventListener(
      "PreloaderFinish",
      () => {
        setTimeout(() => {
          vue.loaded = true;
        }, 3000);
      })
  },
  methods: {
    setCookie(value) {
      if (value == true) {
        localStorage.setItem("Cookie", "accept");
      } else {
        localStorage.setItem("Cookie", "reject");
      }
      this.modal = false;
    }
  },
};
</script>
<style scoped>
.btn-accept {
  background-color: #F6F2E6;
  border-radius: 41px;
  color: #111213;
  text-transform: inherit;
  letter-spacing: -0.01rem;
  font-size: 0.95rem;
}

.btn-reject {
  background-color: #030304;
  border-radius: 41px;
  color: #F6F2E6;
  text-transform: inherit;
  letter-spacing: -0.01rem;
  font-size: 0.95rem;
}

.title-href {
  color: #9A9A9B;
}

.modal-title {
  color: #9A9A9B;
  font-size: 1.1rem;
  line-height: 1.4rem;
  min-height: 95px;
  text-wrap: balance;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.modal-card {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 3;
  width: 400px;
  background-color: rgba(22, 22, 22, 0.78);
  backdrop-filter: blur(4px);
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.3);
  border-radius: 15px;
}

@media screen and (max-width: 600px) {
  .modal-card {
    display: none;
  }
}

@media screen and (min-width: 600px) and (max-width: 960px) {}
</style>