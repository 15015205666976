<template>
    <div style="width:100%;" :style="'height:' + height_image_paralax" id="paralax-image-learn">
        <v-container class="fill-height px-sm-10 px-md-15 container-main" style="align-content: center"
            :fluid="$vuetify.display.mdAndDown ? true : false">

            <v-row class="row-line mh-90" justify="start">
                <v-col cols="auto" align-self="end">
                    <v-expand-transition>
                        <h1 class="title-big" v-show="textAnim.showTextTitle">
                            Legal
                        </h1>
                    </v-expand-transition>
                </v-col>
            </v-row>

            <v-divider class="divider-partner my-4 my-sm-10 my-lg-13" color="info"></v-divider>

            <breadcrumbs name="legal" :Partner="Partner" />
        </v-container>
    </div>
</template>

<script>
import { useLocale } from "vuetify";
import breadcrumbs from "../breadcrumbs.vue";

export default {
    name: "LegalTop",
    components: {
        breadcrumbs
    },
    setup() {
        const { t } = useLocale();
        return {
            t,
        };
    },
    data: () => ({
        textAnim: {
            showTextTitle: false,
            showText: false,
        },
    }),
    mounted() {
        let vue = this;
        if (document.readyState == "complete") {
            setTimeout(() => {
                vue.textAnim.showTextTitle = true;
            }, 800);
            setTimeout(() => {
                vue.textAnim.showText = true;
            }, 1300);
        } else {
            document.addEventListener(
                "PreloaderFinish",
                () => {
                    setTimeout(() => {
                        vue.textAnim.showTextTitle = true;
                    }, 800);
                    setTimeout(() => {
                        vue.textAnim.showText = true;
                    }, 1300);
                },
                false
            );
        }
    },
    computed: {
        height_image_paralax() {
            switch (this.$vuetify.display.name) {
                case "xs":
                    return "auto";
                case "sm":
                    return "auto";
                case "md":
                    return "65vh";
                case "lg":
                    return "65vh";
                case "xl":
                    return "65vh";
                case "xxl":
                    return "50vh";
                default:
                    return "50vh";
            }
        },
    },
};
</script>

<style scoped>
.btn-allp {
    text-transform: inherit;
    letter-spacing: -0.02rem;
    font-weight: 400;
    font-size: 1.2rem;
}

.container-main {
    padding-top: 15vh;
}

.row-line {
    width: 100%;
}

.title-small2 {
    color: #c4c4c4;
    font-weight: 400;
    letter-spacing: -0.035rem !important;
}

.title-small {
    color: #c4c4c4;
    font-weight: 400;
    letter-spacing: -0.04rem !important;
    text-shadow: 8px 13px 35px rgba(0, 0, 0, 0.5);
}

.mh-90 {
    min-height: 69px;
}

.title-big {
    letter-spacing: -0.04rem !important;
    color: #f6f2e6;
    text-shadow: rgba(0, 0, 0, 0.87) 0px 4px 30px;
    font-size: 6rem;
    font-weight: 400;
    line-height: 6rem;
}

.divider-partner {
    border-width: 1px;
    opacity: 1;
    border-image-slice: 1;
    border-image-source: linear-gradient(to right, rgba(246, 242, 230, 0.36), rgba(255, 255, 255, 0));
}

@media screen and (max-width: 600px) {
    .title-big {
        font-size: 3rem;
        line-height: 3rem;
    }

    .title-small,
    .title-small2 {
        font-size: 0.85rem !important;
        line-height: 1.1rem !important;
    }

    .row-line {
        height: unset !important;
    }

    .btn-allp {
        font-size: 1rem;
    }

    .mh-90 {
        min-height: 55px;
    }

}

@media screen and (min-width: 600px) and (max-width: 960px) {
    .title-big {
        zoom: 0.8;
    }

    .title-small,
    .title-small2 {
        zoom: 0.8;
    }
}
</style>