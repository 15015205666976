<template>
  <headerTop />
  <cookiesModal />
  <getConsultation />
  <v-main>
    <PartnersParallax />
    <GeneralPartners class="mt-15" />
    <AllPartners class="mt-200" />
    <GetInTouch id="GetInTouch" class="mt-200" />
    <realEstateWorld class="mt-200" />
  </v-main>
  <footerComponent />
  <Preloader :is-loading="isLoading" />
</template>

<script>
import footerComponent from "../components/footer.vue";
import headerTop from "../components/header.vue";
import PartnersParallax from "../components/PartnersPage/1.PartnersParallax.vue";
import GeneralPartners from "../components/PartnersPage/2.GeneralPartners.vue";
import AllPartners from "../components/PartnersPage/3.AllPartners.vue";
import cookiesModal from "../components/cookiesModal.vue";
import Preloader from "../components/Preloader.vue";
import realEstateWorld from "../components/realEstateWorld.vue";
import GetInTouch from "../components/RealEstatePage/4.GetInTouch.vue";
import getConsultation from "../components/getConsultation.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "PartnersPage",
  components: {
    headerTop,
    cookiesModal,
    Preloader,
    getConsultation,
    realEstateWorld,
    PartnersParallax,
    GeneralPartners,
    AllPartners,
    GetInTouch,
    footerComponent,
  },
  data: () => ({
    isLoading: true,
  }),
  mounted() {
    let vue = this;
    if (document.readyState == "complete") {
      vue.isLoading = false;
    } else {
      window.addEventListener("load", () => {
        vue.isLoading = false;
      });
    }
  },
});
</script>
<style scoped>
.mt-200 {
  margin-top: 200px;
}

.mt-100 {
  margin-top: 100px;
}

@media screen and (max-width: 768px) {
  .mt-200 {
    margin-top: 80px;
  }
}
</style>