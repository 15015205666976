<template>
  <v-container :fluid="$vuetify.display.xlAndDown ? true : false" class="container-main">
    <v-row justify="center">
      <v-col cols="12" md="11">
        <v-row justify="center">
          <v-col cols="12" sm="8">
            <h2 class="block-title-text">
              {{ t("$vuetify.about_page.Learn_ihub_one.title") }}
            </h2>
            <v-row justify="start" class="ma-0">
              <v-btn @click="LearnIhub()" variant="outlined" class="btn-learn rounded-pill px-12 mt-6">
                {{ t("$vuetify.about_page.Learn_ihub_one.btn_learn") }}
              </v-btn>
            </v-row>
          </v-col>
          <v-col offset-md="1" cols="12" sm="3">
            <p class="block-subtitle">
              {{ t("$vuetify.about_page.Learn_ihub_one.subtitle_text_right") }}
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mt-12 row-img">
      <v-img width="100%" class="img-about" src="../../assets/img/about/2.jpg"></v-img>
    </v-row>
  </v-container>
</template>

<script>
import { useLocale } from "vuetify";

export default {
  name: "LearnIhubOne",
  setup() {
    const { t } = useLocale();
    return {
      t,
    };
  },
  data: () => ({

  }),
  mounted() {
  },
  methods: {
    LearnIhub() {
      console.log("LearnIhub");
    }
  }
};
</script>

<style scoped>
.img-about {
  filter: brightness(0.6);
}
.row-img {
  margin-left: -20px;
  margin-right: -20px;
}

.btn-learn {
  color: rgba(246, 242, 230, 1);
  text-transform: inherit;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: -0.03rem;
  font-style: italic;
  transition: 0.7s;
  align-items: center;
}

.btn-learn:hover {
  color: #111213;
  background: #f6f2e6;
}

.block-subtitle {
  color: rgba(154, 154, 155, 1);
  font-size: 1rem;
}

.block-title-text {
  color: rgba(154, 154, 155, 1);
  font-size: 3.5rem;
  line-height: 4.5rem;
  font-weight: 400;
}

@media screen and (max-width: 600px) {

  .btn-learn {
    font-size: 1rem;
  }

  .block-title-text {
    font-size: 2rem;
    line-height: 2.6rem;
  }


}

@media screen and (min-width: 600px) and (max-width: 960px) {
  .block-title-text {
    font-size: 2.7rem;
    line-height: 3.2rem;
  }
}

@media screen and (max-width: 2000px) {
  .container-main {
    max-width: 100% !important;
  }
}
</style>
