import axios from "axios";
import { urlAPI } from "./config";

export default {
  state: {
    Categorie: {},
    AllCategories: [],
  },
  mutations: {
    SetCategorie(state, payload) {
      state.Categorie = payload;
    },
    SetAllCategories(state, payload) {
      state.AllCategories = payload;
    },
  },
  actions: {
    async LoadCategorieEmpty({ commit }) {
      commit("SetCategorie", {});
    },
    async LoadCategorie({ commit }, payload) {
      let data = await axios.get(urlAPI + `/category/${payload}`);
      commit("SetCategorie", data?.data?.data);
    },
    async LoadCategorieLink_name({ commit }, payload) {
      let data = await axios.get(urlAPI + `/category/link_name/${payload}`);
      commit("SetCategorie", data?.data?.data);
    },
    async LoadAllCategories({ commit }) {
      let responce = await axios.get(urlAPI + "/category");
      /* console.log(responce.data.data); */
      commit("SetAllCategories", responce?.data?.data);
    },
  },
  getters: {
    getCategorie: (state) => state.Categorie,
    getAllCategories: (state) => state.AllCategories,
  },
};
