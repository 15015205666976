<template>
    <v-container fluid class="pt-10 fill-height" v-if="getPriority < 4">
        <v-row class="w-100" justify="center">
            <v-col cols="12">
                <h1>Категории объектов недвижимости</h1>
            </v-col>
        </v-row>
        <v-row justify="space-between" class="w-100">
            <v-col cols="auto">
                <v-btn color="#0255B7" rounded="lg" class="btn-text" @click="CategorieCreate()">
                    Добавить категорию
                </v-btn>
            </v-col>
            <v-col cols="auto" class="col-header-gap">
                <v-autocomplete v-model:search="search_value" :items="getCategoriesCRM" hide-no-data clearable="true"
                    item-title="name_ru" hide-details density="compact" rounded="lg" label="Поиск" variant="outlined"
                    class="input-search" color="#6C757D">
                    <template v-slot:item="{ props, item }">
                        <v-list-item class="search_autocomplete_item" v-bind="props" size="x-large"
                            @click="CategorieEdit(item.raw.ID)">
                            <template v-slot:prepend>
                                <v-avatar color="grey" rounded="0" :size="$vuetify.display.smAndUp ? '55' : '35'">
                                    <v-img :src="urlAPI + item.raw.image_path" cover></v-img>
                                </v-avatar>
                            </template>
                        </v-list-item>
                    </template>
                </v-autocomplete>
            </v-col>
        </v-row>
        <v-row class="my-4 mx-0 w-100" justify="center">
            <v-table :height="getCategoriesCRM.length < limit_view ? '65vh' : 'auto'" fixed-header class="table" hover>
                <thead>
                    <tr>
                        <th class="text-left">
                            Фото категории
                        </th>
                        <th class="text-left">
                            ID:
                        </th>
                        <th class="text-left">
                            Название на Ру
                        </th>
                        <th class="text-left">
                            Название на ENG
                        </th>
                        <th class="text-left">
                            Название на Rom
                        </th>
                        <th class="text-left">
                            Количество объектов
                        </th>
                        <th class="text-left">
                            Действия
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in getCategoriesCRM.slice((page_view - 1) * limit_view, page_view * limit_view)"
                        :key="item.ID">
                        <td>
                            <v-img width="80px" :src="urlAPI + item.image_path">
                            </v-img>
                        </td>
                        <td>{{ item.ID }}</td>
                        <td style="max-width: 200px;">{{ item.name_ru }}</td>
                        <td style="max-width: 200px;">{{ item.name_en }}</td>
                        <td style="max-width: 200px;">{{ item.name_ro }}</td>
                        <td>{{ item.property_count }}</td>
                        <td style="text-wrap: nowrap;">
                            <v-col cols="auto" style="flex-direction: column; display: flex;">
                                <v-icon @click="CategorieEdit(item.ID)">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M17.37 6.18658C17.5433 6.35987 17.5433 6.64084 17.37 6.81413L16.4445 7.73968L14.6695 5.9647L15.595 5.03915C15.7683 4.86586 16.0493 4.86586 16.2226 5.03915L17.37 6.18658Z"
                                            fill="#408ACB" />
                                        <path
                                            d="M15.8169 8.36723L14.042 6.59225L7.99548 12.6387C7.94677 12.6874 7.91007 12.7468 7.88828 12.8122L7.17424 14.9543C7.11642 15.1277 7.28144 15.2928 7.45489 15.2349L9.59701 14.5209C9.66236 14.4991 9.72175 14.4624 9.77046 14.4137L15.8169 8.36723Z"
                                            fill="#408ACB" />
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M4.5 16.4466C4.5 17.1818 5.09601 17.7778 5.83124 17.7778H15.5936C16.3289 17.7778 16.9249 17.1818 16.9249 16.4466V11.1216C16.9249 10.8765 16.7262 10.6779 16.4811 10.6779C16.2361 10.6779 16.0374 10.8765 16.0374 11.1216V16.4466C16.0374 16.6916 15.8387 16.8903 15.5936 16.8903H5.83124C5.58616 16.8903 5.38749 16.6916 5.38749 16.4466V6.68416C5.38749 6.43909 5.58616 6.24042 5.83124 6.24042H11.5999C11.845 6.24042 12.0437 6.04174 12.0437 5.79667C12.0437 5.5516 11.845 5.35293 11.5999 5.35293H5.83124C5.09601 5.35293 4.5 5.94894 4.5 6.68416V16.4466Z"
                                            fill="#408ACB" />
                                    </svg>
                                </v-icon>
                                <v-icon @click="CategorieInfo(item)">
                                    <svg width="12" height="13" viewBox="0 0 12 13" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M0 2.34351C0 1.51508 0.671573 0.843506 1.5 0.843506H10.5C11.3284 0.843506 12 1.51508 12 2.34351V11.3435C12 12.1719 11.3284 12.8435 10.5 12.8435H1.5C0.671573 12.8435 0 12.1719 0 11.3435V2.34351ZM6.69803 5.78442L4.97977 5.99976L4.91824 6.2854L5.25662 6.34692C5.47635 6.39966 5.52029 6.47876 5.47195 6.69849L4.91824 9.30005C4.77322 9.97241 4.99734 10.2888 5.52469 10.2888C5.93338 10.2888 6.40799 10.0999 6.62332 9.84058L6.68924 9.52856C6.53982 9.6604 6.3201 9.71313 6.17508 9.71313C5.96854 9.71313 5.89383 9.56812 5.94656 9.31323L6.69803 5.78442ZM6 4.96851C6.41421 4.96851 6.75 4.63272 6.75 4.21851C6.75 3.80429 6.41421 3.46851 6 3.46851C5.58579 3.46851 5.25 3.80429 5.25 4.21851C5.25 4.63272 5.58579 4.96851 6 4.96851Z"
                                            fill="#6C757D" />
                                    </svg>
                                </v-icon>
                                <v-icon @click="CategorieOpenDialogDelete(item)">
                                    <svg width="22" height="24" viewBox="0 0 22 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M5.5 5.85463C4.94772 5.85463 4.5 6.27793 4.5 6.80009V7.74554C4.5 8.2677 4.94772 8.691 5.5 8.691H6V17.2001C6 18.2444 6.89543 19.091 8 19.091H14C15.1046 19.091 16 18.2444 16 17.2001V8.691H16.5C17.0523 8.691 17.5 8.2677 17.5 7.74554V6.80009C17.5 6.27793 17.0523 5.85463 16.5 5.85463H13C13 5.33247 12.5523 4.90918 12 4.90918H10C9.44772 4.90918 9 5.33247 9 5.85463H5.5ZM8.5 9.63645C8.77614 9.63645 9 9.8481 9 10.1092V16.7274C9 16.9884 8.77614 17.2001 8.5 17.2001C8.22386 17.2001 8 16.9884 8 16.7274L8 10.1092C8 9.8481 8.22386 9.63645 8.5 9.63645ZM11 9.63645C11.2761 9.63645 11.5 9.8481 11.5 10.1092V16.7274C11.5 16.9884 11.2761 17.2001 11 17.2001C10.7239 17.2001 10.5 16.9884 10.5 16.7274V10.1092C10.5 9.8481 10.7239 9.63645 11 9.63645ZM14 10.1092V16.7274C14 16.9884 13.7761 17.2001 13.5 17.2001C13.2239 17.2001 13 16.9884 13 16.7274V10.1092C13 9.8481 13.2239 9.63645 13.5 9.63645C13.7761 9.63645 14 9.8481 14 10.1092Z"
                                            fill="#E05757" />
                                    </svg>
                                </v-icon>
                            </v-col>
                        </td>
                    </tr>
                </tbody>
            </v-table>
        </v-row>
        <v-row justify="space-between" class="w-100" v-if="getCategoriesCRM.length > limit_view">
            <v-col cols="auto">
                <v-btn color="#00000018" rounded="lg" elevation="0" class="btn-text" append-icon="mdi-reply"
                    @click="MinusPage()">
                    Предыдущая страница
                </v-btn>
            </v-col>
            <v-col cols="auto">
                <p> Страница: {{ page_view }}</p>
            </v-col>
            <v-col cols="auto">
                <v-btn color="#00000018" rounded="lg" elevation="0" class="btn-text" append-icon="mdi-share"
                    @click="PlusPage()">
                    Следующая страница
                </v-btn>
            </v-col>
        </v-row>
        <v-divider class="mt-4 mb-2 divider-bottom"></v-divider>
        <v-row class="my-0">
            <v-col cols="auto">
                <p>Всего категорий: {{ getCategoriesCRM.length }}</p>
            </v-col>
        </v-row>
        <v-dialog v-model="dialogInfoCategorie.state" max-width="700">
            <v-card class="pa-6 py-md-10 text-center" rounded="lg">
                <v-row justify="end">
                    <v-col cols="6" sm="4">
                        <v-btn color="#6C757D" flat variant="text" text="Закрыть" prepend-icon="mdi-close"
                            @click="dialogInfoCategorie.state = false"></v-btn>
                    </v-col>
                </v-row>
                <v-row justify="center" class="ma-0">
                    <v-col cols="4">
                        <v-img :src="urlAPI + getCategorie.image_path" rounded="lg"></v-img>
                    </v-col>
                </v-row>
                <v-row justify="center" class="mb-4">
                    <h2 class="mb-3">Категория №{{ getCategorie.ID }}</h2>
                </v-row>

                <v-text-field readonly v-model="getCategorie.name_ru" variant="outlined" density="compact"
                    label="Имя на русском" rounded="lg">
                </v-text-field>
                <v-text-field readonly v-model="getCategorie.name_ro" variant="outlined" density="compact"
                    label="Имя на румынском" rounded="lg">
                </v-text-field>
                <v-text-field readonly v-model="getCategorie.name_en" variant="outlined" density="compact"
                    label="Имя на английском" rounded="lg">
                </v-text-field>
                <v-text-field readonly v-model="getCategorie.alt" variant="outlined" density="compact" label="Image Alt"
                    rounded="lg">
                </v-text-field>
                <v-text-field readonly v-model="getCategorie.title" variant="outlined" density="compact"
                    label="Image Title" rounded="lg">
                </v-text-field>

                <v-checkbox readonly v-model="dialogInfoCategorie.categorie.building_type"
                    label="Тип объекта (Новострой, Вторичный)" density="compact" hide-details></v-checkbox>
                <v-checkbox readonly v-model="dialogInfoCategorie.categorie.building_purpose_type"
                    label="Тип предложения (аренда / покупка)" density="compact" hide-details></v-checkbox>
                <v-checkbox readonly v-model="dialogInfoCategorie.categorie.housing_condition_type"
                    label="Состояние объекта" hide-details density="compact"></v-checkbox>
                <v-checkbox readonly v-model="dialogInfoCategorie.categorie.industrial_purpose_type"
                    label="Индустриальное назначение" hide-details density="compact"></v-checkbox>
                <!-- <v-checkbox readonly v-model="dialogInfoCategorie.categorie.renovation_type" label="Тип ремонта"
                    hide-details density="compact"></v-checkbox> -->
                <v-checkbox readonly v-model="dialogInfoCategorie.categorie.land_type" label="Коммерческое назначение"
                    hide-details density="compact"></v-checkbox>
                <v-checkbox readonly v-model="dialogInfoCategorie.categorie.number_of_rooms" label="Количество комнат"
                    hide-details density="compact"></v-checkbox>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDeleteCategorie.state" max-width="700">
            <v-card class="pa-6 py-md-10 text-center" rounded="lg">
                <h2 class="mb-3">Вы уверены что хотите удалить категорию ?</h2>
                <v-row justify="center" class="ma-0">
                    <v-col cols="3">
                        <v-img :src="urlAPI + dialogDeleteCategorie.categorie.image_path" rounded="lg"></v-img>
                    </v-col>
                    <v-col cols="6" align-self="center">
                        <h4> {{ dialogDeleteCategorie.categorie.name_ru }}</h4>
                    </v-col>
                </v-row>
                <h4>Внимание: </h4>
                <v-card-text class="pt-0">Это действие не подлежат возврату!</v-card-text>
                <v-card-actions>
                    <v-row justify="center">
                        <v-col cols="6">
                            <v-btn color="#E05757" variant="flat" class="btn-text" flat rounded="lg" block
                                text="Удалить навсегда"
                                @click="DeleteProduct(dialogDeleteCategorie.categorie.ID)"></v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn color="#C6C6C6" variant="flat" class="btn-text" flat rounded="lg" block
                                text="Подумать" @click="dialogDeleteCategorie.state = false"></v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
                <v-card-text>
                    <i>
                        При нажатии “Удалить навсегда” категория полносью исчезнет из списка и сайта.
                    </i>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-snackbar :timeout="2000" location="top right" :color="alert_snackbar.color" variant="flat"
            v-model="alert_snackbar.state">
            <strong>{{ alert_snackbar.text }}</strong>
        </v-snackbar>
    </v-container>
</template>
<script>
import { defineComponent } from "vue";
import { useLocale } from "vuetify";
import { mapGetters } from 'vuex'
import { urlAPI } from "../../store/config";

export default defineComponent({
    name: "CategoriesCRM",
    setup() {
        const { t } = useLocale();
        return {
            t,
        };
    },
    data: () => ({
        urlAPI: urlAPI + '/',
        page_view: 1,
        limit_view: 10,
        search_value: "",
        is_archived: false,
        dialogInfoCategorie: {
            state: false,
            categorie: {
                building_type: null,
                building_purpose_type: null,
                housing_condition_type: null,
                industrial_purpose_type: null,
                renovation_type: null,
                land_type: null,
                number_of_rooms: null,
            }
        },
        dialogDeleteCategorie: {
            state: false,
            categorie: {}
        },
        alert_snackbar: {
            state: false,
            color: "",
            text: ""
        },
        categories_selected: [],
        property_count: {}
    }),
    computed: {
        ...mapGetters([
            'getCategorie',
            'getAllProducts',
            'getCategoriesCRM',
            'getPriority',
        ]),
    },
    methods: {
        CategorieCreate() {
            this.$router.push({
                name: "create-categories-crm",
            })
        },
        CategorieEdit(id) {
            this.$router.push({
                name: "edit-categories-crm",
                params: {
                    id: id
                }
            })
        },
        async CategorieInfo(item) {
            await this.$store.dispatch('LoadCategorie', item.ID);
            this.dialogInfoCategorie.state = true;
            this.dialogInfoCategorie.categorie = {
                building_type: this.getCategorie.building_type ? true : false,
                building_purpose_type: this.getCategorie ? true : false,
                housing_condition_type: this.getCategorie ? true : false,
                industrial_purpose_type: this.getCategorie ? true : false,
                renovation_type: this.getCategorie ? true : false,
                land_type: this.getCategorie ? true : false,
                number_of_rooms: this.getCategorie ? true : false,
            };
        },
        CategorieOpenDialogDelete(categorie) {
            this.dialogDeleteCategorie.state = true;
            this.dialogDeleteCategorie.categorie = categorie;
        },
        async DeleteProduct(ID) {
            this.dialogDeleteCategorie.state = false;
            this.dialogDeleteCategorie.categorie = {};
            let responce = await this.$store.dispatch('category_delete_by_id', ID);

            if (responce.success == true) {
                await this.$store.dispatch('LoadAllCategoriesCRM');
                this.alert_snackbar.state = true;
                this.alert_snackbar.text = "Категория была удалена с сайта";
                this.alert_snackbar.color = "success";
            } else {
                this.alert_snackbar.state = true;
                this.alert_snackbar.text = responce.error ?? responce.message + " " + Object.values(responce.data);
                this.alert_snackbar.color = "error";
            }
        },
        MinusPage() {
            if (this.page_view > 1) {
                this.page_view -= 1;
            }
        },
        PlusPage() {
            if (this.page_view * this.limit_view < this.getCategoriesCRM.length) {
                this.page_view += 1;
            }
        },
    },
    async mounted() {
        await this.$store.dispatch('LoadAllCategoriesCRM');
    },
})
</script>
<style scoped>
.divider-bottom {
    border: 1px solid rgba(0, 0, 0, 0.2);
    opacity: 1;
}

.btn-text {
    text-transform: inherit;
}

.table {
    width: 100%;
    border-radius: 10px;
}

.input-search {
    min-width: 300px;
}

.select-search {
    min-width: 200px;
    max-width: 400px;
}

.col-header-gap {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 10px;
}

.w-100 {
    min-width: 100%;
}

@media screen and (max-width: 1400px) {
    .input-search {
        min-width: 250px;
    }
}
</style>

<style>
.input-search .v-field__input,
.select-search .v-field__input {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    min-height: 36px;
}
</style>