import { en } from "vuetify/lib/locale";

export default {
  $vuetify: { ...en },
  ...en,
  route: {
    home: "",
    about: "about",
    real_estate: "real-estate",
    real_estate_single: "real-estate-single",
    real_estate_single_gallary: "real-estate-single-gallary",
  },
  header: {
    home: "Home",
    real_estate: "Real estate",
    about: "About us",
    learn: "Learn",
    partners: "Partners",
    contact: "Contact",
    consultation: "Consultation",
    current_lang: "En",
    legal: "Legal",
  },
  home: {
    parallax: {
      invest: "Invest",
      best_choice: "Best choice",
      with_the: "with the",
      work_with: "work with",
      experts: "Experts",
      în_domeniul:
        "In real estate, investments, building for more than 10 years who are",
      passion: "Passionate",
      btn_1: "Recent real estate",
      btn_2: "Learn in Hub",
      btn_3: "Get in touch",
      honest: "Integrity",
      as_principe: "in working with clients",
    },
    real_estate: {
      title: "Reliable real estate partners committed by your assets growths",
      btn_cards_action: "Learn more",
    },
    HelpWithYou: {
      title: "We can help you with...",
      btn_action: "Get a free consultation",
      items: [
        {
          title: "Investments",
          description:
            "An investment service based on three qualities: 1.Location, 2.Liquidity, 3.ROI (investment efficiency).",
        },
        {
          title: "Sell - buy operations",
          description:
            "Our experienced agents will guide you through this process, ensuring optimal results for both sellers and buyers in Chisinau.",
        },
        {
          title: "Real estate rent",
          description:
            "Assistance in selecting quality tenants and landlords, as well as property management.",
        },
        {
          title: "Exchange",
          description:
            "Effective exchange of objects without risks and losses.",
        },
        {
          title: "Property valuation",
          description:
            "We conduct professional market analysis in order to identify the market price of the property.",
        },
        {
          title: "Mortgage brokerage",
          description:
            "Full credit support for the borrower before receiving a mortgage loan for the desired property.",
        },
        {
          title: "Construction and repair",
          description:
            "High-quality construction and repair services in Chisinau. From renovations to ground-up construction, we provide quality solutions to enhance your property.",
        },
        {
          title: "Interior and exterior design",
          description:
            "The service includes: 1. Calculation of the cost of the project, 2. High-quality execution of work, 3. Guarantee of the quality of work for a certain period.",
        },
        {
          title: "Architectural projects.",
          description:
            "Development of architectural projects for any request in Chisinau.",
        },
        {
          title: "Privatization",
          description:
            "Full legal support service from collecting documents to obtaining real estate into private ownership.",
        },
      ],
    },
    AboutG: {
      subtitle: "Who stands behind G Partners?",
      title: {
        part_first: "Real estate",
        part_white: "experts duo",
        part_last: "with over 15 years experience",
      },
      person_1: {
        line_1_name: "Ivanov Maxim",
        line_2: "General Partner",
        line_3: "Commercial real estate",
        line_4: "Investments",
      },
      person_2: {
        line_1_name: "Gheorghies Florentin",
        line_2: "General Partner",
        line_3: "Industrial building management",
        line_4: "Legal expertise",
      },
      about_text: {
        part_1:
          "G Partners is a real estate boutique based in Chisinau, founded by an organic collaboration of highly succesful real estate professionals. Leveraging its stellar leadership and supported by the most refined resources in the industry, G Partners Invest is driven by an unrelenting focus on discretion, transactional excellence, and exceeding client expectations.",
        part_2:
          "We welcome all prospective clients to reach out with any questions or interest in working together. It would be our pleasure to help you.",
        part_3: " ",
      },
      btn_learn_more: "Learn more",
    },
    OurMission: {
      subtitle: "Cooperation with G Partners Invest.",
      title: "Trusted real estate experts",
      text: {
        part_1:
          "G Partners offers the highest level of real estate consulting and outstanding representation to clients in rent, buy & sell, exchange and other operations.",
        part_2:
          "Our clients recieve transparent approach and value our input when operating real estate services in Moldova and worldwide . We strive to negotiate the best terms for our clients in real estate deals. When met with a tough challenge our real estate agents are creative, strategic, and skillful in working through issues.",
        part_3: "Services of design, building and reparation in Chisinau.",
        part_4:
          "G Partners Invest is ready to become your reliable partner in the reinovating, designing, building services in Moldova.",
        part_5: "Make a step.",
      },
    },
    realEstateWorld: {
      title: "Our activities and ultimate hot offers.",
      subtitle:
        "Commercial real estate, residential complexes, apartments and houses with different options in Chisinau and worldwide.",
      items: [
        {
          title: "Real estate in Dubai , UAE",
          btn_text: "Coming soon",
        },
        {
          title: "Commercial real estate in the center of Chisinau.",
          btn_text: "Property details",
        },
        {
          title: "Become real estate expert in G Partners™ Hub",
          btn_text: "Learn more",
        },
      ],
    },
  },
  cookieModal: {
    title:
      "We use cookies to enhance your experience, analyze site traffic and deliver personalized content. Read our",
    title_Cookie_Policy: "Cookie Policy",
    btn_reject: "Reject",
    btn_accept: "Accept",
  },
  getConsultation: {
    btn_title: "Get free consultation",
    name: "OFFICE MANAGER",
    list_item_telegram: "In Telegram",
    list_item_whatts: "In Whatts App",
    list_item_phone: "On mobile",
  },
  real_estate: {
    Categories: {
      page_title: "Real estate",
      page_subtitle: "worthy of your attention",
      btn_1: "Mortgage calculator",
      btn_2: "Request special offer",
    },
    Products: {
      filter: {
        type: "Type",
        offer: "Offer",
        object_price: "Object price",
        location: "Location",
        condition: "Condition",
        reparation_type: "Reparation type",
        land_type: "Land type",
        industrial_purpose_type: "Industrial purpose type",
        rooms: "Rooms",
        remove_filters: "Remove filters",
        show: "Show",
        location_placeholder: "Not selected",
        area: "Area m²",
      },
      filter_input: "Search by name, district or building company",
      btn_new_search: "New search",
      btn_change_filter: "Change filters",
      modal: {
        btn_reset_filter: "Reset filters",
        btn_see_result: "Show",
      },
      btn_remove_filter: "Remove filters",
      btn_we_found: {
        part1: "we found",
        part2: "properties",
      },
      btn_load_more: "Load more",
    },
    MaibCalculator: {
      title_left: "Calculate real estate option",
      btn_right_1: "Mortgage",
      btn_right_2: "Other",
      btn_new_calculation: "New calculation",
    },
    GetInTouch: {
      text_need_offer: "Need special offer?",
      text_title: {
        part1: "Let’s get in",
        part2: "touch",
      },
      text_small_right:
        "Can't find what you're looking for? Don't worry, we've got you covered!",
      btn_get_in_touch: "Get in touch",
    },
  },
  footer: {
    working_worldwide: "Working * worldwide.",
    based_in_chisinau: "Based in Chisinau",
    copyrights: "Copyrights",
    legal_person: "Legal person",
    terms_and_condition: "Terms and condition",
    sitemap: "Sitemap XML",
  },
  real_estate_single: {
    Parallax: {
      subtitle_right: "worthy of your attention",
      btn_1: "Mortgage calculator",
      btn_2: "Request special offer",
    },
    Product_images: {
      btn_open_gallary: "Open gallery",
    },
    Product_details: {
      building_company: "Building company",
      responsible_agent: "Responsible agent",
      btn_contact: "Contact for details",
      btn_see_presentation: "See full presentation",
      highlights: "Highlights",
      about_product: "About",
    },
    OtherProducts: {
      block_title: "See other",
    },
  },
  real_estate_single_gallary: {
    btn_go_back_to_details: "Go back to details",
    btn_go_back: "Go back",
  },
  modals_real_estate: {
    contact: {
      title: "Contact our experts for a private consultation",
      subtitle:
        "Don't waste a lot of time! A real estate expert will contact you and answer all your questions",
      input_1_label: "Your telephone",
      input_2_label: "Name and Surname",
      text_reCAPTCHA:
        "Our site is protected by reCAPTCHA and complies with Google's Privacy Policy and Terms of Service.",
      btn_request: "Request free consultation",
      text_terms: {
        part_1: "By clicking this button you are automatically agree with our",
        part_2: "terms and conditions.",
      },
    },
    see_presentation_pdf: {
      title: "Download presentation about this property",
      subtitle:
        "Learn more about property planning, district, options and many more in this presentation",
      input_1_label: "Your telephone",
      input_2_label: "Name and Surname",
      text_reCAPTCHA:
        "Our site is protected by reCAPTCHA and complies with Google's Privacy Policy and Terms of Service.",
      btn_request: "Download presentation",
      btn_download: "Ready for installation",
      text_terms: {
        part_1: "By clicking this button you are automatically agree with our",
        part_2: "terms and conditions.",
      },
    },
  },
  about_page: {
    Text_animation_1: "G Partners Academy ",
    Text_animation_2: "G Partners Hub ",
    Text_animation_3: "Real Estate Consulting ",
    Parallax: {
      title: "What we do",
      subtitle_right:
        "Complex real estate services. With us it is easy and safe",
    },
    Learn_ihub_one: {
      title:
        "Helping experts to increase the level of competence and learn market insights.",
      subtitle_text_right:
        "Learn top class information from 10-years experienced mentors in real estate market.",
      btn_learn: "Learn in Hub",
    },
    AllActionOne: {
      items: [
        {
          title: "All you need",
          description:
            "We have created comfortable learning conditions so that you can concentrate as much as possible on the learning process. Our classrooms are equipped with state-of-the-art equipment including high quality 4K smart boards.",
        },
        {
          title: "Central location",
          description:
            "G Partners Academy is located in the heart of the city, providing easy access and convenience for you. This allows you to quickly and conveniently get to our classes without wasting time on long journeys. Parking lots are avilable!",
        },
        {
          title: "Benefits of the Initiation Course",
          description:
            "In-depth knowledge in the field, Practical skills, professional recognition, networking, and continuous professional development.",
        },
      ],
      btn: "Start learning",
    },
    Learn_ihub_two: {
      title:
        "Keep 100% commission, be part of a community of professional experts, benefit from a modern office with ultra-central location and parking spaces guaranteed.",
      subtitle_text_right:
        "Networking with other professionals in the industry, including fellow real estate agents, brokers, investors, developers, and service providers. Engaging with this network can lead to potential referrals, partnerships, and collaboration opportunities, ultimately expanding the solo expert's client base and business opportunities.",
      btn_request: "Request Hub access",
    },
    AllActionTwo: {
      items: [
        {
          title: "HUB G Partners",
          description:
            "Unique opportunities for collaboration and continuous development. Our goal is to promote and cultivate a culture of confident and skilled real estate experts in the Republic of Moldova.",
        },
        {
          title: "Our services",
          description:
            "Coworking space; Continuous professional training courses; Networking events and sessions; Legal, informational, and document support; Access to the Company's database and exclusive offers.",
        },
        {
          title: "Benefits",
          description:
            "100% of transactions; Use of the Company's brand; Company's client and partner database; Modern, well-equipped workspace with convenient location; Your own profile website page.",
        },
      ],
      btn: "JOIN HUB",
    },
    Learn_ihub_three: {
      title:
        "We are convinced that honesty is the basis of long-term relationships.",
      subtitle_text_right:
        "All actions with us are conducted transparently and honestly towards the client. Our experts have a high level of knowledge and experience, ensuring successful transactions for property acquisition, sale, intermediation, and consultancy. We take responsibility for each transaction, aiming to ensure maximum satisfaction and safety for our clients.",
      btn_request: "Request Hub access",
    },
    AllActionThree: {
      items: [
        {
          title: "Experience and Expertise",
          description:
            "Extensive experience in the field facilitates the establishment of personalized and efficient solutions for each client.",
        },
        {
          title: "Diversified Portfolio",
          description:
            "Requests for apartments, luxury homes, land, and commercial spaces are easily met through the variety of offers presented by the Company.",
        },
        {
          title: "Transparency and Professionalism",
          description:
            "Open communication and transparency ensure balance and the right and timely choice for clients.",
        },
      ],
      btn: "Contact us",
    },
    Our_services: {
      title: "We currently provide these services",
    },
    FAQs: {
      title: "Frequently Asked Questions",
      items: {
        item1: {
          title:
            "Why is it better and safer to buy real estate through an agency?",
          description:
            "Buying real estate through an agency has many advantages that help minimize risks and streamline the buying process: Legal security, Professional consultation, Time savings, Support at all stages of the transaction, Cost optimization, Protection against fraud, Extensive selection of properties, Legal transaction protection, Secure payment methods, Confidentiality.",
        },
        item2: {
          title: "What documents are required to purchase real estate?",
          description:
            "The following documents are required to purchase real estate: • Buyer's passport. • Spouse's consent to purchase: if buying is done within marriage. • Preliminary purchase agreement: with specified terms and transaction deadlines. • Property documents: extract confirming seller's property rights and absence of encumbrances. • Payment confirmation documents: receipts or bank statements confirming money transfer to the seller.",
        },
        item3: {
          title: "What are the main stages of selling real estate?",
          description:
            "The main stages of selling real estate include: 1. Document preparation: collecting all necessary property documents. 2. Property valuation: determining the market value of the property. 3. Finding a buyer and showing the property: placing advertisements and organizing viewings. 4. Signing a preliminary purchase agreement: agreement with the buyer's deposit. 5. Signing the main purchase agreement: notarizing the transaction and transferring money.",
        },
        item4: {
          title: "How to choose a reliable developer when buying new property?",
          description:
            "When choosing a developer, it is important to pay attention to the following aspects: 1. Experience and reputation: study the company's history, number of completed projects, and client reviews. 2. Documents and permits: check the availability of all necessary construction permits and land documents. 3. Financial stability: assess the developer's financial position, including loans and liabilities. 4. Construction pace: see how well the developer meets deadlines and the quality of work on already built properties. 5. Contract terms: carefully review the contract, including delivery deadlines, warranty obligations, and possible penalties for delays.",
        },
        item5: {
          title:
            "What is a deposit when buying real estate and how to properly formalize it?",
          description:
            "A deposit is a sum of money transferred by the buyer to the seller as confirmation of intent to purchase real estate. It is formalized in a separate agreement or specified in a preliminary purchase agreement. The deposit serves as a guarantee of transaction execution: if the buyer withdraws from the purchase, the deposit remains with the seller; if the seller withdraws, they must return the deposit in double. It is important that the agreement clearly specifies the conditions for deposit refund and circumstances under which it is not returned.",
        },
      },
    },
  },
  learn_page: {
    Parallax: {
      title: "Learn with us",
      subtitle_right:
        "Based on our experience, we have developed our own academy so that you can become a real estate expert.",
    },
    about_the_project: {
      text_before_title: "What’s it all about?",
      text_title: "We’ve developed the most practical course",
      text_after_title:
        "to become a real estate expert in Moldova and start making money.",
      block_1: {
        about_project_title: "About the project.",
        text: {
          part1:
            "Certain trends in the Moldovan real estate market prompted us to create a new direction in our company - a real estate training academy.",
          part2:
            "The training material was developed by us over a long period of time and aims to convey 10 years of experience in real estate business, market dynamics in the Republic of Moldova, methods of communication with clients and operating principles",
        },
      },
      block_2: {
        you_will_master_title: "You will master",
        blocks: [
          {
            title: "Characteristics of the real estate market",
            text: "Learn to distinguish between categories, types, price ranges and factors of real estate properties.",
          },
          {
            title: "In-Depth Knowledge in the Field",
            text: "Essential aspects of the real estate market; Legislative framework of the real estate domain; Financing and Loans; Cadastre and cadastral information.",
          },
          {
            title: "Practical Skills",
            text: "Hands-on training through case studies and simulations; Communication and client interaction skills; Negotiation techniques; Transaction preparation.",
          },
          {
            title: "Professional Recognition",
            text: "Employment within the “G Partners” real estate company and national and International recognition.",
          },
          {
            title: "Networking",
            text: "Interaction with other professionals and trainers in the real estate field; Professional and Responsive Community.",
          },
          {
            title: "Continuous Professional Development:",
            text: "Courses are constantly updated to reflect the latest trends and developments in the real estate sector.",
          },
        ],
        btns: {
          smart_board: "4K Smart Board",
          mini_groups: "Mini groups",
          graduated_students: "+500 graduated students",
          opportunity_to_work: "Opportunity to work with us",
        },
      },
    },
    material_schedule: {
      title_text: "Learning material schedule",
      text_right: {
        title: "Schedule",
        subtitle:
          "All academy course information will be structured and collected in blocks for ease of memorization and consistency. Familiarize yourself with the main blocks.",
      },
      week_1: {
        week_text_number: "Module 01",
        topic_name: "The real estate field and the role of the expert.",
      },
      week_2: {
        week_text_number: "Module 02",
        topic_name:
          "Market analysis and evaluation, types of properties, price formation.",
      },
      week_3: {
        week_text_number: "Module 03",
        topic_name: "Categories of real estate beneficiaries.",
      },
      week_4: {
        week_text_number: "Module 04",
        topic_name: "Types of credit.",
      },
      week_5: {
        week_text_number: "Module 05",
        topic_name: "Legislative basis of the real estate field.",
      },
      week_6: {
        week_text_number: "Module 06",
        topic_name:
          "Tools and working strategies, the real estate expert's activity plan.",
      },
    },
    our_mission: {
      text_subtitle: "what is to be a part of G Partners",
      text_title: "Our main mission",
      text_content_part_1_bold: "Real estate in Moldova and World.",
      text_content_part_2:
        "At G Partners, our unwavering passion for innovation fuels our constant quest to remain at the forefront of real estate trends. Boasting over two decades of collective expertise within the industry, we've honed a diverse set of skills in real estate development, design, and client service.",
      text_content_part_3_bold: "Building and reparation expertise in Moldova.",
      text_content_part_4:
        "We have played a key role in creating unique properties, spaces and developing solutions for real estate projects in Moldova.",
      text_content_part_5: "Invest with G Partners.",
    },
  },
  partners_page: {
    Parallax: {
      title: "Meet our team",
      subtitle_right:
        "Find out more about our team of real estate agents and partners.",
    },
    general_partners: {
      title: "General Partners",
      content: {
        part_1:
          "At G Partners, our constant drive for innovation helps us stay ahead in the real estate market. With two decades of industry experience, we have gained skills in property management, client service, and construction.",
        part_2:
          "We have played a significant role in creating distinctive property identities, transforming spaces, and developing outstanding solutions for numerous real estate projects in Moldova and other countries.",
        part_3: " ",
      },
    },
    our_team: {
      subtitle_text: "Meet our team",
      title_text: "Real estate experts",
    },
  },
  contact_page: {
    Parallax: {
      title: "Contact us",
    },
    get_in_touch: {
      title: "Let’s get in touch",
      text_right:
        "We are online to be in touch with you from 9 am to 20 pm in GMT+3 zone.",
      btn_text: "Get in touch",
    },
    map_location: {
      title_text: "Head office location",
      text_right: "Bulevardul Ștefan cel Mare și Sfînt 6, MD-2012, Chișinău",
    },
  },
  single_partner: {
    about_title: "",
    all_partners_btn: "All partners",
    g_partners_agent: "Partner",
    responsible_for: "Responsible for",
    contact_btn: " Contact for details",
  },
};
