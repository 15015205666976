<template>
    <v-container fluid class="pt-10 fill-height" v-if="getPriority < 4">
        <v-row class="w-100" justify="center">
            <v-col cols="12">
                <h1>База недвижимости</h1>
            </v-col>
        </v-row>
        <v-row justify="space-between" class="w-100">
            <v-col cols="12">
                <v-form ref="form_create_property" v-model="valid">
                    <v-card width="100%" class="pa-4" v-if="loaded">
                        <h2 class="">
                            {{ $route.name == 'edit-real-estate-crm' ?
                                'Редактировать существующий объект' : 'Новый объект'
                            }}
                        </h2>
                        <v-card-title class="px-0 pt-6">
                            Название, описание объекта
                        </v-card-title>
                        <!-- Name -->
                        <div class="mt-3">
                            <v-card-text class="title_property">
                                Название на Русском
                            </v-card-text>
                            <v-text-field v-model="name_ru" variant="outlined" placeholder="Название на Русском"
                                density="comfortable" required :rules="requireRules"
                                label="название будет отображаться на русской версии сайта">
                            </v-text-field>
                        </div>
                        <div>
                            <v-card-text class="title_property">
                                Название на Румынском
                            </v-card-text>
                            <v-text-field v-model="name_ro" variant="outlined" placeholder="Название на Румынском"
                                density="comfortable" required :rules="requireRules"
                                label="название будет отображаться на румынской версии сайта">
                            </v-text-field>
                        </div>
                        <div class="mb-3">
                            <v-card-text class="title_property">
                                Название на Английском
                            </v-card-text>
                            <v-text-field v-model="name_en" variant="outlined" placeholder="Название на Английском"
                                density="comfortable" required :rules="requireRules"
                                label="название будет отображаться на английской версии сайта">
                            </v-text-field>
                        </div>
                        <!-- Description -->
                        <div class="my-3">
                            <v-card-text class="title_property">
                                Описание на Русском
                            </v-card-text>
                            <v-text-field v-model="description_ru" required :rules="requireRules" variant="outlined" placeholder="Описание на Русском"
                                density="comfortable" hide-details label="будет отображаться на русской версии сайта">
                            </v-text-field>
                        </div>
                        <div class="my-3">
                            <v-card-text class="title_property">
                                Описание на Румынском
                            </v-card-text>
                            <v-text-field v-model="description_ro" required :rules="requireRules" variant="outlined"
                                placeholder="Описание на Румынском" density="comfortable" hide-details
                                label="будет отображаться на румынской версии сайта">
                            </v-text-field>
                        </div>
                        <div class="my-3">
                            <v-card-text class="title_property">
                                Описание на Английском
                            </v-card-text>
                            <v-text-field v-model="description_en" required :rules="requireRules" variant="outlined"
                                placeholder="Описание на Английском" density="comfortable" hide-details
                                label="будет отображаться на английской версии сайта">
                            </v-text-field>
                        </div>
                        <!-- meta title -->
                        <div class="my-3">
                            <v-card-text class="title_property">
                                Meta Title на Русском
                            </v-card-text>
                            <v-text-field v-model="meta_title_ru" variant="outlined" placeholder="Meta Title на Русском"
                                density="comfortable" hide-details
                                label="если оставить поле пустым, то будет использовать значение названия объекта">
                            </v-text-field>
                        </div>
                        <div class="my-3">
                            <v-card-text class="title_property">
                                Meta title на Румынском
                            </v-card-text>
                            <v-text-field v-model="meta_title_ro" variant="outlined"
                                placeholder="Meta title на Румынском" density="comfortable" hide-details
                                label="если оставить поле пустым, то будет использовать значение названия объекта">
                            </v-text-field>
                        </div>
                        <div class="my-3">
                            <v-card-text class="title_property">
                                Meta Title на Английском
                            </v-card-text>
                            <v-text-field v-model="meta_title_en" variant="outlined"
                                placeholder="Meta Title на Английском" density="comfortable" hide-details
                                label="если оставить поле пустым, то будет использовать значение названия объекта">
                            </v-text-field>
                        </div>

                        <!-- meta description -->
                        <div class="my-3">
                            <v-card-text class="title_property">
                                Meta Description на Русском
                            </v-card-text>
                            <v-text-field v-model="meta_description_ru" variant="outlined"
                                placeholder="Meta Description на Русском" density="comfortable" hide-details
                                label="если оставить поле пустым, то будет использовать значение описания объекта">
                            </v-text-field>
                        </div>
                        <div class="my-3">
                            <v-card-text class="title_property">
                                Meta Description на Румынском
                            </v-card-text>
                            <v-text-field v-model="meta_description_ro" variant="outlined"
                                placeholder="Meta Description на Румынском" density="comfortable" hide-details
                                label="если оставить поле пустым, то будет использовать значение описания объекта">
                            </v-text-field>
                        </div>
                        <div class="my-3">
                            <v-card-text class="title_property">
                                Meta Description на Английском
                            </v-card-text>
                            <v-text-field v-model="meta_description_en" variant="outlined"
                                placeholder="Meta Description на Английском" density="comfortable" hide-details
                                label="если оставить поле пустым, то будет использовать значение описания объекта">
                            </v-text-field>
                        </div>

                    </v-card>
                    <v-card class="pa-4 mt-4" v-if="loaded2">
                        <h3 class="px-3 pt-6 pb-2">Категория</h3>
                        <v-select v-model="ID_category" :items="getAllCategories" item-title="name_ru" item-value="ID"
                            variant="outlined" hide-details class="select-search" rounded="lg"
                            @update:modelValue="GetCategorieData(ID_category)" required :rules="requireRules">
                            <template v-slot:selection="{ item }">
                                <span>{{ item.title }}</span>
                            </template>
                        </v-select>

                        <h3 class="px-3 pt-6">Локация объекта</h3>
                        <p class="px-3 pb-2">Выберете локацию из списка ранее добавленных или создайте новую. </p>
                        <v-select label="Выбрать ранее добавленную локацию" v-model="ID_location" :items="get_location"
                            item-title="name_ru" item-value="ID" variant="outlined" hide-details class="select-search"
                            rounded="lg" required :rules="requireRules">
                            <template v-slot:selection="{ item }">
                                <span>{{ item.title }}</span>
                            </template>

                            <template v-slot:item="{ item, props, on }">
                                <v-list-item v-on="on" v-bind="props" class="list-items-selects">
                                    <v-list-item-title class="list-items-selects-title">
                                        <v-row justify="space-between">
                                            <v-col cols="auto">
                                                <span>{{ props.title }}</span>
                                            </v-col>
                                            <v-col cols="auto" align-self="center">
                                                <v-icon @click.stop="Delete_property_developer(item, 'location')"
                                                    icon="mdi-close"></v-icon>
                                            </v-col>
                                        </v-row>
                                    </v-list-item-title>
                                </v-list-item>
                            </template>
                        </v-select>
                        <v-btn id="activator-add-new-location" class="btn-text mt-2" flat color="#0255B7" rounded="lg"
                            prepend-icon="mdi-plus">
                            Добавить новую локацию
                        </v-btn>
                        <v-dialog activator="#activator-add-new-location" max-width="700">
                            <template v-slot:default="{ isActive }">
                                <v-card class="pa-6 pb-md-10 text-center" rounded="lg">
                                    <v-row justify="end">
                                        <v-col cols="6" sm="4">
                                            <v-btn color="#6C757D" flat variant="text" text="Закрыть"
                                                prepend-icon="mdi-close" @click="isActive.value = false"></v-btn>
                                        </v-col>
                                    </v-row>
                                    <h2 class="mb-6">Добавить новую локацию</h2>

                                    <v-text-field v-model="new_location.name_ru" variant="outlined" density="compact"
                                        label="Название локации на русском" prepend-inner-icon="mdi-map-marker">
                                    </v-text-field>
                                    <v-text-field v-model="new_location.name_ro" variant="outlined" density="compact"
                                        prepend-inner-icon="mdi-map-marker" label="Название локации на румынском">
                                    </v-text-field>
                                    <v-text-field v-model="new_location.name_en" variant="outlined" density="compact"
                                        prepend-inner-icon="mdi-map-marker" label="Название локации на английском">
                                    </v-text-field>

                                    <v-btn color="#0255B7" flat rounded="lg" block text="Сохранить"
                                        :disabled="!new_location.name_ru || !new_location.name_ro || !new_location.name_en"
                                        @click="CreateLocation(isActive)"></v-btn>
                                    <v-card-text>
                                        <i>
                                            После сохранения локации, в модуле редактирования объектов недвижимости
                                            появится возможность выбрать добавленную локацию.
                                        </i>
                                    </v-card-text>
                                </v-card>
                            </template>
                        </v-dialog>
                    </v-card>
                    <v-card class="pa-4 mt-4" v-if="loaded3">
                        <v-card-title class="px-0 pt-6">
                            Детали объекта
                        </v-card-title>

                        <div class="my-3">
                            <v-card-text class="title_property">
                                Предложение
                            </v-card-text>
                            <v-radio-group v-model="building_purpose_type"
                                v-if="get_building_purpose_type.length !== 0">
                                <v-radio v-for="types in get_building_purpose_type" :key="types.ID"
                                    :label="types.name_ru" :value="types.ID"></v-radio>
                            </v-radio-group>
                        </div>

                        <div class="my-3">
                            <v-card-text class="title_property">
                                Тип Цены
                            </v-card-text>
                            <v-radio-group v-model="price_type" v-if="get_price_type.length !== 0" required
                                :rules="requireRules">
                                <v-radio v-for="types in get_price_type" :key="types.ID" :label="types.name_ru"
                                    :value="types.ID"></v-radio>
                            </v-radio-group>
                        </div>

                        <div class="my-3">
                            <v-card-text class="title_property">
                                Стоимость объекта в ЕВРО (число)
                            </v-card-text>

                            <v-text-field v-model="price" variant="outlined" placeholder="" density="comfortable"
                                required :rules="requireRules"
                                label="Указывается стоимость в евро согласно выбранному типу предложения.">
                            </v-text-field>
                        </div>

                        <div class="my-3">
                            <v-card-text class="title_property">
                                Площадь объекта в м2 (число)
                            </v-card-text>

                            <v-text-field v-model="area" variant="outlined" placeholder="" density="comfortable"
                                 label="" required :rules="requireRules">
                            </v-text-field>
                        </div>


                        <h3 class="px-3 pt-6 pb-2">Ответсвенный партнер</h3>
                        <v-card-subtitle class="pb-2">Вы можете выбрать агента из списка или добавить нового партнера в
                            модуле
                            Партнеры</v-card-subtitle>
                        <v-select v-model="ID_agent" :items="getAllPartners" item-title="name_ru" item-value="ID"
                            variant="outlined" hide-details class="select-search" rounded="lg" required
                            :rules="requireRules">
                            <template v-slot:selection="{ item }">
                                <span>{{ item.title }}</span>
                            </template>
                        </v-select>

                        <h3 class="px-3 pt-6 pb-2">Застройщик объекта</h3>

                        <v-select v-if="get_property_developer.length !== 0" v-model="property_developer"
                            :items="get_property_developer" item-title="name" item-value="ID" variant="outlined"
                            hide-details class="select-search" rounded="lg" required :rules="requireRules">

                            <template v-slot:selection="{ item }">
                                <span>{{ item.title }}</span>
                            </template>

                            <template v-slot:item="{ item, props, on }">
                                <v-list-item v-on="on" v-bind="props" class="list-items-selects">
                                    <v-list-item-title class="list-items-selects-title">
                                        <v-row justify="space-between">
                                            <v-col cols="auto">
                                                <span>{{ props.title }}</span>
                                            </v-col>
                                            <v-col cols="auto" align-self="center">
                                                <v-icon
                                                    @click.stop="Delete_property_developer(item, 'property_developer')"
                                                    icon="mdi-close"></v-icon>
                                            </v-col>
                                        </v-row>
                                    </v-list-item-title>
                                </v-list-item>
                            </template>
                        </v-select>

                        <v-btn id="activator-add-new-property_developer" class="btn-text mt-2" flat color="#0255B7"
                            rounded="lg" prepend-icon="mdi-plus">
                            Добавить нового застройщика
                        </v-btn>
                        <v-dialog activator="#activator-add-new-property_developer" max-width="700">
                            <template v-slot:default="{ isActive }">
                                <v-card class="pa-6 pb-md-10 text-center" rounded="lg">
                                    <v-row justify="end">
                                        <v-col cols="6" sm="4">
                                            <v-btn color="#6C757D" flat variant="text" text="Закрыть"
                                                prepend-icon="mdi-close" @click="isActive.value = false"></v-btn>
                                        </v-col>
                                    </v-row>
                                    <h2 class="mb-6">Добавить нового застройщика</h2>

                                    <v-text-field v-model="new_property_developer.name_en" variant="outlined"
                                        density="compact" prepend-inner-icon="mdi-map-marker"
                                        label="Название застройщика на английском">
                                    </v-text-field>

                                    <v-btn color="#0255B7" flat rounded="lg" block text="Сохранить"
                                        :disabled="!new_property_developer.name_en"
                                        @click="CreatePropertyDeveloper(isActive)"></v-btn>
                                    <v-card-text>
                                        <i>
                                            После сохранения застройщика, в модуле редактирования объектов недвижимости
                                            появится возможность выбрать добавленного застройщика.
                                        </i>
                                    </v-card-text>
                                </v-card>
                            </template>
                        </v-dialog>

                        <div class="my-3" v-if="!getCategorie.ID || getCategorie.building_type">
                            <v-card-text class="title_property">
                                Тип объекта
                            </v-card-text>
                            <v-radio-group v-model="building_type" v-if="get_building_type.length !== 0">
                                <v-radio v-for="types in get_building_type" :key="types.ID" :label="types.name_ru"
                                    :value="types.ID"></v-radio>
                            </v-radio-group>
                        </div>

                        <div class="my-3" v-if="!getCategorie.ID || getCategorie.housing_condition_type">
                            <v-card-text class="title_property">
                                Тип ремонта
                            </v-card-text>
                            <v-radio-group v-model="house_condition_type" v-if="get_house_condition_type.length !== 0">
                                <v-radio v-for="types in get_house_condition_type" :key="types.ID"
                                    :label="types.name_ru" :value="types.ID"></v-radio>
                            </v-radio-group>
                        </div>

                        <div class="my-3" v-if="!getCategorie.ID || getCategorie.number_of_rooms">
                            <v-card-text class="title_property">
                                Количество комнат
                            </v-card-text>
                            <v-radio-group v-model="number_of_rooms">
                                <v-radio label="1" :value="1"></v-radio>
                                <v-radio label="2" :value="2"></v-radio>
                                <v-radio label="3" :value="3"></v-radio>
                                <v-radio label="4+" :value="4"></v-radio>
                            </v-radio-group>
                        </div>

                        <div class="my-3" v-if="!getCategorie.ID || getCategorie.land_type">
                            <v-card-text class="title_property">
                                Назначение
                            </v-card-text>
                            <v-radio-group v-model="land_type" v-if="get_land_type.length !== 0">
                                <v-radio v-for="types in get_land_type" :key="types.ID" :label="types.name_ru"
                                    :value="types.ID"></v-radio>
                            </v-radio-group>
                        </div>

                        <div class="my-3" v-if="!getCategorie.ID || getCategorie.industrial_purpose_type">
                            <v-card-text class="title_property">
                                Индустриальное назначение
                            </v-card-text>
                            <v-radio-group v-model="industrial_purpose_type"
                                v-if="get_industrial_purpose_type.length !== 0">
                                <v-radio v-for="types in get_industrial_purpose_type" :key="types.ID"
                                    :label="types.name_ru" :value="types.ID"></v-radio>
                            </v-radio-group>
                        </div>

                        <div class="my-3">
                            <v-card-text class="title_property">
                                Преймущества объекта на русском
                            </v-card-text>
                            <v-text-field v-model="features_ru" variant="outlined" density="comfortable" hide-details
                                label="После указания одного преймущества поставьте запятую. Пример: Бассейн, Терраса, 7 Этаж.">
                            </v-text-field>
                        </div>

                        <div class="my-3">
                            <v-card-text class="title_property">
                                Преймущества объекта на румынском
                            </v-card-text>
                            <v-text-field v-model="features_ro" variant="outlined" density="comfortable" hide-details
                                label="После указания одного преймущества поставьте запятую. Пример: Бассейн, Терраса, 7 Этаж.">
                            </v-text-field>
                        </div>

                        <div class="my-3">
                            <v-card-text class="title_property">
                                Преймущества объекта на английском
                            </v-card-text>
                            <v-text-field v-model="features_en" variant="outlined" density="comfortable" hide-details
                                label="После указания одного преймущества поставьте запятую. Пример: Бассейн, Терраса, 7 Этаж.">
                            </v-text-field>
                        </div>
                    </v-card>
                    <v-card class="pa-4 mt-4" v-if="loaded4">

                        <div class="mb-3 mt-10">
                            <v-card-text class="title_property_big">
                                Главная фотография
                            </v-card-text>
                            <v-card-subtitle class="mt-0 pt-0 pb-4">
                                Размер фотографии не должен превышать 200 кб . и 400X400 px
                            </v-card-subtitle>
                            <v-row>
                                <v-col :cols="getProduct.main_image_path || main_image_path ? '9' : '12'"
                                    align-self="center">
                                    <v-file-input v-model="main_image_path" label="Upload file" flat show-size clearable
                                        variant="solo-filled" hide-details></v-file-input>
                                </v-col>
                                <v-col cols="3" v-if="getProduct.main_image_path || main_image_path">
                                    <v-img rounded="lg" width="100%"
                                        :src="main_image_path ? windowVue.URL.createObjectURL(main_image_path) : urlAPI + getProduct.main_image_path"></v-img>
                                </v-col>
                            </v-row>
                            <v-row class="ma-0">
                                <v-col cols="6">
                                    <v-text-field required :rules="requireRules" v-model="main_image_title"
                                        variant="outlined" density="comfortable" label="title">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field required :rules="requireRules" v-model="main_image_alt"
                                        variant="outlined" density="comfortable" label="alt">
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </div>

                        <div class="mb-3 mt-3">
                            <v-card-text class="title_property_big">
                                Галлерея фотографий
                            </v-card-text>
                            <v-card-subtitle class="mt-0 pt-0 pb-4">
                                Размер фотографии не должен превышать 200 кб
                            </v-card-subtitle>

                            <v-row class="ma-0" v-if="carousel && windowVue">
                                <v-col cols="6" md="4" lg="3" v-for="(image, index) in carousel" :key="image.ID"
                                    :order="image.carousel_position">
                                    <v-card class="pa-3" rounded="lg">
                                        <v-img v-if="image.file" :src="windowVue.URL.createObjectURL(image.file)"
                                            contain :aspect-ratio="1.45"></v-img>
                                        <v-img v-if="!image.file"
                                            :src="image.path ? urlAPI + image.path : require('../../assets/img/crm/no_photo.jpg')"
                                            rounded="lg" :aspect-ratio="1.45" contain></v-img>
                                        <v-text-field v-model="image.alt" variant="outlined" density="compact"
                                            label="alt" required :rules="requireRules">
                                        </v-text-field>
                                        <v-text-field v-model="image.title" variant="outlined" density="compact"
                                            label="title" required :rules="requireRules">
                                        </v-text-field>
                                        <v-file-input v-model="image.file" label="Upload new file" flat show-size
                                            density="compact" variant="outlined" prepend-icon="" class="mt-3"
                                            hide-details=""></v-file-input>
                                        <v-btn block variant="outlined" rounded="lg" color="red"
                                            @click="removeImageInCarousel(image, index)" class="mt-3">
                                            <v-icon icon="mdi-close"></v-icon>
                                        </v-btn>
                                    </v-card>
                                </v-col>
                                <v-col cols="6" md="4" lg="3" :order="carousel.length">
                                    <v-card @click="addNewImageInCarousel()" class="pa-3 text-center" rounded="lg"
                                        height="100%" style="align-content: space-evenly;">
                                        <v-icon icon="mdi-plus" size="100px"></v-icon>
                                        <h4>Добавить изображение</h4>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </div>


                        <div class="mb-3 mt-3">
                            <v-card-text class="title_property_big">
                                PDF презентация объекта
                            </v-card-text>
                            <v-card-subtitle class="mt-0 pt-0 pb-4">
                                Размер pdf не должен превышать 1мб
                            </v-card-subtitle>
                            <v-row>
                                <v-col :cols="getProduct.pdf_path && pdf_path == null ? '9' : '12'" align-self="center">
                                    <v-file-input v-model="pdf_path" label="Upload file" flat show-size clearable
                                        variant="solo-filled"></v-file-input>
                                </v-col>
                                <v-col cols="3" v-if="getProduct.pdf_path && pdf_path == null">
                                    <iframe rounded="lg" :src="urlAPI + getProduct.pdf_path" width="100%" height="auto"
                                        frameborder="0"></iframe>
                                </v-col>
                            </v-row>
                        </div>


                        <v-row justify="center" class="mb-15">
                            <v-col cols="12" sm="8" md="6">
                                <v-btn color="#0255B7" block flat class="btn-text" rounded="lg" size="large"
                                    @click="CreateProduct()">
                                    {{ $route.name == 'edit-real-estate-crm'
                                        ? 'Сохранить изменения в обьекте' :
                                        'Сохранить новый объект' }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
        <v-snackbar :timeout="2000" location="top right" :color="alert_snackbar.color" variant="flat"
            v-model="alert_snackbar.state">
            <strong>{{ alert_snackbar.text }}</strong>
        </v-snackbar>
        <v-dialog v-model="dialogDeleteProperty.state" max-width="700">
            <v-card class="pa-6 py-md-10 text-center" rounded="lg">
                <h2 class="mb-3">Вы уверены что хотите удалить ?</h2>
                <v-row justify="center" class="mx-0 my-6">
                    <v-col cols="9">
                        <h4> {{ dialogDeleteProperty.title }}</h4>
                    </v-col>
                </v-row>
                <h4>Внимание: </h4>
                <v-card-text class="pt-0">Это действие не подлежат возврату!</v-card-text>
                <v-card-action>
                    <v-row justify="center">
                        <v-col cols="6">
                            <v-btn color="#E05757" class="btn-text" flat rounded="lg" block text="Удалить навсегда"
                                @click="DeletePropertyConfirm(dialogDeleteProperty)"></v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn color="#C6C6C6" class="btn-text" flat rounded="lg" block text="Подумать"
                                @click="dialogDeleteProperty.state = false"></v-btn>
                        </v-col>
                    </v-row>
                </v-card-action>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import { defineComponent } from "vue";
import { useLocale } from "vuetify";
import { mapGetters } from 'vuex'
import { urlAPI } from "../../store/config";

export default defineComponent({
    name: "CreateRealEstatePage",
    setup() {
        const { t } = useLocale();
        return {
            t,
        };
    },
    data: () => ({
        urlAPI: urlAPI + '/',
        dialogDeleteProperty: {
            state: false,
            ID: null,
            type: null,
            title: null,
        },
        valid: false,
        requireRules: [
            value => {
                if (value) return true

                return 'Это поле обьязательное.'
            },
        ],
        new_location: {
            name_ru: null,
            name_ro: null,
            name_en: null,
        },
        new_property_developer: {
            name_en: null,
        },
        alert_snackbar: {
            state: false,
            color: "",
            text: ""
        },
        name_ru: null,
        name_ro: null,
        name_en: null,
        description_ru: null,
        description_ro: null,
        description_en: null,
        meta_title_ru: null,
        meta_title_ro: null,
        meta_title_en: null,
        meta_description_ru: null,
        meta_description_ro: null,
        meta_description_en: null,
        area: null,
        ID_category: null,
        ID_location: null,
        price: null,
        ID_agent: null,
        property_developer: null,
        price_type: null,
        building_type: null,
        house_condition_type: null,
        number_of_rooms: null,
        building_purpose_type: null,
        land_type: null,
        industrial_purpose_type: null,
        features_ru: null,
        features_ro: null,
        features_en: null,
        main_image_path: null,
        main_image_alt: null,
        main_image_title: null,
        pdf_path: null,
        carousel: [],
        carousel_toDelete: [],
        loaded: null,
        loaded2: null,
        loaded3: null,
        loaded4: null,
        windowVue: null,
    }),
    computed: {
        ...mapGetters([
            'getProduct',
            'getCategorie',
            'getAllCategories',
            'get_building_purpose_type',
            'get_location',
            'get_price_type',
            'getAllPartners',
            'get_building_type',
            'get_house_condition_type',
            'get_property_developer',
            'get_land_type',
            'get_industrial_purpose_type',
            'getPriority',
        ]),
    },
    methods: {
        loadAllResorces() {
            this.$store.dispatch('LoadAllCategories');
            this.$store.dispatch('Load_building_purpose_type');
            this.$store.dispatch('Load_location');
            this.$store.dispatch('Load_price_type');
            this.$store.dispatch('Load_building_type');
            this.$store.dispatch('Load_house_condition_type');
            this.$store.dispatch('Load_property_developer');
            this.$store.dispatch('LoadAllPartners');
            this.$store.dispatch('Load_land_type');
            this.$store.dispatch('Load_industrial_purpose_type');
        },
        async CreateLocation(isActive) {
            let data = {
                name_ru: this.new_location.name_ru,
                name_ro: this.new_location.name_ro,
                name_en: this.new_location.name_en,
            }
            let responce = await this.$store.dispatch('create_location', data);

            if (responce.success == true) {
                await this.$store.dispatch('Load_location');
                isActive.value = false;
                this.alert_snackbar.state = true;
                this.alert_snackbar.text = "Локация " + this.new_location.name_ru + " успешно добавлена";
                this.alert_snackbar.color = "success";
                this.new_location.name_ru = null;
                this.new_location.name_ro = null;
                this.new_location.name_en = null;
            } else {
                this.alert_snackbar.state = true;
                this.alert_snackbar.text = responce.error ?? responce.message + " " + Object.values(responce.data);
                this.alert_snackbar.color = "error";
            }
        },
        async CreatePropertyDeveloper(isActive) {
            let data = {
                name: this.new_property_developer.name_en,
            }
            let responce = await this.$store.dispatch('create_property_developer', data);

            if (responce.success == true) {
                await this.$store.dispatch('Load_property_developer');
                isActive.value = false;
                this.alert_snackbar.state = true;
                this.alert_snackbar.text = "Застройщик " + this.new_property_developer.name_en + " успешно добавлен";
                this.alert_snackbar.color = "success";
                this.new_property_developer.name_en = null;
            } else {
                this.alert_snackbar.state = true;
                this.alert_snackbar.text = responce.message + " " + Object.values(responce.data);
                this.alert_snackbar.color = "error";
            }
        },
        async CreateProduct() {
            const { valid } = await this.$refs.form_create_property.validate()
            if (!valid) {
                alert('Введите все обьязательные данные')
                return
            }
            let vue = this;
            try {
                if (this.$route.name == 'edit-real-estate-crm') {
                    let data = {
                        name_ru: this.name_ru,
                        name_ro: this.name_ro,
                        name_en: this.name_en,
                        description_ru: this.description_ru,
                        description_ro: this.description_ro,
                        description_en: this.description_en,
                        meta_title_ru: this.meta_title_ru ? this.meta_title_ru.slice(0, 85) : this.name_ru.slice(0, 85),
                        meta_title_ro: this.meta_title_ro ? this.meta_title_ro.slice(0, 85) : this.name_ro.slice(0, 85),
                        meta_title_en: this.meta_title_en ? this.meta_title_en.slice(0, 85) : this.name_en.slice(0, 85),
                        meta_description_ru: this.meta_description_ru ? this.meta_description_ru.slice(0, 300) : this.description_ru.slice(0, 300),
                        meta_description_ro: this.meta_description_ro ? this.meta_description_ro.slice(0, 300) : this.description_ro.slice(0, 300),
                        meta_description_en: this.meta_description_en ? this.meta_description_en.slice(0, 300) : this.description_en.slice(0, 300),
                        ID_agent: this.ID_agent,
                        price: this.price,
                        ID_price_type: this.price_type,
                        alt: this.main_image_alt,
                        title: this.main_image_title,
                        ID_category: this.ID_category,
                        ID_location: this.ID_location,
                        area: this.area,
                        number_of_rooms: this.number_of_rooms ?? undefined,
                        ID_property_developer: this.property_developer ?? undefined,
                        ID_building_type: this.building_type ?? undefined,
                        ID_housing_condition_type: this.house_condition_type ?? undefined,
                        ID_land_type: this.land_type ?? undefined,
                        ID_industrial_purpose_type: this.industrial_purpose_type ?? undefined,
                        ID_building_purpose_type: this.building_purpose_type ?? undefined,
                        image_upload: this.main_image_path ?? undefined,
                        pdf_upload: this.pdf_path ?? undefined,
                    };
                    if (this.features_ru) {
                        this.features_ru.split(/,/g).map((features, index) => {
                            data[`features_ru[${index}]`] = features;
                        })
                    }
                    if (this.features_ro) {
                        this.features_ro.split(/,/g).map((features, index) => {
                            data[`features_ro[${index}]`] = features;
                        })
                    }
                    if (this.features_en) {
                        this.features_en.split(/,/g).map((features, index) => {
                            data[`features_en[${index}]`] = features;
                        })
                    }
                    this.carousel_toDelete.map((id, index) => {
                        data[`carousel_toDelete[${index}]`] = id;
                    })
                    this.carousel.map((image, index) => {
                        data[`carousel_IDs[${index}]`] = image.ID ?? -1;
                        data[`carousel_title[${index}]`] = image.title;
                        data[`carousel_alt[${index}]`] = image.alt;
                        data[`carousel_position[${index}]`] = index;
                        if (image.file) {
                            data[`images_upload[${index}]`] = image.file;
                        }
                    })
                    let responce = await this.$store.dispatch('UpdateProduct', { ID: this.getProduct.ID, data: data });
                    if (responce.success == true) {
                        this.alert_snackbar.state = true;
                        this.alert_snackbar.text = "Недвижимость успешно обновлена";
                        this.alert_snackbar.color = "success";
                    } else {
                        this.alert_snackbar.state = true;
                        this.alert_snackbar.text = responce.error ?? responce.message + " " + Object.values(responce.data);
                        this.alert_snackbar.color = "error";
                    }
                } else {
                    let data = {
                        name_ru: this.name_ru,
                        name_ro: this.name_ro,
                        name_en: this.name_en,
                        description_ru: this.description_ru,
                        description_ro: this.description_ro,
                        description_en: this.description_en,
                        meta_title_ru: this.meta_title_ru ? this.meta_title_ru.slice(0, 85) : this.name_ru.slice(0, 85),
                        meta_title_ro: this.meta_title_ro ? this.meta_title_ro.slice(0, 85) : this.name_ro.slice(0, 85),
                        meta_title_en: this.meta_title_en ? this.meta_title_en.slice(0, 85) : this.name_en.slice(0, 85),
                        meta_description_ru: this.meta_description_ru ? this.meta_description_ru.slice(0, 300) : this.description_ru.slice(0, 300),
                        meta_description_ro: this.meta_description_ro ? this.meta_description_ro.slice(0, 300) : this.description_ro.slice(0, 300),
                        meta_description_en: this.meta_description_en ? this.meta_description_en.slice(0, 300) : this.description_en.slice(0, 300),
                        ID_agent: this.ID_agent,
                        price: this.price,
                        ID_price_type: this.price_type,
                        alt: this.main_image_alt,
                        title: this.main_image_title,
                        ID_category: this.ID_category,
                        ID_location: this.ID_location,
                        area: this.area,
                        number_of_rooms: this.number_of_rooms ?? undefined,
                        ID_property_developer: this.property_developer ?? undefined,
                        ID_building_type: this.building_type ?? undefined,
                        ID_housing_condition_type: this.house_condition_type ?? undefined,
                        ID_land_type: this.land_type ?? undefined,
                        ID_industrial_purpose_type: this.industrial_purpose_type ?? undefined,
                        ID_building_purpose_type: this.building_purpose_type ?? undefined,
                        image_upload: this.main_image_path ?? undefined,
                        pdf_upload: this.pdf_path ?? undefined,
                    };
                    if (this.features_ru != null) {
                        this.features_ru.split(/,/g).map((features, index) => {
                            data[`features_ru[${index}]`] = features;
                        })
                    }
                    if (this.features_ro != null) {
                        this.features_ro.split(/,/g).map((features, index) => {
                            data[`features_ro[${index}]`] = features;
                        })
                    }
                    if (this.features_en != null) {
                        this.features_en.split(/,/g).map((features, index) => {
                            data[`features_en[${index}]`] = features;
                        })
                    }
                    this.carousel.map((image, index) => {
                        data[`images_upload[${index}]`] = image.file;
                        data[`carousel_title[${index}]`] = image.title;
                        data[`carousel_alt[${index}]`] = image.alt;
                        data[`carousel_position[${index}]`] = image.carousel_position;
                    })
                    let responce = await this.$store.dispatch('CreateProduct', data);
                    if (responce.success == true) {
                        this.alert_snackbar.state = true;
                        this.alert_snackbar.text = "Недвижимость успешно создана";
                        this.alert_snackbar.color = "success";

                        setTimeout(function () {
                            vue.$router.push({
                                name: "real-estate-crm",
                            })
                        }, 1500);
                    } else {
                        this.alert_snackbar.state = true;
                        this.alert_snackbar.text = responce.error ?? responce.message + " " + Object.values(responce.data);
                        this.alert_snackbar.color = "error";
                    }
                }
            } catch (error) {
                console.log(error);
                this.alert_snackbar.state = true;
                this.alert_snackbar.text = error.message;
                this.alert_snackbar.color = "error";
            }
        },
        Delete_property_developer(item, type) {
            this.dialogDeleteProperty.ID = item.raw.ID;
            this.dialogDeleteProperty.type = type;
            this.dialogDeleteProperty.title = item.raw.name ? item.raw.name : item.raw.name_ru;
            this.dialogDeleteProperty.state = true;
        },
        async DeletePropertyConfirm(data) {
            let responce = await this.$store.dispatch('property_type_delete', data);
            this.dialogDeleteProperty.state = false;
            this.dialogDeleteProperty.type = null;
            this.dialogDeleteProperty.title = null;
            this.dialogDeleteProperty.ID = null;
            if (responce.success == true) {
                this.loadAllResorces();
                this.alert_snackbar.state = true;
                this.alert_snackbar.text = "Обьект успешно был удалён с сайта";
                this.alert_snackbar.color = "success";
            } else {
                this.alert_snackbar.state = true;
                this.alert_snackbar.text = responce.message + " " + Object.values(responce.data);
                this.alert_snackbar.color = "error";
            }
        },
        removeImageInCarousel(image, index) {
            this.carousel.splice(index, 1);
            this.carousel_toDelete.push(image.ID);
        },
        addNewImageInCarousel() {
            this.carousel.push({
                carousel_position: this.carousel.length,
                alt: "",
                title: "",
            })
        },
        GetCategorieData(id) {
            this.$store.dispatch('LoadCategorie', id);
        }
    },
    async beforeCreate() {
        if (this.$route.params.id) {
            await this.$store.dispatch('LoadProduct', { type: "", value: this.$route.params.id });
            this.name_ru = this.getProduct.name_ru;
            this.name_ro = this.getProduct.name_ro;
            this.name_en = this.getProduct.name_en;

            this.description_ru = this.getProduct.description_ru;
            this.description_ro = this.getProduct.description_ro;
            this.description_en = this.getProduct.description_en;

            this.meta_title_ru = this.getProduct.meta_title_ru;
            this.meta_title_ro = this.getProduct.meta_title_ro;
            this.meta_title_en = this.getProduct.meta_title_en;

            this.meta_description_ru = this.getProduct.meta_description_ru;
            this.meta_description_ro = this.getProduct.meta_description_ro;
            this.meta_description_en = this.getProduct.meta_description_en;

            this.ID_category = this.getProduct.ID_category;
            this.ID_location = this.getProduct.ID_location;

            this.price = this.getProduct.price;
            this.ID_agent = this.getProduct.ID_agent;
            this.property_developer = this.getProduct.ID_property_developer;

            this.building_purpose_type = this.getProduct.ID_building_purpose_type;
            this.house_condition_type = this.getProduct.ID_housing_condition_type;
            this.price_type = this.getProduct.ID_price_type;
            this.building_type = this.getProduct.ID_building_type;
            this.number_of_rooms = this.getProduct.number_of_rooms;
            this.land_type = this.getProduct.ID_land_type;
            this.industrial_purpose_type = this.getProduct.ID_industrial_purpose_type;

            this.area = this.getProduct.area;

            this.main_image_alt = this.getProduct.main_image_alt;
            this.main_image_title = this.getProduct.main_image_title;

            this.features_ru = this.getProduct.features_ru.replace(/\$\$\$/g, ',');
            this.features_ro = this.getProduct.features_ro.replace(/\$\$\$/g, ',');
            this.features_en = this.getProduct.features_en.replace(/\$\$\$/g, ',');

            this.carousel = this.getProduct.carousel;
            this.$store.dispatch('LoadCategorie', this.ID_category);

        } else {
            this.$store.dispatch('LoadProduct');
        }

    },
    mounted() {
        this.windowVue = window;
        let vue = this;
        this.loadAllResorces();
        setTimeout(() => { vue.loaded = true }, 100)
        setTimeout(() => { vue.loaded2 = true }, 500)
        setTimeout(() => { vue.loaded3 = true }, 1000)
        setTimeout(() => { vue.loaded4 = true }, 1500)
    },
})
</script>
<style>
.list-items-selects .v-list-item__content .v-list-item-title:not(.list-items-selects-title) {
    display: none !important;
}
</style>
<style scoped>
.title_property_big {
    font-weight: 500;
    font-size: 1.4rem;
}

.title_property {
    font-weight: 500;
    font-size: 1.1rem;
}

.divider-bottom {
    border: 1px solid rgba(0, 0, 0, 0.2);
    opacity: 1;
}

.btn-text {
    text-transform: inherit;
}

.input-search {
    min-width: 300px;
}

.select-search {
    min-width: 200px;
    max-width: 400px;
}

.col-header-gap {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 10px;
}

.w-100 {
    min-width: 100%;
}

@media screen and (max-width: 1400px) {
    .input-search {
        min-width: 140px;
    }
}
</style>

<style>
.input-search .v-field__input,
.select-search .v-field__input {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    min-height: 36px;
}
</style>