<template>
    <v-container fluid class="pt-10 fill-height" v-if="getPriority < 4">
        <v-row class="w-100" justify="center">
            <v-col cols="12">
                <h1>Партнеры</h1>
            </v-col>
        </v-row>
        <v-row justify="space-between" class="w-100">
            <v-col cols="auto">
                <v-btn color="#0255B7" rounded="lg" class="btn-text" @click="AgentCreateOpenDialog()">
                    Добавить партнера
                </v-btn>
                <v-btn color="#0255B7" rounded="lg" class="btn-text ml-3" @click="AgentTransferOpenDialog()">
                    Передать объекты другому партнеру
                    <svg class="ml-2" width="16" height="17" viewBox="0 0 16 17" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M1 12C1 12.2761 1.22386 12.5 1.5 12.5H13.2929L10.1464 15.6464C9.95118 15.8417 9.95118 16.1583 10.1464 16.3536C10.3417 16.5488 10.6583 16.5488 10.8536 16.3536L14.8536 12.3536C15.0488 12.1583 15.0488 11.8417 14.8536 11.6464L10.8536 7.64645C10.6583 7.45118 10.3417 7.45118 10.1464 7.64645C9.95118 7.84171 9.95118 8.15829 10.1464 8.35355L13.2929 11.5H1.5C1.22386 11.5 1 11.7239 1 12Z"
                            fill="#F4F4F4" />
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M15 5C15 5.27614 14.7761 5.5 14.5 5.5L2.70711 5.5L5.85355 8.64645C6.04882 8.84171 6.04882 9.15829 5.85355 9.35355C5.65829 9.54882 5.34171 9.54882 5.14645 9.35355L1.14645 5.35355C0.951184 5.15829 0.951184 4.84171 1.14645 4.64645L5.14645 0.646447C5.34171 0.451184 5.65829 0.451184 5.85355 0.646447C6.04882 0.841709 6.04882 1.15829 5.85355 1.35355L2.70711 4.5L14.5 4.5C14.7761 4.5 15 4.72386 15 5Z"
                            fill="#F4F4F4" />
                    </svg>

                </v-btn>
            </v-col>
            <v-col cols="auto" class="col-header-gap" v-if="getAllPartnersCRM.length > 0">
                <v-autocomplete v-model:search="search_value" :items="getAllPartnersCRM" hide-no-data clearable="true"
                    item-title="name_ru" hide-details density="compact" rounded="lg" label="Поиск по имени"
                    variant="outlined" class="input-search" color="#6C757D">
                    <template v-slot:item="{ props, item }">
                        <v-list-item class="search_autocomplete_item" v-bind="props" size="x-large"
                            @click="AgentEditOpenDialog(item.raw)">
                            <template v-slot:prepend>
                                <v-avatar color="grey" rounded="0" :size="$vuetify.display.smAndUp ? '55' : '35'">
                                    <v-img :src="urlAPI + item.raw.path" cover></v-img>
                                </v-avatar>
                            </template>
                        </v-list-item>
                    </template>
                </v-autocomplete>
            </v-col>
        </v-row>
        <v-row class="my-4 mx-0 w-100" justify="center">
            <v-table :height="getAllPartnersCRM.length < limit_view ? '65vh' : 'auto'" fixed-header class="table" hover>
                <thead>
                    <tr>
                        <th class="text-left">
                            Фото профиля
                        </th>
                        <th class="text-left">
                            Имя на латинице
                        </th>
                        <th class="text-left">
                            Имя на киррилице
                        </th>
                        <th class="text-left">
                            ID:
                        </th>
                        <th class="text-left">
                            Номер телефона
                        </th>
                        <th class="text-left">
                            Количество объектов
                        </th>
                        <th class="text-left">
                            Действия
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in getAllPartnersCRM.slice((page_view - 1) * limit_view, page_view * limit_view)"
                        :key="item.ID">
                        <td>
                            <v-img width="80px" :src="urlAPI + item.path">
                            </v-img>
                        </td>
                        <td style="max-width: 200px;">{{ item.name_ro }}</td>
                        <td style="max-width: 200px;">{{ item.name_ru }}</td>
                        <td style="max-width: 200px;">{{ item.ID }}</td>
                        <td>{{ item.phone }}</td>
                        <td>{{ item.property_count }}</td>
                        <td style="text-wrap: nowrap;">
                            <v-col cols="auto" style="flex-direction: column; display: flex;">
                                <v-icon @click="AgentEditOpenDialog(item)">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M17.37 6.18658C17.5433 6.35987 17.5433 6.64084 17.37 6.81413L16.4445 7.73968L14.6695 5.9647L15.595 5.03915C15.7683 4.86586 16.0493 4.86586 16.2226 5.03915L17.37 6.18658Z"
                                            fill="#408ACB" />
                                        <path
                                            d="M15.8169 8.36723L14.042 6.59225L7.99548 12.6387C7.94677 12.6874 7.91007 12.7468 7.88828 12.8122L7.17424 14.9543C7.11642 15.1277 7.28144 15.2928 7.45489 15.2349L9.59701 14.5209C9.66236 14.4991 9.72175 14.4624 9.77046 14.4137L15.8169 8.36723Z"
                                            fill="#408ACB" />
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M4.5 16.4466C4.5 17.1818 5.09601 17.7778 5.83124 17.7778H15.5936C16.3289 17.7778 16.9249 17.1818 16.9249 16.4466V11.1216C16.9249 10.8765 16.7262 10.6779 16.4811 10.6779C16.2361 10.6779 16.0374 10.8765 16.0374 11.1216V16.4466C16.0374 16.6916 15.8387 16.8903 15.5936 16.8903H5.83124C5.58616 16.8903 5.38749 16.6916 5.38749 16.4466V6.68416C5.38749 6.43909 5.58616 6.24042 5.83124 6.24042H11.5999C11.845 6.24042 12.0437 6.04174 12.0437 5.79667C12.0437 5.5516 11.845 5.35293 11.5999 5.35293H5.83124C5.09601 5.35293 4.5 5.94894 4.5 6.68416V16.4466Z"
                                            fill="#408ACB" />
                                    </svg>
                                </v-icon>
                                <v-icon @click="AgentInfo(item)">
                                    <svg width="15" height="12" viewBox="0 0 15 12" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M0 2.17684C0 1.16432 0.839466 0.343506 1.875 0.343506H13.125C14.1605 0.343506 15 1.16432 15 2.17684V9.51017C15 10.5227 14.1605 11.3435 13.125 11.3435H1.875C0.839467 11.3435 0 10.5227 0 9.51017V2.17684ZM8.4375 3.55184C8.4375 3.29871 8.64737 3.09351 8.90625 3.09351H12.6562C12.9151 3.09351 13.125 3.29871 13.125 3.55184C13.125 3.80497 12.9151 4.01017 12.6562 4.01017H8.90625C8.64737 4.01017 8.4375 3.80497 8.4375 3.55184ZM8.4375 5.84351C8.4375 5.59038 8.64737 5.38517 8.90625 5.38517H12.6562C12.9151 5.38517 13.125 5.59038 13.125 5.84351C13.125 6.09664 12.9151 6.30184 12.6562 6.30184H8.90625C8.64737 6.30184 8.4375 6.09664 8.4375 5.84351ZM9.375 8.13517C9.375 7.88204 9.58487 7.67684 9.84375 7.67684H12.6562C12.9151 7.67684 13.125 7.88204 13.125 8.13517C13.125 8.3883 12.9151 8.59351 12.6562 8.59351H9.84375C9.58487 8.59351 9.375 8.3883 9.375 8.13517ZM8.43749 9.96851C8.43749 10.1241 8.42454 10.2771 8.39951 10.4268H1.87499C1.41522 10.4268 1.03275 10.1032 0.952835 9.67626C1.12539 8.04131 2.73157 6.76017 4.68749 6.76017C6.75855 6.76017 8.43749 8.19659 8.43749 9.96851ZM6.56249 4.01017C6.56249 5.02269 5.72302 5.84351 4.68749 5.84351C3.65195 5.84351 2.81249 5.02269 2.81249 4.01017C2.81249 2.99765 3.65195 2.17684 4.68749 2.17684C5.72302 2.17684 6.56249 2.99765 6.56249 4.01017Z"
                                            fill="#6C757D" />
                                    </svg>

                                </v-icon>
                                <v-icon @click="CategorieOpenDialogDelete(item)">
                                    <svg width="22" height="24" viewBox="0 0 22 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M5.5 5.85463C4.94772 5.85463 4.5 6.27793 4.5 6.80009V7.74554C4.5 8.2677 4.94772 8.691 5.5 8.691H6V17.2001C6 18.2444 6.89543 19.091 8 19.091H14C15.1046 19.091 16 18.2444 16 17.2001V8.691H16.5C17.0523 8.691 17.5 8.2677 17.5 7.74554V6.80009C17.5 6.27793 17.0523 5.85463 16.5 5.85463H13C13 5.33247 12.5523 4.90918 12 4.90918H10C9.44772 4.90918 9 5.33247 9 5.85463H5.5ZM8.5 9.63645C8.77614 9.63645 9 9.8481 9 10.1092V16.7274C9 16.9884 8.77614 17.2001 8.5 17.2001C8.22386 17.2001 8 16.9884 8 16.7274L8 10.1092C8 9.8481 8.22386 9.63645 8.5 9.63645ZM11 9.63645C11.2761 9.63645 11.5 9.8481 11.5 10.1092V16.7274C11.5 16.9884 11.2761 17.2001 11 17.2001C10.7239 17.2001 10.5 16.9884 10.5 16.7274V10.1092C10.5 9.8481 10.7239 9.63645 11 9.63645ZM14 10.1092V16.7274C14 16.9884 13.7761 17.2001 13.5 17.2001C13.2239 17.2001 13 16.9884 13 16.7274V10.1092C13 9.8481 13.2239 9.63645 13.5 9.63645C13.7761 9.63645 14 9.8481 14 10.1092Z"
                                            fill="#E05757" />
                                    </svg>
                                </v-icon>
                            </v-col>
                        </td>
                    </tr>
                </tbody>
            </v-table>
        </v-row>
        <v-row justify="space-between" class="w-100" v-if="getAllPartnersCRM.length > limit_view">
            <v-col cols="auto">
                <v-btn color="#00000018" rounded="lg" elevation="0" class="btn-text" append-icon="mdi-reply"
                    @click="MinusPage()">
                    Предыдущая страница
                </v-btn>
            </v-col>
            <v-col cols="auto">
                <p> Страница: {{ page_view }}</p>
            </v-col>
            <v-col cols="auto">
                <v-btn color="#00000018" rounded="lg" elevation="0" class="btn-text" append-icon="mdi-share"
                    @click="PlusPage()">
                    Следующая страница
                </v-btn>
            </v-col>
        </v-row>
        <v-divider class="mt-4 mb-2 divider-bottom"></v-divider>
        <v-row class="my-0">
            <v-col cols="auto">
                <p>Всего партнеров: {{ getAllPartnersCRM.length }}</p>
            </v-col>
        </v-row>
        <v-dialog v-model="dialogDeleteAgent.state" max-width="700">
            <v-card class="pa-6 py-md-10 text-center" rounded="lg">
                <h2 class="mb-3">Вы уверены что хотите удалить агента ?</h2>
                <v-row justify="center" class="ma-0">
                    <v-col cols="3">
                        <v-img :src="urlAPI + dialogDeleteAgent.agent.path" rounded="lg"></v-img>
                    </v-col>
                    <v-col cols="6" align-self="center">
                        <h4> {{ dialogDeleteAgent.agent.name_ru }}</h4>
                    </v-col>
                </v-row>
                <h4>Внимание: </h4>
                <v-card-text class="pt-0">Это действие не подлежат возврату!</v-card-text>
                <h6 v-if="dialogDeleteAgent.agent.property_count > 0">
                    Удаление невозможно, вначале передайте продукты другому партнёру
                </h6>
                <v-card-actions>
                    <v-row justify="center">
                        <v-col cols="6">
                            <v-btn color="#E05757" class="btn-text" variant="flat" flat rounded="lg" block
                                text="Удалить навсегда" :disabled="dialogDeleteAgent.agent.property_count > 0"
                                @click="DeleteAgent(dialogDeleteAgent.agent.ID)"></v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn color="#C6C6C6" class="btn-text" variant="flat" flat rounded="lg" block
                                text="Подумать" @click="dialogDeleteAgent.state = false"></v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
                <v-card-text>
                    <i>
                        При нажатии “Удалить навсегда” агент полносью исчезнет из списка и сайта.
                    </i>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogAddAgent.state" max-width="700">
            <v-card class="pa-6 pb-md-10 text-center" rounded="lg">
                <v-row justify="end">
                    <v-col cols="6" sm="4">
                        <v-btn color="#6C757D" flat variant="text" text="Закрыть" prepend-icon="mdi-close"
                            @click="dialogAddAgent.state = false, dialogAddAgent.agent = {}"></v-btn>
                    </v-col>
                </v-row>
                <h2 class="mb-6">Добавить нового партнера</h2>
                <v-form v-model="valid" ref="form">

                    <v-text-field v-model="dialogAddAgent.agent.name_ru" variant="outlined" density="compact"
                        label="Фамилия и Имя (Киррилице)" prepend-inner-icon="mdi-account-tie">
                    </v-text-field>
                    <v-text-field v-model="dialogAddAgent.agent.name_ro" variant="outlined" density="compact"
                        label="Name and Surname" prepend-inner-icon="mdi-account-tie">
                    </v-text-field>
                    <v-text-field :rules="phoneRules" v-model="dialogAddAgent.agent.phone" variant="outlined"
                        density="compact" prepend-inner-icon="mdi-phone" label="Номер телефона">
                    </v-text-field>
                    <div class="mb-3 mt-0">
                        <v-card-text class="title_property_big text-left">
                            Фотография
                        </v-card-text>
                        <v-card-subtitle class="mt-0 pt-0 pb-0 text-left">
                            Размер фотографии не должен превышать 200 кб . и 400X400 px
                        </v-card-subtitle>
                        <v-row>
                            <v-col :cols="dialogAddAgent.agent.path || dialogAddAgent.agent.new_path ? '10' : '12'"
                                align-self="center">
                                <v-file-input v-model="dialogAddAgent.agent.new_path" label="Upload file" flat show-size
                                    clearable variant="solo-filled" hide-details></v-file-input>
                            </v-col>
                            <v-col cols="2" v-if="dialogAddAgent.agent.path || dialogAddAgent.agent.new_path">
                                <v-img rounded="lg" width="100%"
                                    :src="dialogAddAgent.agent.new_path ? windowVue.URL.createObjectURL(dialogAddAgent.agent.new_path) : urlAPI + dialogAddAgent.agent.path"></v-img>
                            </v-col>
                        </v-row>
                        <v-row class="ma-0">
                            <v-col cols="6">
                                <v-text-field v-model="dialogAddAgent.agent.title" variant="outlined" density="compact"
                                    hide-details label="title">
                                </v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field v-model="dialogAddAgent.agent.alt" variant="outlined" density="compact"
                                    hide-details label="alt">
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </div>
                </v-form>

                <v-card-actions class="mt-4">
                    <v-btn color="#0255B7" class="btn-text" size="large" variant="flat" flat rounded="lg" block
                        text="Сохранить"
                        :disabled="!dialogAddAgent.agent.name_ru || !dialogAddAgent.agent.name_ro || !dialogAddAgent.agent.phone || !dialogAddAgent.agent.new_path || !dialogAddAgent.agent.alt || !dialogAddAgent.agent.title || !valid"
                        @click="CreateAgent(dialogAddAgent.agent)"></v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogEditAgent.state" max-width="700">
            <v-card class="pa-6 pb-md-10 text-center" rounded="lg">
                <v-row justify="end">
                    <v-col cols="6" sm="4">
                        <v-btn color="#6C757D" flat variant="text" text="Закрыть" prepend-icon="mdi-close"
                            @click="dialogEditAgent.state = false"></v-btn>
                    </v-col>
                </v-row>
                <h2 class="mb-6">Редактировать партнёра</h2>
                <v-form v-model="valid2" ref="formEditLead">
                    <v-text-field v-model="dialogEditAgent.agent.name_ru" variant="outlined" density="compact"
                        label="Фамилия и Имя (Киррилице)" prepend-inner-icon="mdi-account-tie">
                    </v-text-field>
                    <v-text-field v-model="dialogEditAgent.agent.name_ro" variant="outlined" density="compact"
                        label="Name and Surname" prepend-inner-icon="mdi-account-tie">
                    </v-text-field>
                    <v-text-field :rules="phoneRules" v-model="dialogEditAgent.agent.phone" variant="outlined"
                        density="compact" prepend-inner-icon="mdi-phone" label="Номер телефона">
                    </v-text-field>

                    <div class="mb-3 mt-0">
                        <v-card-text class="title_property_big text-left">
                            Фотография
                        </v-card-text>
                        <v-card-subtitle class="mt-0 pt-0 pb-0 text-left">
                            Размер фотографии не должен превышать 200 кб . и 400X400 px
                        </v-card-subtitle>
                        <v-row>
                            <v-col :cols="dialogEditAgent.agent.path || dialogEditAgent.agent.new_path ? '10' : '12'"
                                align-self="center">
                                <v-file-input v-model="dialogEditAgent.agent.new_path" label="Upload file" flat
                                    show-size clearable variant="solo-filled" hide-details></v-file-input>
                            </v-col>
                            <v-col cols="2" v-if="dialogEditAgent.agent.path || dialogEditAgent.agent.new_path">
                                <v-img rounded="lg" width="100%"
                                    :src="dialogEditAgent.agent.new_path ? windowVue.URL.createObjectURL(dialogEditAgent.agent.new_path) : urlAPI + dialogEditAgent.agent.path"></v-img>
                            </v-col>
                        </v-row>
                        <v-row class="ma-0">
                            <v-col cols="6">
                                <v-text-field v-model="dialogEditAgent.agent.title" variant="outlined" density="compact"
                                    hide-details label="title">
                                </v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field v-model="dialogEditAgent.agent.alt" variant="outlined" density="compact"
                                    hide-details label="alt">
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </div>

                </v-form>

                <v-row class="mt-3">
                    <v-btn color="#C4C4C4" class="mb-3 btn-text" variant="flat" flat rounded="lg" block
                        text="Отменить редактирование" @click="dialogEditAgent.state = false"></v-btn>
                </v-row>
                <v-row>
                    <v-btn color="#0255B7" size="large" flat block variant="flat" rounded="lg" text="Сохранить"
                        class="btn-text"
                        :disabled="!dialogEditAgent.agent.path || !dialogEditAgent.agent.alt || !dialogEditAgent.agent.title || !dialogEditAgent.agent.phone || !dialogEditAgent.agent.name_ru || !dialogEditAgent.agent.name_ro"
                        @click="SaveEditAgent(dialogEditAgent.agent)"></v-btn>
                </v-row>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogInfoAgent.state" max-width="900">
            <v-card class="pa-6 py-md-10 text-center" rounded="lg">
                <v-row justify="end">
                    <v-col cols="6" sm="4">
                        <v-btn color="#6C757D" flat variant="text" text="Закрыть" prepend-icon="mdi-close"
                            @click="dialogInfoAgent.state = false"></v-btn>
                    </v-col>
                </v-row>
                <v-row justify="center" class="ma-0">
                    <v-col cols="6" sm="3">
                        <v-img :src="urlAPI + dialogInfoAgent.agent.path" rounded="lg"></v-img>
                    </v-col>
                </v-row>
                <v-row justify="center" class="mb-4">
                    <h2 class="mb-3">Агент №{{ dialogInfoAgent.agent.ID }}</h2>
                </v-row>

                <v-text-field readonly v-model="dialogInfoAgent.agent.name_ru" variant="outlined" density="compact"
                    label="Имя на киррилице" rounded="lg">
                </v-text-field>
                <v-text-field readonly v-model="dialogInfoAgent.agent.name_ro" variant="outlined" density="compact"
                    label="Имя на латинице" rounded="lg">
                </v-text-field>
                <v-text-field readonly v-model="dialogInfoAgent.agent.phone" variant="outlined" density="compact"
                    label="Номер телефона" rounded="lg">
                </v-text-field>
                <v-row class="my-0">
                    <v-col cols="12" sm="6" class="py-0">
                        <v-text-field readonly v-model="dialogInfoAgent.agent.alt" variant="outlined" density="compact"
                            label="Image Alt" rounded="lg">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" class="py-0">
                        <v-text-field readonly v-model="dialogInfoAgent.agent.title" variant="outlined"
                            density="compact" label="Image Title" rounded="lg">
                        </v-text-field>
                    </v-col>
                </v-row>


                <v-row justify="center">
                    <v-col cols="12" class="py-0">
                        <v-row justify="center">
                            <v-col cols="auto">
                                <h4>Объекты партнера</h4>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="12">
                                <v-table height="400px" fixed-header class="table" hover>
                                    <thead>
                                        <tr>
                                            <th class="text-left">
                                                №
                                            </th>
                                            <th class="text-left">
                                                Изображение
                                            </th>
                                            <th class="text-left">
                                                Название объекта
                                            </th>
                                            <th class="text-left">
                                                Действия
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in getRealEstateByAgent" :key="item.ID">
                                            <td style="max-width: 200px;">{{ item.ID }}</td>
                                            <td width="80px">
                                                <v-img width="80px" :src="urlAPI + item.main_image_path">
                                                </v-img>
                                            </td>
                                            <td style="max-width: 200px;">{{ item.property_ru }}</td>
                                            <td style="text-wrap: nowrap;">
                                                <v-col cols="auto" style="flex-direction: column; display: flex;">
                                                    <v-icon @click="GoToRealEstate(item.ID)">
                                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M17.37 6.18658C17.5433 6.35987 17.5433 6.64084 17.37 6.81413L16.4445 7.73968L14.6695 5.9647L15.595 5.03915C15.7683 4.86586 16.0493 4.86586 16.2226 5.03915L17.37 6.18658Z"
                                                                fill="#408ACB" />
                                                            <path
                                                                d="M15.8169 8.36723L14.042 6.59225L7.99548 12.6387C7.94677 12.6874 7.91007 12.7468 7.88828 12.8122L7.17424 14.9543C7.11642 15.1277 7.28144 15.2928 7.45489 15.2349L9.59701 14.5209C9.66236 14.4991 9.72175 14.4624 9.77046 14.4137L15.8169 8.36723Z"
                                                                fill="#408ACB" />
                                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                                d="M4.5 16.4466C4.5 17.1818 5.09601 17.7778 5.83124 17.7778H15.5936C16.3289 17.7778 16.9249 17.1818 16.9249 16.4466V11.1216C16.9249 10.8765 16.7262 10.6779 16.4811 10.6779C16.2361 10.6779 16.0374 10.8765 16.0374 11.1216V16.4466C16.0374 16.6916 15.8387 16.8903 15.5936 16.8903H5.83124C5.58616 16.8903 5.38749 16.6916 5.38749 16.4466V6.68416C5.38749 6.43909 5.58616 6.24042 5.83124 6.24042H11.5999C11.845 6.24042 12.0437 6.04174 12.0437 5.79667C12.0437 5.5516 11.845 5.35293 11.5999 5.35293H5.83124C5.09601 5.35293 4.5 5.94894 4.5 6.68416V16.4466Z"
                                                                fill="#408ACB" />
                                                        </svg>
                                                    </v-icon>
                                                </v-col>
                                            </td>
                                        </tr>
                                    </tbody>
                                </v-table>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>

            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogTransferAgent.state" max-width="700">
            <v-card class="pa-6 py-md-10 text-center" rounded="lg">
                <v-row justify="end">
                    <v-col cols="6" sm="4">
                        <v-btn color="#6C757D" flat variant="text" text="Закрыть" prepend-icon="mdi-close"
                            @click="dialogTransferAgent.state = false"></v-btn>
                    </v-col>
                </v-row>
                <strong>От кого:</strong>
                <v-row justify="center" class="ma-0">
                    <v-col cols="12" sm="10" md="6" class="pa-0">
                        <v-expand-transition>
                            <v-card v-show="dialogTransferAgent.from.ID" class="pa-3 mt-3" color="#fafafa" elevation="5"
                                rounded="lg" @click="dialogTransferAgent.from = {}">
                                <v-row justify="center">
                                    <v-col cols="3" align-self="center">
                                        <v-img :src="urlAPI + dialogTransferAgent.from.path" rounded="lg"></v-img>
                                    </v-col>
                                    <v-col cols="9" align-self="center">
                                        <v-card-title>
                                            {{ dialogTransferAgent.from.name_ru }}
                                        </v-card-title>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-expand-transition>
                    </v-col>
                </v-row>
                <v-expand-transition>
                    <v-row justify="center" v-show="!dialogTransferAgent.from.ID">
                        <v-col cols="12" class="pt-0">
                            <h2 class="pt-0">Выберите агента из списка: </h2>
                        </v-col>
                        <v-col cols="12">
                            <v-table height="250px" fixed-header class="table" hover>
                                <thead>
                                    <tr>
                                        <th class="text-center">
                                            Изображение
                                        </th>
                                        <th class="text-center">
                                            Имя
                                        </th>
                                        <th class="text-center">
                                            Количество объектов
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="agent in getAllPartnersCRM" :key="agent.ID"
                                        @click="dialogTransferAgent.from = agent"
                                        v-show="agent.ID != dialogTransferAgent.to.ID && agent.property_count > 0">
                                        <td width="100px" class="py-2">
                                            <v-row justify="center">
                                                <v-col cols="8">
                                                    <v-img :src="urlAPI + agent.path" rounded="lg" aspect-ratio="1"
                                                        cover>
                                                    </v-img>
                                                </v-col>
                                            </v-row>
                                        </td>
                                        <td style="max-width: 200px;">{{ agent.name_ru }}</td>
                                        <td style="max-width: 200px;">{{ agent.property_count }}</td>
                                    </tr>
                                </tbody>
                            </v-table>
                        </v-col>
                    </v-row>
                </v-expand-transition>

                <strong class="mt-6">Кому:</strong>
                <v-row justify="center" class="ma-0">
                    <v-col cols="12" sm="10" md="6" class="pa-0">
                        <v-expand-transition>
                            <v-card v-show="dialogTransferAgent.to.ID" class="pa-3 mt-3" color="#fafafa" elevation="5"
                                rounded="lg" @click="dialogTransferAgent.to = {}">
                                <v-row justify="center">
                                    <v-col cols="3" align-self="center">
                                        <v-img :src="urlAPI + dialogTransferAgent.to.path" rounded="lg"></v-img>
                                    </v-col>
                                    <v-col cols="9" align-self="center">
                                        <v-card-title>
                                            {{ dialogTransferAgent.to.name_ru }}
                                        </v-card-title>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-expand-transition>
                    </v-col>
                </v-row>
                <v-expand-transition>
                    <v-row justify="center" v-show="!dialogTransferAgent.to.ID">
                        <v-col cols="12">
                            <h2>Выберите агента из списка: </h2>
                        </v-col>
                        <v-col cols="12">
                            <v-table height="250px" fixed-header class="table" hover>
                                <thead>
                                    <tr>
                                        <th class="text-center">
                                            Изображение
                                        </th>
                                        <th class="text-center">
                                            Имя
                                        </th>
                                        <th class="text-center">
                                            Количество объектов
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="agent in getAllPartnersCRM" :key="agent.ID"
                                        @click="dialogTransferAgent.to = agent"
                                        v-show="agent.ID != dialogTransferAgent.from.ID">
                                        <td width="100px" class="py-2">
                                            <v-row justify="center">
                                                <v-col cols="8">
                                                    <v-img :src="urlAPI + agent.path" rounded="lg" aspect-ratio="1"
                                                        cover>
                                                    </v-img>
                                                </v-col>
                                            </v-row>
                                        </td>
                                        <td style="max-width: 200px;">{{ agent.name_ru }}</td>
                                        <td style="max-width: 200px;">{{ agent.property_count }}</td>
                                    </tr>
                                </tbody>
                            </v-table>
                        </v-col>
                    </v-row>
                </v-expand-transition>
                <v-card-actions class="mt-6">
                    <v-row justify="center">
                        <v-col cols="12" sm="8">
                            <v-btn color="#0255B7" variant="flat" class="btn-text" block flat
                                @click="TransferObjects(dialogTransferAgent.from.ID, dialogTransferAgent.to.ID)"
                                :disabled="!dialogTransferAgent.from.ID || !dialogTransferAgent.to.ID" rounded="lg">
                                Передать все обьекты
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
                <h4 class="mt-3">Внимание: </h4>
                <v-card-text class="pt-0">Это действие не подлежат возврату!</v-card-text>
            </v-card>
        </v-dialog>
        <v-snackbar :timeout="2000" location="top right" :color="alert_snackbar.color" variant="flat"
            v-model="alert_snackbar.state">
            <strong>{{ alert_snackbar.text }}</strong>
        </v-snackbar>
    </v-container>
</template>
<script>
import { defineComponent } from "vue";
import { useLocale } from "vuetify";
import { mapGetters } from 'vuex'
import { urlAPI } from "../../store/config";

export default defineComponent({
    name: "AgentsCRM",
    setup() {
        const { t } = useLocale();
        return {
            t,
        };
    },
    data: () => ({
        urlAPI: urlAPI + '/',
        page_view: 1,
        limit_view: 10,
        search_value: "",
        is_archived: false,
        phoneRules: [
            v => !!v || 'Phone is required',
            v => v.length >= 7 || 'Phone > 8 characters',
            (value) => {
                const regex = /^[\\+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,6}$/;
                if (regex.test(value)) {
                    return true;
                }
                return 'Phone number is invalid';
            }
        ],
        dialogDeleteAgent: {
            state: false,
            agent: {}
        },
        dialogAddAgent: {
            state: false,
            agent: {
                name_ro: null,
                name_ru: null,
                phone: null,
                new_path: null,
                alt: null,
                title: null,
            },
        },
        dialogEditAgent: {
            state: false,
            agent: {
                name_ro: null,
                name_ru: null,
                phone: null,
                path: null,
                new_path: null,
                alt: null,
                title: null,
            },
        },
        dialogInfoAgent: {
            state: false,
            agent: {}
        },
        dialogTransferAgent: {
            state: false,
            from: {},
            to: {}
        },
        alert_snackbar: {
            state: false,
            color: "",
            text: ""
        },
    }),
    computed: {
        ...mapGetters([
            'getAllPartnersCRM',
            'getAllProducts',
            'getPartner',
            'getRealEstateByAgent',
            'getPriority',
        ]),
    },
    methods: {
        async TransferObjects(from, to) {
            let responce = await this.$store.dispatch('TransferRealEstateAgent', { from: from, to: to });
            if (responce.success == true) {
                this.alert_snackbar.state = true;
                this.alert_snackbar.text = "Передача обьектов успешна";
                this.alert_snackbar.color = "success";
                await this.$store.dispatch('LoadAllAgentsCRM');
                this.dialogTransferAgent = {
                    state: false,
                    from: {},
                    to: {}
                }
            } else {
                this.alert_snackbar.state = true;
                this.alert_snackbar.text = responce.error ?? responce.message + " " + Object.values(responce.data);
                this.alert_snackbar.color = "error";
            }
        },
        AgentTransferOpenDialog() {
            this.dialogTransferAgent = {
                state: true,
                from: {},
                to: {}
            }
        },
        AgentCreateOpenDialog() {
            this.dialogAddAgent.state = true;
            this.dialogAddAgent.agent = {};
        },
        AgentEditOpenDialog(agent) {
            this.dialogEditAgent.agent.ID = agent.ID;
            this.dialogEditAgent.agent.name_ro = agent.name_ro;
            this.dialogEditAgent.agent.name_ru = agent.name_ru;
            this.dialogEditAgent.agent.phone = agent.phone;
            this.dialogEditAgent.agent.path = agent.path;
            this.dialogEditAgent.agent.alt = agent.alt;
            this.dialogEditAgent.agent.title = agent.title;
            this.dialogEditAgent.agent.new_path = null;
            this.dialogEditAgent.state = true;
        },
        async SaveEditAgent(agent) {
            let data = {
                name_ru: agent.name_ru ?? undefined,
                name_ro: agent.name_ro ?? undefined,
                phone: agent.phone ?? undefined,
                alt: agent.alt ?? undefined,
                title: agent.title ?? undefined,
                image_upload: agent.new_path ?? undefined,
            }
            let responce = await this.$store.dispatch('SaveAgentPut', { ID: agent.ID, data: data });
            if (responce.success == true) {
                this.alert_snackbar.state = true;
                this.alert_snackbar.text = "Агент был обновлён";
                this.alert_snackbar.color = "success";
                await this.$store.dispatch('LoadAllAgentsCRM');
                this.dialogEditAgent.state = false;
                this.dialogEditAgent.new_path = null;
                this.dialogEditAgent.path = null;
            } else {
                this.alert_snackbar.state = true;
                this.alert_snackbar.text = responce.error ?? responce.message + " " + Object.values(responce.data);
                this.alert_snackbar.color = "error";
            }
        },
        async CreateAgent(agent) {
            let data = {
                name_ru: agent.name_ru,
                name_ro: agent.name_ro,
                phone: agent.phone,
                alt: agent.alt ?? undefined,
                title: agent.title ?? undefined,
                image_upload: agent.new_path ?? undefined,
            };
            let responce = await this.$store.dispatch('CreateAgent', data);
            if (responce.success == true) {
                this.alert_snackbar.state = true;
                this.alert_snackbar.text = "Агент успешно создан";
                this.alert_snackbar.color = "success";
                await this.$store.dispatch('LoadAllAgentsCRM');
                this.dialogAddAgent.state = false;
                this.dialogAddAgent.agent = {};
            } else {
                this.alert_snackbar.state = true;
                this.alert_snackbar.text = responce.error ?? responce.message + " " + Object.values(responce.data);
                this.alert_snackbar.color = "error";
            }
        },
        async AgentInfo(item) {
            await this.$store.dispatch('LoadRealEstateByAgent', item.name_ro);
            this.dialogInfoAgent.state = true;
            this.dialogInfoAgent.agent = item;
        },
        CategorieOpenDialogDelete(agent) {
            this.dialogDeleteAgent.state = true;
            this.dialogDeleteAgent.agent = agent;
        },
        async DeleteAgent(ID) {
            this.dialogDeleteAgent.state = false;
            this.dialogDeleteAgent.agent = {};
            let responce = await this.$store.dispatch('AgentDeleteById', ID);
            if (responce.success == true) {
                await this.$store.dispatch('LoadAllAgentsCRM');
                this.alert_snackbar.state = true;
                this.alert_snackbar.text = "Объект был удалён с сайта";
                this.alert_snackbar.color = "success";
            } else {
                this.alert_snackbar.state = true;
                this.alert_snackbar.text = responce.error ?? responce.message + " " + Object.values(responce.data);
                this.alert_snackbar.color = "error";
            }
        },
        MinusPage() {
            if (this.page_view > 1) {
                this.page_view -= 1;
            }
        },
        PlusPage() {
            if (this.page_view * this.limit_view < this.getAllPartnersCRM.length) {
                this.page_view += 1;
            }
        },
        GoToRealEstate(ID) {
            this.$router.push({
                name: "edit-real-estate-crm",
                params: {
                    id: ID
                }
            })
        }
    },
    async mounted() {
        this.windowVue = window;
        await this.$store.dispatch('LoadAllAgentsCRM');
    },
})
</script>
<style scoped>
.title_property_big {
    font-weight: 500;
    font-size: 1.4rem;
}

.title_property {
    font-weight: 500;
    font-size: 1.1rem;
}

.divider-bottom {
    border: 1px solid rgba(0, 0, 0, 0.2);
    opacity: 1;
}

.btn-text {
    text-transform: inherit;
}

.table {
    border: 1px solid rgba(0, 0, 0, 0.12);
    width: 100%;
    border-radius: 10px;
}

.input-search {
    min-width: 300px;
}

.select-search {
    min-width: 200px;
    max-width: 400px;
}

.col-header-gap {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 10px;
}

.w-100 {
    min-width: 100%;
}

@media screen and (max-width: 1400px) {
    .input-search {
        min-width: 250px;
    }
}
</style>

<style>
.input-search .v-field__input,
.select-search .v-field__input {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    min-height: 36px;
}
</style>