import { axiosInstance } from "../config";

export default {
  state: {
    Lead: [],
    AllLeads: [],
    AllLeadsOrigin: [],
  },
  mutations: {
    SetLead(state, payload) {
      state.Lead = payload;
    },
    SetAllLeads(state, payload) {
      state.AllLeads = payload;
    },
    SetAllLeadsOrigin(state, payload) {
      state.AllLeadsOrigin = payload;
    },
  },
  actions: {
    async LoadLead({ commit }, payload) {
      await axiosInstance
        .get(`/lead/${payload}`)
        .then(function (data) {
          commit("SetLead", data?.data?.data);
        })
        .catch(function (error) {
          return {
            error: error.response.data.message,
          };
        });
    },
    async LoadAllLeads({ commit }) {
      await axiosInstance
        .get("/lead")
        .then(function (data) {
          commit("SetAllLeads", data?.data?.data);
        })
        .catch(function (error) {
          return {
            error: error.response.data.message,
          };
        });
    },
    async LoadAllLeadsOrigin({ commit }) {
      await axiosInstance
        .get("/lead_origin")
        .then(function (data) {
          commit("SetAllLeadsOrigin", data?.data?.data);
        })
        .catch(function (error) {
          return {
            error: error.response.data.message,
          };
        });
    },
    async Leads_origin_toggle_archive(commit, payload) {
      return await axiosInstance
        .put("/lead/toggle_archive/" + payload)
        .then(function (data) {
          return data?.data;
        })
        .catch(function (error) {
          return {
            error: error.response.data.message,
          };
        });
    },
    async Lead_delete_by_id(commit, payload) {
      return await axiosInstance
        .delete("/lead/" + payload)
        .then(function (data) {
          return data?.data;
        })
        .catch(function (error) {
          return {
            error: error.response.data.message,
          };
        });
    },
    async Lead_Edit(commit, payload) {
      return await axiosInstance
        .put("/lead/" + payload.ID, payload.data)
        .then(function (data) {
          return data?.data;
        })
        .catch(function (error) {
          return {
            error: error.response.data.message,
          };
        });
    },
    async Lead_origin_Edit(commit, payload) {
      return await axiosInstance
        .put("/lead_origin/" + payload.ID, payload.data)
        .then(function (data) {
          return data?.data;
        })
        .catch(function (error) {
          return {
            error: error.response.data.message,
          };
        });
    },
    /* async Lead_origin_delete_by_id(commit, payload) {
      return await axiosInstance
        .delete("/lead_origin/" + payload)
        .then(function (data) {
          return data?.data;
        })
        .catch(function (error) {
          return {
            error: error.response.data.message,
          };
        });
    }, */
  },
  getters: {
    getLead: (state) => state.Lead,
    getAllLeads: (state) => state.AllLeads,
    getAllLeadsOrigin: (state) => state.AllLeadsOrigin,
  },
};
