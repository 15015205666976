<template>
  <v-container :fluid="$vuetify.display.mdAndDown ? true : false">
    <v-row justify="center">
      <v-col cols="11" md="11" lg="10">
        <v-row justify="center" justify-sm="space-between" style="position: relative;">
          <div class="blur-1"></div>
          <v-col cols="12" sm="auto" style="z-index: 1;">
            <p class="learn-text">
              {{ t("$vuetify.learn_page.material_schedule.title_text") }}
            </p>
          </v-col>
          <v-col cols="12" sm="4" class="mr-md-10" style="z-index: 1;">
            <p class="description-title">
              {{ t("$vuetify.learn_page.material_schedule.text_right.title") }}
            </p>
            <p class="description-text mt-2">
              {{ t("$vuetify.learn_page.material_schedule.text_right.subtitle") }}
            </p>
          </v-col>
        </v-row>
        <v-divider class="divider-bottom my-12" color="info"></v-divider>
        <v-row justify="center" justify-sm="space-between" class="opacity-30">
          <v-col cols="12" sm="auto">
            <p class="weeks-text">
              {{ t("$vuetify.learn_page.material_schedule.week_1.week_text_number") }}
            </p>
          </v-col>
          <v-col cols="12" sm="8" md="7">
            <p class="topic-title">
              {{ t("$vuetify.learn_page.material_schedule.week_1.topic_name") }}
            </p>
          </v-col>
        </v-row>
        <v-divider class="divider-bottom my-12" color="info"></v-divider>
        <v-row justify="center" justify-sm="space-between" class="opacity-65">
          <v-col cols="12" sm="auto">
            <p class="weeks-text">
              {{ t("$vuetify.learn_page.material_schedule.week_2.week_text_number") }}
            </p>
          </v-col>
          <v-col cols="12" sm="8" md="7">
            <p class="topic-title">
              {{ t("$vuetify.learn_page.material_schedule.week_2.topic_name") }}
            </p>
          </v-col>
        </v-row>
        <v-divider class="divider-bottom my-12" color="info"></v-divider>
        <v-row justify="center" justify-sm="space-between">
          <v-col cols="12" sm="auto">
            <p class="weeks-text">
              {{ t("$vuetify.learn_page.material_schedule.week_3.week_text_number") }}
            </p>
          </v-col>
          <v-col cols="12" sm="8" md="7">
            <p class="topic-title">
              {{ t("$vuetify.learn_page.material_schedule.week_3.topic_name") }}
            </p>
          </v-col>
        </v-row>
        <v-divider class="divider-bottom my-12" color="info"></v-divider>
        <v-row justify="center" justify-sm="space-between">
          <v-col cols="12" sm="auto">
            <p class="weeks-text">
              {{ t("$vuetify.learn_page.material_schedule.week_4.week_text_number") }}
            </p>
          </v-col>
          <v-col cols="12" sm="8" md="7">
            <p class="topic-title">
              {{ t("$vuetify.learn_page.material_schedule.week_4.topic_name") }}
            </p>
          </v-col>
        </v-row>
        <v-divider class="divider-bottom my-12" color="info"></v-divider>
        <v-row justify="center" justify-sm="space-between">
          <v-col cols="12" sm="auto">
            <p class="weeks-text">
              {{ t("$vuetify.learn_page.material_schedule.week_5.week_text_number") }}
            </p>
          </v-col>
          <v-col cols="12" sm="8" md="7">
            <p class="topic-title">
              {{ t("$vuetify.learn_page.material_schedule.week_5.topic_name") }}
            </p>
          </v-col>
        </v-row>
        <v-divider class="divider-bottom my-12" color="info"></v-divider>
        <v-row justify="center" justify-sm="space-between">
          <v-col cols="12" sm="auto">
            <p class="weeks-text">
              {{ t("$vuetify.learn_page.material_schedule.week_6.week_text_number") }}
            </p>
          </v-col>
          <v-col cols="12" sm="8" md="7">
            <p class="topic-title">
              {{ t("$vuetify.learn_page.material_schedule.week_6.topic_name") }}
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useLocale } from "vuetify";

export default {
  name: "MaterialSchedule",
  setup() {
    const { t } = useLocale();
    return {
      t,
    };
  },
  data: () => ({
  }),
  mounted() {
  },
  computed: {
  },
};
</script>
<style scoped>
.blur-1 {
  width: 200px;
  height: 200px;
  position: absolute;
  top: 0%;
  left: 90%;
  background: #F6F2E6;
  -webkit-backdrop-filter: blur(200px);
  filter: blur(200px);
  border-radius: 100%;
  will-change: transform;
  z-index: 0;
}

.opacity-30 {
  opacity: 0.3;
}

.opacity-65 {
  opacity: 0.65;
}

.topic-title {
  width: fit-content;
  color: #F6F2E6;
  font-size: 4rem;
  line-height: 4.5rem;
}

.weeks-text {
  color: #F6F2E6;
  font-size: 1.1rem;
  letter-spacing: 0.05rem;
}

.divider-bottom {
  border-width: 1px;
  border-color: rgba(154, 154, 155, 0.36);
  opacity: 1;
}

.description-text {
  color: #9A9A9B;
  font-size: 1.1rem;
  line-height: 1.8rem;
}

.description-title {
  color: #F6F2E6;
  font-size: 1.1rem;
}

.learn-text {
  color: #F6F2E6;
  font-size: 1.9rem;
  letter-spacing: -0.02rem;
}

@media screen and (max-width: 600px) {
  .topic-title {
    font-size: 2.2rem;
    line-height: 2.5rem;
  }
}

@media screen and (min-width: 600px) and (max-width: 1100px) {
  .topic-title {
    font-size: 3rem;
    line-height: 3.8rem;
  }
}
</style>
