<template>
  <v-container :fluid="$vuetify.display.mdAndDown ? true : false">
    <v-row justify="center">
      <v-col cols="11" sm="11" md="11" lg="10" style="position: relative;">
        <v-row justify="center" style="position: relative; z-index: 1;">
          <v-col cols="12" class="pb-0" align-self="end">
            <h6 class="text-block">
              {{ t("$vuetify.partners_page.our_team.subtitle_text") }}
            </h6>
          </v-col>
        </v-row>
        <v-row class="mb-6" justify="center" style="position: relative; z-index: 1;">
          <v-col cols="12">
            <h3 class="title-block">
              {{ t("$vuetify.partners_page.our_team.title_text") }}
            </h3>
          </v-col>
        </v-row>
        <v-row justify="center" style="position: relative; z-index: 1;"
          :class="$vuetify.display.smAndUp ? 'row-partners' : ''">
          <v-col cols="11" sm="6" md="4" class="px-sm-8 mt-4" align-self="stretch" v-for="partner in getAllPartners"
            :key="partner.ID">
            <v-card class="pa-0 card-block" flat>
              <v-img :src="urlAPI + partner.path" class="card-image" aspect-ratio="1" cover></v-img>
              <v-card-item class="px-0">
                <v-card-title class="px-0 py-2 card-name">
                  {{ $vuetify.locale.current == 'ru' ? partner['name_ru'] : partner['name_ro'] }}
                </v-card-title>
                <v-card-text class="pa-0 card-btns">
                  <v-btn variant="text" :href="'tel:' + partner.phone" class="btn-phone pl-0">
                    <svg width="12" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M0.927734 2C0.927734 0.89543 1.82316 0 2.92773 0H8.92773C10.0323 0 10.9277 0.895431 10.9277 2V14C10.9277 15.1046 10.0323 16 8.92773 16H2.92773C1.82316 16 0.927734 15.1046 0.927734 14V2ZM6.92773 13C6.92773 12.4477 6.48002 12 5.92773 12C5.37545 12 4.92773 12.4477 4.92773 13C4.92773 13.5523 5.37545 14 5.92773 14C6.48002 14 6.92773 13.5523 6.92773 13Z"
                        fill="#F6F2E6" />
                    </svg>
                    <span class="ml-3">
                      {{ partner.phone }}
                    </span>
                  </v-btn>
                </v-card-text>
                <v-card-actions class="px-0">
                  <v-btn variant="flat" block color="#000000" class="btn-open-partner" @click="OpenPartner(partner)">
                    Responsible for
                  </v-btn>
                </v-card-actions>
              </v-card-item>
            </v-card>
          </v-col>
        </v-row>
        <svg class="svg-g-logo" :width="width_SVG_G" viewBox="0 0 554 506" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M69.2572 355.164V434.626L8.47907 399.735C3.23304 396.723 0 391.148 0 385.114V315.405L69.2572 355.164Z"
            fill="url(#paint0_linear_1189_755)" />
          <path d="M138.482 394.926L138.459 474.384L69.2572 434.626V355.164L138.482 394.926Z"
            fill="url(#paint1_linear_1189_755)" />
          <path
            d="M207.716 434.626V494.684C207.716 503.337 198.322 508.75 190.798 504.431L138.459 474.384L138.482 394.926L207.716 434.626Z"
            fill="url(#paint2_linear_1189_755)" />
          <path
            d="M207.716 355.164V434.626L138.482 394.926L138.462 325.142C138.461 320.813 143.16 318.106 146.922 320.268L207.613 355.136C207.644 355.154 207.68 355.164 207.716 355.164Z"
            fill="url(#paint3_linear_1189_755)" />
          <path d="M69.2572 275.647V355.164L0 315.405V235.888L69.2572 275.647Z" fill="url(#paint4_linear_1189_755)" />
          <path d="M69.241 196.141L69.2572 275.647L0 235.888V156.384L69.241 196.141Z"
            fill="url(#paint5_linear_1189_755)" />
          <path d="M138.404 156.426L69.241 196.141L0 156.384L69.2572 116.668L138.404 156.426Z"
            fill="url(#paint6_linear_1189_755)" />
          <path
            d="M207.605 196.185L138.482 235.898L127.11 229.39L69.241 196.141L138.404 156.426H138.459L207.605 196.185Z"
            fill="url(#paint7_linear_1189_755)" />
          <path
            d="M268.374 231.07C272.142 233.234 272.143 238.653 268.375 240.817L216.166 270.795C210.935 273.798 204.497 273.798 199.266 270.795L138.482 235.898L207.605 196.185L268.374 231.07Z"
            fill="url(#paint8_linear_1189_755)" />
          <path
            d="M415.478 396.009L415.375 469.322C415.372 471.181 415.371 472.111 415.103 472.944C414.856 473.713 414.446 474.42 413.9 475.017C413.308 475.663 412.5 476.128 410.884 477.056L373.071 498.781C364.336 503.8 359.968 506.309 356.384 505.97C353.119 505.66 350.15 503.951 348.251 501.286C346.166 498.361 346.166 493.336 346.166 483.287V434.722L415.478 396.009Z"
            fill="url(#paint9_linear_1189_755)" />
          <path d="M415.479 316.547L415.478 396.009L346.277 434.722V355.094L415.479 316.547Z"
            fill="url(#paint10_linear_1189_755)" />
          <path d="M415.368 235.984L415.479 316.547L346.277 355.094L346.166 275.743L415.368 235.984Z"
            fill="url(#paint11_linear_1189_755)" />
          <path
            d="M415.368 235.984L346.166 275.743L290.321 243.658C285.98 241.164 283.81 239.917 283.059 238.293C282.374 236.813 282.367 235.11 283.039 233.625C283.777 231.995 285.937 230.73 290.257 228.201L346.277 195.4L415.368 235.984Z"
            fill="url(#paint12_linear_1189_755)" />
          <path d="M484.68 195.4L415.368 235.984L346.277 195.4L414.268 155.711L484.68 195.4Z"
            fill="url(#paint13_linear_1189_755)" />
          <path d="M553.882 156.853L484.68 195.4L414.268 155.711L484.586 116.668L553.882 156.853Z"
            fill="url(#paint14_linear_1189_755)" />
          <path d="M553.882 156.853L553.828 235.984L484.687 276.821L484.68 195.4L553.882 156.853Z"
            fill="url(#paint15_linear_1189_755)" />
          <path
            d="M553.828 235.984L553.795 300.163C553.792 305.672 553.791 308.426 553.007 310.902C552.283 313.186 551.079 315.291 549.476 317.076C547.739 319.01 545.362 320.415 540.608 323.225L484.68 356.306L484.687 276.821L553.828 235.984Z"
            fill="url(#paint16_linear_1189_755)" />
          <path d="M484.687 276.821L484.68 356.306L415.478 396.009L415.479 316.547L484.687 276.821Z"
            fill="url(#paint17_linear_1189_755)" />
          <path
            d="M484.57 116.669L414.268 155.711L404.074 149.875L346.205 116.625L415.368 76.9105H415.423L484.57 116.669Z"
            fill="url(#paint18_linear_1189_755)" />
          <path d="M415.328 76.9119L346.205 116.625L276.964 76.9105L346.087 37.1549L415.328 76.9119Z"
            fill="url(#paint19_linear_1189_755)" />
          <path
            d="M346.087 37.1549L276.964 76.9105L207.723 37.1534L268.431 2.25655C273.664 -0.751491 280.108 -0.75223 285.342 2.25461L346.087 37.1549Z"
            fill="url(#paint20_linear_1189_755)" />
          <path d="M69.241 116.669L138.364 156.382L149.737 149.875L207.605 116.625L138.471 76.7667L69.241 116.669Z"
            fill="url(#paint21_linear_1189_755)" />
          <path d="M138.471 76.7667L207.723 116.669L219.095 110.161L276.964 76.9105L207.723 37.1534L138.471 76.7667Z"
            fill="url(#paint22_linear_1189_755)" />
          <path
            d="M207.706 348.731V393.177L126.03 349.484C125.164 349.02 124.624 348.125 124.624 347.152V300.866L207.706 348.731Z"
            fill="url(#paint23_linear_1189_755)" />
          <path
            d="M207.706 348.731V393.177L126.03 349.484C125.164 349.02 124.624 348.125 124.624 347.152V300.866L207.706 348.731Z"
            fill="url(#paint24_linear_1189_755)" />
          <defs>
            <linearGradient id="paint0_linear_1189_755" x1="276.941" y1="0" x2="276.941" y2="506"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#F6F2E6" stop-opacity="0.03" />
              <stop offset="0.892812" stop-color="#908E87" stop-opacity="0" />
            </linearGradient>
            <linearGradient id="paint1_linear_1189_755" x1="276.941" y1="0" x2="276.941" y2="506"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#F6F2E6" stop-opacity="0.03" />
              <stop offset="0.892812" stop-color="#908E87" stop-opacity="0" />
            </linearGradient>
            <linearGradient id="paint2_linear_1189_755" x1="276.941" y1="0" x2="276.941" y2="506"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#F6F2E6" stop-opacity="0.03" />
              <stop offset="0.892812" stop-color="#908E87" stop-opacity="0" />
            </linearGradient>
            <linearGradient id="paint3_linear_1189_755" x1="276.941" y1="0" x2="276.941" y2="506"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#F6F2E6" stop-opacity="0.03" />
              <stop offset="0.892812" stop-color="#908E87" stop-opacity="0" />
            </linearGradient>
            <linearGradient id="paint4_linear_1189_755" x1="276.941" y1="0" x2="276.941" y2="506"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#F6F2E6" stop-opacity="0.03" />
              <stop offset="0.892812" stop-color="#908E87" stop-opacity="0" />
            </linearGradient>
            <linearGradient id="paint5_linear_1189_755" x1="276.941" y1="0" x2="276.941" y2="506"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#F6F2E6" stop-opacity="0.03" />
              <stop offset="0.892812" stop-color="#908E87" stop-opacity="0" />
            </linearGradient>
            <linearGradient id="paint6_linear_1189_755" x1="276.941" y1="0" x2="276.941" y2="506"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#F6F2E6" stop-opacity="0.03" />
              <stop offset="0.892812" stop-color="#908E87" stop-opacity="0" />
            </linearGradient>
            <linearGradient id="paint7_linear_1189_755" x1="276.941" y1="0" x2="276.941" y2="506"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#F6F2E6" stop-opacity="0.03" />
              <stop offset="0.892812" stop-color="#908E87" stop-opacity="0" />
            </linearGradient>
            <linearGradient id="paint8_linear_1189_755" x1="276.941" y1="0" x2="276.941" y2="506"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#F6F2E6" stop-opacity="0.03" />
              <stop offset="0.892812" stop-color="#908E87" stop-opacity="0" />
            </linearGradient>
            <linearGradient id="paint9_linear_1189_755" x1="276.941" y1="0" x2="276.941" y2="506"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#F6F2E6" stop-opacity="0.03" />
              <stop offset="0.892812" stop-color="#908E87" stop-opacity="0" />
            </linearGradient>
            <linearGradient id="paint10_linear_1189_755" x1="276.941" y1="0" x2="276.941" y2="506"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#F6F2E6" stop-opacity="0.03" />
              <stop offset="0.892812" stop-color="#908E87" stop-opacity="0" />
            </linearGradient>
            <linearGradient id="paint11_linear_1189_755" x1="276.941" y1="0" x2="276.941" y2="506"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#F6F2E6" stop-opacity="0.03" />
              <stop offset="0.892812" stop-color="#908E87" stop-opacity="0" />
            </linearGradient>
            <linearGradient id="paint12_linear_1189_755" x1="276.941" y1="0" x2="276.941" y2="506"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#F6F2E6" stop-opacity="0.03" />
              <stop offset="0.892812" stop-color="#908E87" stop-opacity="0" />
            </linearGradient>
            <linearGradient id="paint13_linear_1189_755" x1="276.941" y1="0" x2="276.941" y2="506"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#F6F2E6" stop-opacity="0.03" />
              <stop offset="0.892812" stop-color="#908E87" stop-opacity="0" />
            </linearGradient>
            <linearGradient id="paint14_linear_1189_755" x1="276.941" y1="0" x2="276.941" y2="506"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#F6F2E6" stop-opacity="0.03" />
              <stop offset="0.892812" stop-color="#908E87" stop-opacity="0" />
            </linearGradient>
            <linearGradient id="paint15_linear_1189_755" x1="276.941" y1="0" x2="276.941" y2="506"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#F6F2E6" stop-opacity="0.03" />
              <stop offset="0.892812" stop-color="#908E87" stop-opacity="0" />
            </linearGradient>
            <linearGradient id="paint16_linear_1189_755" x1="276.941" y1="0" x2="276.941" y2="506"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#F6F2E6" stop-opacity="0.03" />
              <stop offset="0.892812" stop-color="#908E87" stop-opacity="0" />
            </linearGradient>
            <linearGradient id="paint17_linear_1189_755" x1="276.941" y1="0" x2="276.941" y2="506"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#F6F2E6" stop-opacity="0.03" />
              <stop offset="0.892812" stop-color="#908E87" stop-opacity="0" />
            </linearGradient>
            <linearGradient id="paint18_linear_1189_755" x1="276.941" y1="0" x2="276.941" y2="506"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#F6F2E6" stop-opacity="0.03" />
              <stop offset="0.892812" stop-color="#908E87" stop-opacity="0" />
            </linearGradient>
            <linearGradient id="paint19_linear_1189_755" x1="276.941" y1="0" x2="276.941" y2="506"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#F6F2E6" stop-opacity="0.03" />
              <stop offset="0.892812" stop-color="#908E87" stop-opacity="0" />
            </linearGradient>
            <linearGradient id="paint20_linear_1189_755" x1="276.941" y1="0" x2="276.941" y2="506"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#F6F2E6" stop-opacity="0.03" />
              <stop offset="0.892812" stop-color="#908E87" stop-opacity="0" />
            </linearGradient>
            <linearGradient id="paint21_linear_1189_755" x1="276.941" y1="0" x2="276.941" y2="506"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#F6F2E6" stop-opacity="0.03" />
              <stop offset="0.892812" stop-color="#908E87" stop-opacity="0" />
            </linearGradient>
            <linearGradient id="paint22_linear_1189_755" x1="276.941" y1="0" x2="276.941" y2="506"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#F6F2E6" stop-opacity="0.03" />
              <stop offset="0.892812" stop-color="#908E87" stop-opacity="0" />
            </linearGradient>
            <linearGradient id="paint23_linear_1189_755" x1="276.941" y1="0" x2="276.941" y2="506"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#F6F2E6" stop-opacity="0.03" />
              <stop offset="0.892812" stop-color="#908E87" stop-opacity="0" />
            </linearGradient>
            <linearGradient id="paint24_linear_1189_755" x1="276.941" y1="0" x2="276.941" y2="506"
              gradientUnits="userSpaceOnUse">
              <stop stop-color="#F6F2E6" stop-opacity="0.03" />
              <stop offset="0.892812" stop-color="#908E87" stop-opacity="0" />
            </linearGradient>
          </defs>
        </svg>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useLocale } from "vuetify";
import { mapGetters } from 'vuex'
import { urlAPI } from "../../store/config";

export default {
  name: "AllPartners",
  setup() {
    const { t } = useLocale();
    return {
      t,
    };
  },
  data: () => ({
    urlAPI: urlAPI + '/',
  }),
  async mounted() {
    await this.$store.dispatch('LoadAllPartners');
  },
  computed: {
    width_SVG_G() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "200px";
        case "sm":
          return "320px";
        case "md":
          return "500px";
        case "lg":
          return "520px";
        case "xl":
          return "520px";
        case "xxl":
          return "520px";
        default:
          return "500px";
      }
    },
    ...mapGetters([
      'getAllPartners',
    ])
  },
  methods: {
    OpenPartner(partner) {
      this.$router.push({
        name: "partner-single",
        params: {
          lang: this.$vuetify.locale.current,
          name: partner.name_ro,
        }
      });
    }
  }
};
</script>

<style scoped>
.row-partners {
  margin-left: -30px;
  margin-right: -30px;
}

.btn-open-partner {
  font-style: italic;
  font-size: 1.1rem;
  text-transform: inherit;
  color: #F6F2E6;
  letter-spacing: -0.02rem;
  border-radius: 41px;
}

.btn-phone {
  color: #9A9A9B;
  font-size: 1rem;
  letter-spacing: -0.02rem;
}

.card-image {
  border-top-left-radius: 40px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.card-text {
  font-size: 15px;
  letter-spacing: -0.02rem;
  font-weight: 400;
  color: #9a9a9b;
  line-height: 20px;
}

.card-name {
  font-size: 1.75rem;
  letter-spacing: -0.02rem;
  font-weight: 400;
  color: #f6f2e6;
}

.card-block {
  background: transparent;
  transition: 0.7s;
  border-bottom: 6px;
  border-top-left-radius: 40px;
  border-top-right-radius: 4px;
}

.svg-g-logo {
  position: absolute;
  right: 0px;
  top: 0px;
  transform: translate(0%, -40%);
  z-index: 0;
}

.title-block {
  color: #9a9a9b;
  font-size: 90px;
  line-height: 90px;
  font-weight: 400;
  letter-spacing: -0.12rem;
}

.text-block {
  color: #626262;
  font-weight: 400;
  letter-spacing: -0.04rem;
  font-size: 30px;
}

@media screen and (max-width: 600px) {
  .title-block {
    font-size: 40px !important;
    line-height: 44px;
  }

  .text-block {
    font-size: 15px;
  }

}

@media screen and (min-width: 600px) and (max-width: 960px) {
  .title-block {
    font-size: 60px !important;
    line-height: 64px;
  }

  .text-block {
    font-size: 20px;
  }
}

@media screen and (min-width: 960px) and (max-width: 1280px) {
  .title-block {
    font-size: 80px !important;
    line-height: 85px;
  }

  .text-block {
    font-size: 25px;
  }
}
</style>