<template>
  <v-container :fluid="$vuetify.display.mdAndDown ? true : false" class="container-block">
    <v-row justify="center" class="px-6">
      <v-col cols="12" md="11" lg="10">
        <v-row justify="start">
          <span class="subtitle-text">
            {{ t("$vuetify.real_estate.GetInTouch.text_need_offer") }}
          </span>
        </v-row>
        <v-row justify="space-between">
          <v-col cols="7" md="7">
            <v-row style="flex-wrap: nowrap;">
              <v-col cols="auto" class="py-0" align-self="center">
                <v-img :width="$vuetify.display.smAndUp ? '80px' : '50px'" aspect-ratio="1" class="img-icon"
                  src="../../assets/img/real_estate/GetInTouch/icon.jpg"></v-img>
              </v-col>
              <v-col cols="auto" class="py-0" align-self="center">
                <span class="title-text">
                  {{ t("$vuetify.real_estate.GetInTouch.text_title.part1") }}
                </span>
              </v-col>
            </v-row>
            <v-row class="mt-0 py-0">
              <span class="title-text">
                {{ t("$vuetify.real_estate.GetInTouch.text_title.part2") }}
              </span>
            </v-row>
          </v-col>
          <v-col cols="3" md="3" class="pl-lg-12">
            <span class="text-right">
              {{ t("$vuetify.real_estate.GetInTouch.text_small_right") }}
            </span>
          </v-col>
        </v-row>
        <v-row justify="end" style="position: relative;">
          <div class="line-white"></div>
          <v-card class="btn-get-touch" @click="OpenContactUsModalActive()">
            {{ t("$vuetify.real_estate.GetInTouch.btn_get_in_touch") }}
          </v-card>
        </v-row>
        <v-row class="mt-4">
          <v-col cols="auto" align-self="center" class="px-0">
            <v-btn variant="flat" class="btn-bottom-left1 rounded-pill px-8"
              :size="$vuetify.display.mdAndUp ? 'x-large' : 'large'" href="mailto:contact@gpartners.md">
              contact@gpartners.md
            </v-btn>
            <v-btn variant="outlined" class="btn-bottom-left rounded-pill px-8 ml-4"
              :size="$vuetify.display.mdAndUp ? 'x-large' : 'large'" href="tel:+37369169180">
              +373 69 169 180
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <ContactUsModal ref="OpenContactUsModal" />
  </v-container>
</template>

<script>
import { useLocale } from "vuetify";
import ContactUsModal from "../Modals/ContactUsModal.vue";

export default {
  name: "GetInTouch",
  setup() {
    const { t } = useLocale();
    return {
      t,
    };
  },
  components: {
    ContactUsModal,
  },
  methods: {
    OpenContactUsModalActive() {
      this.$refs.OpenContactUsModal.dialog = true;
    }
  }
};
</script>

<style scoped>
.btn-bottom-left1 {
  color: #111213;
  background: #F6F2E6;
  text-transform: inherit;
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: -0.03rem;
  transition: 0.7s;
}

.btn-bottom-left1:hover {
  color: #111213;
  background: #F6F2E6;
}

.btn-bottom-left {
  color: #F6F2E6;
  text-transform: inherit;
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: -0.03rem;
  transition: 0.7s;
}

.btn-bottom-left:hover {
  color: #111213;
  background: #f6f2e6;
}

.line-white {
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(0px, -50%);
  width: 100%;
  height: 0px;
  border-top: 1px solid #9A9A9B;
}

.btn-get-touch {
  margin-right: 15vw;
  width: 190px;
  height: 190px;
  background: #F6F2E6;
  color: #111213;
  font-size: 1.1rem;
  letter-spacing: -0.02rem;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}

.text-right {
  color: #F6F2E6;
  letter-spacing: -0.02rem;
  font-size: 1.1rem;
}

.title-text {
  color: #9A9A9B;
  font-size: 6.5rem;
  line-height: 7.5rem;
}

.img-icon {
  border-radius: 100%;
}

.subtitle-text {
  color: #9A9A9B;
  font-size: 2.5rem;
  font-weight: 400;
}

@media screen and (max-width: 600px) {
  .btn-get-touch {
    width: 100px;
    height: 100px;
    font-size: .7rem;
  }

  .text-right {
    font-size: 0.65rem;
    line-height: 1rem;
    display: block;
  }

  .subtitle-text {
    font-size: 1.7rem;
    margin-bottom: 10px;
  }

  .title-text {
    font-size: 2.2rem;
    line-height: 3.5rem;
  }

  /* .container-block {
    zoom: .48;
  } */

  .btn-bottom-left1 {
    zoom: .65;
  }

  .btn-bottom-left {
    zoom: .65;
  }
}

@media screen and (min-width: 600px) and (max-width: 960px) {
  .title-text {
    font-size: 5rem;
    line-height: 6.5rem;
  }

  .btn-bottom-left1 {
    zoom: 0.9;
  }

  .btn-bottom-left {
    zoom: 0.9;
  }
}
</style>

<style></style>
