<template>
    <v-container class="container-main" :fluid="$vuetify.display.mdAndDown ? true : false">
        <v-row justify="center" v-if="Partner">
            <v-col cols="12" sm="11" md="9" lg="9">
                <v-row>
                    <v-col cols="auto">
                        <span class="block-title">
                            {{ t("$vuetify.single_partner.responsible_for") }}
                        </span>
                    </v-col>
                </v-row>
                <v-row justify="center" class="mt-6">
                    <v-col cols="6" md="4" v-for="(card, i) in Cards" :key="i">
                        <cardRealEstate :card="card" />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { useLocale } from "vuetify";
import cardRealEstate from "../cardRealEstate.vue";

export default {
    name: "PartnerRealEstate",
    props: {
        Partner: Object,
        Cards: Object,
    },
    setup() {
        const { t } = useLocale();
        return {
            t,
        };
    },
    components: {
        cardRealEstate,
    },
};
</script>

<style scoped>
.block-title {
    color: #9A9A9B;
    font-size: 1.1rem;
}

@media screen and (max-width: 600px) {}

@media screen and (min-width: 600px) and (max-width: 960px) {}
</style>