import axios from "axios";
import { urlAPI } from "./config";

export default {
  state: {
    house_condition_type: [],
    renovation_type: [],
    price_type: [],
    building_purpose_type: [],
    building_type: [],
    industrial_purpose_type: [],
    land_type: [],
    location: [],
    property_developer: [],
  },
  mutations: {
    Set_house_condition_type(state, payload) {
      state.house_condition_type = payload;
    },
    Set_renovation_type(state, payload) {
      state.renovation_type = payload;
    },
    Set_price_type(state, payload) {
      state.price_type = payload;
    },
    Set_building_purpose_type(state, payload) {
      state.building_purpose_type = payload;
    },
    Set_building_type(state, payload) {
      state.building_type = payload;
    },
    Set_industrial_purpose_type(state, payload) {
      state.industrial_purpose_type = payload;
    },
    Set_land_type(state, payload) {
      state.land_type = payload;
    },
    Set_location(state, payload) {
      state.location = payload;
    },
    Set_property_developer(state, payload) {
      state.property_developer = payload;
    },
  },
  actions: {
    async Load_house_condition_type({ commit }, payload) {
      let data = await axios.get(
        urlAPI + `/house_condition_type${payload ? "/" + payload : ""}`
      );
      commit("Set_house_condition_type", data?.data?.data);
    },
    async Load_renovation_type({ commit }, payload) {
      let data = await axios.get(
        urlAPI + `/renovation_type${payload ? "/" + payload : ""}`
      );
      commit("Set_renovation_type", data?.data?.data);
    },
    async Load_price_type({ commit }, payload) {
      let data = await axios.get(
        urlAPI + `/price_type${payload ? "/" + payload : ""}`
      );
      commit("Set_price_type", data?.data?.data);
    },
    async Load_building_purpose_type({ commit }, payload) {
      let data = await axios.get(
        urlAPI + `/building_purpose_type${payload ? "/" + payload : ""}`
      );
      commit("Set_building_purpose_type", data?.data?.data);
      return data?.data?.data;
    },
    async Load_building_type({ commit }, payload) {
      let data = await axios.get(
        urlAPI + `/building_type${payload ? "/" + payload : ""}`
      );
      commit("Set_building_type", data?.data?.data);
    },
    async Load_industrial_purpose_type({ commit }, payload) {
      let data = await axios.get(
        urlAPI + `/industrial_purpose_type${payload ? "/" + payload : ""}`
      );
      commit("Set_industrial_purpose_type", data?.data?.data);
    },
    async Load_land_type({ commit }, payload) {
      let data = await axios.get(
        urlAPI + `/land_type${payload ? "/" + payload : ""}`
      );
      commit("Set_land_type", data?.data?.data);
    },
    async Load_location({ commit }, payload) {
      let data = await axios.get(
        urlAPI + `/location${payload ? "/" + payload : ""}`
      );
      commit("Set_location", data?.data?.data);
    },
    async Load_property_developer({ commit }, payload) {
      let data = await axios.get(
        urlAPI + `/property_developer${payload ? "/" + payload : ""}`
      );
      commit("Set_property_developer", data?.data?.data);
    },
  },
  getters: {
    get_house_condition_type: (state) => state.house_condition_type,
    get_renovation_type: (state) => state.renovation_type,
    get_price_type: (state) => state.price_type,
    get_building_purpose_type: (state) => state.building_purpose_type,
    get_building_type: (state) => state.building_type,
    get_industrial_purpose_type: (state) => state.industrial_purpose_type,
    get_land_type: (state) => state.land_type,
    get_location: (state) => state.location,
    get_property_developer: (state) => state.property_developer,
  },
};
