import { ru } from "vuetify/lib/locale";

export default {
  $vuetify: { ...ru },
  ...ru,
  route: {
    home: "",
    about: "o-nas",
    real_estate: "nedvijimosti",
    real_estate_single: "real-estate-single",
    real_estate_single_gallary: "real-estate-single-gallary",
  },
  header: {
    home: "Главная",
    real_estate: "Недвижимость",
    about: "О Нас",
    learn: "Академия",
    partners: "Партнеры",
    contact: "Контакты",
    consultation: "Консультация",
    current_lang: "Ru",
    legal: "Legal",
  },
  home: {
    parallax: {
      invest: "Инвестируй",
      best_choice: "в лучшие варианты",
      with_the: "",
      work_with: "работай",
      experts: "с экспертами",
      în_domeniul:
        "в сфере недвижимости, инвестиций, строительства более 10 лет",
      passion: "увлеченные",
      btn_1: "Недвижимость",
      btn_2: "Обучение",
      btn_3: "Связаться с нами",
      honest: "Честность",
      as_principe: "В работе с клиентами",
    },
    real_estate: {
      title: "Доверенные партнеры, заинтересованные в росте ваших активов",
      btn_cards_action: "Узнать больше",
    },
    HelpWithYou: {
      title: "Мы поможем с...",
      btn_action: "Бесплатная консультиация",
      items: [
        {
          title: "Инвестиции",
          description:
            "Услуга инвестиций основанная трех качествах: 1.Локация, 2.Ликвидность, 3.ROI (инвестиционная эффективность).",
        },
        {
          title: "Продажа",
          description:
            " Услуга сопровождения в сделке с момента упаковки продукта, до закрытия сделки и передачи обьекта новому владельцу. Помощь в оценке рынка недвижимости, проверка физического, юридического состояния недвижимости, сопровождение в сделке до получения обьекта в собственность.",
        },
        {
          title: "Аренда",
          description:
            "Помощь подбора качественных арендаторов и арендодателей, а так же управление недвижимостью.",
        },
        {
          title: "Обмен",
          description: "Эффективный обмен обьектов без рисков и потерь.",
        },
        {
          title: "Оценка",
          description:
            "Проводим профессиональный анализ рынка в целях выявления рыночной цены обьекта.",
        },
        {
          title: "Ипотека",
          description:
            "Полное кредитное сопровождение заемщика до получения ипотечного кредита на желаемую недвижимость.",
        },
        {
          title: "Строительство/ремонт",
          description:
            "Качественные строительные и ремонтные услуги в Кишиневе. От ремонта до строительства с нуля, мы предлагаем качественные решения для улучшения вашей собственности.",
        },
        {
          title: "Дизайн Интерьера",
          description:
            "В услугу входят: 1.Оценка проекта, 2.Качественная работа, 3.Гарантия качества работ.",
        },
        {
          title: "Архитектурные работы.",
          description:
            "Разработка архитектурных проектов под любой запрос в Кишиневе.",
        },
        {
          title: "Приватизация",
          description:
            "Услуга полного юридического сопровождения от сбора документов до получения недвижимости в частную собственность.",
        },
      ],
    },
    AboutG: {
      subtitle: "Кто стоит за G Partners?",
      title: {
        part_first: "Эксперты",
        part_white: "в недвижимости",
        part_last: "с опытом более 10 лет",
      },
      person_1: {
        line_1_name: "Иванов Максим",
        line_2: "Генеральный Партнер",
        line_3: "Коммерческая недвижимость",
        line_4: "Инвестиции",
      },
      person_2: {
        line_1_name: "Георгиешь Флорентин",
        line_2: "Генеральный Партнер",
        line_3: "Индустриальная недвижимость",
        line_4: "Земельное право",
      },
      about_text: {
        part_1:
          "G Partners – бутик недвижимости в Кишиневе, основанный в результате органического сотрудничества успешных профессионалов в сфере недвижимости. При работе G Partners Invest руководствуется вниманием к конфиденциальности информации, прозрачных транзакций и исполнениния ожиданий клиентов.",
        part_2:
          "Мы рады всем потенциальных клиентам, которые могут задать любые вопросы или имеют интерес в сотрудничестве. Будем рады помочь вам.",
        part_3: " ",
      },
      btn_learn_more: "Узнать больше",
    },
    OurMission: {
      subtitle: "Сотрудничество с G Partners Invest.",
      title: "Надежные эксперты по недвижимости",
      text: {
        part_1:
          "G Partners предлагает высочайший уровень консалтинга в сфере недвижимости и превосходное представительство клиентов при аренде недвижимости, покупке и продаже недвижимости, обмене и других операциях.",
        part_2:
          "Наши клиенты получают прозрачный подход и ценят наш вклад при оказании услуг в сфере недвижимости в Молдове и по всему миру. Мы стремимся обеспечить лучшие условия для наших клиентов при сделках с недвижимостью. Когда мы сталкиваемся с трудной задачей, наши агенты по недвижимости проявляют творческий подход, стратегический подход и умелость в решении проблем.",
        part_3: "Услуги проектирования, строительства и ремонта в Кишиневе.",
        part_4:
          "G Partners Invest готова стать вашим надежным партнером в сфере реновации, проектирования, строительства в Молдове.",
        part_5: "Сделай первый шаг.",
      },
    },
    realEstateWorld: {
      title: "Наша деятельность и лучшие горячие предложения.",
      subtitle:
        "Коммерческая недвижимость, жилые комплексы, квартиры и дома различных вариантов в Кишиневе и по всему миру.",
      items: [
        {
          title: "Недвижимость в Дубае, ОАЭ",
          btn_text: "Скоро",
        },
        {
          title: "Коммерческая недвижимость в Молдове",
          btn_text: "Узнать больше",
        },
        {
          title: "Станьте экспертом по недвижимости в G Partners™ Hub",
          btn_text: "Узнать больше",
        },
      ],
    },
  },
  cookieModal: {
    title:
      "Мы используем файлы cookie, чтобы улучшить ваш опыт, анализировать трафик сайта и предоставлять персонализированный контент. Прочтите нашу",
    title_Cookie_Policy: "Политику Использования",
    btn_reject: "Отклонить",
    btn_accept: "Принять",
  },
  getConsultation: {
    btn_title: "Получить бесплатную консультацию",
    name: "ОФИС-МЕНЕДЖЕР",
    list_item_telegram: "В Telegram",
    list_item_whatts: "В Whatts App",
    list_item_phone: "По телефону",
  },
  real_estate: {
    Categories: {
      page_title: "Недвижимость",
      page_subtitle: "достойная вашего внимания",
      btn_1: "Ипотечный калькулятор",
      btn_2: "Подобрать недвижимость",
    },
    Products: {
      filter: {
        type: "Тип",
        offer: "Предложение",
        object_price: "Стоимость объекта",
        location: "Локация",
        condition: "Состояние жилья",
        reparation_type: "Тип ремонта",
        land_type: "Назначение",
        industrial_purpose_type: "Индустриальное назначение",
        rooms: "Количество комнат",
        remove_filters: "Сбросить фильтры",
        show: "Показать",
        location_placeholder: "Не выбрано",
        area: "Площадь м²",
      },
      filter_input: "Введите название объекта, район или застройщика",
      btn_new_search: "Новый поиск",
      btn_change_filter: "Изменить фильтры",
      modal: {
        btn_reset_filter: "Сбросить фильтры",
        btn_see_result: "Показать",
      },
      btn_remove_filter: "Очистить фильтры",
      btn_we_found: {
        part1: "мы нашли",
        part2: ".",
      },
      btn_load_more: "Больше",
    },
    MaibCalculator: {
      title_left: "Рассчитать вариант недвижимости",
      btn_right_1: "Ипотека",
      btn_right_2: "Другое",
      btn_new_calculation: "Новый расчет",
    },
    GetInTouch: {
      text_need_offer: "Ищите особое предложение?",
      text_title: {
        part1: "Давайте",
        part2: "свяжемся",
      },
      text_small_right: "Не можете найти что то конкретное? Будем рады помочь!",
      btn_get_in_touch: "Связаться",
    },
  },
  footer: {
    working_worldwide: "Работаем * по всему миру.",
    based_in_chisinau: "Основано в Кишинёве, Молдова",
    copyrights: "Copyrights",
    legal_person: "Юридическое лицо",
    terms_and_condition: "Правила Использования",
    sitemap: "Карта сайта XML",
  },
  real_estate_single: {
    Parallax: {
      subtitle_right: "достойная вашего внимания",
      btn_1: "Ипотечный калькулятор",
      btn_2: "Специальный Запрос",
    },
    Product_images: {
      btn_open_gallary: "Открыть галлерею",
    },
    Product_details: {
      building_company: "Застройщик",
      responsible_agent: "Отвественный агент",
      btn_contact: "Свяжитесь для получения деталей",
      btn_see_presentation: "Скачать презентацию",
      highlights: "Плюсы объекта",
      about_product: "Описание",
    },
    OtherProducts: {
      block_title: "Смотреть другие объекты:",
    },
  },
  real_estate_single_gallary: {
    btn_go_back_to_details: "Назад к деталям",
    btn_go_back: "Назад",
  },
  modals_real_estate: {
    contact: {
      title: "Свяжитесь с экспертом для консультации",
      subtitle:
        "Не теряйте много времени! Специалист по недвижимости свяжется с вами и ответит на все ваши вопросы.",
      input_1_label: "Номер телефона",
      input_2_label: "Имя и Фамилия",
      text_reCAPTCHA:
        "Наш сайт защищен reCAPTCHA и соответствует Политике конфиденциальности и Условиям обслуживания Google.",
      btn_request: "Request free consultation",
      text_terms: {
        part_1: "Нажимая эту кнопку, вы автоматически соглашаетесь с нашими",
        part_2: "условиями и положениями сайта.",
      },
    },
    see_presentation_pdf: {
      title: "Скачать презентацию об этом объекте",
      subtitle:
        "Больше информации о планировке недвижимости, локации, и о другом в этой презентации.",
      input_1_label: "Номер телефона",
      input_2_label: "Имя и Фамилия",
      text_reCAPTCHA:
        "Наш сайт защищен reCAPTCHA и соответствует Политике конфиденциальности и Условиям обслуживания Google.",
      btn_request: "Скачать каталог",
      btn_download: "Готово к скачиванию",
      text_terms: {
        part_1: "Нажимая эту кнопку, вы автоматически соглашаетесь с нашими",
        part_2: "условия и положения.",
      },
    },
  },
  about_page: {
    Text_animation_1: "Академия G Partners",
    Text_animation_2: "Хаб G Partners",
    Text_animation_3: "Консалтинг по недвижимости",
    Parallax: {
      title: "Наша деятельность",
      subtitle_right: "Широкий спектр услуг в области недвижимости",
    },
    Learn_ihub_one: {
      title:
        "Помощь экспертам в повышении уровня компетенции и изучении рыночных аспектов.",
      subtitle_text_right:
        "Получение первоклассной информации от опытных наставников с 10-летним стажем на рынке недвижимости.",
      btn_learn: "Изучение в хабе",
    },
    AllActionOne: {
      items: [
        {
          title: "Всё, что вам нужно",
          description:
            "Мы создали комфортные условия обучения, чтобы вы могли максимально сконцентрироваться на процессе обучения. Наши аудитории оборудованы современным оборудованием, включая высококачественные 4K умные доски.",
        },
        {
          title: "Центральное расположение",
          description:
            "Академия G Partners расположена в самом центре города, что обеспечивает легкий доступ и удобство для вас. Это позволяет быстро и удобно добираться до наших занятий без потери времени на долгие поездки. Есть парковочные места!",
        },
        {
          title: "Преимущества курса начального обучения",
          description:
            "Глубокие знания в области, практические навыки, профессиональное признание, сетевое взаимодействие и непрерывное профессиональное развитие.",
        },
      ],
      btn: "Начать обучение",
    },
    Learn_ihub_two: {
      title:
        "Сохраняйте 100% комиссии, становитесь частью сообщества профессиональных экспертов, пользуйтесь современным офисом в ультрацентральном местоположении с гарантированными парковочными местами.",
      subtitle_text_right:
        "Взаимодействие с другими профессионалами отрасли, включая коллег-агентов по недвижимости, брокеров, инвесторов, застройщиков и поставщиков услуг. Участие в этой сети может привести к потенциальным рекомендациям, партнерству и возможностям сотрудничества, что в конечном итоге расширит клиентскую базу индивидуального эксперта и бизнес-возможности.",
      btn_request: "Запросите детали по хабу",
    },
    AllActionTwo: {
      items: [
        {
          title: "Хаб G Partners",
          description:
            "Уникальные возможности для сотрудничества и непрерывного развития. Наша цель - продвигать и развивать культуру уверенных и квалифицированных экспертов по недвижимости в Республике Молдова.",
        },
        {
          title: "Наши услуги",
          description:
            "Коворкинг-пространство; Курсы непрерывного профессионального обучения; Сетевые мероприятия и сессии; Юридическая, информационная и документальная поддержка; Доступ к базе данных компании и эксклюзивные предложения.",
        },
        {
          title: "Преимущества",
          description:
            "100% от сделок; Использование бренда компании; Клиентская и партнерская база данных компании; Современное, хорошо оборудованное рабочее пространство с удобным расположением; Собственная страница профиля на вебсайте.",
        },
      ],
      btn: "Присоединиться к хабу",
    },
    Learn_ihub_three: {
      title: "Мы уверены, что честность - основа долгосрочных отношений.",
      subtitle_text_right:
        "Все действия с нами проводятся прозрачно и честно по отношению к клиенту. Наши эксперты имеют высокий уровень знаний и опыта, обеспечивая успешные сделки по приобретению, продаже, посредничеству и консультированию недвижимости. Мы берем на себя ответственность за каждую сделку, стремясь обеспечить максимальное удовлетворение и безопасность для наших клиентов.",
      btn_request: "Запросить доступ к хабу",
    },
    AllActionThree: {
      items: [
        {
          title: "Опыт и экспертиза",
          description:
            "Обширный опыт в отрасли способствует созданию персонализированных и эффективных решений для каждого клиента.",
        },
        {
          title: "Разнообразный портфель",
          description:
            "Запросы на квартиры, роскошные дома, земли и коммерческие помещения легко удовлетворяются благодаря разнообразию предложений, представленных компанией.",
        },
        {
          title: "Прозрачность и профессионализм",
          description:
            "Открытость и прозрачность общения обеспечивают баланс и правильный выбор для клиентов.",
        },
      ],
      btn: "Свяжитесь с нами",
    },
    Our_services: {
      title: "В настоящее время мы предоставляем эти услуги",
    },
    FAQs: {
      title: "Часто задаваемые вопросы",
      items: {
        item1: {
          title:
            "Почему покупать недвижимость через агентство лучше и безопасней?",
          description:
            "Приобретение недвижимости через агентство имеет множество преимуществ, которые помогают минимизировать риски и оптимизировать процесс покупки: Юридическая безопасность, Профессиональная консультация, Экономия времени, Поддержка на всех этапах сделки, Оптимизация стоимости, Защита от мошенничества, Обширный выбор объектов, Юридическая защита сделки, Безопасность расчетов, Конфиденциальность.",
        },
        item2: {
          title: "Какие документы нужны для покупки недвижимости?",
          description:
            "Для покупки недвижимости необходимы следующие документы: • Паспорт покупателя. • Согласие супруга на покупку: если покупка осуществляется в браке. • Предварительный договор купли-продажи: с прописанными условиями и сроками сделки. • Документы на объект недвижимости: выписка, подтверждающая право собственности продавца и отсутствие обременений. • Документы, подтверждающие оплату: квитанции или выписки по счету, подтверждающие перевод денег продавцу.",
        },
        item3: {
          title: "Какие основные этапы продажи недвижимости?",
          description:
            "Основные этапы продажи недвижимости включают: 1. Подготовка документов: сбор всех необходимых правоустанавливающих документов на недвижимость. 2. Оценка недвижимости: определение рыночной стоимости объекта. 3. Поиск покупателя и показ объекта: размещение объявлений и организация просмотров. 4. Заключение предварительного договора: договор с внесением задатка покупателем. 5. Подписание основного договора купли-продажи: нотариальное оформление сделки и передача денег.",
        },
        item4: {
          title: "Как выбрать надежного застройщика при покупке новостройки?",
          description:
            "При выборе застройщика важно обратить внимание на следующие аспекты: 1. Опыт и репутация: изучите историю компании, количество завершённых объектов и отзывы клиентов. 2. Документы и разрешения: проверьте наличие всех необходимых разрешений на строительство и документы на земельный участок. 3. Финансовая стабильность: оцените финансовое состояние застройщика, наличие кредитов и обязательств. 4. Темпы строительства: посмотрите, насколько застройщик соблюдает сроки и качество работ на уже построенных объектах.",
        },
        item5: {
          title:
            "Что такое задаток при покупке недвижимости и как его правильно оформить?",
          description:
            "Задаток — это денежная сумма, передаваемая покупателем продавцу в качестве подтверждения намерения купить недвижимость. Он оформляется в виде отдельного соглашения или прописывается в предварительном договоре купли-продажи. Задаток служит гарантией выполнения сделки: если покупатель отказывается от покупки, задаток остается у продавца, если продавец отказывается, он должен вернуть задаток в двойном размере. Важно, чтобы в соглашении были четко прописаны условия возврата задатка и обстоятельства, при которых он не возвращается.",
        },
      },
    },
  },
  partners_page: {
    Parallax: {
      title: "Познакомьтесь с нашей командой",
      subtitle_right:
        "Узнайте больше о нашей команде агентов по недвижимости и партнерах.",
    },
    general_partners: {
      title: "Генеральные партнёры",
      content: {
        part_1:
          "В G Partners наше постоянное стремление к инновациям помогает нам оставаться в лидерах рынка недвижимости. С двадцатилетним опытом работы в отрасли мы приобрели навыки в управлении недвижимостью, обслуживании клиентов и строительстве.",
        part_2:
          "Мы сыграли важную роль в создании уникальных идентичностей объектов недвижимости, трансформации пространств и разработке выдающихся решений для многочисленных проектов в Молдове и других странах.",
        part_3: " ",
      },
    },
    our_team: {
      subtitle_text: "Познакомьтесь с нашей командой",
      title_text: "Эксперты по недвижимости",
    },
  },
  learn_page: {
    Parallax: {
      title: "Учитесь с нами",
      subtitle_right:
        "На основе нашего опыта мы разработали собственную академию, чтобы вы могли стать экспертом по недвижимости.",
    },
    about_the_project: {
      text_before_title: "Что это?",
      text_title: "Мы разработали самый практический курс",
      text_after_title:
        "чтобы стать экспертом по недвижимости в Молдове и начать зарабатывать деньги.",
      block_1: {
        about_project_title: "О проекте.",
        text: {
          part1:
            "Определенные тенденции на рынке недвижимости Молдовы побудили нас создать новое направление в нашей компании - академию обучения недвижимости.",
          part2:
            "Учебный материал был разработан нами в течение длительного периода времени и направлен на передачу 10-летнего опыта в сфере недвижимости, динамики рынка в Республике Молдова, методов общения с клиентами и принципов работы.",
        },
      },
      block_2: {
        you_will_master_title: "Ты освоишь",
        blocks: [
          {
            title: "Характеристики рынка недвижимости",
            text: "Научитесь различать категории, типы, ценовые диапазоны и факторы недвижимости.",
          },
          {
            title: "Глубокие знания в области",
            text: "Основные аспекты рынка недвижимости; Законодательная база в области недвижимости; Финансирование и кредиты; Кадастр и кадастровая информация.",
          },
          {
            title: "Практические навыки",
            text: "Практическое обучение через кейсы и симуляции; Навыки общения и взаимодействия с клиентами; Техники переговоров; Подготовка сделок.",
          },
          {
            title: "Профессиональное признание",
            text: "Трудоустройство в компании недвижимости “G Partners” и национальное и международное признание.",
          },
          {
            title: "Нетворкинг",
            text: "Взаимодействие с другими профессионалами и тренерами в области недвижимости; Профессиональное и отзывчивое сообщество.",
          },
          {
            title: "Непрерывное профессиональное развитие",
            text: "Курсы постоянно обновляются, чтобы отражать последние тенденции и изменения в секторе недвижимости.",
          },
        ],
        btns: {
          smart_board: "Смарт-доска 4K",
          mini_groups: "Мини-группы",
          graduated_students: "+500 выпускников",
          opportunity_to_work: "Возможность работать с нами",
        },
      },
    },
    material_schedule: {
      title_text: "Расписание учебных материалов",
      text_right: {
        title: "Расписание",
        subtitle:
          "Вся информация о курсах академии будет структурирована и собрана в блоки для облегчения запоминания и согласованности. Ознакомьтесь с основными блоками.",
      },
      week_1: {
        week_text_number: "Модуль 01",
        topic_name: "Сфера недвижимости и роль эксперта.",
      },
      week_2: {
        week_text_number: "Модуль 02",
        topic_name:
          "Анализ и оценка рынка, виды недвижимости, формирование цен.",
      },
      week_3: {
        week_text_number: "Модуль 03",
        topic_name: "Категории получателей услуг в сфере недвижимости.",
      },
      week_4: {
        week_text_number: "Модуль 04",
        topic_name: "Виды кредитов.",
      },
      week_5: {
        week_text_number: "Модуль 05",
        topic_name: "Законодательная база в сфере недвижимости.",
      },
      week_6: {
        week_text_number: "Модуль 06",
        topic_name:
          "Инструменты и стратегии работы, план деятельности эксперта по недвижимости.",
      },
    },
    our_mission: {
      text_subtitle: "что значит быть частью G Partners",
      text_title: "Наша основная миссия",
      text_content_part_1_bold: "Недвижимость в Молдове и в мире.",
      text_content_part_2:
        "В G Partners наша неизменная страсть к инновациям питает нашу постоянную стремительность оставаться на передовых позициях в трендах недвижимости. Обладая более двух десятилетий коллективного опыта в отрасли, мы отточили разнообразный набор навыков в области развития недвижимости, дизайна и обслуживания клиентов.",
      text_content_part_3_bold: "Строительные и ремонтные навыки в Молдове.",
      text_content_part_4:
        "Мы сыграли ключевую роль в создании уникальной недвижимости, пространств и разработке решений для проектов недвижимости в Молдове.",
      text_content_part_5: "Инвестируйте вместе с G Partners.",
    },
  },
  contact_page: {
    Parallax: {
      title: "Свяжитесь с нами",
    },
    get_in_touch: {
      title: "Давайте свяжемся",
      text_right:
        "Мы онлайн для связи с вами с 9 утра до 20 вечера по часовому поясу GMT+3.",
      btn_text: "Связаться",
    },
    map_location: {
      title_text: "Местоположение главного офиса",
      text_right: "Бульвар Штефан чел Маре ши Сфынт 6, MD-2012, Кишинев",
    },
  },
  single_partner: {
    about_title: "",
    all_partners_btn: "Все партнеры",
    g_partners_agent: "Партнёр",
    responsible_for: "Ответственен за",
    contact_btn: " Свяжитесь для получения деталей",
  },
};
