<template>
  <v-container :fluid="$vuetify.display.mdAndDown ? true : false">
    <v-row justify="center">
      <v-col cols="11" sm="11" md="11">
        <v-row justify="space-between">
          <v-col cols="12" sm="6">
            <h4 class="text-title">
              {{ t("$vuetify.contact_page.map_location.title_text") }}
            </h4>
          </v-col>
          <v-col cols="12" sm="3" md="2" v-if="$vuetify.display.smAndUp" align-self="center">
            <p class="text-subtitle">
              {{ t("$vuetify.contact_page.map_location.text_right") }}
            </p>
          </v-col>
        </v-row>
        <v-row justify="center" class="mt-10">
          <v-col cols="12">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2720.2743070571887!2d28.844554676266995!3d47.01522042862799!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97d094651b563%3A0xbf24656efec9a24a!2sG%20Partners!5e0!3m2!1sru!2s!4v1715264307211!5m2!1sru!2s"
              width="100%" :height="$vuetify.display.smAndUp ? '650' : '400'" style="border:0;" allowfullscreen=""
              loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="iframe-map"></iframe>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useLocale } from "vuetify";

export default {
  name: "HomeRealEstate",
  setup() {
    const { t } = useLocale();
    return {
      t,
    };
  },
  data: () => ({
  }),
  mounted() {

  },
};
</script>

<style scoped>
.iframe-map {
  border-top-right-radius: 120px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}


.card-text {
  color: #f6f2e6;
  font-size: 1.2rem;
  letter-spacing: -0.03rem;
  font-weight: 400;
  line-height: 25px;
  min-height: 80px;
}

.btn-bottom {
  color: rgba(246, 242, 230, 1);
  text-transform: inherit;
  font-size: 1rem;
  letter-spacing: -0.03rem;
  transition: 0.7s;
  font-weight: 400;
}

.btn-bottom:hover {
  color: #111213;
  background: #f6f2e6;
}

.cards-block {
  background: transparent;
  border-top-left-radius: 40px;
}

.text-subtitle {
  color: #f6f2e6;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: -0.01rem;
  text-wrap: balance;
}

.text-title {
  color: #9A9A9B;
  font-size: 6rem;
  letter-spacing: -0.05rem;
  line-height: 6.5rem;
  font-weight: 400;
}

.svg-bottom {
  margin-top: 100px;
  margin-bottom: 100px;
}

@media screen and (max-width: 600px) {
  .text-title {
    font-size: 3.5rem;
    letter-spacing: -0.05rem;
    line-height: 3.7rem;
  }

}

@media screen and (min-width: 600px) and (max-width: 960px) {}
</style>