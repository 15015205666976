<template>
  <v-container :fluid="$vuetify.display.mdAndDown ? true : false" style="position: relative">
    <v-row class="mb-10" :justify="$vuetify.display.mdAndUp ? 'start' : 'center'"
      style="z-index: 2; position: relative">
      <v-col cols="12" sm="10" md="9">
        <h3 class="text-h3 title-block">
          {{ t("$vuetify.home.HelpWithYou.title") }}
        </h3>
      </v-col>
    </v-row>
    <div class="blur_div"></div>
    <v-row justify="center" class="mt-md-15" style="z-index: 2; position: relative">
      <v-col cols="12" sm="10" md="10">
        <v-row justify="center">
          <v-col cols="12" sm="6" md="4" class="pa-0 mt-3" v-for="(item, index) in items" :key="index"
            align-self="stretch">
            <v-card class="card-block px-4 pt-4 pb-1" flat
              @click="OpenContactUsModalActive({ title: t(`$vuetify.${item.title}`) })">
              <v-img :src="item.img" :lazy-src="item.lazy_img" class="card-image" aspect-ratio="2.46" cover>
              </v-img>
              <v-card-item class="pa-0">
                <v-card-text class="cards-number px-0 py-3">
                  {{ index < 9 ? "0" + (index + 1).toString() : index + 1 }} </v-card-text>
                    <v-divider class="card-divider my-4"></v-divider>
                    <v-card-text class="card-title pa-0">
                      {{ t(`$vuetify.${item.title}`) }}
                    </v-card-text>
                    <v-btn class="card-link pa-0" variant="text" v-if="!$vuetify.display.smAndDown">
                      {{ t("$vuetify.home.HelpWithYou.btn_action") }}
                    </v-btn>
                    <v-card-text class="px-0 pb-2 pt-0" :class="$vuetify.display.smAndDown
                      ? 'card-text-mobile'
                      : 'card-text'
                      ">
                      {{ t(`$vuetify.${item.description}`) }}
                    </v-card-text>
                    <v-btn v-if="$vuetify.display.smAndDown" class="pa-0 card-link" variant="text">
                      {{ t("$vuetify.home.HelpWithYou.btn_action") }}
                    </v-btn>
              </v-card-item>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <ContactUsModal ref="OpenContactUsModal" />
  </v-container>
</template>

<script>
import { useLocale } from "vuetify";
import ContactUsModal from "../Modals/ContactUsModal.vue";

export default {
  name: "HomeHelpWithYou",
  setup() {
    const { t } = useLocale();
    return {
      t,
    };
  },
  components: {
    ContactUsModal,
  },
  data: () => ({
    items: [
      {
        img: require("../../assets/img/home/helpWithYou/1.jpg"),
        lazy_img: require("../../assets/img/home/helpWithYou/lazy/1.jpg"),
        title: "home.HelpWithYou.items[0].title",
        description:
          "home.HelpWithYou.items[0].description",
      },
      {
        img: require("../../assets/img/home/helpWithYou/2.jpg"),
        lazy_img: require("../../assets/img/home/helpWithYou/lazy/2.jpg"),
        title: "home.HelpWithYou.items[1].title",
        description:
          "home.HelpWithYou.items[1].description",
      },
      {
        img: require("../../assets/img/home/helpWithYou/3.jpg"),
        lazy_img: require("../../assets/img/home/helpWithYou/lazy/3.jpg"),
        title: "home.HelpWithYou.items[2].title",
        description:
          "home.HelpWithYou.items[2].description",
      },
      {
        img: require("../../assets/img/home/helpWithYou/4.jpg"),
        lazy_img: require("../../assets/img/home/helpWithYou/lazy/4.jpg"),
        title: "home.HelpWithYou.items[3].title",
        description:
          "home.HelpWithYou.items[3].description",
      },
      {
        img: require("../../assets/img/home/helpWithYou/5.jpg"),
        lazy_img: require("../../assets/img/home/helpWithYou/lazy/5.jpg"),
        title: "home.HelpWithYou.items[4].title",
        description:
          "home.HelpWithYou.items[4].description",
      },
      {
        img: require("../../assets/img/home/helpWithYou/6.jpg"),
        lazy_img: require("../../assets/img/home/helpWithYou/lazy/6.jpg"),
        title: "home.HelpWithYou.items[5].title",
        description:
          "home.HelpWithYou.items[5].description",
      },
      {
        img: require("../../assets/img/home/helpWithYou/7.jpg"),
        lazy_img: require("../../assets/img/home/helpWithYou/lazy/7.jpg"),
        title: "home.HelpWithYou.items[6].title",
        description:
          "home.HelpWithYou.items[6].description",
      },
      {
        img: require("../../assets/img/home/helpWithYou/8.jpg"),
        lazy_img: require("../../assets/img/home/helpWithYou/lazy/8.jpg"),
        title: "home.HelpWithYou.items[7].title",
        description:
          "home.HelpWithYou.items[7].description",
      },
      {
        img: require("../../assets/img/home/helpWithYou/9.jpg"),
        lazy_img: require("../../assets/img/home/helpWithYou/lazy/9.jpg"),
        title: "home.HelpWithYou.items[8].title",
        description:
          "home.HelpWithYou.items[8].description",
      },
      {
        img: require("../../assets/img/home/helpWithYou/10.jpg"),
        lazy_img: require("../../assets/img/home/helpWithYou/lazy/10.jpg"),
        title: "home.HelpWithYou.items[9].title",
        description:
          "home.HelpWithYou.items[9].description",
      },
    ],
  }),
  methods: {
    OpenContactUsModalActive(item) {
      this.$refs.OpenContactUsModal.dialog = true;
      this.$refs.OpenContactUsModal.services = item;
    },
  }
};
</script>

<style scoped>
.blur_div {
  position: absolute;
  top: 0px;
  right: 0%;
  width: 250px;
  height: 250px;
  background: rgba(246, 242, 230, 0.55);
  -webkit-backdrop-filter: blur(200px);
  filter: blur(200px);
  -webkit-filter: blur(200px);
  border-radius: 100%;
  will-change: transform;
}

.card-text {
  font-size: 11px;
  line-height: 16px;
  width: 85%;
  color: rgba(154, 154, 155, 0);
  transition: 0.5s;
}

.card-text-mobile {
  color: #9a9a9b;
}

.card-link {
  text-transform: inherit;
  text-decoration: underline;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: -0.02px;
  text-underline-offset: 2px;
  color: #f6f2e6;
  transition: 0.5s;
}

.card-block:hover .card-link {
  color: #111213;
}

.card-title {
  font-size: 30px;
  color: #f6f2e6;
  line-height: 42px;
  transition: 0.5s;
  letter-spacing: -0.02rem;
}

.card-divider {
  border-color: #9a9a9b;
  opacity: 1;
  transition: 0.5s;
}

.cards-number {
  color: #9a9a9b;
  font-size: 15px;
  transition: 0.5s;
}

.card-block .card-image {
  border-top-left-radius: 40px;
  transition: all 0.5s ease-out allow-discrete;
  filter: saturate(0) brightness(1);
  -webkit-filter: saturate(0) brightness(1);
}

.card-block:hover .card-image {
  filter: saturate(1) brightness(1.7);
  -webkit-filter: saturate(1) brightness(1.7);
}

.card-block {
  background: transparent;
  transition: 0.5s;
  border-bottom: 6px;
  border-top-left-radius: 40px;
  border-top-right-radius: 4px;
  height: 100%;
}

.card-block:hover {
  background: #f6f2e6;
}

.card-block:hover .card-title {
  color: #111213;
}

.card-block:hover .card-divider {
  color: #111213;
}

.card-block:hover .cards-number {
  color: #111213;
}

.card-block:hover .card-text {
  color: #9a9a9b;
}

.title-block {
  color: #9a9a9b;
}

@media screen and (max-width: 600px) {
  .title-block {
    font-size: 32px !important;
    line-height: 40px;
  }
}

@media screen and (max-width: 600px) and (max-width: 960px) {
  .title-block {
    font-size: 32px !important;
    line-height: 40px;
  }
}
</style>