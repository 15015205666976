<template>
    <ContactUsModal ref="OpenContactUsModal" />
    <v-container class="container-main" :fluid="$vuetify.display.mdAndDown ? true : false">
        <v-row justify="center" v-if="Partner">
            <v-col cols="12" md="11" lg="11">
                <v-card flat color="#000000" class="card-details px-6 py-11 pa-xl-14">
                    <v-row justify="center">
                        <v-col cols="12" sm="11" md="9" lg="8">
                            <v-row justify="center">
                                <v-col align-self="center" cols="12" sm="4">
                                    <v-img :src="urlAPI + Partner.path" :alt="Partner.alt" aspect-ratio="1" cover
                                        width="100%" class="img-agent">
                                    </v-img>
                                </v-col>
                                <v-col align-self="center" cols="12" sm="auto" md="auto" lg="auto"
                                    class="pl-md-8 pl-lg-10 pr-0">
                                    <v-card-text class="px-0 card-text-agent">
                                        {{ t("$vuetify.single_partner.g_partners_agent") }}
                                    </v-card-text>
                                    <v-card-title class="px-0 card-title-agent">
                                        {{ $vuetify.locale.current == 'ru' ? Partner['name_ru'] : Partner['name_ro'] }}
                                        <svg class="svg-green-circle" width="10" height="10" viewBox="0 0 10 10"
                                            fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="5" cy="5" r="5" fill="#19D442" />
                                        </svg>
                                    </v-card-title>
                                    <!-- @click="OpenContactUsModalActive(null, Partner)" -->
                                    <v-btn :href="'tel:' + Partner.phone" variant="outlined"
                                        class="btn-contact rounded-pill px-12 mt-3" color="#F6F2E6">
                                        <template v-slot:prepend>
                                            <v-icon>
                                                <svg width="17" viewBox="0 0 15 15" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M1.87559 10.3267V12.5252L0.229626 11.5599C0.0875559 11.4765 0 11.3223 0 11.1553V9.22662L1.87559 10.3267Z"
                                                        fill="#F6F2E6" />
                                                    <path
                                                        d="M3.75031 11.4268L3.74969 13.6252L1.87559 12.5252V10.3267L3.75031 11.4268Z"
                                                        fill="#F6F2E6" />
                                                    <path
                                                        d="M5.62528 12.5252V14.1869C5.62528 14.4263 5.37087 14.5761 5.16712 14.4566L3.74969 13.6252L3.75031 11.4268L5.62528 12.5252Z"
                                                        fill="#F6F2E6" />
                                                    <path
                                                        d="M5.62528 10.3267V12.5252L3.75031 11.4268L3.74976 9.49601C3.74973 9.37626 3.877 9.30135 3.97889 9.36116L5.62248 10.3259C5.62333 10.3264 5.6243 10.3267 5.62528 10.3267Z"
                                                        fill="#F6F2E6" />
                                                    <path
                                                        d="M1.87559 8.12659V10.3267L0 9.22662V7.02655L1.87559 8.12659Z"
                                                        fill="#F6F2E6" />
                                                    <path
                                                        d="M1.87516 5.92682L1.87559 8.12659L0 7.02655V4.82682L1.87516 5.92682Z"
                                                        fill="#F6F2E6" />
                                                    <path
                                                        d="M3.74819 4.828L1.87516 5.92682L0 4.82682L1.87559 3.72796L3.74819 4.828Z"
                                                        fill="#F6F2E6" />
                                                    <path
                                                        d="M5.62228 5.92803L3.75031 7.02682L3.44233 6.84676L1.87516 5.92682L3.74819 4.828H3.74969L5.62228 5.92803Z"
                                                        fill="#F6F2E6" />
                                                    <path
                                                        d="M7.26799 6.89325C7.37004 6.9531 7.37005 7.10305 7.26801 7.16292L5.8541 7.99235C5.71246 8.07544 5.53809 8.07544 5.39644 7.99236L3.75031 7.02682L5.62228 5.92803L7.26799 6.89325Z"
                                                        fill="#F6F2E6" />
                                                    <path
                                                        d="M11.2518 11.4568L11.249 13.4852C11.2489 13.5366 11.2489 13.5624 11.2416 13.5854C11.235 13.6067 11.2238 13.6262 11.2091 13.6428C11.193 13.6606 11.1712 13.6735 11.1274 13.6992L10.1033 14.3003C9.86678 14.4391 9.7485 14.5085 9.65143 14.4992C9.56301 14.4906 9.48262 14.4433 9.43118 14.3696C9.37472 14.2886 9.37472 14.1496 9.37472 13.8716V12.5279L11.2518 11.4568Z"
                                                        fill="#F6F2E6" />
                                                    <path
                                                        d="M11.2518 9.25821L11.2518 11.4568L9.37772 12.5279V10.3247L11.2518 9.25821Z"
                                                        fill="#F6F2E6" />
                                                    <path
                                                        d="M11.2488 7.02921L11.2518 9.25821L9.37772 10.3247L9.37472 8.12924L11.2488 7.02921Z"
                                                        fill="#F6F2E6" />
                                                    <path
                                                        d="M11.2488 7.02921L9.37472 8.12924L7.86235 7.24153C7.7448 7.17253 7.68602 7.13803 7.66567 7.09309C7.64713 7.05215 7.64694 7.00503 7.66515 6.96394C7.68513 6.91883 7.74363 6.88384 7.86062 6.81385L9.37772 5.90633L11.2488 7.02921Z"
                                                        fill="#F6F2E6" />
                                                    <path
                                                        d="M13.1259 5.90632L11.2488 7.02921L9.37772 5.90633L11.219 4.8082L13.1259 5.90632Z"
                                                        fill="#F6F2E6" />
                                                    <path
                                                        d="M15 4.8398L13.1259 5.90632L11.219 4.8082L13.1233 3.72796L15 4.8398Z"
                                                        fill="#F6F2E6" />
                                                    <path
                                                        d="M15 4.8398L14.9985 7.0292L13.1261 8.15908L13.1259 5.90632L15 4.8398Z"
                                                        fill="#F6F2E6" />
                                                    <path
                                                        d="M14.9985 7.0292L14.9976 8.80491C14.9976 8.95732 14.9975 9.03353 14.9763 9.10203C14.9567 9.16523 14.9241 9.22346 14.8807 9.27285C14.8336 9.32636 14.7693 9.36523 14.6405 9.44297L13.1259 10.3583L13.1261 8.15908L14.9985 7.0292Z"
                                                        fill="#F6F2E6" />
                                                    <path
                                                        d="M13.1261 8.15908L13.1259 10.3583L11.2518 11.4568L11.2518 9.25821L13.1261 8.15908Z"
                                                        fill="#F6F2E6" />
                                                    <path
                                                        d="M13.1229 3.728L11.219 4.8082L10.943 4.64673L9.37578 3.72678L11.2488 2.62796H11.2503L13.1229 3.728Z"
                                                        fill="#F6F2E6" />
                                                    <path
                                                        d="M11.2477 2.628L9.37578 3.72678L7.50062 2.62796L9.37259 1.528L11.2477 2.628Z"
                                                        fill="#F6F2E6" />
                                                    <path
                                                        d="M9.37259 1.528L7.50062 2.62796L5.62547 1.52796L7.26952 0.562434C7.41124 0.479208 7.58577 0.479187 7.7275 0.562381L9.37259 1.528Z"
                                                        fill="#F6F2E6" />
                                                    <path
                                                        d="M1.87516 3.728L3.74712 4.82678L4.0551 4.64673L5.62228 3.72678L3.75 2.62398L1.87516 3.728Z"
                                                        fill="#F6F2E6" />
                                                    <path
                                                        d="M3.75 2.62398L5.62547 3.728L5.93345 3.54794L7.50062 2.62796L5.62547 1.52796L3.75 2.62398Z"
                                                        fill="#F6F2E6" />
                                                    <path
                                                        d="M5.625 10.1487V11.3784L3.49034 10.2117C3.41928 10.1729 3.375 10.0979 3.375 10.0163V8.82437L5.625 10.1487Z"
                                                        fill="#F6F2E6" />
                                                    <path
                                                        d="M5.625 10.1487V11.3784L3.49034 10.2117C3.41928 10.1729 3.375 10.0979 3.375 10.0163V8.82437L5.625 10.1487Z"
                                                        fill="#F6F2E6" />
                                                </svg>
                                            </v-icon>
                                        </template>
                                        {{ t("$vuetify.single_partner.contact_btn") }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { useLocale } from "vuetify";
import ContactUsModal from "../Modals/ContactUsModal.vue";
import { urlAPI } from "../../store/config";

export default {
    name: "PartnerDetails",
    props: {
        Partner: Object,
    },
    components: {
        ContactUsModal,
    },
    setup() {
        const { t } = useLocale();
        return {
            t,
        };
    },
    data: () => ({
        urlAPI: urlAPI + '/',
    }),
    methods: {
        OpenContactUsModalActive(property, agent) {
            this.$refs.OpenContactUsModal.dialog = true;
            this.$refs.OpenContactUsModal.property = property;
            this.$refs.OpenContactUsModal.agent = agent;
        }
    }
};
</script>
<style scoped>
.svg-green-circle {
    vertical-align: top;
}

.card-text-agent {
    color: #9A9A9B;
    font-size: 1.1rem;
}

.card-title-agent {
    color: #F6F2E6;
    font-size: 2.8rem;
    letter-spacing: -0.02rem;
    text-wrap: balance;
    line-height: 3.2rem;
}

.img-agent {
    border-top-left-radius: 40px;
    border-top-right-radius: 2px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
}

.btn-contact {
    text-transform: inherit;
    letter-spacing: -0.02rem;
    font-weight: 400;
    font-size: 1rem;
    padding-top: 2px;
}

.card-details {
    border-radius: 4px;
}

@media screen and (max-width: 600px) {}

@media screen and (min-width: 600px) and (max-width: 960px) {}
</style>