<template>
    <v-container fluid class="pt-10 fill-height" v-if="getPriority == 1">
        <v-row class="w-100" justify="center">
            <v-col cols="12">
                <h1>Логи</h1>
            </v-col>
        </v-row>
        <v-row justify="space-between" class="w-100">
            <v-col cols="auto">
                <v-btn color="#0255B7" rounded="lg" class="btn-text" @click="GoToSettings()">
                    Список админов
                </v-btn>
                <v-btn color="#0255B7" rounded="lg" class="btn-text ml-3" @click="GoToFilters()">
                    Настройка параметров фильтров
                </v-btn>
            </v-col>
        </v-row>
        <v-row class="my-4 mx-0 w-100" justify="center">
            <v-row class="my-4 mx-0 w-100" justify="center">
                <v-table :height="getAllLogs.length < limit_view ? '65vh' : 'auto'" fixed-header class="table" hover>
                    <thead>
                        <tr>
                            <th class="text-left">
                                Admin login
                            </th>
                            <th class="text-left">
                                ID
                            </th>
                            <th class="text-left">
                                Дата и время
                            </th>
                            <th class="text-left">
                                Описание лога
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in getAllLogs.slice((page_view - 1) * limit_view, page_view * limit_view)"
                            :key="item.ID">
                            <td style="width: 150px;">{{ item.username }}</td>
                            <td style="width: 70px;">{{ item.ID }}</td>
                            <td style="width: 200px;">{{ item.log_date }}</td>
                            <td class="log-description">{{ item.log_description }}</td>
                        </tr>
                    </tbody>
                </v-table>
            </v-row>
            <v-row justify="space-between" class="w-100" v-if="getAllLogs.length > limit_view">
                <v-col cols="auto">
                    <v-btn color="#00000018" rounded="lg" elevation="0" class="btn-text" append-icon="mdi-reply"
                        @click="MinusPage()">
                        Предыдущая страница
                    </v-btn>
                </v-col>
                <v-col cols="auto">
                    <p> Страница: {{ page_view }}</p>
                </v-col>
                <v-col cols="auto">
                    <v-btn color="#00000018" rounded="lg" elevation="0" class="btn-text" append-icon="mdi-share"
                        @click="PlusPage()">
                        Следующая страница
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider class="mt-4 mb-2 divider-bottom"></v-divider>
            <v-row class="my-0">
                <v-col cols="auto">
                    <p>Всего записей: {{ getAllLogs.length }}</p>
                </v-col>
            </v-row>
        </v-row>
    </v-container>
    <v-snackbar :timeout="2000" location="top right" :color="alert_snackbar.color" variant="flat"
        v-model="alert_snackbar.state">
        <strong>{{ alert_snackbar.text }}</strong>
    </v-snackbar>
</template>
<script>
import { defineComponent } from "vue";
import { useLocale } from "vuetify";
import { mapGetters } from 'vuex'
import { urlAPI } from "../../store/config";

export default defineComponent({
    name: "SettingsLogsCRM",
    setup() {
        const { t } = useLocale();
        return {
            t,
        };
    },
    data: () => ({
        urlAPI: urlAPI + '/',
        page_view: 1,
        limit_view: 100,
        alert_snackbar: {
            state: false,
            color: "",
            text: ""
        },
    }),
    computed: {
        ...mapGetters([
            'getAllLogs',
            'getPriority',
        ]),
    },
    methods: {
        MinusPage() {
            if (this.page_view > 1) {
                this.page_view -= 1;
            }
        },
        PlusPage() {
            if (this.page_view * this.limit_view < this.getAllLogs.length) {
                this.page_view += 1;
            }
        },
        GoToSettings() {
            this.$router.push({
                name: "admin-settings-crm",
            })
        },
        GoToFilters() {
            this.$router.push({
                name: "filters-settings-crm",
            })
        }
    },
    async mounted() {
        await this.$store.dispatch('LoadAllLogs');
    },
})
</script>
<style scoped>
.title_property_big {
    font-weight: 500;
    font-size: 1.4rem;
}

.title_property {
    font-weight: 500;
    font-size: 1.1rem;
}

.divider-bottom {
    border: 1px solid rgba(0, 0, 0, 0.2);
    opacity: 1;
}

.btn-text-2 {
    text-transform: inherit;
    letter-spacing: 0px;
    text-align: left;
}

.btn-text {
    text-transform: inherit;
}

.table {
    border: 1px solid rgba(0, 0, 0, 0.12);
    width: 100%;
    border-radius: 10px;
}

.input-search {
    min-width: 300px;
}

.select-search {
    min-width: 200px;
    max-width: 400px;
}

.col-header-gap {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 10px;
}

.w-100 {
    min-width: 100%;
}

@media screen and (max-width: 600px) {
    .log-description {
        font-size: 0.6rem;
    }
}
</style>