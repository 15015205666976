<template>
  <v-container :fluid="$vuetify.display.mdAndDown ? true : false">
    <v-row :justify="$vuetify.display.mdAndUp ? 'start' : 'center'">
      <v-col cols="12" sm="11" md="9">
        <h1 class="text-h1 title-block">
          <svg :width="$vuetify.display.mdAndUp ? '46' : '22'" viewBox="0 0 46 46" fill="none"
            xmlns="http://www.w3.org/2000/svg" class="svg-animation mb-2">
            <path d="M20 0.0444614L24 0.0373381L24.0801 45.0373L20.0801 45.0444L20 0.0444614Z" fill="#F6F2E6" />
            <path d="M45.0373 20.9999L45.0444 24.9999L0.0445209 25.08L0.0373977 21.08L45.0373 20.9999Z"
              fill="#F6F2E6" />
            <path d="M36.8047 4.99062L39.5765 7.75262L7.81343 39.629L5.04157 36.867L36.8047 4.99062Z" fill="#F6F2E6" />
            <path d="M39.6291 37.2664L36.8671 40.0383L4.99065 8.27517L7.75266 5.50331L39.6291 37.2664Z"
              fill="#F6F2E6" />
          </svg>
          {{ t("$vuetify.home.real_estate.title") }}
        </h1>
      </v-col>
    </v-row>
    <v-row :justify="$vuetify.display.mdAndUp ? 'end' : 'center'" class="mt-8">
      <v-col cols="12" sm="11" md="9">
        <v-row justify="center">
          <v-col cols="6" md="4" v-for="(card, i) in getTOPProducts" :key="i">
            <cardRealEstate :card="card" />
          </v-col>
        </v-row>
        <v-row justify="start">
          <v-col cols="12" md="10" lg="9">
            <v-btn variant="outlined" v-for="categorie in getAllCategories" :key="categorie.ID"
              class="btn-bottom rounded-pill mr-4 px-6 px-sm-9 my-2"
              :size="$vuetify.display.mdAndUp ? 'large' : 'small'" @click="SelectCategory(categorie)">
              {{ categorie[`name_${$vuetify.locale.current}`] }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useLocale } from "vuetify";
import cardRealEstate from "../cardRealEstate.vue";
import { mapGetters } from 'vuex'

export default {
  name: "HomeRealEstate",
  setup() {
    const { t } = useLocale();
    return {
      t,
    };
  },
  components: {
    cardRealEstate,
  },
  computed: {
    ...mapGetters([
      'getAllCategories',
      'getTOPProducts'
    ])
  },
  data: () => ({
  }),
  async mounted() {
    await this.$store.dispatch('LoadAllCategories');
    await this.$store.dispatch('LoadTOPProducts');
  },
  methods: {
    SelectCategory(categorie) {
      this.$router.push({ name: 'real-estate', params: { categorie: categorie[`link_${this.$vuetify.locale.current}`], lang: this.$route.params.lang } })
    }
  }
};
</script>

<style scoped>
.svg-animation {

  animation-name: svg-anim;
  animation-duration: 14s;
  animation-direction: normal;
  animation-timing-function: linear;
  transform: rotate(0deg);
  animation-iteration-count: infinite;
}

@keyframes svg-anim {
  from {
    transform: rotate(-360deg);
  }

  to {
    transform: rotate(0deg);
  }
}

.btn-bottom {
  color: rgba(246, 242, 230, 1);
  text-transform: inherit;
  font-style: italic;
  font-size: 1.2rem;
  letter-spacing: -0.03rem;
  transition: 0.7s;
}

.btn-bottom:hover {
  color: #111213;
  background: #f6f2e6;
}

.title-block {
  color: #9a9a9b;
  letter-spacing: -0.25rem !important;
}

@media screen and (min-width: 600px) and (max-width: 960px) {
  .title-block {
    font-size: 52px !important;
    line-height: 60px;
    letter-spacing: -0.09rem !important;
  }
}

@media screen and (max-width: 600px) {
  .title-block {
    font-size: 32px !important;
    line-height: 40px;
    letter-spacing: -0.06rem !important;
  }

  .btn-bottom {
    font-size: 0.9rem;
    letter-spacing: -0.02rem;
  }
}
</style>