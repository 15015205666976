<template>
  <v-row style="flex-wrap: nowrap;" class="ml-0">
    <v-img @click="$router.push({ name: 'home', params: { lang: $vuetify.locale.current } })" absolute min-width="40px"
      max-width="40px" height="40px" src="../assets/img/header/logo.png" class="img-logo my-3"></v-img>
    <v-breadcrumbs class="breadcrumbs-products pr-0">
      <v-breadcrumbs-item @click="$router.push('/')" class="breadcrumbs-products-item">
        {{ t("$vuetify.header.home") }}
      </v-breadcrumbs-item>
      <v-breadcrumbs-divider></v-breadcrumbs-divider>
      <v-breadcrumbs-item
        @click="$router.push({ name: name.replace('_', '-'), params: { categorie: '', lang: $vuetify.locale.current } })"
        class="breadcrumbs-products-item">
        {{ t(`$vuetify.header[${name}]`) }}
      </v-breadcrumbs-item>

      <template v-if="Categorie">

        <v-breadcrumbs-divider></v-breadcrumbs-divider>
        <v-breadcrumbs-item
          @click="$router.push({ name: 'real-estate', params: { categorie: Categorie.id, lang: $vuetify.locale.current } })"
          class="breadcrumbs-products-item">
          {{ Categorie[`name_${$vuetify.locale.current}`] }}
        </v-breadcrumbs-item>

      </template>

      <template v-if="Product && $vuetify.display.mdAndUp">

        <v-breadcrumbs-divider></v-breadcrumbs-divider>
        <v-breadcrumbs-item class="breadcrumbs-products-item">
          {{ Product[`name_${$vuetify.locale.current}`] }}
        </v-breadcrumbs-item>

      </template>

      <template v-if="Partner && $vuetify.display.mdAndUp">

        <v-breadcrumbs-divider></v-breadcrumbs-divider>
        <v-breadcrumbs-item class="breadcrumbs-products-item">
          {{ $vuetify.locale.current == 'ru' ? Partner['name_ru'] : Partner['name_ro'] }}
        </v-breadcrumbs-item>

      </template>

    </v-breadcrumbs>
  </v-row>
</template>

<script>
import { useLocale } from "vuetify";

export default {
  name: "breadcrumbsComponent",
  setup() {
    const { t } = useLocale();
    return {
      t,
    };
  },
  data: () => ({}),
  props: {
    name: String,
    Categorie: Object,
    Product: Object,
    Partner: Object,
  },
  methods: {
  }
};
</script>
<style scoped>
.breadcrumbs-products {
  font-style: italic;
  font-size: 1.2rem;
  letter-spacing: -0.02rem;
  color: #F6F2E6;
}

.breadcrumbs-products-item {
  margin-left: 30px;
  margin-right: 30px;
}

.breadcrumbs-products-item:hover {
  cursor: pointer;
  color: #f6f2e6b2;
}

.img-logo {
  transition: .3s;
}

.img-logo:hover {
  cursor: pointer;
  filter: brightness(4);
}

@media screen and (max-width: 600px) {
  .breadcrumbs-products {
    font-size: 0.9rem;

  }

  .breadcrumbs-products-item {
    margin-left: 5px;
    margin-right: 5px;
  }
}

@media screen and (min-width: 600px) and (max-width: 960px) {}
</style>