<template>
  <headerTop />
  <cookiesModal />
  <getConsultation />
  <v-main class="main-page">
    <v-container class="mb-150 px-0" :fluid="$vuetify.display.mdAndDown ? true : false">
      <v-row justify="center" v-if="getProduct">
        <v-col cols="12" md="11" lg="10">
          <v-row justify="center" class="ma-0 img_main">
            <v-img :src="urlAPI + getProduct.main_image_path" height="auto" width="100%"
              style="display: flex; border-radius: 4px;">
              <v-row justify="start" class="ma-0" style="height: 100%;">
                <v-col align-self="start" cols="auto" class="pa-4">
                  <v-btn class="btn-gallary px-8" @click="GoToProduct()">
                    <svg width="15" height="10" class="mr-2" viewBox="0 0 15 10" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M14.5 5C14.5 4.72386 14.2761 4.5 14 4.5L2.20711 4.5L5.35355 1.35355C5.54882 1.15829 5.54882 0.841708 5.35355 0.646446C5.15829 0.451183 4.84171 0.451183 4.64645 0.646446L0.646446 4.64645C0.451184 4.84171 0.451184 5.15829 0.646446 5.35355L4.64645 9.35355C4.84171 9.54882 5.15829 9.54882 5.35355 9.35355C5.54882 9.15829 5.54882 8.84171 5.35355 8.64645L2.20711 5.5L14 5.5C14.2761 5.5 14.5 5.27614 14.5 5Z"
                        fill="#F6F2E6" />
                    </svg>
                    {{ t("$vuetify.real_estate_single_gallary.btn_go_back_to_details") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-img>
          </v-row>
          <v-row justify="center" class="ma-0 mt-2">
            <v-col :cols="getProduct.carousel.length % 2 != 0 && index + 1 == getProduct.carousel.length ? 12 : 6"
              v-for="(img, index) in getProduct.carousel" :key="index" class="other-images">
              <v-img :src="urlAPI + img.path" cover height="100%" width="100%" style="border-radius: 4px;"></v-img>
            </v-col>
          </v-row>
          <v-row justify="center" class="ma-0 mt-15">
            <v-btn variant="outlined" class="btn-paralax rounded-pill my-2 px-10"
              :size="$vuetify.display.lgAndUp ? 'x-large' : 'large'" @click="GoToProduct()">
              {{ t("$vuetify.real_estate_single_gallary.btn_go_back") }}
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <GetInTouch class="mb-150" />
  </v-main>
  <footerComponent />
  <Preloader :is-loading="isLoading" />
</template>

<script>
import headerTop from "../components/header.vue";
import cookiesModal from "../components/cookiesModal.vue";
import getConsultation from "../components/getConsultation.vue";
import Preloader from "../components/Preloader.vue";
import GetInTouch from "../components/RealEstatePage/4.GetInTouch.vue";
import footerComponent from "../components/footer.vue";
import { useLocale } from "vuetify";
import { defineComponent } from "vue";
import { mapGetters } from 'vuex'
import { urlAPI } from "../store/config";

export default defineComponent({
  name: "RealEstateSingleGallary",
  components: {
    headerTop,
    GetInTouch,
    Preloader,
    cookiesModal,
    getConsultation,
    footerComponent,
  },
  setup() {
    const { t } = useLocale();
    return {
      t,
    };
  },
  metaInfo() {
    if (this.getProduct.ID) {
      if (!this.getProduct[`meta_title_${this.$vuetify.locale.current}`] || !this.getProduct[`meta_description_${this.$vuetify.locale.current}`]) return {}
      return {
        htmlAttrs: {
          lang: this.$vuetify.locale.current,
        },
        title: this.getProduct[`meta_title_${this.$vuetify.locale.current}`],
        description: this.getProduct[`meta_description_${this.$vuetify.locale.current}`],
        meta: [
          { vmid: 'description', name: 'description', content: this.getProduct[`meta_description_${this.$vuetify.locale.current}`] }
        ]
      }
    } else {
      return {}
    }
  },
  data: () => ({
    urlAPI: urlAPI + '/',
    isLoading: true,
  }),
  computed: {
    ...mapGetters([
      'getProduct',
    ]),
  },
  async mounted() {
    await this.$store.dispatch('LoadProduct', { type: "link_name", value: this.$route.params.propertyID });

    let vue = this;
    if (document.readyState == "complete") {
      vue.isLoading = false;
    } else {
      window.addEventListener("load", () => {
        vue.isLoading = false;
      });
    }
  },
  methods: {
    GoToProduct() {
      this.$router.push({
        name: 'property',
        params: {
          lang: this.$route.params.lang,
          propertyID: this.$route.params.propertyID,
        }
      });
    }
  }
});
</script>
<style scoped>
.btn-paralax {
  color: rgba(246, 242, 230, 1);
  text-transform: inherit;
  font-style: italic;
  font-size: 1.4rem;
  letter-spacing: -0.03rem;
  transition: 0.7s;
}

.btn-paralax:hover {
  color: #111213;
  background: #f6f2e6;
}

.btn-go-back {
  color: #FFFFFF;
  font-size: 17px;
  text-decoration: underline;
  text-transform: inherit;
  font-weight: 400;
  font-style: italic;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
}

.img_main {
  padding: 2px;

}

.other-images {
  padding: 2px;
}

.main-page {
  background: linear-gradient(#6C7379 -50%, #111213 58%);
  padding-top: 20vh;
}

.mb-150 {
  padding-bottom: 150px;
}

.btn-gallary {
  background: #000000;
  text-transform: inherit;
  color: #F6F2E6;
  border-radius: 41px;
}

@media screen and (max-width: 768px) {
  .mb-150 {
    padding-bottom: 50px;
  }

  .btn-paralax {
    zoom: 0.6;
  }
}

@media screen and (min-width: 600px) and (max-width: 960px) {
  .btn-paralax {
    zoom: 0.9;
  }
}
</style>