<template>
  <headerTop />
  <cookiesModal />
  <getConsultation />
  <v-main>
    <AboutParallax />
    <TextAnimation :text="t('$vuetify.about_page.Text_animation_1')" />
    <LearnIhubOne />
    <AllActionsOne class="mt-15" />
    <TextAnimation :text="t('$vuetify.about_page.Text_animation_2')" />
    <LearnIhubTwo id="ihub" />
    <AllActionsTwo class="mt-15" />
    <TextAnimation :text="t('$vuetify.about_page.Text_animation_3')" />
    <LearnIhubThree />
    <AllActionsThree class="my-15" />
    <OurServices />
    <FAQs class="my-15" />
    <realEstateWorld class="mt-10" />
  </v-main>
  <footerComponent />
  <Preloader :is-loading="isLoading" />
</template>

<script>
import headerTop from "../components/header.vue";
import cookiesModal from "../components/cookiesModal.vue";
import Preloader from "../components/Preloader.vue";
import AboutParallax from "../components/AboutPage/1.AboutParallax.vue";
import TextAnimation from "../components/AboutPage/2.TextAnimation.vue";
import LearnIhubOne from "../components/AboutPage/3.LearnIhubOne.vue";
import LearnIhubTwo from "../components/AboutPage/4.LearnIhubTwo.vue";
import LearnIhubThree from "../components/AboutPage/5.LearnIhubThree.vue";
import AllActionsOne from "../components/AboutPage/6.AllActionsOne.vue";
import AllActionsTwo from "../components/AboutPage/7.AllActionsTwo.vue";
import AllActionsThree from "../components/AboutPage/8.AllActionsThree.vue";
import OurServices from "../components/AboutPage/9.OurServices.vue";
import FAQs from "../components/AboutPage/10.FAQs.vue";
import realEstateWorld from "../components/realEstateWorld.vue";
import getConsultation from "../components/getConsultation.vue";
import footerComponent from "../components/footer.vue";
import { useLocale } from "vuetify";
import { defineComponent } from "vue";

export default defineComponent({
  name: "AboutPage",
  components: {
    headerTop,
    Preloader,
    cookiesModal,
    getConsultation,
    TextAnimation,
    AboutParallax,
    AllActionsOne,
    AllActionsTwo,
    AllActionsThree,
    LearnIhubTwo,
    LearnIhubOne,
    LearnIhubThree,
    realEstateWorld,
    OurServices,
    FAQs,
    footerComponent,
  },
  setup() {
    const { t } = useLocale();
    return {
      t,
    };
  },
  data: () => ({
    isLoading: true,
  }),
  mounted() {
    let vue = this;
    if (document.readyState == "complete") {
      vue.isLoading = false;
    } else {
      window.addEventListener("load", () => {
        vue.isLoading = false;
      });
    }
  },
});
</script>
<style scoped>
.mt-200 {
  margin-top: 200px;
}

.mt-100 {
  margin-top: 100px;
}

@media screen and (max-width: 768px) {
  .mt-200 {
    margin-top: 50px;
  }
}
</style>