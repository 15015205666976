<template>
    <v-container class="mt-10" fluid v-if="getPriority != 3">
        <v-row class="" justify="center">
            <v-col cols="12">
                <h1>Лиды сайта</h1>
            </v-col>
        </v-row>
        <v-row justify="space-between" class="">
            <v-col cols="auto">
                <v-btn color="#0255B7" rounded="lg" class="btn-text" @click="ExportToPDF()">
                    Экспорт в PDF
                </v-btn>
            </v-col>
            <v-col cols="auto" class="col-header-gap">

                <v-btn :color="is_archived ? '#2B9336' : '#6C757D'"
                    :prepend-icon="is_archived ? 'mdi-fire' : 'mdi-archive'"
                    :variant="is_archived ? 'flat' : 'outlined'" rounded="lg"
                    @click="is_archived = !is_archived; page_view = 1" class="btn-text">
                    {{ is_archived ? 'Назад к актуальным лидам' : 'Открыть архив' }}
                </v-btn>

                <v-btn color="#6C757D" prepend-icon="mdi-account-plus" @click="dialogAddLead.state = true"
                    variant="outlined" rounded="lg" class="btn-text">
                    Добавить лид
                </v-btn>

                <v-autocomplete v-model:search="search_value" :items="AllLeadsOrigin" hide-no-data clearable="true"
                    item-title="name" hide-details density="compact" rounded="lg" label="Поиск" variant="outlined"
                    class="input-search" color="#6C757D">
                    <template v-slot:item="{ props, item }">
                        <v-list-item class="search_autocomplete_item" v-bind="props" size="x-large"
                            @click="LeadEditOpenDialog(item.raw)">
                        </v-list-item>
                    </template>
                </v-autocomplete>
            </v-col>
        </v-row>
        <v-row class="my-4 mx-0" justify="center">
            <v-col cols="12" :sm="leadSelected.ID ? '7' : '12'" :lg="leadSelected.ID ? '9' : '12'">
                <v-table id="table_leads" :height="AllLeadsOrigin.length < limit_view ? '65vh' : 'auto'" fixed-header
                    class="table">
                    <thead>
                        <tr>
                            <th class="text-left">
                                Номер телефона
                            </th>
                            <th class="text-left">
                                Имя и Фамилия
                            </th>
                            <th class="text-left">
                                Интерес (страница)
                            </th>
                            <th class="text-left">
                                Объект интереса
                            </th>
                            <th class="text-left">
                                Дата и время
                            </th>
                            <th class="text-left">
                                Язык
                            </th>
                            <th class="text-left">
                                Архив
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in AllLeadsOrigin.slice((page_view - 1) * limit_view, page_view * limit_view)"
                            :key="item.ID" @click="SelectLead(item)" class="tr-leads">
                            <td>{{ item.phone }}</td>
                            <td>{{ item.name }}</td>
                            <td style="max-width: 200px; word-wrap: break-word; font-size: 0.8rem;"
                                @click="windowVue.open(GetFullURL(item.url), '_blank')">
                                {{ GetFullURL(item.url) }}
                            </td>
                            <td>{{ GetInterestingObject(item) }}</td>
                            <td>{{ item.origin_date }}</td>
                            <td>{{ item.language }}</td>
                            <td style="text-wrap: nowrap;">
                                <v-icon @click.stop="LeadEditOpenDialog(item)">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M17.37 6.18658C17.5433 6.35987 17.5433 6.64084 17.37 6.81413L16.4445 7.73968L14.6695 5.9647L15.595 5.03915C15.7683 4.86586 16.0493 4.86586 16.2226 5.03915L17.37 6.18658Z"
                                            fill="#408ACB" />
                                        <path
                                            d="M15.8169 8.36723L14.042 6.59225L7.99548 12.6387C7.94677 12.6874 7.91007 12.7468 7.88828 12.8122L7.17424 14.9543C7.11642 15.1277 7.28144 15.2928 7.45489 15.2349L9.59701 14.5209C9.66236 14.4991 9.72175 14.4624 9.77046 14.4137L15.8169 8.36723Z"
                                            fill="#408ACB" />
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M4.5 16.4466C4.5 17.1818 5.09601 17.7778 5.83124 17.7778H15.5936C16.3289 17.7778 16.9249 17.1818 16.9249 16.4466V11.1216C16.9249 10.8765 16.7262 10.6779 16.4811 10.6779C16.2361 10.6779 16.0374 10.8765 16.0374 11.1216V16.4466C16.0374 16.6916 15.8387 16.8903 15.5936 16.8903H5.83124C5.58616 16.8903 5.38749 16.6916 5.38749 16.4466V6.68416C5.38749 6.43909 5.58616 6.24042 5.83124 6.24042H11.5999C11.845 6.24042 12.0437 6.04174 12.0437 5.79667C12.0437 5.5516 11.845 5.35293 11.5999 5.35293H5.83124C5.09601 5.35293 4.5 5.94894 4.5 6.68416V16.4466Z"
                                            fill="#408ACB" />
                                    </svg>
                                </v-icon>
                                <v-icon @click.stop="LeadArchive(item)" class="mx-1">
                                    <svg width="22" height="21" viewBox="0 0 22 21" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M14.7723 16.2842C15.8579 16.2842 16.6875 15.3459 16.6875 14.2529V8.15918H5.3125V14.2529C5.3125 15.3459 6.14213 16.2842 7.22768 16.2842H14.7723ZM8.96876 9.78419H13.0313C13.2556 9.78419 13.4375 9.96607 13.4375 10.1904C13.4375 10.4148 13.2556 10.5967 13.0313 10.5967H8.96876C8.74439 10.5967 8.56251 10.4148 8.56251 10.1904C8.56251 9.96607 8.74439 9.78419 8.96876 9.78419Z"
                                            :fill="item.is_archived ? '#D19131' : '#D4D4D4'" />
                                        <path
                                            d="M5.15 4.90918C4.79102 4.90918 4.5 5.2002 4.5 5.55918V6.53418C4.5 6.89317 4.79102 7.18418 5.15 7.18418H16.85C17.209 7.18418 17.5 6.89317 17.5 6.53418V5.55918C17.5 5.2002 17.209 4.90918 16.85 4.90918H5.15Z"
                                            :fill="item.is_archived ? '#D19131' : '#D4D4D4'" />
                                    </svg>

                                </v-icon>
                                <v-icon @click.stop="CategorieOpenDialogDelete(item)">
                                    <svg width="22" height="24" viewBox="0 0 22 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M5.5 5.85463C4.94772 5.85463 4.5 6.27793 4.5 6.80009V7.74554C4.5 8.2677 4.94772 8.691 5.5 8.691H6V17.2001C6 18.2444 6.89543 19.091 8 19.091H14C15.1046 19.091 16 18.2444 16 17.2001V8.691H16.5C17.0523 8.691 17.5 8.2677 17.5 7.74554V6.80009C17.5 6.27793 17.0523 5.85463 16.5 5.85463H13C13 5.33247 12.5523 4.90918 12 4.90918H10C9.44772 4.90918 9 5.33247 9 5.85463H5.5ZM8.5 9.63645C8.77614 9.63645 9 9.8481 9 10.1092V16.7274C9 16.9884 8.77614 17.2001 8.5 17.2001C8.22386 17.2001 8 16.9884 8 16.7274L8 10.1092C8 9.8481 8.22386 9.63645 8.5 9.63645ZM11 9.63645C11.2761 9.63645 11.5 9.8481 11.5 10.1092V16.7274C11.5 16.9884 11.2761 17.2001 11 17.2001C10.7239 17.2001 10.5 16.9884 10.5 16.7274V10.1092C10.5 9.8481 10.7239 9.63645 11 9.63645ZM14 10.1092V16.7274C14 16.9884 13.7761 17.2001 13.5 17.2001C13.2239 17.2001 13 16.9884 13 16.7274V10.1092C13 9.8481 13.2239 9.63645 13.5 9.63645C13.7761 9.63645 14 9.8481 14 10.1092Z"
                                            fill="#E05757" />
                                    </svg>

                                </v-icon>
                            </td>
                        </tr>
                    </tbody>
                </v-table>
            </v-col>
            <v-col cols="12" sm="5" lg="3" v-show="leadSelected.ID">
                <v-card rounded="lg" flat>
                    <v-row justify="end">
                        <v-col cols="auto">
                            <v-btn variant="text" icon="mdi-close" @click="leadSelected = {}">
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-card-title class="text-center">
                        Лид №{{ leadSelected.ID }}
                    </v-card-title>

                    <v-list class="lead-selected">
                        <v-list-item color="primary">
                            <template v-slot:prepend>
                                <v-icon icon="mdi-account" color="#0255B7" class="opacity-1"></v-icon>
                            </template>
                            <v-list-item-title>
                                {{ leadSelected.name }}
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item color="primary">
                            <template v-slot:prepend>
                                <v-icon icon="mdi-phone" color="#0255B7" class="opacity-1"></v-icon>
                            </template>
                            <v-list-item-title>
                                {{ leadSelected.phone }}
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item color="primary">
                            <template v-slot:prepend>
                                <v-icon icon="mdi-binoculars" color="#0255B7" class="opacity-1"></v-icon>
                            </template>
                            <v-list-item-title>
                                Интерес (страница):<br />
                                <span
                                    style="text-wrap: pretty; font-size: 0.6rem; color: #0D73EB; line-height: 0.6rem; cursor: pointer;"
                                    @click="windowVue.open(GetFullURL(leadSelected.url), '_blank')">
                                    {{ GetFullURL(leadSelected.url) }}
                                </span>
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item color="primary">
                            <template v-slot:prepend>
                                <v-icon icon="mdi-sign-real-estate" color="#0255B7" class="opacity-1"></v-icon>
                            </template>
                            <v-list-item-title>
                                {{ GetInterestingObject(leadSelected) }}
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item color="primary">
                            <template v-slot:prepend>
                                <v-icon icon="mdi-tag" color="#0255B7" class="opacity-1"></v-icon>
                            </template>
                            <v-list-item-title>
                                ID лида: {{ leadSelected.ID }}
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item color="primary">
                            <template v-slot:prepend>
                                <v-icon icon="mdi-download" color="#0255B7" class="opacity-1"></v-icon>
                            </template>
                            <v-list-item-title>
                                Дата: {{ leadSelected.origin_date }}
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item color="primary">
                            <template v-slot:prepend>
                                <v-icon icon="mdi-translate" color="#0255B7" class="opacity-1"></v-icon>
                            </template>
                            <v-list-item-title>
                                Язык: {{ leadSelected.language }}
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item color="primary" style="overflow: visible;">
                            <template v-slot:prepend>
                                <v-icon icon="mdi-message-reply-text-outline" color="#0255B7"
                                    class="opacity-1"></v-icon>
                            </template>
                            <v-textarea v-model="leadSelected.comment" label="Комментарий" rounded="lg"
                                variant="outlined" hide-details rows="2"></v-textarea>
                        </v-list-item>
                        <v-list-item style="overflow: visible;" class="pb-0 mb-0">
                            <v-btn color="#0255B7" flat rounded="lg" block text="Сохранить" class="btn-text"
                                @click="EditLeadComment(leadSelected)"></v-btn>
                        </v-list-item>
                        <v-list-item style="overflow: visible;" class="pt-0 mt-0">
                            <v-btn :color="leadSelected.is_archived ? '#2B9336' : '#D19131'" flat rounded="lg" block
                                :text="leadSelected.is_archived ? 'Разорхивировать лид' : 'Архивировать лид'"
                                class="btn-text" @click="LeadArchive(leadSelected); leadSelected = {}"></v-btn>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-col>
        </v-row>
        <v-row justify="space-between" class="w-100" v-if="AllLeadsOrigin.length > limit_view">
            <v-col cols="auto">
                <v-btn color="#00000018" rounded="lg" elevation="0" class="btn-text" append-icon="mdi-reply"
                    @click="MinusPage()">
                    Предыдущая страница
                </v-btn>
            </v-col>
            <v-col cols="auto">
                <p> Страница: {{ page_view }}</p>
            </v-col>
            <v-col cols="auto">
                <v-btn color="#00000018" rounded="lg" elevation="0" class="btn-text" append-icon="mdi-share"
                    @click="PlusPage()">
                    Следующая страница
                </v-btn>
            </v-col>
        </v-row>
        <v-divider class="mt-4 mb-2 divider-bottom"></v-divider>
        <v-row class="my-0">
            <v-col cols="auto">
                <p>Всего лидов: {{ LeadsLength }}</p>
            </v-col>
            <v-col cols="auto">
                <p>Архивированные лиды: {{ archivedLeadsLength }}</p>
            </v-col>
        </v-row>
        <v-snackbar :timeout="2000" location="top right" :color="alert_snackbar.color" variant="flat"
            v-model="alert_snackbar.state">
            <strong>{{ alert_snackbar.text }}</strong>
        </v-snackbar>
        <v-dialog v-model="dialogDeleteLead.state" max-width="700">
            <v-card class="pa-6 py-md-10 text-center" rounded="lg">
                <h2 class="mb-3">Вы уверены что хотите удалить лида ?</h2>
                <v-row justify="center" class="mx-0 my-6">
                    <v-col cols="3">
                        <h4> {{ dialogDeleteLead.lead.phone }}</h4>
                    </v-col>
                    <v-col cols="6" align-self="center">
                        <h4> {{ dialogDeleteLead.lead.name }}</h4>
                    </v-col>
                </v-row>
                <h4>Внимание: </h4>
                <v-card-text class="pt-0">Это действие не подлежат возврату!</v-card-text>
                <v-card-action>
                    <v-row justify="center">
                        <v-col cols="6">
                            <v-btn color="#E05757" class="btn-text" flat rounded="lg" block text="Удалить навсегда"
                                @click="DeleteLeadConfirm(dialogDeleteLead.lead)"></v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn color="#C6C6C6" class="btn-text" flat rounded="lg" block text="Подумать"
                                @click="dialogDeleteLead.state = false"></v-btn>
                        </v-col>
                    </v-row>
                </v-card-action>
                <v-card-text>
                    <i>
                        При нажатии “Удалить навсегда” лид полносью исчезнет из списка.
                    </i>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogAddLead.state" max-width="700">
            <v-card class="pa-6 pb-md-10 text-center" rounded="lg">
                <v-row justify="end">
                    <v-col cols="6" sm="4">
                        <v-btn color="#6C757D" flat variant="text" text="Закрыть" prepend-icon="mdi-close"
                            @click="dialogAddLead.state = false"></v-btn>
                    </v-col>
                </v-row>
                <h2 class="mb-6">Добавить лид</h2>
                <v-form v-model="valid" ref="form">

                    <v-text-field v-model="dialogAddLead.fio" variant="outlined" density="compact" label="Фамилия и Имя"
                        prepend-inner-icon="mdi-account-tie">
                    </v-text-field>
                    <v-text-field :rules="phoneRules" v-model="dialogAddLead.phone" variant="outlined" density="compact"
                        prepend-inner-icon="mdi-phone" label="Номер телефона">
                    </v-text-field>
                    <v-text-field v-model="dialogAddLead.url" variant="outlined" density="compact"
                        prepend-inner-icon="mdi-link-variant" label="Объект интереса (ссылка)">
                    </v-text-field>
                    <v-text-field v-model="dialogAddLead.source_name" variant="outlined" density="compact"
                        prepend-inner-icon="mdi-sign-real-estate" label="Объект интереса (Название)">
                    </v-text-field>
                    <v-select label="Язык общения" v-model="dialogAddLead.language" :items="['En', 'Ro', 'Ru']"
                        variant="outlined" hide-details class="mb-4" rounded="lg" density="compact">
                        <template v-slot:selection="{ item }">
                            <span>{{ item.title }}</span>
                        </template>
                    </v-select>
                    <v-textarea v-model="dialogAddLead.comment" label="Комментарий" rounded="lg"
                        variant="outlined"></v-textarea>
                </v-form>

                <v-card-action class="mt-4">
                    <v-btn color="#0255B7" size="large" flat rounded="lg" block text="Сохранить"
                        :disabled="!dialogAddLead.language || !dialogAddLead.url || !dialogAddLead.phone || !dialogAddLead.fio || !valid"
                        @click="CreateLead(dialogAddLead)"></v-btn>
                </v-card-action>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogEditLead.state" max-width="700">
            <v-card class="pa-6 pb-md-10 text-center" rounded="lg">
                <v-row justify="end">
                    <v-col cols="6" sm="4">
                        <v-btn color="#6C757D" flat variant="text" text="Закрыть" prepend-icon="mdi-close"
                            @click="dialogEditLead.state = false"></v-btn>
                    </v-col>
                </v-row>
                <h2 class="mb-6">Редактировать лид</h2>
                <v-form v-model="valid2" ref="formEditLead">
                    <v-text-field v-model="dialogEditLead.fio" variant="outlined" density="compact"
                        label="Фамилия и Имя" prepend-inner-icon="mdi-account-tie">
                    </v-text-field>
                    <v-text-field :rules="phoneRules" v-model="dialogEditLead.phone" variant="outlined"
                        density="compact" prepend-inner-icon="mdi-phone" label="Номер телефона">
                    </v-text-field>
                    <v-text-field v-model="dialogEditLead.url" variant="outlined" density="compact"
                        prepend-inner-icon="mdi-link-variant" label="Объект интереса (ссылка)">
                    </v-text-field>
                    <v-text-field v-model="dialogEditLead.source_name" variant="outlined" density="compact"
                        prepend-inner-icon="mdi-sign-real-estate" label="Объект интереса (Название)">
                    </v-text-field>
                    <v-select label="Язык общения" v-model="dialogEditLead.language" :items="['En', 'Ro', 'Ru']"
                        variant="outlined" hide-details class="mb-4" rounded="lg" density="compact">
                        <template v-slot:selection="{ item }">
                            <span>{{ item.title }}</span>
                        </template>
                    </v-select>
                    <v-textarea v-model="dialogEditLead.comment" label="Комментарий" rounded="lg"
                        variant="outlined"></v-textarea>
                </v-form>

                <v-card-action class="mt-3">
                    <v-btn color="#C4C4C4" class="mb-3 btn-text" flat rounded="lg" block text="Отменить редактирование"
                        @click="dialogEditLead.state = false"></v-btn>
                    <v-btn color="#0255B7" size="large" flat rounded="lg" block text="Сохранить" class="btn-text"
                        :disabled="!dialogEditLead.language || !dialogEditLead.url || !dialogEditLead.phone || !dialogEditLead.fio || !dialogEditLead.comment || !valid2"
                        @click="EditLead(dialogEditLead)"></v-btn>
                </v-card-action>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import { defineComponent } from "vue";
import { useLocale } from "vuetify";
import { mapGetters } from 'vuex'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
/* import { url } from "./../../store/config.js"; */
import { amiriFont } from "./../../assets/amiriFont.js";


export default defineComponent({
    name: "LeadsPage",
    setup() {
        const { t } = useLocale();
        return {
            t,
        };
    },
    data: () => ({
        page_view: 1,
        limit_view: 10,
        search_value: "",
        is_archived: false,
        alert_snackbar: {
            state: false,
            color: "",
            text: ""
        },
        dialogAddLead: {
            state: false,
            fio: null,
            phone: null,
            url: null,
            language: null,
            comment: null,
        },
        dialogEditLead: {
            state: false,
            fio: null,
            phone: null,
            url: null,
            language: null,
            comment: null,
            source_name: null,
        },
        dialogDeleteLead: {
            state: false,
            lead: {}
        },
        phoneRules: [
            v => !!v || 'Phone is required',
            v => v.length >= 7 || 'Phone > 8 characters',
            (value) => {
                const regex = /^[\\+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,6}$/;
                if (regex.test(value)) {
                    return true;
                }
                return 'Phone number is invalid';
            }
        ],
        leadSelected: {},
        windowVue: null,
    }),
    computed: {
        ...mapGetters([
            'getAllLeadsOrigin',
            'getPriority',
        ]),
        LeadsLength() {
            return this.getAllLeadsOrigin.length
        },
        archivedLeadsLength() {
            return this.getAllLeadsOrigin.filter(l => l.is_archived == true).length
        },
        AllLeadsOrigin() {
            return this.getAllLeadsOrigin.filter(l => l.is_archived == this.is_archived).sort((a, b) => {
                if (a.origin_date < b.origin_date) {
                    return 1;
                }
                if (a.origin_date > b.origin_date) {
                    return -1;
                }
                return 0;
            })
        }
    },
    methods: {
        GetInterestingObject(item) {
            if (item.ID_property) {
                return "Обьект ID: " + item.ID_property
            } else if (item.ID_agent) {
                return "Агент ID: " + item.ID_agent
            } else if (item.source_name) {
                return "Услуга : " + item.source_name
            } else if (item.ID_category) {
                return "Категория ID : " + item.ID_category
            } else {
                return
            }
        },
        GetFullURL(url) {
            if (!url || !window) return
            if (url.includes("https")) {
                return url
            } else {
                return window.location.origin + url
            }
        },
        ExportToPDF() {
            const doc = new jsPDF();
            doc.addFileToVFS("Amiri-Regular.ttf", amiriFont);
            doc.addFont("Amiri-Regular.ttf", "Amiri", "normal");
            doc.setFont("Amiri");

            let leads = this.AllLeadsOrigin.map(l => [l.phone, l.name, this.GetFullURL(l.url), l.property_ru, l.origin_date, l.language])
            autoTable(doc, {
                head: [['Номер телефона', 'Имя и Фамилия', 'Интерес (страница)', 'Объект недвижимости', 'Дата и время', 'Язык']],
                body: leads,
                styles: {
                    font: 'Amiri',
                    fontStyle: 'normal',
                },
                theme: 'grid',
                columnStyles: { 2: { cellWidth: 40, fontSize: 6 }, 3: { cellWidth: 35, fontSize: 8 } },
            })

            doc.save('table_leads.pdf')
        },
        async EditLeadComment(leadSelected) {
            let data_lead_origin = {
                ID_lead: leadSelected.ID_lead,
                comment: leadSelected.comment,
                language: leadSelected.language,
                url: leadSelected.url,
            }
            let responce = await this.$store.dispatch('Lead_origin_Edit', { ID: leadSelected.ID, data: data_lead_origin });
            if (responce.success == true) {
                this.alert_snackbar.state = true;
                this.alert_snackbar.text = "Лид был изменён";
                this.alert_snackbar.color = "success";
            } else {
                this.alert_snackbar.state = true;
                this.alert_snackbar.text = responce.message + " " + Object.values(responce.data);
                this.alert_snackbar.color = "error";
            }
        },
        SelectLead(lead) {
            this.leadSelected = lead;
        },
        LeadEditOpenDialog(lead) {
            this.dialogEditLead.ID = lead.ID;
            this.dialogEditLead.ID_lead = lead.ID_lead;
            this.dialogEditLead.fio = lead.name;
            this.dialogEditLead.phone = lead.phone;
            this.dialogEditLead.url = lead.url;
            this.dialogEditLead.language = lead.language;
            this.dialogEditLead.comment = lead.comment;
            this.dialogEditLead.source_name = lead.source_name;
            this.dialogEditLead.state = true;
        },
        async EditLead(data) {
            let data_lead = {
                name: data.fio,
                phone: data.phone
            }
            let responce = await this.$store.dispatch('Lead_Edit', { ID: data.ID_lead, data: data_lead });

            if (responce.success == true) {
                let data_lead_origin = {
                    ID_lead: data.ID_lead,
                    language: data.language,
                    url: data.url,
                    comment: data.comment,
                    source_name: data.source_name,
                }
                let responce2 = await this.$store.dispatch('Lead_origin_Edit', { ID: data.ID, data: data_lead_origin });
                if (responce2.success == true) {
                    await this.$store.dispatch('LoadAllLeadsOrigin');
                    this.alert_snackbar.state = true;
                    this.alert_snackbar.text = "Лид был изменён";
                    this.alert_snackbar.color = "success";

                    this.dialogEditLead.ID = null;
                    this.dialogEditLead.ID_lead = null;
                    this.dialogEditLead.fio = null;
                    this.dialogEditLead.phone = null;
                    this.dialogEditLead.url = null;
                    this.dialogEditLead.language = null;
                    this.dialogEditLead.comment = null;
                    this.dialogEditLead.state = false;
                } else {
                    this.alert_snackbar.state = true;
                    this.alert_snackbar.text = responce2.message + " " + Object.values(responce2.data);
                    this.alert_snackbar.color = "error";
                }
            } else {
                this.alert_snackbar.state = true;
                this.alert_snackbar.text = responce.message + " " + Object.values(responce.data);
                this.alert_snackbar.color = "error";
            }
        },
        async LeadArchive(lead) {
            let responce = await this.$store.dispatch('Leads_origin_toggle_archive', lead.ID_lead);
            if (responce.success == true) {
                await this.$store.dispatch('LoadAllLeadsOrigin');
                this.alert_snackbar.state = true;
                if (lead.is_archived) {
                    this.alert_snackbar.text = "Лид был разорхивирован";
                    this.alert_snackbar.color = "success";
                } else {
                    this.alert_snackbar.text = "Лид был архивирован";
                    this.alert_snackbar.color = "warning";
                }
            }
        },
        CategorieOpenDialogDelete(lead) {
            this.dialogDeleteLead.state = true;
            this.dialogDeleteLead.lead = lead;
        },
        async DeleteLeadConfirm(lead) {
            this.dialogDeleteLead.state = false;
            this.dialogDeleteLead.lead = {};
            let responce = await this.$store.dispatch('Lead_delete_by_id', lead.ID_lead);
            if (responce.success == true) {
                await this.$store.dispatch('LoadAllLeadsOrigin');
                this.alert_snackbar.state = true;
                this.alert_snackbar.text = "Лид был удалён с сайта";
                this.alert_snackbar.color = "success";
            } else {
                this.alert_snackbar.state = true;
                this.alert_snackbar.text = responce.message + " " + Object.values(responce.data);
                this.alert_snackbar.color = "error";
            }
        },
        async CreateLead(data) {
            let data_lead = {
                name: data.fio,
                phone: data.phone
            }
            this.dialogAddLead = {
                state: false,
                fio: null,
                phone: null,
                url: null,
                language: null,
                comment: null,
            };
            let create_lead = await this.$store.dispatch('create_lead', data_lead);
            let data_lead_origin = {
                ID_lead: create_lead.ID,
                language: data.language,
                url: data.url,
                comment: data.comment,
                source_name: data.source_name,
            }
            let responce = await this.$store.dispatch('create_lead_origin', data_lead_origin);

            if (responce.ID || responce.success) {
                await this.$store.dispatch('LoadAllLeadsOrigin');
                this.alert_snackbar.state = true;
                this.alert_snackbar.text = "Лид был успешно создан";
                this.alert_snackbar.color = "success";
            } else {
                this.alert_snackbar.state = true;
                this.alert_snackbar.text = responce.message + " " + Object.values(responce.data);
                this.alert_snackbar.color = "error";
            }
        },
        MinusPage() {
            if (this.page_view > 1) {
                this.page_view -= 1;
            }
        },
        PlusPage() {
            if (this.page_view * this.limit_view < this.AllLeadsOrigin.length) {
                this.page_view += 1;
            }
        },
    },
    async mounted() {
        await this.$store.dispatch('LoadAllLeadsOrigin');
        this.windowVue = window;
    }
})
</script>
<style scoped>
.tr-leads:hover {
    cursor: pointer;
    background-color: #fafafa;
}

.opacity-1 {
    opacity: 1;
}

.divider-bottom {
    border: 1px solid rgba(0, 0, 0, 0.2);
    opacity: 1;
}

.btn-text {
    text-transform: inherit;
}

.table {
    width: 100%;
    border-radius: 10px;
}

.input-search {
    min-width: 300px;
}

.col-header-gap {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 10px;
}

.w-100 {
    width: 100%;
}

@media screen and (max-width: 1400px) {
    .input-search {
        min-width: 140px;
    }
}
</style>
<style>
.lead-selected .v-list-item__content {
    overflow: visible;
}

.input-search .v-field__input {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    min-height: 36px;
}
</style>