<template>
  <v-container class="container-main mt-15" :fluid="$vuetify.display.mdAndDown ? true : false">
    <v-row justify="space-between">
      <v-col cols="12" :md="Product.carousel.length > 0 ? '6' : '12'" class="pa-0" style="align-self: stretch;"
        v-if="Product.main_image_path">
        <v-img :src="urlAPI + Product.main_image_path" cover height="auto" @click="GoToGallary()" class="img-gallary"
          :aspect-ratio="Product.carousel.length > 0 ? '1.45' : '2'" width="100%" style="display: flex;"
          :title="Product.main_image_title" :alt="Product.main_image_alt">
          <v-row justify="end" class="ma-0" style="height: 100%;">
            <v-col align-self="end" cols="auto">
              <v-btn class="btn-gallary" @click="GoToGallary()">
                <svg width="16" height="14" class="mr-1" viewBox="0 0 16 14" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M14.7692 0H3.69231C3.36589 0 3.05284 0.13409 2.82202 0.372773C2.59121 0.611456 2.46154 0.935179 2.46154 1.27273V2.54545H1.23077C0.904349 2.54545 0.591298 2.67955 0.360484 2.91823C0.12967 3.15691 0 3.48063 0 3.81818V12.7273C0 13.0648 0.12967 13.3885 0.360484 13.6272C0.591298 13.8659 0.904349 14 1.23077 14H12.3077C12.6341 14 12.9472 13.8659 13.178 13.6272C13.4088 13.3885 13.5385 13.0648 13.5385 12.7273V11.4545H14.7692C15.0957 11.4545 15.4087 11.3205 15.6395 11.0818C15.8703 10.8431 16 10.5194 16 10.1818V1.27273C16 0.935179 15.8703 0.611456 15.6395 0.372773C15.4087 0.13409 15.0957 0 14.7692 0ZM11.3846 2.54545C11.5672 2.54545 11.7457 2.60144 11.8974 2.70632C12.0492 2.81121 12.1676 2.96029 12.2374 3.13471C12.3073 3.30913 12.3256 3.50106 12.29 3.68622C12.2543 3.87139 12.1664 4.04147 12.0373 4.17497C11.9082 4.30846 11.7438 4.39937 11.5647 4.4362C11.3856 4.47304 11.2 4.45413 11.0314 4.38189C10.8627 4.30964 10.7185 4.18729 10.6171 4.03032C10.5157 3.87334 10.4615 3.68879 10.4615 3.5C10.4615 3.24684 10.5588 3.00405 10.7319 2.82503C10.905 2.64602 11.1398 2.54545 11.3846 2.54545ZM12.3077 12.7273H1.23077V3.81818H2.46154V10.1818C2.46154 10.5194 2.59121 10.8431 2.82202 11.0818C3.05284 11.3205 3.36589 11.4545 3.69231 11.4545H12.3077V12.7273ZM14.7692 10.1818H3.69231V7.31818L6.46154 4.45455L10.2815 8.40477C10.3969 8.52402 10.5534 8.59101 10.7165 8.59101C10.8797 8.59101 11.0361 8.52402 11.1515 8.40477L13.1254 6.36364L14.7692 8.06432V10.1818Z"
                    fill="#F6F2E6" />
                </svg>
                {{ t("$vuetify.real_estate_single.Product_images.btn_open_gallary") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-img>
      </v-col>
      <v-col v-if="Product.carousel" cols="12" md="6" class="pa-0">
        <v-row v-if="Product.carousel.length > 0" justify="center" class="ma-0">
          <v-col cols="6" v-for="(img, index) in Product.carousel.slice(0, 4)" :key="index" class="pa-0"
            align-self="center">
            <v-img :src="urlAPI + img.path" @click="GoToGallary()" class="img-gallary" cover height="auto"
              :aspect-ratio="1.45" width="100%"></v-img>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useLocale } from "vuetify";
import { urlAPI } from "../../store/config";

export default {
  name: "ProductImages",
  props: {
    Product: Object,
  },
  setup() {
    const { t } = useLocale();
    return {
      t,
    };
  },
  data: () => ({
    urlAPI: urlAPI + '/',
  }),
  methods: {
    GoToGallary() {
      this.$router.push(
        {
          name: 'property_gallery',
          params: {
            lang: this.$vuetify.locale.current,
            propertyID: this.Product[`link_${this.$vuetify.locale.current}`]
          }
        }
      )
    },
  }
};
</script>

<style scoped>
.img-gallary {
  transition: .3s;
}

.img-gallary:hover {
  cursor: pointer;
  filter: brightness(1.10);
}

.btn-gallary {
  background: #000000;
  text-transform: inherit;
  color: #F6F2E6;
  border-radius: 41px;
}

@media screen and (max-width: 600px) {}

@media screen and (min-width: 600px) and (max-width: 960px) {}
</style>