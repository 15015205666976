<template>
    <v-container fluid class="pt-10 fill-height" v-if="getPriority < 4">
        <v-row class="w-100" justify="center">
            <v-col cols="12">
                <h1>База недвижимости</h1>
            </v-col>
        </v-row>
        <v-row justify="space-between" class="w-100">
            <v-col cols="auto">
                <v-btn color="#0255B7" rounded="lg" class="btn-text" @click="RealEstateCreate">
                    Добавить Объект
                </v-btn>
            </v-col>
            <v-col cols="auto" class="col-header-gap">
                <v-btn :color="is_archived ? '#D19131' : '#6C757D'"
                    :prepend-icon="is_archived ? 'mdi-fire' : 'mdi-archive'"
                    :variant="is_archived ? 'flat' : 'outlined'" rounded="lg"
                    @click="is_archived = !is_archived; page_view = 1" class="btn-text">
                    {{ is_archived ? 'Закрыть архив' : 'Открыть архив' }}
                </v-btn>
                <v-select label="Все категории" v-model="categories_selected" :items="getAllCategories"
                    item-title="name_ru" item-value="ID" multiple variant="outlined" density="compact" hide-details
                    class="select-search" rounded="lg">
                    <template v-slot:selection="{ item, index }">
                        <v-chip v-if="index < 2" style="font-size: 11px;">
                            <span style="max-width: 50px;">{{ item.title }}</span>
                        </v-chip>
                        <span v-if="index === 2" class="text-grey text-caption align-self-center">
                            (+{{ categories_selected.length - 2 }} others)
                        </span>
                    </template>
                </v-select>
                <v-autocomplete v-model:search="search_value" :items="AllAllProducts" hide-no-data clearable="true"
                    item-title="property_ru" hide-details density="compact" rounded="lg" label="Поиск"
                    variant="outlined" class="input-search" color="#6C757D">
                    <template v-slot:item="{ props, item }">
                        <v-list-item class="search_autocomplete_item" v-bind="props" size="x-large"
                            @click="RealEstateEdit(item.raw.ID)">
                            <template v-slot:prepend>
                                <v-avatar color="grey" rounded="0" :size="$vuetify.display.smAndUp ? '55' : '35'">
                                    <v-img :src="urlAPI + item.raw.main_image_path" cover></v-img>
                                </v-avatar>
                            </template>
                        </v-list-item>
                    </template>
                </v-autocomplete>
            </v-col>
        </v-row>
        <v-row class="my-4 mx-0 w-100" justify="center">
            <v-table :height="AllAllProducts.length < limit_view ? '65vh' : 'auto'" fixed-header class="table" hover>
                <thead>
                    <tr>
                        <th class="text-left">
                            Фото
                        </th>
                        <th class="text-left">
                            ID:
                        </th>
                        <th class="text-left">
                            Ру Название
                        </th>
                        <th class="text-left">
                            Предложение
                        </th>
                        <th class="text-left">
                            Район
                        </th>
                        <th class="text-left">
                            Агент
                        </th>
                        <th class="text-left">
                            Категория
                        </th>
                        <th class="text-left">
                            Стоимость
                        </th>
                        <th class="text-left">
                            Действия
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in AllAllProducts.slice((page_view - 1) * limit_view, page_view * limit_view)"
                        :key="item.ID">
                        <td>
                            <v-img width="80px" :src="urlAPI + item.main_image_path">
                            </v-img>
                        </td>
                        <td>{{ item.ID }}</td>
                        <td style="max-width: 200px;">{{ item.property_ru }}</td>

                        <td v-if="get_building_purpose_type.length">
                            {{
                                get_building_purpose_type.filter(t => t.ID == item.ID_building_purpose_type)[0] ?
                                    get_building_purpose_type.filter(t => t.ID == item.ID_building_purpose_type)[0].name_ru : ""
                            }}
                        </td>
                        <td v-if="get_location.length">
                            {{
                                get_location.filter(t => t.ID == item.ID_location)[0] ? get_location.filter(t => t.ID ==
                                    item.ID_location)[0].name_ru : ""
                            }}
                        </td>
                        <td v-if="getAllPartners.length">
                            {{
                                getAllPartners.filter(t => t.ID == item.ID_agent)[0] ? getAllPartners.filter(t => t.ID ==
                                    item.ID_agent)[0].name_ru : ''
                            }}
                        </td>
                        <td v-if="getAllCategories.length">
                            {{
                                getAllCategories.filter(t => t.ID == item.ID_category)[0] ? getAllCategories.filter(t =>
                                    t.ID ==
                                    item.ID_category)[0].name_ru : ""
                            }}
                        </td>
                        <td>{{ '€ ' + item.price.toLocaleString('ru-RU') }}</td>
                        <td style="text-wrap: nowrap;">
                            <v-col cols="auto" style="flex-direction: column; display: flex;">
                                <v-icon @click="RealEstateEdit(item.ID)">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M17.37 6.18658C17.5433 6.35987 17.5433 6.64084 17.37 6.81413L16.4445 7.73968L14.6695 5.9647L15.595 5.03915C15.7683 4.86586 16.0493 4.86586 16.2226 5.03915L17.37 6.18658Z"
                                            fill="#408ACB" />
                                        <path
                                            d="M15.8169 8.36723L14.042 6.59225L7.99548 12.6387C7.94677 12.6874 7.91007 12.7468 7.88828 12.8122L7.17424 14.9543C7.11642 15.1277 7.28144 15.2928 7.45489 15.2349L9.59701 14.5209C9.66236 14.4991 9.72175 14.4624 9.77046 14.4137L15.8169 8.36723Z"
                                            fill="#408ACB" />
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M4.5 16.4466C4.5 17.1818 5.09601 17.7778 5.83124 17.7778H15.5936C16.3289 17.7778 16.9249 17.1818 16.9249 16.4466V11.1216C16.9249 10.8765 16.7262 10.6779 16.4811 10.6779C16.2361 10.6779 16.0374 10.8765 16.0374 11.1216V16.4466C16.0374 16.6916 15.8387 16.8903 15.5936 16.8903H5.83124C5.58616 16.8903 5.38749 16.6916 5.38749 16.4466V6.68416C5.38749 6.43909 5.58616 6.24042 5.83124 6.24042H11.5999C11.845 6.24042 12.0437 6.04174 12.0437 5.79667C12.0437 5.5516 11.845 5.35293 11.5999 5.35293H5.83124C5.09601 5.35293 4.5 5.94894 4.5 6.68416V16.4466Z"
                                            fill="#408ACB" />
                                    </svg>
                                </v-icon>
                                <v-icon @click="RealEstateArchive(item)">
                                    <svg width="22" height="21" viewBox="0 0 22 21" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M14.7723 16.2842C15.8579 16.2842 16.6875 15.3459 16.6875 14.2529V8.15918H5.3125V14.2529C5.3125 15.3459 6.14213 16.2842 7.22768 16.2842H14.7723ZM8.96876 9.78419H13.0313C13.2556 9.78419 13.4375 9.96607 13.4375 10.1904C13.4375 10.4148 13.2556 10.5967 13.0313 10.5967H8.96876C8.74439 10.5967 8.56251 10.4148 8.56251 10.1904C8.56251 9.96607 8.74439 9.78419 8.96876 9.78419Z"
                                            :fill="item.is_archived ? '#D19131' : '#D4D4D4'" />
                                        <path
                                            d="M5.15 4.90918C4.79102 4.90918 4.5 5.2002 4.5 5.55918V6.53418C4.5 6.89317 4.79102 7.18418 5.15 7.18418H16.85C17.209 7.18418 17.5 6.89317 17.5 6.53418V5.55918C17.5 5.2002 17.209 4.90918 16.85 4.90918H5.15Z"
                                            :fill="item.is_archived ? '#D19131' : '#D4D4D4'" />
                                    </svg>

                                </v-icon>
                                <v-icon @click="RealEstateOpenDialogDelete(item)">
                                    <svg width="22" height="24" viewBox="0 0 22 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M5.5 5.85463C4.94772 5.85463 4.5 6.27793 4.5 6.80009V7.74554C4.5 8.2677 4.94772 8.691 5.5 8.691H6V17.2001C6 18.2444 6.89543 19.091 8 19.091H14C15.1046 19.091 16 18.2444 16 17.2001V8.691H16.5C17.0523 8.691 17.5 8.2677 17.5 7.74554V6.80009C17.5 6.27793 17.0523 5.85463 16.5 5.85463H13C13 5.33247 12.5523 4.90918 12 4.90918H10C9.44772 4.90918 9 5.33247 9 5.85463H5.5ZM8.5 9.63645C8.77614 9.63645 9 9.8481 9 10.1092V16.7274C9 16.9884 8.77614 17.2001 8.5 17.2001C8.22386 17.2001 8 16.9884 8 16.7274L8 10.1092C8 9.8481 8.22386 9.63645 8.5 9.63645ZM11 9.63645C11.2761 9.63645 11.5 9.8481 11.5 10.1092V16.7274C11.5 16.9884 11.2761 17.2001 11 17.2001C10.7239 17.2001 10.5 16.9884 10.5 16.7274V10.1092C10.5 9.8481 10.7239 9.63645 11 9.63645ZM14 10.1092V16.7274C14 16.9884 13.7761 17.2001 13.5 17.2001C13.2239 17.2001 13 16.9884 13 16.7274V10.1092C13 9.8481 13.2239 9.63645 13.5 9.63645C13.7761 9.63645 14 9.8481 14 10.1092Z"
                                            fill="#E05757" />
                                    </svg>
                                </v-icon>
                                <v-icon @click="RealEstateTopToggle(item)">
                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M9.61757 4.44119C9.77401 4.13679 10.226 4.13679 10.3824 4.44119L11.9061 7.40608C11.9683 7.52695 12.0883 7.61073 12.2273 7.63012L15.6344 8.10556C15.9842 8.15437 16.1239 8.56718 15.8707 8.80412L13.4053 11.112C13.3048 11.2061 13.2589 11.3416 13.2827 11.4745L13.8647 14.7332C13.9244 15.0678 13.5588 15.3229 13.2459 15.1649L10.1985 13.6264C10.0742 13.5636 9.92579 13.5636 9.80155 13.6264L6.75411 15.1649C6.44124 15.3229 6.07557 15.0678 6.13533 14.7332L6.71733 11.4745C6.74106 11.3416 6.6952 11.2061 6.59468 11.112L4.12925 8.80412C3.87614 8.56718 4.01581 8.15437 4.36561 8.10556L7.77275 7.63012C7.91165 7.61073 8.03173 7.52695 8.09385 7.40608L9.61757 4.44119Z"
                                            :fill="item.pin ? '#408ACB' : '#B7B7B7'" />
                                    </svg>
                                </v-icon>
                            </v-col>
                        </td>
                    </tr>
                </tbody>
            </v-table>
        </v-row>
        <v-row justify="space-between" class="w-100" v-if="AllAllProducts.length > limit_view">
            <v-col cols="auto">
                <v-btn color="#00000018" rounded="lg" elevation="0" class="btn-text" append-icon="mdi-reply"
                    @click="MinusPage()">
                    Предыдущая страница
                </v-btn>
            </v-col>
            <v-col cols="auto">
                <p> Страница: {{ page_view }}</p>
            </v-col>
            <v-col cols="auto">
                <v-btn color="#00000018" rounded="lg" elevation="0" class="btn-text" append-icon="mdi-share"
                    @click="PlusPage()">
                    Следующая страница
                </v-btn>
            </v-col>
        </v-row>
        <v-divider class="mt-4 mb-2 divider-bottom"></v-divider>
        <v-row class="my-0">
            <v-col cols="auto">
                <p>Всего обектов: {{ getAllProducts.length }}</p>
            </v-col>
            <v-col cols="auto">
                <p>Архивированные обьекты: {{ ProductsArchivedLength }}</p>
            </v-col>
            <v-col cols="auto">
                <p>Избранные обьекты: {{ ProductsPinLength }}</p>
            </v-col>
        </v-row>
        <v-dialog v-model="dialogDeleteProduct.state" max-width="700">
            <v-card class="pa-6 py-md-10 text-center" rounded="lg">
                <h2 class="mb-3">Вы уверены что хотите удалить продукт ?</h2>
                <v-row justify="center" class="ma-0">
                    <v-col cols="3">
                        <v-img :src="urlAPI + dialogDeleteProduct.product.main_image_path" rounded="lg"></v-img>
                    </v-col>
                    <v-col cols="9" align-self="center">
                        <h4> {{ dialogDeleteProduct.product.property_ru }}</h4>
                    </v-col>
                </v-row>
                <h4>Внимание: </h4>
                <v-card-text class="pt-0">Это действие не подлежат возврату!</v-card-text>
                <v-card-actions>
                    <v-row justify="center">
                        <v-col cols="6">
                            <v-btn color="#E05757" variant="flat" class="btn-text" flat rounded="lg" block text="Удалить навсегда"
                                @click="DeleteProduct(dialogDeleteProduct.product.ID)"></v-btn>
                        </v-col>
                        <v-col cols="6">
                            <v-btn color="#C6C6C6" variant="flat" class="btn-text" flat rounded="lg" block text="Подумать"
                                @click="dialogDeleteProduct.state = false"></v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
                <v-card-text>
                    <i>
                        При нажатии “Удалить навсегда” продукт полносью исчезнет из списка и сайта.
                    </i>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-snackbar :timeout="2000" location="top right" :color="alert_snackbar.color" variant="flat"
            v-model="alert_snackbar.state">
            <strong>{{ alert_snackbar.text }}</strong>
        </v-snackbar>
    </v-container>
</template>
<script>
import { defineComponent } from "vue";
import { useLocale } from "vuetify";
import { mapGetters } from 'vuex'
import { urlAPI } from "../../store/config";

export default defineComponent({
    name: "RealEstatePage",
    setup() {
        const { t } = useLocale();
        return {
            t,
        };
    },
    data: () => ({
        urlAPI: urlAPI + '/',
        page_view: 1,
        limit_view: 10,
        search_value: "",
        is_archived: false,
        dialogDeleteProduct: {
            state: false,
            product: {}
        },
        alert_snackbar: {
            state: false,
            color: "",
            text: ""
        },
        categories_selected: [],
    }),
    computed: {
        ...mapGetters([
            'getAllCategories',
            'getAllProducts',
            'get_building_purpose_type',
            'get_location',
            'getAllPartners',
            'getPriority',
        ]),
        ProductsPinLength() {
            return this.getAllProducts.filter(l => l.pin == true).length
        },
        ProductsArchivedLength() {
            return this.getAllProducts.filter(l => l.is_archived == true).length
        },
        AllAllProducts() {
            if (this.categories_selected.length > 0) {
                return this.getAllProducts.filter(l => this.categories_selected.includes(l.ID_category) && l.is_archived == this.is_archived);
            } else {
                return this.getAllProducts.filter(l => l.is_archived == this.is_archived)
            }
        }
    },
    methods: {
        RealEstateCreate() {
            this.$router.push({
                name: "create-real-estate-crm",
            })
        },
        RealEstateEdit(id) {
            this.$router.push({
                name: "edit-real-estate-crm",
                params: {
                    id: id
                }
            })
        },
        async RealEstateArchive(item) {
            let responce = await this.$store.dispatch('property_toggle_archive', item.ID);

            if (responce.success == true) {
                await this.$store.dispatch('LoadAllProducts');
                this.alert_snackbar.state = true;
                if (item.is_archived) {
                    this.alert_snackbar.text = "Объект был разорхивирован";
                    this.alert_snackbar.color = "success";
                } else {
                    this.alert_snackbar.text = "Объект был архивирован";
                    this.alert_snackbar.color = "warning";
                }
            }
        },
        RealEstateOpenDialogDelete(product) {
            this.dialogDeleteProduct.state = true;
            this.dialogDeleteProduct.product = product;
        },
        async DeleteProduct(ID) {
            this.dialogDeleteProduct.state = false;
            this.dialogDeleteProduct.product = {};
            let responce = await this.$store.dispatch('property_delete_by_id', ID);

            if (responce.success == true) {
                await this.$store.dispatch('LoadAllProducts');
                this.alert_snackbar.state = true;
                this.alert_snackbar.text = "Объект был удалён с сайта";
                this.alert_snackbar.color = "success";
            }
        },
        async RealEstateTopToggle(item) {
            if (this.ProductsPinLength > 8 && !item.pin) {
                this.alert_snackbar.state = true;
                this.alert_snackbar.text = "Достигнуто максимальное кол-во топ товаров";
                this.alert_snackbar.color = "error";
                return
            }
            let responce = await this.$store.dispatch('property_toggle_pin', item.ID);
            if (responce.success == true) {
                await this.$store.dispatch('LoadAllProducts');
                this.alert_snackbar.state = true;
                if (item.pin) {
                    this.alert_snackbar.text = "Объект был удалён из топ недвижимости на главной странице";
                    this.alert_snackbar.color = "error";
                } else {
                    this.alert_snackbar.text = "Объект был добавлен в топ недвижимость на главную страницу";
                    this.alert_snackbar.color = "success";
                }
            }
        },
        MinusPage() {
            if (this.page_view > 1) {
                this.page_view -= 1;
            }
        },
        PlusPage() {
            if (this.page_view * this.limit_view < this.AllAllProducts.length) {
                this.page_view += 1;
            }
        },
    },
    async mounted() {
        await this.$store.dispatch('LoadAllProducts');
        await this.$store.dispatch('LoadAllCategories');
        await this.$store.dispatch('Load_building_purpose_type');
        await this.$store.dispatch('Load_location');
        await this.$store.dispatch('LoadAllPartners');

    },
})
</script>
<style scoped>
.divider-bottom {
    border: 1px solid rgba(0, 0, 0, 0.2);
    opacity: 1;
}

.btn-text {
    text-transform: inherit;
}

.table {
    width: 100%;
    border-radius: 10px;
}

.input-search {
    min-width: 300px;
}

.select-search {
    min-width: 200px;
    max-width: 400px;
}

.col-header-gap {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 10px;
}

.w-100 {
    min-width: 100%;
}

@media screen and (max-width: 1400px) {
    .input-search {
        min-width: 140px;
    }
}
</style>

<style>
.input-search .v-field__input,
.select-search .v-field__input {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    min-height: 36px;
}
</style>