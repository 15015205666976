<template>
  <headerTop />
  <cookiesModal />
  <getConsultation />
  <v-main>
    <ContactParallax />
    <GetInTouchContact class="mt-200" />
    <HeadLocation class="mt-200" />
    <realEstateWorld class="mt-200" />
  </v-main>
  <footerComponent />
  <Preloader :is-loading="isLoading" />
</template>

<script>
import footerComponent from "../components/footer.vue";
import headerTop from "../components/header.vue";
import ContactParallax from "../components/ContactPage/1.ContactParallax.vue";
import cookiesModal from "../components/cookiesModal.vue";
import Preloader from "../components/Preloader.vue";
import realEstateWorld from "../components/realEstateWorld.vue";
import GetInTouchContact from "../components/ContactPage/2.GetInTouchContact.vue";
import HeadLocation from "../components/ContactPage/3.HeadLocation.vue";
import getConsultation from "../components/getConsultation.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ContactPage",
  components: {
    headerTop,
    cookiesModal,
    Preloader,
    getConsultation,
    realEstateWorld,
    ContactParallax,
    GetInTouchContact,
    HeadLocation,
    footerComponent,
  },
  data: () => ({
    isLoading: true,
  }),
  mounted() {
    let vue = this;
    if (document.readyState == "complete") {
      vue.isLoading = false;
    } else {
      window.addEventListener("load", () => {
        vue.isLoading = false;
      });
    }
  },
});
</script>
<style scoped>
.mt-200 {
  margin-top: 200px;
}

.mt-100 {
  margin-top: 100px;
}

@media screen and (max-width: 768px) {
  .mt-200 {
    margin-top: 80px;
  }
}
</style>