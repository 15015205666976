import { axiosInstance } from "../config";

export default {
  state: {},
  mutations: {},
  actions: {
    async FilterAdd(commit, payload) {
      return await axiosInstance
        .post(`/${payload.type}`, payload.data)
        .then(function (data) {
          return data?.data?.data;
        })
        .catch(function (error) {
          return {
            error: error.response.data.message,
          };
        });
    },
    async FilterDelete(commit, payload) {
      return await axiosInstance
        .delete(`/${payload.type}/${payload.ID}`)
        .then(function (data) {
          return data?.data;
        })
        .catch(function (error) {
          return {
            error: error.response.data.message,
          };
        });
    },
    async FilterEdit(commit, payload) {
      return await axiosInstance
        .put(`/${payload.type}/${payload.ID}`, payload.data)
        .then(function (data) {
          return data?.data?.data;
        })
        .catch(function (error) {
          return {
            error: error.response.data.message,
          };
        });
    },
  },
  getters: {},
};
