import axios from "axios";
import { urlAPI } from "./config";

export default {
  state: {
    current_lead: {},
    current_lead_origin: {},
  },
  mutations: {
    SetCurrentLead(state, payload) {
      state.current_lead = payload;
    },
    SetCurrentLeadOrigin(state, payload) {
      state.current_lead = payload;
    },
  },
  actions: {
    async create_lead({ commit }, payload) {
      return await axios
        .post(urlAPI + "/lead", payload)
        .then(function (data) {
          commit("SetCurrentLead", data?.data?.data);
          return data?.data?.data;
        })
        .catch(function (error) {
          return error;
        });
    },
    async create_lead_origin({ commit }, payload) {
      return await axios
        .post(urlAPI + "/lead_origin", payload)
        .then(function (data) {
          commit("SetCurrentLeadOrigin", data?.data?.data);
          return data?.data?.data;
        })
        .catch(function (error) {
          return error;
        });
    },
  },
  getters: {},
};
