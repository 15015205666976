<template>
  <v-container :fluid="$vuetify.display.mdAndDown ? true : false">
    <breadcrumbs name="real_estate" :Categorie="CategorieCurrent" />
    <v-row justify="center" class="mt-12">
      <v-col cols="12" md="11" lg="10">
        <v-row justify="center">
          <v-card class="card-search-and-filter pa-3" flat width="100%">
            <v-row justify="center" class="ma-0" v-if="get_price_type && Products">
              <v-col cols="12" sm="10">
                <v-combobox v-model="product_search_text" :items="Products" hide-no-data :menu="openAutocomplete"
                  :item-title="[`property_${$vuetify.locale.current}`]" hide-details
                  :label="t('$vuetify.real_estate.Products.filter_input')" variant="underlined" class="search-input"
                  base-color="#9A9A9B" prepend-icon="mdi-magnify" :clearable="true"
                  @click:clear="search_by_text = null">
                  <template v-slot:item="{ props, item }">
                    <v-list-item class="search_autocomplete_item" v-bind="props" size="x-large"
                      @click="GoToProduct(item.raw)">
                      <template v-slot:prepend>
                        <v-avatar color="grey" rounded="0" :size="$vuetify.display.smAndUp ? '75' : '55'">
                          <v-img :src="urlAPI + item.raw.main_image_path" cover></v-img>
                        </v-avatar>
                      </template>
                      <v-list-item-subtitle>
                        {{ getPriceTextForSearch(item.raw.price, item.raw.ID_price_type) }}
                      </v-list-item-subtitle>
                      <template v-slot:append v-if="$vuetify.display.smAndUp">
                        <span>{{ item.raw.location_en }}</span>
                      </template>
                    </v-list-item>
                  </template>
                </v-combobox>
              </v-col>
              <v-col cols="6" sm="2">
                <v-btn class="button-search mt-2" variant="outlined" block
                  @click="BtnSearchProduct(product_search_text)">
                  {{ t("$vuetify.real_estate.Products.btn_new_search") }}
                </v-btn>
              </v-col>
              <v-col cols="6" v-if="$vuetify.display.xs && $route.params.categorie">
                <v-btn class="button-search mt-2" variant="outlined" block @click="OpenFilterModal()">
                  {{ t("$vuetify.real_estate.Products.btn_change_filter") }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="ma-0 px-sm-8">
              <v-col cols="auto" v-if="$vuetify.display.smAndUp && $route.params.categorie">
                <v-btn class="button-search" variant="outlined" @click="OpenFilterModal()">
                  {{ t("$vuetify.real_estate.Products.btn_change_filter") }}
                </v-btn>
              </v-col>
              <v-col cols="10">
                <v-row class="ma-0 pa-0">
                  <div class="filter-choose pa-2" v-if="price[0] != '0' || price[1] != '2000000'">
                    {{ "€ " + price[0].toLocaleString('ru-RU') }} - {{ "€ " + price[1].toLocaleString('ru-RU') }}
                    <v-icon @click="price = ['0', '2000000']">mdi-close</v-icon>
                  </div>

                  <div class="filter-choose pa-2" v-if="area[0] != '0' || area[1] != '1000'">
                    {{ area[0].toLocaleString('ru-RU') + " m²" }} - {{ area[1].toLocaleString('ru-RU') + " m²" }}
                    <v-icon @click="area = ['0', '1000']">mdi-close</v-icon>
                  </div>

                  <div class="filter-choose pa-2" v-for="(location, index) in locations" :key="location">
                    {{ location }}
                    <v-icon @click="locations.splice(index, 1);" class="">mdi-close</v-icon>
                  </div>

                  <div class="filter-choose pa-2" v-for="filter in filters" :key="filter.ID">
                    {{ filter[`name_${$vuetify.locale.current}`] }}
                    <v-icon @click="deleteFilter(filter)" class="">mdi-close</v-icon>
                  </div>
                </v-row>
              </v-col>
            </v-row>
            <v-expand-transition>
              <v-row class="pb-10" v-show="filterModal">
                <v-col cols="12" class="py-1" v-show="!CategorieCurrent || CategorieCurrent.building_type">
                  <v-card flat color="transparent">
                    <v-card-title class="filter-title">
                      {{ t("$vuetify.real_estate.Products.filter.type") }}
                    </v-card-title>
                    <v-card-actions class="px-4 py-0" style="flex-wrap: wrap;">
                      <v-btn variant="outlined" color="#9A9A9B" class="px-4 px-sm-10 px-md-15 mr-2 ml-1"
                        :size="$vuetify.display.smAndUp ? 'large' : 'default'"
                        v-for="building_type in get_building_type" :key="building_type.ID"
                        :class="filters.includes(building_type) ? 'filter-btn-active' : 'filter-btn'"
                        @click="ClickFilter(building_type, 'ID_building_type')">
                        {{ building_type[`name_${$vuetify.locale.current}`] }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
                <v-col cols="12" class="py-1" v-show="!CategorieCurrent || CategorieCurrent.building_purpose_type">
                  <v-card flat color="transparent">
                    <v-card-title class="filter-title">
                      {{ t("$vuetify.real_estate.Products.filter.offer") }}
                    </v-card-title>
                    <v-card-actions class="px-4 py-0" style="flex-wrap: wrap; row-gap: 10px;">
                      <v-btn variant="outlined" color="#9A9A9B" class="px-4 px-sm-10 px-md-15 mr-2 ml-1"
                        :size="$vuetify.display.smAndUp ? 'large' : 'default'"
                        v-for="building_purpose_type in get_building_purpose_type" :key="building_purpose_type.ID"
                        :class="filters.includes(building_purpose_type) ? 'filter-btn-active' : 'filter-btn'"
                        @click="ClickFilter(building_purpose_type, 'ID_building_purpose_type')">
                        {{ building_purpose_type[`name_${$vuetify.locale.current}`] }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
                <v-col cols="12" class="py-1">
                  <v-card flat color="transparent">
                    <v-card-title class="filter-title">
                      {{ t("$vuetify.real_estate.Products.filter.object_price") }}
                    </v-card-title>
                    <v-card-actions class="px-4 pt-8 pb-0" style="flex-wrap: wrap;">
                      <v-row class="ma-0 pa-0">
                        <v-col class="pa-0" cols="12" md="9" lg="8">
                          <v-range-slider v-model="price" color="#F6F2E6" :max="2000000" :min="0" :step="100"
                            thumb-label="always">
                            <template v-slot:prepend>
                              <span class="price-text">€ 0</span>
                            </template>
                            <template v-slot:append>
                              <span class="price-text">€ 2 000 000 +</span>
                            </template>
                          </v-range-slider>
                        </v-col>
                      </v-row>
                    </v-card-actions>
                  </v-card>
                </v-col>
                <v-col cols="12" class="py-1" v-show="!CategorieCurrent || CategorieCurrent.location">
                  <v-card flat color="transparent">
                    <v-card-title class="filter-title">
                      {{ t("$vuetify.real_estate.Products.filter.area") }}
                    </v-card-title>
                    <v-card-actions class="px-4 pt-8 pb-0">
                      <v-row class="ma-0 pa-0">
                        <v-col class="pa-0" cols="10" sm="7" lg="6">
                          <v-range-slider v-model="area" color="#F6F2E6" :max="1000" :min="0" :step="10"
                            thumb-label="always">
                            <template v-slot:prepend>
                              <span class="price-text">0 m²</span>
                            </template>
                            <template v-slot:append>
                              <span class="price-text">1000 m²</span>
                            </template>
                          </v-range-slider>
                        </v-col>
                      </v-row>
                    </v-card-actions>
                  </v-card>
                </v-col>
                <v-col cols="12" class="py-1" v-show="!CategorieCurrent || CategorieCurrent.location">
                  <v-card flat color="transparent">
                    <v-card-title class="filter-title">
                      {{ t("$vuetify.real_estate.Products.filter.location") }}
                    </v-card-title>
                    <v-card-actions class="px-4 py-0">
                      <v-row class="ma-0 pa-0">
                        <v-col class="pa-0" cols="10" sm="5" lg="4">
                          <v-select :placeholder="t('$vuetify.real_estate.Products.filter.location_placeholder')"
                            v-model="locations" variant="outlined" base-color="#9A9A9B" bg-color="transparent"
                            color="#9A9A9B" hide-details item-color="#9A9A9B" class="filter-select" density="compact"
                            multiple :items="get_location" :item-title="'name_' + $vuetify.locale.current">
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-card-actions>
                  </v-card>
                </v-col>
                <v-col cols="12" class="py-1" v-show="!CategorieCurrent || CategorieCurrent.housing_condition_type">
                  <v-card flat color="transparent">
                    <v-card-title class="filter-title">
                      {{ t("$vuetify.real_estate.Products.filter.reparation_type") }}
                    </v-card-title>
                    <v-card-actions class="px-4 py-0" style="flex-wrap: wrap; row-gap: 10px;">
                      <v-btn variant="outlined" color="#9A9A9B" class="px-4 px-sm-10 px-md-15 mr-2 ml-1"
                        :size="$vuetify.display.smAndUp ? 'large' : 'default'"
                        v-for="house_condition_type in get_house_condition_type" :key="house_condition_type.ID"
                        :class="filters.includes(house_condition_type) ? 'filter-btn-active' : 'filter-btn'"
                        @click="ClickFilter(house_condition_type, 'ID_housing_condition_type')">
                        {{ house_condition_type[`name_${$vuetify.locale.current}`] }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
                <!-- <v-col cols="12" class="py-1" v-show="!CategorieCurrent || CategorieCurrent.housing_condition_type">
                  <v-card flat color="transparent">
                    <v-card-title class="filter-title">
                      {{ t("$vuetify.real_estate.Products.filter.reparation_type") }}
                    </v-card-title>
                    <v-card-actions class="px-4 py-0" style="flex-wrap: wrap; row-gap: 10px;">
                      <v-btn variant="outlined" color="#9A9A9B" class="px-4 px-sm-10 px-md-15 mr-2 ml-1"
                        :size="$vuetify.display.smAndUp ? 'large' : 'default'"
                        v-for="housing_condition_type in get_renovation_type" :key="housing_condition_type.ID"
                        :class="filters.includes(housing_condition_type) ? 'filter-btn-active' : 'filter-btn'"
                        @click="ClickFilter(housing_condition_type, 'ID_housing_condition_type')">
                        {{ housing_condition_type[`name_${$vuetify.locale.current}`] }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col> -->

                <v-col cols="12" class="py-1" v-show="!CategorieCurrent || CategorieCurrent.land_type">
                  <v-card flat color="transparent">
                    <v-card-title class="filter-title">
                      {{ t("$vuetify.real_estate.Products.filter.land_type") }}
                    </v-card-title>
                    <v-card-actions class="px-4 py-0" style="flex-wrap: wrap; row-gap: 10px;">
                      <v-btn variant="outlined" color="#9A9A9B" class="px-4 px-sm-10 px-md-15 mr-2 ml-1"
                        :size="$vuetify.display.smAndUp ? 'large' : 'default'" v-for="land_type in get_land_type"
                        :key="land_type.ID" :class="filters.includes(land_type) ? 'filter-btn-active' : 'filter-btn'"
                        @click="ClickFilter(land_type, 'ID_land_type')">
                        {{ land_type[`name_${$vuetify.locale.current}`] }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
                <v-col cols="12" class="py-1" v-show="!CategorieCurrent || CategorieCurrent.industrial_purpose_type">
                  <v-card flat color="transparent">
                    <v-card-title class="filter-title">
                      {{ t("$vuetify.real_estate.Products.filter.industrial_purpose_type") }}
                    </v-card-title>
                    <v-card-actions class="px-4 py-0" style="flex-wrap: wrap; row-gap: 10px;">
                      <v-btn variant="outlined" color="#9A9A9B" class="px-4 px-sm-10 px-md-15 mr-2 ml-1"
                        :size="$vuetify.display.smAndUp ? 'large' : 'default'"
                        v-for="industrial_purpose_type in get_industrial_purpose_type" :key="industrial_purpose_type.ID"
                        :class="filters.includes(industrial_purpose_type) ? 'filter-btn-active' : 'filter-btn'"
                        @click="ClickFilter(industrial_purpose_type, 'ID_industrial_purpose_type')">
                        {{ industrial_purpose_type[`name_${$vuetify.locale.current}`] }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
                <v-col cols="12" class="py-1" v-show="!CategorieCurrent || CategorieCurrent.number_of_rooms">
                  <v-card flat color="transparent">
                    <v-card-title class="filter-title">
                      {{ t("$vuetify.real_estate.Products.filter.rooms") }}
                    </v-card-title>
                    <v-card-actions class="px-4 py-0" style="flex-wrap: wrap; row-gap: 10px;">
                      <v-btn class="px-5 px-sm-8  mr-2 ml-1" v-for="room in number_of_rooms" :key="room.ID"
                        :class="filters.includes(room) ? 'filter-btn-active' : 'filter-btn'"
                        @click="ClickFilter(room, 'number_of_rooms')" variant="outlined"
                        :size="$vuetify.display.smAndUp ? 'large' : 'default'" color="#9A9A9B">
                        {{ room[`name_${$vuetify.locale.current}`] }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
                <v-col cols="12" class="pt-8">
                  <v-row justify="center">
                    <v-col cols="5" sm="4">
                      <v-btn size="large" variant="outlined" block color="#9A9A9B" class="filter-btn px-14"
                        @click="removeFilters()">
                        {{ t("$vuetify.real_estate.Products.filter.remove_filters") }}
                      </v-btn>
                    </v-col>
                    <v-col cols="5" sm="4">
                      <v-btn size="large" color="#F6F2E6" block class="filter-btn-white px-14" @click="SeeProducts()">
                        {{ t("$vuetify.real_estate.Products.filter.show") }}
                        {{ ProductsFilter.length }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-expand-transition>
          </v-card>
        </v-row>
        <v-row :justify="$vuetify.display.smAndUp ? 'space-between' : 'center'" class="mt-12">
          <v-col cols="auto" align-self="center" v-if="$vuetify.display.smAndUp">
            <v-btn v-if="$route.params.categorie" variant="text" class="btn-remove-filters" size="large"
              @click="removeFilters()">
              {{ t("$vuetify.real_estate.Products.btn_remove_filter") }}
            </v-btn>
          </v-col>
          <v-col cols="auto" align-self="center">
            <span class="categorie-title" v-if="CategorieCurrent">
              {{ CategorieCurrent[`name_${$vuetify.locale.current}`] }}
            </span>
          </v-col>
          <v-col cols="auto" align-self="center" v-if="$vuetify.display.smAndUp">
            <v-btn variant="text" class="btn-remove-filters" size="large">
              {{ t("$vuetify.real_estate.Products.btn_we_found.part1") }}
              {{ ProductsFilter.length }}
              {{ t("$vuetify.real_estate.Products.btn_we_found.part2") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row justify="center" class="mt-12">
          <v-col cols="6" md="4" class="pa-md-8" v-for="(product, i) in ProductsFilter.slice(0, this.limit)" :key="i">
            <cardRealEstate :card="product" />
          </v-col>
        </v-row>
        <v-row justify="center" class="mt-6">
          <v-btn v-if="Products.length > limit" variant="text" class="btn-load-more" size="large" @click="AddLimit()">
            {{ t("$vuetify.real_estate.Products.btn_load_more") }}
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useLocale } from "vuetify";
import cardRealEstate from "../cardRealEstate.vue";
import breadcrumbs from "../breadcrumbs.vue";
import { mapGetters } from 'vuex'
import { urlAPI } from "../../store/config";

export default {
  name: "CategoriesParalax",
  props: {
    Categories: Array,
    Products: Array,
  },
  components: {
    cardRealEstate,
    breadcrumbs,
  },
  setup() {
    const { t } = useLocale();
    return {
      t,
    };
  },
  data: () => ({
    urlAPI: urlAPI + '/',
    price: ["0", "2000000"],
    area: ["0", "1000"],
    locations: [],
    filters: [],
    number_of_rooms: [
      {
        ID: 1,
        name_en: 1,
        name_ro: 1,
        name_ru: 1,
      },
      {
        ID: 2,
        name_en: 2,
        name_ro: 2,
        name_ru: 2,
      },
      {
        ID: 3,
        name_en: 3,
        name_ro: 3,
        name_ru: 3,
      },
      {
        ID: 4,
        name_en: '4+',
        name_ro: '4+',
        name_ru: '4+',
      },
    ],
    limit: 15,
    filterModal: false,
    product_search_text: "",
    search_by_text: null,
    openAutocomplete: false,
  }),
  async mounted() {
    await this.$store.dispatch("Load_building_type");
    await this.$store.dispatch("Load_building_purpose_type");
    await this.$store.dispatch("Load_house_condition_type");
    await this.$store.dispatch("Load_renovation_type");
    await this.$store.dispatch("Load_price_type");
    await this.$store.dispatch("Load_land_type");
    await this.$store.dispatch("Load_industrial_purpose_type");
    await this.$store.dispatch("Load_location");
  },
  computed: {
    ProductsFilter() {
      let vue = this;
      let products = this.Products;

      if (this.search_by_text) {
        products = products.filter((product) => {
          return product["property_" + vue.$vuetify.locale.current].toLowerCase().replace(/\s/g, '').includes(this.search_by_text.toLowerCase().replace(/\s/g, ''))
        })
      }

      if (this.locations.length > 0) {
        let locationsIDs = this.locations.map(l => {
          let g = this.get_location.filter(gl => {
            return gl[`name_${vue.$vuetify.locale.current}`] == l
          })
          return g[0] ? g[0].ID : null
        })
        products = products.filter((product) => {
          return locationsIDs.includes(product.ID_location)
        })
      }

      if (this.price[0] != '0' || this.price[1] != '2000000') {
        products = products.filter(p => p.price >= this.price[0] && p.price <= this.price[1])
      }

      if (this.area[0] != '0' || this.area[1] != '1000') {
        products = products.filter(p => p.area >= this.area[0] && p.area <= this.area[1])
      }

      let filterIdsByType = {};
      this.filters.forEach(f => {
        if (!filterIdsByType[f.type]) {
          filterIdsByType[f.type] = [];
        }
        filterIdsByType[f.type].push(f.ID);
      });
      let arrayFilterType = Object.keys(filterIdsByType);

      arrayFilterType.forEach(filter_type => {
        let filters_type = filterIdsByType[filter_type];
        products = products.filter(product => {
          /* if (product[filter_type] == null) {
            return true
          } */
          return filters_type.includes(product[filter_type])
        });
      });
      return products;
    },
    CategorieCurrent() {
      if (this.$route.params.categorie) {
        if (this.Categories.length == null) {
          return {}
        }
        let c = this.Categories.filter((el) => el[`link_${this.$vuetify.locale.current}`] ==
          this.$route.params.categorie);

        if (c[0]) {
          return c[0]
        } else {
          return null
        }
      } else {
        return null
      }
    },
    ...mapGetters([
      'get_house_condition_type',
      'get_renovation_type',
      'get_price_type',
      'get_building_purpose_type',
      'get_building_type',
      'get_industrial_purpose_type',
      'get_land_type',
      'get_location',
      'get_property_developer',
    ])
  },
  methods: {
    BtnSearchProduct(text) {
      if (!text) {
        this.openAutocomplete = true
      } else {
        this.search_by_text = text;
      }
    },
    GoToProduct(item) {
      this.$router.push(
        {
          name: 'property',
          params: {
            lang: this.$route.params.lang,
            propertyID: item['link_' + this.$vuetify.locale.current],
          }
        }
      )
    },
    getPriceTextForSearch(price, ID_price_type) {
      if (this.get_price_type.filter(pt => pt.ID == ID_price_type)[0]) {
        if (ID_price_type == 1) {
          return `€ ${price} ${this.get_price_type.filter(pt => pt.ID ==
            ID_price_type)[0][`name_${this.$vuetify.locale.current}`]}`
        } else if (ID_price_type == 2) {
          return this.get_price_type.filter(pt =>
            pt.ID ==
            ID_price_type)[0][`name_${this.$vuetify.locale.current}`]
        } else {
          return `€ ${price}`
        }
      }
      return ""
    },
    ClickFilter(filter, type) {
      let newF = filter;
      newF.type = type;
      let index = this.filters.indexOf(newF)
      if (index != -1) {
        this.filters.splice(index, 1);
      } else {
        this.filters.push(newF);
      }
    },
    deleteFilter(filter) {
      let index = this.filters.indexOf(filter);
      if (index != -1) {
        this.filters.splice(index, 1);
      }
    },
    removeFilters() {
      this.filters = [];
      this.locations = [];
      this.price = ["0", "2000000"];
      this.area = ["0", "1000"];
      this.filterModal = false;
    },
    AddLimit() {
      this.limit += 15;
    },
    OpenFilterModal() {
      this.filterModal = !this.filterModal;
    },
    SeeProducts() {
      this.filterModal = false;
    }
  },
};
</script>

<style>
.search_autocomplete_item .v-avatar {
  border-radius: 10px !important;
}

.search_autocomplete_item .v-list-item-title {
  text-wrap: wrap;
  word-break: keep-all;
}
</style>

<style scoped>
.price-text {
  color: #F6F2E6;
}

.filter-select {
  border-radius: 41px;
  text-transform: inherit;
  letter-spacing: -0.02rem;
  font-weight: 400;
  color: #9A9A9B;
}

.filter-btn-active {
  background-color: #9A9A9B;
  color: #111213 !important;
  border-radius: 41px;
  font-weight: 400;
  text-transform: inherit;
  letter-spacing: -0.02rem;
}

.filter-btn-active:hover {
  background-color: #bababa;
}

.filter-btn {
  border-radius: 41px;
  text-transform: inherit;
  letter-spacing: -0.02rem;
}

.filter-btn:hover {
  background-color: rgb(35, 35, 35);
}

.filter-btn-white {
  border-radius: 41px;
  text-transform: inherit;
  letter-spacing: -0.02rem;
}

.filter-btn-white:hover {
  background-color: #dadada !important;
}

.filter-title {
  color: #F6F2E6;
  font-weight: 400;
  letter-spacing: -0.02rem;
}

.btn-load-more {
  color: #fff;
  letter-spacing: -0.02rem;
  text-transform: inherit;
  text-decoration: underline;
  font-weight: 400;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
}

.categorie-title {
  color: #F6F2E6;
  letter-spacing: -0.02rem;
  font-size: 1.2rem;
}

.btn-remove-filters {
  color: #9A9A9B;
  letter-spacing: -0.02rem;
  text-transform: inherit;
  text-decoration: underline;
  font-weight: 400;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
}

.filter-choose {
  color: #9A9A9B;
  letter-spacing: -0.02rem;
  text-transform: inherit;
  font-weight: 400;
  height: 35px;
}

.button-search {
  color: #9A9A9B;
  letter-spacing: -0.02rem;
  text-transform: inherit;
  border-radius: 41px;
  font-weight: 400;
}

.card-search-and-filter {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 41px;
}

.breadcrumbs-products {
  font-style: italic;
  font-size: 1.2rem;
  letter-spacing: -0.02rem;
  color: #F6F2E6;
}

.breadcrumbs-products-item {
  margin-left: 30px;
  margin-right: 30px;
}

.breadcrumbs-products-item:hover {
  cursor: pointer;
  color: #f6f2e6b2;
}

@media screen and (max-width: 600px) {
  .breadcrumbs-products {
    font-size: 0.9rem;

  }

  .breadcrumbs-products-item {
    margin-left: 5px;
    margin-right: 5px;
  }
}

@media screen and (min-width: 600px) and (max-width: 960px) {}
</style>

<style>
.card-search-and-filter .filter-select .v-field {
  border-radius: 41px !important;
}

.card-search-and-filter .filter-select .v-select__selection-text {
  padding-left: 20px;
}

.search-input .v-field__clearable {
  color: #fff;
}

.search-input .v-label {
  color: #9A9A9B !important;
}

.search-input .v-input__prepend {
  color: #F6F2E6 !important;
}

.search-input .v-field__input {
  color: #F6F2E6 !important;
}
</style>
