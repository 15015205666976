<template>
  <v-parallax src="../../assets/img/home/bg.jpg" width="100%" :height="height_image_paralax" id="paralax-image-home">
    <v-container class="fill-height container-main" style="align-content: center"
      :fluid="$vuetify.display.mdAndDown ? true : false">
      <v-row justify="space-around" class="row-line">
        <v-col cols="auto">
          <!-- <v-expand-transition> -->
          <v-fade-transition>
            <h1 class="title-big" v-show="textAnim.showTextInvest">
              {{ t("$vuetify.home.parallax.invest") }}
            </h1>
          </v-fade-transition>
        </v-col>
        <v-col cols="1" lg="2" align-self="end" class="px-0 px-md-3" v-if="$vuetify.locale.current != 'ru'">
          <v-fade-transition>
            <h5 class="text-h5 title-small" v-show="textAnim.showTextsub1">
              {{ t("$vuetify.home.parallax.with_the") }}
            </h5>
          </v-fade-transition>
        </v-col>
        <v-col cols="auto">
          <v-fade-transition>
            <h1 class="title-big" v-show="textAnim.showTextBest">
              {{ t("$vuetify.home.parallax.best_choice") }}
            </h1>
          </v-fade-transition>
        </v-col>
      </v-row>

      <v-divider class="divider-paralax my-4 my-sm-10 my-lg-13" color="info"></v-divider>

      <v-row class="row-line">
        <v-col cols="2" md="1" align-self="end" class="pr-0 px-md-3">
          <v-fade-transition>
            <h5 class="text-h5 title-small" v-show="textAnim.showTextsub2">
              {{ t("$vuetify.home.parallax.work_with") }}
            </h5>
          </v-fade-transition>
        </v-col>
        <v-col cols="auto" align-self="end">
          <v-fade-transition>
            <h1 class="title-big" v-show="textAnim.showTextExperts">
              {{ t("$vuetify.home.parallax.experts") }}
            </h1>
          </v-fade-transition>
        </v-col>
        <v-col cols="6" md="3" offset-md="1" align-self="center">
          <v-fade-transition>
            <h6 class="text-h6 title-small2" v-show="textAnim.showTextsub2">
              {{ t("$vuetify.home.parallax.în_domeniul") }}
            </h6>
          </v-fade-transition>
        </v-col>
        <v-col cols="3" v-if="$vuetify.display.mdAndUp && $vuetify.locale.current != 'ru'">
          <v-fade-transition>
            <h1 class="title-big" v-show="textAnim.showTextPassion">
              {{ t("$vuetify.home.parallax.passion") }}
            </h1>
          </v-fade-transition>
        </v-col>
      </v-row>

      <v-divider class="divider-paralax my-4 my-sm-10 my-lg-13" color="info"></v-divider>

      <v-row class="row-line-3">
        <v-col cols="12" md="6" align-self="center" order="3" order-md="1">
          <v-btn variant="outlined" class="btn-paralax rounded-pill my-2"
            :size="$vuetify.display.lgAndUp ? 'x-large' : 'large'" @click="GoRealEstate()">
            {{ t("$vuetify.home.parallax.btn_1") }}
          </v-btn>
          <v-btn variant="outlined" class="btn-paralax rounded-pill mx-4 my-2"
            :size="$vuetify.display.lgAndUp ? 'x-large' : 'large'" @click="GoToIhub()">
            {{ t("$vuetify.home.parallax.btn_2") }}
          </v-btn>
          <v-btn variant="outlined" class="btn-paralax rounded-pill my-2"
            :size="$vuetify.display.lgAndUp ? 'x-large' : 'large'" @click="OpenContactUsModalActive()">
            {{ t("$vuetify.home.parallax.btn_3") }}
          </v-btn>
        </v-col>
        <v-col cols="auto" order="1" order-md="2">
          <v-fade-transition>
            <h1 class="title-big" v-show="textAnim.showTextHonest">
              {{ t("$vuetify.home.parallax.honest") }}
            </h1>
          </v-fade-transition>
        </v-col>
        <v-col cols="auto" md="2" align-self="end" order="2" order-md="3">
          <v-fade-transition>
            <h6 class="text-h6 title-small2" v-show="textAnim.showTextsub3">
              {{ t("$vuetify.home.parallax.as_principe") }}
            </h6>
          </v-fade-transition>
        </v-col>
      </v-row>
    </v-container>
    <ContactUsModal ref="OpenContactUsModal" />
  </v-parallax>
</template>

<script>
import { useLocale } from "vuetify";
import ContactUsModal from "../Modals/ContactUsModal.vue";

export default {
  name: "HomeParalax",
  setup() {
    const { t } = useLocale();
    return {
      t,
    };
  },
  components: {
    ContactUsModal,
  },
  data: () => ({
    textAnim: {
      showTextInvest: false,
      showTextBest: false,
      showTextExperts: false,
      showTextPassion: false,
      showTextHonest: false,
      showTextsub1: false,
      showTextsub2: false,
      showTextsub3: false,
    },
  }),
  mounted() {
    let vue = this;
    if (document.readyState == "complete") {
      setTimeout(() => {
        this.textAnim.showTextInvest = true;
        this.textAnim.showTextBest = true;
      }, 800);
      setTimeout(() => {
        vue.textAnim.showTextExperts = true;
        vue.textAnim.showTextPassion = true;
      }, 1300);
      setTimeout(() => {
        vue.textAnim.showTextHonest = true;
      }, 2000);
      setTimeout(() => {
        this.textAnim.showTextsub1 = true;
        this.textAnim.showTextsub2 = true;
        this.textAnim.showTextsub3 = true;
      }, 2500);
    } else {
      document.addEventListener(
        "PreloaderFinish",
        () => {
          setTimeout(() => {
            this.textAnim.showTextInvest = true;
            this.textAnim.showTextBest = true;
          }, 800);
          setTimeout(() => {
            vue.textAnim.showTextExperts = true;
            vue.textAnim.showTextPassion = true;
          }, 1300);
          setTimeout(() => {
            vue.textAnim.showTextHonest = true;
          }, 2000);
          setTimeout(() => {
            this.textAnim.showTextsub1 = true;
            this.textAnim.showTextsub2 = true;
            this.textAnim.showTextsub3 = true;
          }, 2500);
        },
        false
      );
    }
  },
  computed: {
    height_image_paralax() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return "auto";
        case "lg":
          return "100vh";
        case "xl":
          return "75vh";
        case "xxl":
          return "75vh";
        default:
          return "100vh";
      }
    },
  },
  methods: {
    OpenContactUsModalActive() {
      this.$refs.OpenContactUsModal.dialog = true;
      this.$refs.OpenContactUsModal.property = {};
    },
    GoRealEstate() {
      this.$router.push({
        name: "real-estate",
        params: {
          lang: this.$vuetify.locale.current,
        }
      })
    },
    GoToIhub() {
      this.$router.push({
        name: "about",
        params: {
          lang: this.$vuetify.locale.current,
        },
        hash: '#ihub'
      })
    }
  }
};
</script>

<style scoped>
.row-line-3 {
  min-height: 114px;
}

.row-line {
  height: 114px;
  flex-wrap: nowrap;
}

.btn-paralax {
  color: rgba(246, 242, 230, 1);
  text-transform: inherit;
  font-style: italic;
  font-size: 1.4rem;
  letter-spacing: -0.03rem;
  transition: 0.7s;
}

.btn-paralax:hover {
  color: #111213;
  background: #f6f2e6;
}

.title-small2 {
  text-wrap: balance;
  color: #c4c4c4;
  font-weight: 400;
  letter-spacing: -0.035rem !important;
}

.title-small {
  text-wrap: balance;
  color: #c4c4c4;
  font-weight: 400;
  letter-spacing: -0.04rem !important;
  text-shadow: 8px 13px 35px rgba(0, 0, 0, 0.5);
}

.title-big {
  letter-spacing: -0.24rem !important;
  color: rgba(246, 242, 230, 1);
  text-shadow: rgba(0, 0, 0, 0.87) 0px 4px 30px;
  font-size: 6rem;
  font-weight: 300;
  line-height: 6rem;
  text-wrap: nowrap;
  white-space: nowrap;
}

.divider-paralax {
  border-width: 1px;
  border-color: rgba(154, 154, 155, 0.36);
  opacity: 1;
}

.container-main {
  padding-top: 15vh;
}

@media screen and (max-width: 600px) {

  .title-small,
  .title-small2 {
    font-size: 0.85rem !important;
    line-height: 1.1rem !important;
  }

  .row-line-3 {
    min-height: 114px;
    height: unset !important;
  }

  .row-line {
    min-height: 74px;
    height: unset !important;
  }

  .btn-paralax {
    zoom: 0.6;
  }
}

@media screen and (min-width: 991px) and (max-width: 1300px) {
  .title-big {
    font-size: 3.5rem;
    line-height: 4rem;
    letter-spacing: -0.05rem !important;
  }

  .row-line {
    height: 80px;
  }

  .title-small,
  .title-small2 {
    zoom: 0.8;
  }

  .btn-paralax {
    zoom: 0.8;
  }

  .row-line-3 {
    min-height: 90px;
    height: unset !important;
  }
}

@media screen and (min-width: 650px) and (max-width: 991px) {
  .title-big {
    font-size: 3rem;
    line-height: 3.2rem;
    letter-spacing: -0.035rem !important;
  }

  .row-line {
    height: 75px;
  }


  .title-small,
  .title-small2 {
    zoom: 0.8;
  }

  .btn-paralax {
    zoom: 0.9;
  }
}

@media screen and (min-width: 480px) and (max-width: 650px) {
  .title-big {
    font-size: 2rem;
    line-height: 2.5rem;
    letter-spacing: -0.025rem !important;
  }
}

@media screen and (max-width: 480px) {
  .title-big {
    font-size: 1.5rem;
    line-height: 2rem;
    letter-spacing: -0.02rem !important;
  }
}
</style>
<style>
#paralax-image-home>.v-responsive__content {
  background: linear-gradient(rgba(17, 18, 19, 0.27) 0%,
      rgba(17, 18, 19, 1) 97%);
  bottom: -1px;
  position: relative;
}
</style>