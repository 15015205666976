<template>
  <v-container :fluid="$vuetify.display.mdAndDown ? true : false" style="position: relative">
    <div class="blur-div"></div>
    <v-row justify="center" style="z-index: 2; position: relative">
      <v-col cols="11" sm="8" md="6" lg="6">
        <p class="text-top">
          {{ t("$vuetify.home.OurMission.subtitle") }}
        </p>
        <h2 class="title-text">
          {{ t("$vuetify.home.OurMission.title") }}
        </h2>
        <h5 class="content-text px-sm-12 mt-4 mt-md-12">
          <b>
            {{ t("$vuetify.home.OurMission.text.part_1") }}
          </b>
        </h5>
        <p class="content-text px-sm-12 my-6">
          {{ t("$vuetify.home.OurMission.text.part_2") }}
        </p>
        <h5 class="content-text px-sm-12">
          <b>
            {{ t("$vuetify.home.OurMission.text.part_3") }}
          </b>
        </h5>
        <p class="content-text px-sm-12 my-6">
          {{ t("$vuetify.home.OurMission.text.part_4") }}
        </p>
        <p class="content-text px-sm-12">
          {{ t("$vuetify.home.OurMission.text.part_5") }}
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useLocale } from "vuetify";

export default {
  name: "HomeOurMission",
  setup() {
    const { t } = useLocale();
    return {
      t,
    };
  },
  data: () => ({}),
  mounted() { },
};
</script>

<style scoped>
.blur-div {
  width: 250px;
  height: 250px;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, 0%);
  background: rgba(246, 242, 230, 0.7);
  -webkit-backdrop-filter: blur(200px);
  filter: blur(200px);
  border-radius: 100%;
  will-change: transform;
}

.content-text {
  color: #9a9a9b;
  font-size: 20px;
}

.text-top {
  color: #9a9a9b;
  font-size: 14px;
  text-align: center;
  font-weight: 400;
  letter-spacing: -0.01rem;
}

.title-text {
  text-align: center;
  font-size: 56px;
  font-weight: 400;
  color: #f6f2e6;
}

@media screen and (max-width: 600px) {
  .content-text {
    font-size: 14px;
  }

  .title-text {
    text-align: start;
    font-size: 36px;
  }

  .text-top {
    text-align: start;
  }
}

@media screen and (min-width: 600px) and (max-width: 960px) {}
</style>