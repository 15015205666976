<template>
  <v-container :fluid="$vuetify.display.mdAndDown ? true : false">
    <v-row justify="center" class="mt-10 mt-sm-4">
      <v-col cols="11" md="11" lg="10">
        <v-row>
          <h6 class="block-text">
            {{ t("$vuetify.learn_page.about_the_project.text_before_title") }}
          </h6>
        </v-row>
        <v-row>
          <h2 class="block-title my-2 my-sm-8">
            {{ t("$vuetify.learn_page.about_the_project.text_title") }}
          </h2>
        </v-row>
        <v-row>
          <h4 class="block-subtitle">
            {{ t("$vuetify.learn_page.about_the_project.text_after_title") }}
          </h4>
        </v-row>
        <v-row class="mt-150 mb-15">
          <v-col cols="12" sm="4" lg="3" class="px-sm-0" style="position: relative; z-index: 1;">
            <div class="blur_1"></div>
            <div class="blur_2"></div>
            <h5 class="title-about">
              {{ t("$vuetify.learn_page.about_the_project.block_1.about_project_title") }}
            </h5>
          </v-col>
          <v-col cols="12" sm="7">
            <p class="text-about">
              {{ t("$vuetify.learn_page.about_the_project.block_1.text.part1") }}
            </p>
            <p class="text-about mt-6">
              {{ t("$vuetify.learn_page.about_the_project.block_1.text.part2") }}
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useLocale } from "vuetify";

export default {
  name: "AboutTheProject",
  setup() {
    const { t } = useLocale();
    return {
      t,
    };
  },
  data: () => ({
  }),
  mounted() {
  },
  computed: {
  },
};
</script>

<style scoped>
.text-about {
  color: #9A9A9B;
  font-size: 1.35rem;
  line-height: 1.9rem;
  font-weight: 400;
}

.blur_1 {
  position: absolute;
  top: 0%;
  left: 0%;
  transform: translate(-50%, -50%) translateZ(0);
  width: 270px;
  height: 270px;
  background: #CC8702;
  filter: blur(160px);
  -webkit-filter: blur(160px);
  border-radius: 100%;
  will-change: transform;
  z-index: 0;
}

.blur_2 {
  position: absolute;
  top: 100px;
  left: 0px;
  width: 270px;
  height: 270px;
  background: #F6F2E6;
  filter: blur(170px);
  -webkit-filter: blur(170px);
  border-radius: 100%;
  will-change: transform;
  z-index: 0;
}

.mt-150 {
  margin-top: 150px;
}

.title-about {
  color: #F6F2E6;
  font-size: 2rem;
  letter-spacing: -0.02rem;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
  z-index: 1;
}

.block-subtitle {
  color: #818181;
  font-weight: 400;
  font-size: 3.9rem;
  line-height: 4.2rem;
}

.block-title {
  font-weight: 400;
  color: #F6F2E6;
  font-size: 5.9rem;
  letter-spacing: -0.06rem;
  line-height: 6.5rem;
}

.block-text {
  color: #C4C4C4;
  letter-spacing: -0.02rem;
  font-size: 1.9rem;
  line-height: 2rem;
  font-weight: 400;
}

@media screen and (max-width: 600px) {
  .text-about {
    font-size: 1.1rem;
    line-height: 1.5rem;
  }

  .title-about {
    font-size: 1.8rem;
  }

  .block-subtitle {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }

  .block-title {
    font-size: 2.1rem;
    line-height: 2.9rem;
  }

  .block-text {
    font-size: 1.2rem;
    line-height: 1.7rem;
  }

  .mt-150 {
    margin-top: 80px;
  }

  .blur_1 {
    width: 150px;
    height: 150px;
    filter: blur(100px);
    -webkit-filter: blur(100px);
  }

  .blur_2 {
    width: 150px;
    height: 150px;
    filter: blur(100px);
    -webkit-filter: blur(100px);
  }
}

@media screen and (min-width: 600px) and (max-width: 960px) {}
</style>
