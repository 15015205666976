<template>
  <headerTop />
  <cookiesModal />
  <getConsultation />
  <v-main>
    <LearnParallax />
    <AboutTheProject class="mt-15" />
    <YouMaster />
    <MaterialSchedule class="mt-200" />
    <OurMission class="mt-200" />
    <GetInTouch id="GetInTouch" class="mt-200" />
    <realEstateWorld class="mt-200" />
  </v-main>
  <footerComponent />
  <Preloader :is-loading="isLoading" />
</template>

<script>
import footerComponent from "../components/footer.vue";
import headerTop from "../components/header.vue";
import LearnParallax from "../components/LearnPage/1.LearnParallax.vue";
import AboutTheProject from "../components/LearnPage/2.AboutTheProject.vue";
import YouMaster from "../components/LearnPage/3.YouMaster.vue";
import MaterialSchedule from "../components/LearnPage/4.MaterialSchedule.vue";
import OurMission from "../components/LearnPage/5.OurMission.vue";
import cookiesModal from "../components/cookiesModal.vue";
import Preloader from "../components/Preloader.vue";
import realEstateWorld from "../components/realEstateWorld.vue";
import GetInTouch from "../components/RealEstatePage/4.GetInTouch.vue";
import getConsultation from "../components/getConsultation.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "LearnPage",
  components: {
    headerTop,
    cookiesModal,
    Preloader,
    getConsultation,
    realEstateWorld,
    LearnParallax,
    AboutTheProject,
    MaterialSchedule,
    OurMission,
    YouMaster,
    GetInTouch,
    footerComponent,
  },
  data: () => ({
    isLoading: true,
  }),
  mounted() {
    let vue = this;
    if (document.readyState == "complete") {
      vue.isLoading = false;
    } else {
      window.addEventListener("load", () => {
        vue.isLoading = false;
      });
    }
  },
});
</script>
<style scoped>
.mt-200 {
  margin-top: 200px;
}

.mt-100 {
  margin-top: 100px;
}

@media screen and (max-width: 768px) {
  .mt-200 {
    margin-top: 80px;
  }
}
</style>