import { axiosInstance } from "../config";

export default {
  state: {
    AllLogs: [],
    AdminLogs: [],
  },
  mutations: {
    SetAllLogs(state, payload) {
      state.AllLogs = payload;
    },
    SetAdminLogs(state, payload) {
      state.AdminLogs = payload;
    },
  },
  actions: {
    async LoadAllLogs({ commit }) {
      return await axiosInstance
        .get("/log")
        .then(function (data) {
          commit("SetAllLogs", data?.data?.data);
          return data?.data?.data;
        })
        .catch(function (error) {
          return {
            error: error.response.data.message,
          };
        });
    },
    async LoadAdminLogs({ commit }, payload) {
      return await axiosInstance
        .get("/log")
        .then(function (data) {
          if (data.status == 200) {
            let logs = data.data.data.filter((l) => l.ID_admin == payload);
            commit("SetAdminLogs", logs);
            return logs;
          } else {
            return [];
          }
        })
        .catch(function (error) {
          return {
            error: error.response.data.message,
          };
        });
    },
  },
  getters: {
    getAllLogs: (state) => state.AllLogs,
    getAdminLogs: (state) => state.AdminLogs,
  },
};
