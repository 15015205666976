import { axiosInstance } from "../config";

export default {
  state: {},
  mutations: {},
  actions: {
    async property_toggle_archive(commit, payload) {
      return await axiosInstance
        .put("/property/toggle_archive/" + payload)
        .then(function (data) {
          return data?.data;
        })
        .catch(function (error) {
          return {
            error: error.response.data.message,
          };
        });
    },
    async property_toggle_pin(commit, payload) {
      if (payload.pin) {
        return await axiosInstance
          .delete("/top_property/" + payload.id)
          .then(function (data) {
            return data?.data;
          })
          .catch(function (error) {
            return {
              error: error.response.data.message,
            };
          });
      } else {
        return await axiosInstance
          .put("/property/toggle_pin/" + payload)
          .then(function (data) {
            return data?.data;
          })
          .catch(function (error) {
            return {
              error: error.response.data.message,
            };
          });
      }
    },
    async property_delete_by_id(commit, payload) {
      return await axiosInstance
        .delete("/property/" + payload)
        .then(function (data) {
          return data?.data;
        })
        .catch(function (error) {
          return {
            error: error.response.data.message,
          };
        });
    },
    async create_location(commit, payload) {
      return await axiosInstance
        .post("/location", payload)
        .then(function (data) {
          return data?.data;
        })
        .catch(function (error) {
          return {
            error: error.response.data.message,
          };
        });
    },
    async create_property_developer(commit, payload) {
      return await axiosInstance
        .post("/property_developer", payload)
        .then(function (data) {
          return data?.data;
        })
        .catch(function (error) {
          return {
            error: error.response.data.message,
          };
        });
    },
    async CreateProduct(commit, payload) {
      const formData = new FormData();
      for (const key in payload) {
        if (
          Object.prototype.hasOwnProperty.call(payload, key) &&
          payload[key] !== undefined
        ) {
          formData.append(key, payload[key]);
        }
      }

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      return await axiosInstance
        .post("/property", formData, config)
        .then(function (data) {
          return data?.data;
        })
        .catch(function (error) {
          return {
            error: error.response.data.message,
          };
        });
    },
    async UpdateProduct(commit, payload) {
      const formData = new FormData();
      formData.append("_method", "PUT");
      for (const key in payload.data) {
        if (
          Object.prototype.hasOwnProperty.call(payload.data, key) &&
          payload.data[key] !== undefined
        ) {
          formData.append(key, payload.data[key]);
        }
      }

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
       return await axiosInstance
        .post("/property/" + payload.ID, formData, config)
        .then(function (data) {
          return data?.data;
        })
        .catch(function (error) {
          return {
            error: error.response.data.message,
          };
        });
    },
    async property_type_delete(commit, payload) {
      return await axiosInstance
        .delete("/" + payload.type + "/" + payload.ID)
        .then(function (data) {
          return data?.data;
        })
        .catch(function (error) {
          return {
            error: error.response.data.message,
          };
        });
    },
  },
  getters: {},
};
