import { createStore } from "vuex";
import Categories from "./Categories";
import Partners from "./Partners";
import Product from "./Product";
import Location from "./Location";
import Lead_origin from "./Lead_origin";
import CRM_Auth from "./CRM/Auth";
import CRM_Leads from "./CRM/Leads";
import CRM_RealEstate from "./CRM/RealEstate_crm";
import CRM_Categories from "./CRM/Categories_crm";
import CRM_Agent from "./CRM/Agent_crm";
import CRM_Admin from "./CRM/Admin_crm";
import CRM_Logs from "./CRM/Logs_crm";
import CRM_Filters from "./CRM/Filters_crm";
import Filter from "./Filter";
import axios from "axios";
/* import { urlAPI } from "./config"; */

/* function getCookieValue(name) {
  // Create a regular expression to match the cookie name
  const nameEQ = name + "=";
  // Split the document.cookie string into individual cookies
  const cookies = document.cookie.split(";");
  // Iterate over each cookie
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];
    // Remove leading spaces
    while (cookie.charAt(0) == " ") {
      cookie = cookie.substring(1, cookie.length);
    }
    // Check if the current cookie matches the name
    if (cookie.indexOf(nameEQ) == 0) {
      // Return the cookie value
      return cookie.substring(nameEQ.length, cookie.length);
    }
  }
  // If the cookie is not found, return null
  return null;
} */
export default createStore({
  state: {},
  getters: {
    /* getHouse_condition_type: (state) => state.house_condition_type, */
  },
  mutations: {
    /* SetHouse_condition_typeM(state, payload) {
      state.house_condition_type = payload;
    }, */
  },
  actions: {
    async csrfCookie() {
      /*  document.cookie =
        "XSRF-TOKEN=eyJpdiI6IkdUbkJyRU04WjUvaGpTSCszZnJuS0E9PSIsInZhbHVlIjoiR2FDMm0yRmFrZ09vY0UxWUIya0tQRVNwTGN5RktWcFFucVBJczRKdUxMbU1hUUltbzNjbHFJZHN4R0xMZ0RESFl5SlBNSnZnRWQ2S0tKRXF6aWNGOURPYVA5NnFTekNMZGJFczhyb0xUSWM0QUJUcm9GdWVGQlF4bTFBbTQrT0UiLCJtYWMiOiJkZDA3NGFlMTVlOGQ2N2JjMGQ5NGZkODc1MTNhMTZiYzE1MzcxODFjYmJkNzhkYWVlZjNjNjdhNTQ4MjBhZWVlIiwidGFnIjoiIn0%3D";*/
      // Пример использования:
      /* let myCookie = getCookieValue("XSRF-TOKEN");
      let myCookie2 = getCookieValue("gpartners_session"); */

      axios.defaults.withCredentials = true;
      axios.defaults.withXSRFToken = true;
      /* axios.defaults.xsrfCookieName ='XSRF-TOKEN';
      axios.defaults.xsrfHeaderName ='X-XSRF-TOKEN'; */

      /* let csrf = await axios.get(`${urlAPI}/sanctum/csrf-cookie`, {
        withCredentials: "include",
      });
      console.log(csrf); */
    },
  },
  modules: {
    Categories,
    Product,
    Partners,
    Location,
    Lead_origin,
    CRM_Auth,
    CRM_RealEstate,
    CRM_Leads,
    CRM_Categories,
    CRM_Agent,
    CRM_Admin,
    CRM_Logs,
    CRM_Filters,
    Filter,
  },
});
