<template>
  <headerTop />
  <cookiesModal />
  <getConsultation />
  <v-main>
    <LegalTop />
    <LegalContent />
    <realEstateWorld class="mt-200" />
  </v-main>
  <footerComponent />
  <Preloader :is-loading="isLoading" />
</template>

<script>
import footerComponent from "../components/footer.vue";
import headerTop from "../components/header.vue";
import cookiesModal from "../components/cookiesModal.vue";
import Preloader from "../components/Preloader.vue";
import LegalTop from "../components/LegalPage/1.LegalTop.vue";
import LegalContent from "../components/LegalPage/2.LegalContent.vue";
import realEstateWorld from "../components/realEstateWorld.vue";
import getConsultation from "../components/getConsultation.vue";
import { defineComponent } from "vue";

export default defineComponent({
  name: "LegalPage",
  components: {
    headerTop,
    cookiesModal,
    Preloader,
    getConsultation,
    LegalTop,
    LegalContent,
    realEstateWorld,
    footerComponent,
  },
  data: () => ({
    isLoading: true,
    Partner: {
      name_en: "Maxim Ivanov",
      name_ro: "Maxim Ivanov",
      name_ru: "Maxim Ivanov",
      img: require("../assets/img/home/aboutG/1.jpg")
    },
    cards: [
      {
        id: "1",
        img: require("../assets/img/home/cardRealEstate/test.jpg"),
        pdf: "",
        title: "Real estate objects by G Partners are coming soon!",
        price: "Price",
        location: "District",
        href: "#",
      },
      {
        id: "2",
        img: require("../assets/img/home/cardRealEstate/test.jpg"),
        pdf: "",
        title: "Real estate objects by G Partners are coming soon!",
        price: "Price",
        location: "District",
        href: "#",
      },
      {
        id: "3",
        img: require("../assets/img/home/cardRealEstate/test.jpg"),
        pdf: "",
        title: "Real estate objects by G Partners are coming soon!",
        price: "Price",
        location: "District",
        href: "#",
      },
      {
        id: "4",
        img: require("../assets/img/home/cardRealEstate/test.jpg"),
        pdf: "",
        title: "Real estate objects by G Partners are coming soon!",
        price: "Price",
        location: "District",
        href: "#",
      },
      {
        id: "4",
        img: require("../assets/img/home/cardRealEstate/test.jpg"),
        pdf: "",
        title: "Real estate objects by G Partners are coming soon!",
        price: "Price",
        location: "District",
        href: "#",
      },
      {
        id: "5",
        img: require("../assets/img/home/cardRealEstate/test.jpg"),
        pdf: "",
        title: "Real estate objects by G Partners are coming soon!",
        price: "Price",
        location: "District",
        href: "#",
      },
    ],
  }),
  mounted() {
    let vue = this;
    if (document.readyState == "complete") {
      vue.isLoading = false;
    } else {
      window.addEventListener("load", () => {
        vue.isLoading = false;
      });
    }
  },
});
</script>
<style scoped>
.mt-200 {
  margin-top: 200px;
}

.mt-100 {
  margin-top: 100px;
}

@media screen and (max-width: 768px) {
  .mt-200 {
    margin-top: 80px;
  }
}
</style>