import SignIn from "../views/CRM/SignIn.vue";
import Dashboard from "../views/CRM/Dashboard.vue";
import AdminLeads from "../views/CRM/Leads.vue";
import AdminRealEstate from "../views/CRM/RealEstate.vue";
import CreateOrEditRealEstate from "../views/CRM/CreateRealEstate.vue";
import CreateOrEditCategories from "../views/CRM/CreateCategories.vue";
import CategoriesCRM from "../views/CRM/Categories.vue";
import AgentsCRM from "../views/CRM/Agents.vue";
import SettingsAdminsCRM from "../views/CRM/SettingsAdmins.vue";
import CreateOrEditAdmin from "../views/CRM/CreateAdmin.vue";
import SettingsFilterCRM from "../views/CRM/FiltersSettings.vue";
import SettingsLogsCRM from "../views/CRM/Logs.vue";
import store from "@/store";

const crm_router = [
  {
    path: `/:lang?/auth`,
    name: "auth",
    component: SignIn,
    beforeEnter: async (to, from, next) => {
      let responce = await store.dispatch("AdminCkeck");

      if (responce == true) {
        next({
          name: "dashboard",
        });
      } else {
        next();
      }
    },
  },
  {
    path: `/:lang?/admin`,
    name: "dashboard",
    component: Dashboard,
    beforeEnter: async (to, from, next) => {
      let responce = await store.dispatch("AdminCkeck");

      if (responce == true) {
        next();
      } else {
        next({
          name: "auth",
        });
      }
    },
    children: [
      {
        path: "leads",
        name: "leads",
        component: AdminLeads,
      },
      {
        path: "real-estate",
        name: "real-estate-crm",
        component: AdminRealEstate,
      },
      {
        path: "create-real-estate",
        name: "create-real-estate-crm",
        component: CreateOrEditRealEstate,
      },
      {
        path: "edit-real-estate/:id",
        name: "edit-real-estate-crm",
        component: CreateOrEditRealEstate,
      },
      {
        path: "categories",
        name: "categories-crm",
        component: CategoriesCRM,
      },
      {
        path: "create-categories",
        name: "create-categories-crm",
        component: CreateOrEditCategories,
      },
      {
        path: "edit-categories/:id",
        name: "edit-categories-crm",
        component: CreateOrEditCategories,
      },
      {
        path: "agents",
        name: "agents-crm",
        component: AgentsCRM,
      },
      {
        path: "admin-settings",
        name: "admin-settings-crm",
        component: SettingsAdminsCRM,
      },
      {
        path: "create-admin",
        name: "create-admin-crm",
        component: CreateOrEditAdmin,
      },
      {
        path: "edit-admin/:id",
        name: "edit-admin-crm",
        component: CreateOrEditAdmin,
      },
      {
        path: "filters-settings",
        name: "filters-settings-crm",
        component: SettingsFilterCRM,
      },
      {
        path: "logs",
        name: "logs-crm",
        component: SettingsLogsCRM,
      },
    ],
  },
];

export default crm_router;
