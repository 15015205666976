//import { ru } from "vuetify/locale";
import en from "./en.js";
import ru from "./ru.js";
import ro from "./ro.js";

let lang = localStorage.getItem("language")
  ? localStorage.getItem("language")
  : "en";
export const locale = {
  locale: lang,
  fallback: "ru",
  globalInjection: true,
  messages: { en, ru, ro },
};
