<template>
    <v-container fluid class="pt-10 fill-height" v-if="getPriority < 4">
        <v-row class="w-100" justify="center">
            <v-col cols="12">
                <h1>Категории объектов недвижимости</h1>
            </v-col>
        </v-row>
        <v-row justify="space-between" class="w-100">
            <v-col cols="12">
                <v-card width="100%" class="pa-4">
                    <h2 class="">
                        {{ $route.name == 'edit-categories-crm' ? 'Редактировать категорию объектов недвижимости' :
                            'Создать категорию объектов недвижимости'
                        }}
                    </h2>

                    <div class="mb-3 mt-10">
                        <v-card-text class="title_property_big">
                            Главная фотография
                        </v-card-text>
                        <v-card-subtitle class="mt-0 pt-0 pb-4">
                            Размер фотографии не должен превышать 200 кб . и 400X400 px
                        </v-card-subtitle>
                        <v-row>
                            <v-col :cols="getCategorie.image_path || main_image_path ? '9' : '12'" align-self="center">
                                <v-file-input v-model="main_image_path" label="Upload file" flat show-size clearable
                                    variant="solo-filled" hide-details></v-file-input>
                            </v-col>
                            <v-col cols="3" v-if="getCategorie.image_path || main_image_path">
                                <v-img rounded="lg" width="100%"
                                    :src="main_image_path ? windowVue.URL.createObjectURL(main_image_path) : urlAPI + getCategorie.image_path"></v-img>
                            </v-col>
                        </v-row>
                        <v-row class="ma-0">
                            <v-col cols="6">
                                <v-text-field v-model="main_image_title" variant="outlined" density="comfortable"
                                    hide-details label="title">
                                </v-text-field>
                            </v-col>
                            <v-col cols="6">
                                <v-text-field v-model="main_image_alt" variant="outlined" density="comfortable"
                                    hide-details label="alt">
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </div>



                    <!-- Name -->
                    <div class="my-3">
                        <v-card-text class="title_property">
                            Название на Русском
                        </v-card-text>
                        <v-text-field v-model="name_ru" variant="outlined" placeholder="Название на Русском"
                            density="comfortable" hide-details
                            label="название будет отображаться на русской версии сайта">
                        </v-text-field>
                    </div>
                    <div class="my-3">
                        <v-card-text class="title_property">
                            Название на Румынском
                        </v-card-text>
                        <v-text-field v-model="name_ro" variant="outlined" placeholder="Название на Русском"
                            density="comfortable" hide-details
                            label="название будет отображаться на румынской версии сайта">
                        </v-text-field>
                    </div>
                    <div class="my-3">
                        <v-card-text class="title_property">
                            Название на Английском
                        </v-card-text>
                        <v-text-field v-model="name_en" variant="outlined" placeholder="Название на Русском"
                            density="comfortable" hide-details
                            label="название будет отображаться на английской версии сайта">
                        </v-text-field>
                    </div>


                    <!-- meta title -->
                    <div class="my-3">
                        <v-card-text class="title_property">
                            Meta Title на Русском
                        </v-card-text>
                        <v-text-field v-model="meta_title_ru" variant="outlined" placeholder="Meta Title на Русском"
                            density="comfortable" hide-details
                            label="если оставить поле пустым, то будет использовать значение названия объекта">
                        </v-text-field>
                    </div>
                    <div class="my-3">
                        <v-card-text class="title_property">
                            Meta title на Румынском
                        </v-card-text>
                        <v-text-field v-model="meta_title_ro" variant="outlined" placeholder="Meta title на Румынском"
                            density="comfortable" hide-details
                            label="если оставить поле пустым, то будет использовать значение названия объекта">
                        </v-text-field>
                    </div>
                    <div class="my-3">
                        <v-card-text class="title_property">
                            Meta Title на Английском
                        </v-card-text>
                        <v-text-field v-model="meta_title_en" variant="outlined" placeholder="Meta Title на Английском"
                            density="comfortable" hide-details
                            label="если оставить поле пустым, то будет использовать значение названия объекта">
                        </v-text-field>
                    </div>

                    <!-- meta description -->
                    <div class="my-3">
                        <v-card-text class="title_property">
                            Meta Description на Русском
                        </v-card-text>
                        <v-text-field v-model="meta_description_ru" variant="outlined"
                            placeholder="Meta Description на Русском" density="comfortable" hide-details
                            label="если оставить поле пустым, то будет использовать значение названия объекта">
                        </v-text-field>
                    </div>
                    <div class="my-3">
                        <v-card-text class="title_property">
                            Meta Description на Румынском
                        </v-card-text>
                        <v-text-field v-model="meta_description_ro" variant="outlined"
                            placeholder="Meta Description на Румынском" density="comfortable" hide-details
                            label="если оставить поле пустым, то будет использовать значение названия объекта">
                        </v-text-field>
                    </div>
                    <div class="my-3">
                        <v-card-text class="title_property">
                            Meta Description на Английском
                        </v-card-text>
                        <v-text-field v-model="meta_description_en" variant="outlined"
                            placeholder="Meta Description на Английском" density="comfortable" hide-details
                            label="если оставить поле пустым, то будет использовать значение названия объекта">
                        </v-text-field>
                    </div>


                    <v-card-title class="pt-6">
                        Параметры категории
                    </v-card-title>
                    <v-card-subtitle>
                        Какие фильтры должна содержать в себе данная категория
                    </v-card-subtitle>
                    <!-- <v-card-text style="color: #FF0000;">
                        ВНИМАНИЕ! Данное дейтсвие не подлежит дальнейшему изменению.
                    </v-card-text> -->


                    <v-checkbox v-model="building_type" label="Тип объекта (Новострой, Вторичный)"
                        hide-details></v-checkbox>
                    <v-checkbox v-model="building_purpose_type" label="Тип предложения (аренда / покупка)"
                        hide-details></v-checkbox>
                    <v-checkbox v-model="housing_condition_type" label="Тип ремонта" hide-details></v-checkbox>
                    <v-checkbox v-model="industrial_purpose_type" label="Индустриальное назначение"
                        hide-details></v-checkbox>
                    <!-- <v-checkbox v-model="renovation_type" label="Тип ремонта" hide-details></v-checkbox> -->
                    <v-checkbox v-model="land_type" label="Коммерческое назначение" hide-details></v-checkbox>
                    <v-checkbox v-model="number_of_rooms" label="Количество комнат" hide-details></v-checkbox>



                    <v-row justify="center" class="my-15">
                        <v-col cols="12" sm="8" md="6">
                            <v-btn color="#0255B7" block flat class="btn-text" rounded="lg" size="large"
                                :disabled="!name_ru || !name_ro || !name_en" @click="CreateCategory()">
                                {{ $route.name == 'edit-categories-crm'
                                    ? 'Сохранить изменения в категории' :
                                    'Создать новую категорию' }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-snackbar :timeout="2000" location="top right" :color="alert_snackbar.color" variant="flat"
            v-model="alert_snackbar.state">
            <strong>{{ alert_snackbar.text }}</strong>
        </v-snackbar>
    </v-container>
</template>
<script>
import { defineComponent } from "vue";
import { useLocale } from "vuetify";
import { mapGetters } from 'vuex'
import { urlAPI } from "../../store/config";

export default defineComponent({
    name: "CreateOrEditCategories",
    setup() {
        const { t } = useLocale();
        return {
            t,
        };
    },
    data: () => ({
        urlAPI: urlAPI + '/',
        alert_snackbar: {
            state: false,
            color: "",
            text: ""
        },
        name_ru: null,
        name_ro: null,
        name_en: null,
        meta_title_ru: null,
        meta_title_ro: null,
        meta_title_en: null,
        meta_description_ru: null,
        meta_description_ro: null,
        meta_description_en: null,
        main_image_path: null,
        main_image_alt: null,
        main_image_title: null,
        building_type: null,
        building_purpose_type: null,
        housing_condition_type: null,
        industrial_purpose_type: null,
        /* renovation_type: null, */
        land_type: null,
        number_of_rooms: null,
        windowVue: null,
    }),
    computed: {
        ...mapGetters([
            'getCategorie',
            'getPriority',
        ]),
    },
    methods: {
        async CreateCategory() {
            let vue = this;
            try {
                if (this.$route.name == 'edit-categories-crm') {
                    let data = {
                        name_ru: this.name_ru,
                        name_ro: this.name_ro,
                        name_en: this.name_en,
                        meta_title_ru: this.meta_title_ru ? this.meta_title_ru.slice(0, 85) : this.name_ru.slice(0, 85),
                        meta_title_ro: this.meta_title_ro ? this.meta_title_ro.slice(0, 85) : this.name_ro.slice(0, 85),
                        meta_title_en: this.meta_title_en ? this.meta_title_en.slice(0, 85) : this.name_en.slice(0, 85),
                        meta_description_ru: this.meta_description_ru ? this.meta_description_ru.slice(0, 85) : this.name_ru.slice(0, 85),
                        meta_description_ro: this.meta_description_ro ? this.meta_description_ro.slice(0, 85) : this.name_ro.slice(0, 85),
                        meta_description_en: this.meta_description_en ? this.meta_description_en.slice(0, 85) : this.name_en.slice(0, 85),
                        image_upload: this.main_image_path ?? undefined,
                        alt: this.main_image_alt,
                        title: this.main_image_title,
                        building_type: this.building_type ? 1 : 0,
                        building_purpose_type: this.building_purpose_type ? 1 : 0,
                        housing_condition_type: this.housing_condition_type ? 1 : 0,
                        industrial_purpose_type: this.industrial_purpose_type ? 1 : 0,
                        renovation_type: 1,
                        land_type: this.land_type ? 1 : 0,
                        number_of_rooms: this.number_of_rooms ? 1 : 0,
                        property_developer: 1,
                        location: 1,
                        area: 1,
                    };
                    let responce = await this.$store.dispatch('UpdateCategory', { ID: this.getCategorie.ID, data: data });
                    if (responce.success == true) {
                        this.alert_snackbar.state = true;
                        this.alert_snackbar.text = "Категория успешно обновлена";
                        this.alert_snackbar.color = "success";
                    } else {
                        this.alert_snackbar.state = true;
                        this.alert_snackbar.text = responce.error ?? responce.message + " " + Object.values(responce.data);
                        this.alert_snackbar.color = "error";
                    }

                } else {
                    let data = {
                        name_ru: this.name_ru,
                        name_ro: this.name_ro,
                        name_en: this.name_en,
                        meta_title_ru: this.meta_title_ru ? this.meta_title_ru.slice(0, 85) : this.name_ru.slice(0, 85),
                        meta_title_ro: this.meta_title_ro ? this.meta_title_ro.slice(0, 85) : this.name_ro.slice(0, 85),
                        meta_title_en: this.meta_title_en ? this.meta_title_en.slice(0, 85) : this.name_en.slice(0, 85),
                        meta_description_ru: this.meta_description_ru ? this.meta_description_ru.slice(0, 85) : this.name_ru.slice(0, 85),
                        meta_description_ro: this.meta_description_ro ? this.meta_description_ro.slice(0, 85) : this.name_ro.slice(0, 85),
                        meta_description_en: this.meta_description_en ? this.meta_description_en.slice(0, 85) : this.name_en.slice(0, 85),
                        image_upload: this.main_image_path ?? undefined,
                        alt: this.main_image_alt,
                        title: this.main_image_title,
                        building_type: this.building_type ? 1 : 0,
                        building_purpose_type: this.building_purpose_type ? 1 : 0,
                        housing_condition_type: this.housing_condition_type ? 1 : 0,
                        industrial_purpose_type: this.industrial_purpose_type ? 1 : 0,
                        renovation_type: 1,
                        land_type: this.land_type ? 1 : 0,
                        number_of_rooms: this.number_of_rooms ? 1 : 0,
                        property_developer: 1,
                        location: 1,
                        area: 1,
                    };
                    let responce = await this.$store.dispatch('CreateCategory', data);
                    if (responce.success == true) {
                        this.alert_snackbar.state = true;
                        this.alert_snackbar.text = "Категория успешно создана";
                        this.alert_snackbar.color = "success";

                        setTimeout(function () {
                            vue.$router.push({
                                name: "categories-crm",
                            })
                        }, 1500);
                    } else {
                        this.alert_snackbar.state = true;
                        this.alert_snackbar.text = responce.error ?? responce.message + " " + Object.values(responce.data);
                        this.alert_snackbar.color = "error";
                    }
                }
            } catch (error) {
                console.log(error);
                this.alert_snackbar.state = true;
                this.alert_snackbar.text = error.message;
                this.alert_snackbar.color = "error";
            }
        },
    },
    async beforeCreate() {
        if (this.$route.params.id) {
            await this.$store.dispatch('LoadCategorie', this.$route.params.id);
            this.name_ru = this.getCategorie.name_ru;
            this.name_ro = this.getCategorie.name_ro;
            this.name_en = this.getCategorie.name_en;

            this.meta_title_ru = this.getCategorie.meta_title_ru;
            this.meta_title_ro = this.getCategorie.meta_title_ro;
            this.meta_title_en = this.getCategorie.meta_title_en;

            this.meta_description_ru = this.getCategorie.meta_description_ru;
            this.meta_description_ro = this.getCategorie.meta_description_ro;
            this.meta_description_en = this.getCategorie.meta_description_en;

            this.building_type = this.getCategorie.building_type == 1 ? true : false;
            this.building_purpose_type = this.getCategorie.building_purpose_type == 1 ? true : false;
            this.housing_condition_type = this.getCategorie.housing_condition_type == 1 ? true : false;
            this.industrial_purpose_type = this.getCategorie.industrial_purpose_type == 1 ? true : false;
            /* this.renovation_type = this.getCategorie.renovation_type == 1 ? true : false; */
            this.land_type = this.getCategorie.land_type == 1 ? true : false;
            this.number_of_rooms = this.getCategorie.number_of_rooms == 1 ? true : false;

            this.main_image_alt = this.getCategorie.alt;
            this.main_image_title = this.getCategorie.title;
        } else {
            this.$store.dispatch('LoadCategorieEmpty');
        }
    },
    mounted() {
        this.windowVue = window;
    }
})
</script>
<style>
.list-items-selects .v-list-item__content .v-list-item-title:not(.list-items-selects-title) {
    display: none !important;
}
</style>
<style scoped>
.title_property_big {
    font-weight: 500;
    font-size: 1.4rem;
}

.title_property {
    font-weight: 500;
    font-size: 1.1rem;
}

.divider-bottom {
    border: 1px solid rgba(0, 0, 0, 0.2);
    opacity: 1;
}

.btn-text {
    text-transform: inherit;
}

.input-search {
    min-width: 300px;
}

.select-search {
    min-width: 200px;
    max-width: 400px;
}

.col-header-gap {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 10px;
}

.w-100 {
    min-width: 100%;
}

@media screen and (max-width: 1400px) {
    .input-search {
        min-width: 140px;
    }
}
</style>

<style>
.input-search .v-field__input,
.select-search .v-field__input {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    min-height: 36px;
}
</style>