<template>
  <v-container :fluid="$vuetify.display.mdAndDown ? true : false">
    <v-row justify="center">
      <v-col cols="12" sm="11" md="11" lg="10">
        <v-row :justify="$vuetify.display.smAndUp ? 'space-between' : 'center'">
          <v-col cols="auto" align-self="center">
            <span class="block-title">
              {{ t("$vuetify.real_estate.MaibCalculator.title_left") }}
            </span>
          </v-col>
          <!-- <v-col cols="auto" align-self="center">
            <v-btn variant="outlined" class="btn-top-right rounded-pill px-8"
              :size="$vuetify.display.lgAndUp ? 'x-large' : 'large'">
              {{ t("$vuetify.real_estate.MaibCalculator.btn_right_1") }}
            </v-btn>
            <v-btn variant="outlined" class="btn-top-right rounded-pill px-8 ml-4"
              :size="$vuetify.display.lgAndUp ? 'x-large' : 'large'">
              {{ t("$vuetify.real_estate.MaibCalculator.btn_right_2") }}
            </v-btn>
          </v-col> -->
        </v-row>
        <v-row justify="center" class="mt-8">
          <iframe id="maib_credit_frame" class="credit_frame" src="https://creditemaibune.md/iframe/Remark"
            scrolling="no" width="100%" frameborder="0" :style="'height:' + IframeHeight + 'px;'">
            Browser not
            compatible with
            iframe.
          </iframe>
        </v-row>
        <v-row justify="center" class="mt-8 mt-sm-12">
          <v-btn variant="outlined" class="btn-top-right rounded-pill px-12" @click="ResetIframe()"
            :size="$vuetify.display.lgAndUp ? 'x-large' : 'large'">
            {{ t("$vuetify.real_estate.MaibCalculator.btn_new_calculation") }}
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useLocale } from "vuetify";

export default {
  name: "MaibCalculator",
  setup() {
    const { t } = useLocale();
    return {
      t,
    };
  },
  data: () => ({
    IframeHeight: 539,
  }),
  mounted() {
    window.addEventListener(
      "message",
      (event) => {
        if (event.origin !== "https://creditemaibune.md") return;
        let h = event.data.frameHeight;
        if (window.innerWidth < 500 && event.data.frameHeight < 1000) {
          h += 20;
        }
        this.IframeHeight = h;
      },
      false,
    );
  },
  unmounted() {
    window.removeEventListener("message", () => { }, false);
  },
  methods: {
    ResetIframe() {
      let src = document.getElementById('maib_credit_frame').getAttribute('src');
      document.getElementById('maib_credit_frame').setAttribute('src', src);

      document.getElementById('maib_credit_frame').scrollIntoView({
        behavior: 'smooth'
      });
    }
  }
};
</script>

<style scoped>
.credit_frame {
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 41px;
  border-bottom-right-radius: 41px;
}

.block-title {
  color: #9A9A9B;
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 3rem;
}

.btn-top-right {
  color: rgba(246, 242, 230, 1);
  text-transform: inherit;
  font-style: italic;
  font-size: 1.4rem;
  letter-spacing: -0.03rem;
  transition: 0.7s;
}

.btn-top-right:hover {
  color: #111213;
  background: #f6f2e6;
}

@media screen and (max-width: 600px) {
  .btn-top-right {
    zoom: 0.75;
  }

  .block-title {
    text-align: center;
    display: flex;
  }

}

@media screen and (min-width: 600px) and (max-width: 960px) {
  .btn-top-right {
    zoom: 0.9;
  }
}
</style>

<style></style>
