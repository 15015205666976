<template>
  <v-container :fluid="$vuetify.display.mdAndDown ? true : false" class="py-12">
    <v-row justify="center">
      <v-col cols="12" md="11">
        <v-divider class="action-divider"></v-divider>
        <v-card width="100%" color="transparent" flat v-for="(action, index) in actions" :key="index">
          <v-row :justify="$vuetify.display.smAndUp ? 'space-between' : 'center'" class="py-6 ma-0">
            <v-col cols="12" sm="6" md="4" align-self="center">
              <h6 class="action-title">
                {{ t(`$vuetify.about_page.AllActionTwo.items.${index}.title`) }}
              </h6>
              <p class="action-subtitle mt-1">
                {{ t(`$vuetify.about_page.AllActionTwo.items.${index}.description`) }}
              </p>
            </v-col>
            <v-col cols="12" sm="4" md="4" align-self="center">
              <v-img class="action-img" width="100%" :src="action.img"></v-img>
            </v-col>
          </v-row>
          <v-divider class="action-divider"></v-divider>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center" class="ma-0 mt-10">
      <v-btn @click="LearnIhub()" variant="outlined" class="btn-learn rounded-pill px-12">
        {{ t(`$vuetify.about_page.AllActionTwo.btn`) }}
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import { useLocale } from "vuetify";

export default {
  name: "AllActions",
  setup() {
    const { t } = useLocale();
    return {
      t,
    };
  },
  data: () => ({
    actions: [
      {
        img: require("../../assets/img/about/4.jpg"),
      },
      {
        img: require("../../assets/img/about/5.jpg"),
      },
      {
        img: require("../../assets/img/about/6.jpg"),
      },
    ]
  }),
  mounted() {
  },
  methods: {
    LearnIhub() {
      console.log("LearnIhub");
    }
  }
};
</script>

<style scoped>
.btn-learn {
  color: rgba(246, 242, 230, 1);
  text-transform: inherit;
  font-size: 1.1rem;
  font-weight: 400;
  letter-spacing: -0.03rem;
  font-style: italic;
  transition: 0.7s;
  align-items: center;
}

.btn-learn:hover {
  color: #111213;
  background: #f6f2e6;
}

.action-divider {
  border-color: rgba(246, 242, 230, 0.1);
  border-width: 0.5px;
  opacity: 1;
}

.action-img {
  border-radius: 4px;
}

.action-subtitle {
  color: rgba(154, 154, 155, 1);
  font-size: 1.1rem;
}

.action-title {
  font-size: 1.1rem;
  color: rgba(246, 242, 230, 1);
  font-weight: 400;
}

@media screen and (max-width: 600px) {
  .btn-learn {
    font-size: 1rem;
    text-align: center;
  }
}

@media screen and (min-width: 600px) and (max-width: 960px) {}
</style>
