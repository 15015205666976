<template>
    <v-container fluid class="pt-10 fill-height" v-if="getPriority == 1">
        <v-row class="w-100" justify="center">
            <v-col cols="12">
                <h1>Менеджмент</h1>
            </v-col>
        </v-row>
        <v-row justify="space-between" class="w-100">
            <v-col cols="auto">
                <v-btn color="#0255B7" rounded="lg" class="btn-text" @click="GoToLogs()">
                    Логи (история действий)
                </v-btn>
                <v-btn color="#0255B7" rounded="lg" class="btn-text ml-3" @click="GoToAddAdmin()">
                    Добавить админа
                </v-btn>
                <v-btn color="#2B9336" rounded="lg" class="btn-text ml-3" @click="GoToFilters()">
                    Настройка параметров фильтров
                </v-btn>
            </v-col>
        </v-row>
        <v-row class="my-4 mx-0 w-100" justify="center">
            <v-row class="my-4 mx-0 w-100" justify="center">
                <v-table :height="getAllAdmins.length < limit_view ? '65vh' : 'auto'" fixed-header class="table" hover>
                    <thead>
                        <tr>
                            <th class="text-left">
                                Login
                            </th>
                            <th class="text-left">
                                Доступ к недвижимости
                            </th>
                            <th class="text-left">
                                Доступ к Лидам
                            </th>
                            <th class="text-left">
                                Уровень доступа
                            </th>

                            <th class="text-left">
                                Действия
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in getAllAdmins.slice((page_view - 1) * limit_view, page_view * limit_view)"
                            :key="item.ID">
                            <td>{{ item.username }}</td>
                            <td>{{ getInfoRealEstate(item.access_priority) }}</td>
                            <td>{{ getInfoLeads(item.access_priority) }}</td>
                            <td>{{ item.access_level_name }}</td>
                            <td style="text-wrap: nowrap;">
                                <v-col cols="12" style="flex-direction: column; display: flex;">
                                    <v-row class="ma-0">
                                        <v-btn variant="text" class="btn-text-2" @click="GoToEditAdmin(item.ID)">
                                            <template v-slot:prepend>
                                                <v-icon>
                                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M17.37 6.18658C17.5433 6.35987 17.5433 6.64084 17.37 6.81413L16.4445 7.73968L14.6695 5.9647L15.595 5.03915C15.7683 4.86586 16.0493 4.86586 16.2226 5.03915L17.37 6.18658Z"
                                                            fill="#408ACB" />
                                                        <path
                                                            d="M15.8169 8.36723L14.042 6.59225L7.99548 12.6387C7.94677 12.6874 7.91007 12.7468 7.88828 12.8122L7.17424 14.9543C7.11642 15.1277 7.28144 15.2928 7.45489 15.2349L9.59701 14.5209C9.66236 14.4991 9.72175 14.4624 9.77046 14.4137L15.8169 8.36723Z"
                                                            fill="#408ACB" />
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M4.5 16.4466C4.5 17.1818 5.09601 17.7778 5.83124 17.7778H15.5936C16.3289 17.7778 16.9249 17.1818 16.9249 16.4466V11.1216C16.9249 10.8765 16.7262 10.6779 16.4811 10.6779C16.2361 10.6779 16.0374 10.8765 16.0374 11.1216V16.4466C16.0374 16.6916 15.8387 16.8903 15.5936 16.8903H5.83124C5.58616 16.8903 5.38749 16.6916 5.38749 16.4466V6.68416C5.38749 6.43909 5.58616 6.24042 5.83124 6.24042H11.5999C11.845 6.24042 12.0437 6.04174 12.0437 5.79667C12.0437 5.5516 11.845 5.35293 11.5999 5.35293H5.83124C5.09601 5.35293 4.5 5.94894 4.5 6.68416V16.4466Z"
                                                            fill="#408ACB" />
                                                    </svg>
                                                </v-icon>
                                            </template>
                                            Редактировать
                                        </v-btn>
                                    </v-row>
                                    <v-row class="ma-0">
                                        <v-btn variant="text" class="btn-text-2" @click="AdminInfoOpenDialog(item)">
                                            <template v-slot:prepend>
                                                <v-icon>
                                                    <svg width="15" height="12" viewBox="0 0 15 12" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M0 2.17684C0 1.16432 0.839466 0.343506 1.875 0.343506H13.125C14.1605 0.343506 15 1.16432 15 2.17684V9.51017C15 10.5227 14.1605 11.3435 13.125 11.3435H1.875C0.839467 11.3435 0 10.5227 0 9.51017V2.17684ZM8.4375 3.55184C8.4375 3.29871 8.64737 3.09351 8.90625 3.09351H12.6562C12.9151 3.09351 13.125 3.29871 13.125 3.55184C13.125 3.80497 12.9151 4.01017 12.6562 4.01017H8.90625C8.64737 4.01017 8.4375 3.80497 8.4375 3.55184ZM8.4375 5.84351C8.4375 5.59038 8.64737 5.38517 8.90625 5.38517H12.6562C12.9151 5.38517 13.125 5.59038 13.125 5.84351C13.125 6.09664 12.9151 6.30184 12.6562 6.30184H8.90625C8.64737 6.30184 8.4375 6.09664 8.4375 5.84351ZM9.375 8.13517C9.375 7.88204 9.58487 7.67684 9.84375 7.67684H12.6562C12.9151 7.67684 13.125 7.88204 13.125 8.13517C13.125 8.3883 12.9151 8.59351 12.6562 8.59351H9.84375C9.58487 8.59351 9.375 8.3883 9.375 8.13517ZM8.43749 9.96851C8.43749 10.1241 8.42454 10.2771 8.39951 10.4268H1.87499C1.41522 10.4268 1.03275 10.1032 0.952835 9.67626C1.12539 8.04131 2.73157 6.76017 4.68749 6.76017C6.75855 6.76017 8.43749 8.19659 8.43749 9.96851ZM6.56249 4.01017C6.56249 5.02269 5.72302 5.84351 4.68749 5.84351C3.65195 5.84351 2.81249 5.02269 2.81249 4.01017C2.81249 2.99765 3.65195 2.17684 4.68749 2.17684C5.72302 2.17684 6.56249 2.99765 6.56249 4.01017Z"
                                                            fill="#6C757D" />
                                                    </svg>
                                                </v-icon>
                                            </template>
                                            История действий админа
                                        </v-btn>
                                    </v-row>
                                    <v-row class="ma-0">
                                        <v-btn variant="text" class="btn-text-2" @click="AdminOpenDialogDelete(item)">
                                            <template v-slot:prepend>
                                                <v-icon>
                                                    <svg width="22" height="24" viewBox="0 0 22 24" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M5.5 5.85463C4.94772 5.85463 4.5 6.27793 4.5 6.80009V7.74554C4.5 8.2677 4.94772 8.691 5.5 8.691H6V17.2001C6 18.2444 6.89543 19.091 8 19.091H14C15.1046 19.091 16 18.2444 16 17.2001V8.691H16.5C17.0523 8.691 17.5 8.2677 17.5 7.74554V6.80009C17.5 6.27793 17.0523 5.85463 16.5 5.85463H13C13 5.33247 12.5523 4.90918 12 4.90918H10C9.44772 4.90918 9 5.33247 9 5.85463H5.5ZM8.5 9.63645C8.77614 9.63645 9 9.8481 9 10.1092V16.7274C9 16.9884 8.77614 17.2001 8.5 17.2001C8.22386 17.2001 8 16.9884 8 16.7274L8 10.1092C8 9.8481 8.22386 9.63645 8.5 9.63645ZM11 9.63645C11.2761 9.63645 11.5 9.8481 11.5 10.1092V16.7274C11.5 16.9884 11.2761 17.2001 11 17.2001C10.7239 17.2001 10.5 16.9884 10.5 16.7274V10.1092C10.5 9.8481 10.7239 9.63645 11 9.63645ZM14 10.1092V16.7274C14 16.9884 13.7761 17.2001 13.5 17.2001C13.2239 17.2001 13 16.9884 13 16.7274V10.1092C13 9.8481 13.2239 9.63645 13.5 9.63645C13.7761 9.63645 14 9.8481 14 10.1092Z"
                                                            fill="#E05757" />
                                                    </svg>
                                                </v-icon>
                                            </template>
                                            Удалить
                                        </v-btn>
                                    </v-row>
                                </v-col>
                            </td>
                        </tr>
                    </tbody>
                </v-table>
            </v-row>
            <v-row justify="space-between" class="w-100" v-if="getAllAdmins.length > limit_view">
                <v-col cols="auto">
                    <v-btn color="#00000018" rounded="lg" elevation="0" class="btn-text" append-icon="mdi-reply"
                        @click="MinusPage()">
                        Предыдущая страница
                    </v-btn>
                </v-col>
                <v-col cols="auto">
                    <p> Страница: {{ page_view }}</p>
                </v-col>
                <v-col cols="auto">
                    <v-btn color="#00000018" rounded="lg" elevation="0" class="btn-text" append-icon="mdi-share"
                        @click="PlusPage()">
                        Следующая страница
                    </v-btn>
                </v-col>
            </v-row>
            <v-divider class="mt-4 mb-2 divider-bottom"></v-divider>
            <v-row class="my-0">
                <v-col cols="auto">
                    <p>Всего админов: {{ getAllAdmins.length }}</p>
                </v-col>
            </v-row>
            <v-dialog v-model="dialogInfoAdmin.state" max-width="900">
                <v-card class="pa-6 py-md-10 text-center" rounded="lg">
                    <v-row justify="end">
                        <v-col cols="6" sm="4">
                            <v-btn color="#6C757D" flat variant="text" text="Закрыть" prepend-icon="mdi-close"
                                @click="dialogInfoAdmin.state = false"></v-btn>
                        </v-col>
                    </v-row>
                    <h2 class="mb-3 text-left">Админ №{{ dialogInfoAdmin.admin.ID }}</h2>
                    <h3 class="mb-3 text-left">Login: {{ dialogInfoAdmin.admin.username }}</h3>
                    <v-row class="my-4 mx-0 w-100" justify="center">
                        <v-table :height="getAdminLogs.length < limit_view_logs ? '45vh' : 'auto'" fixed-header
                            class="table" hover>
                            <thead>
                                <tr>
                                    <th class="text-center">
                                        Admin login
                                    </th>
                                    <th class="text-center">
                                        ID
                                    </th>
                                    <th class="text-center">
                                        Дата и время
                                    </th>
                                    <th class="text-center">
                                        Описание лога
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in getAdminLogs.slice((page_view_logs - 1) * limit_view_logs, page_view_logs * limit_view_logs)"
                                    :key="item.ID">
                                    <td style="width: 120px;">{{ item.username }}</td>
                                    <td style="width: 70px;">{{ item.ID }}</td>
                                    <td style="width: 200px;">{{ item.log_date }}</td>
                                    <td class="log-description">{{ item.log_description }}</td>
                                </tr>
                            </tbody>
                        </v-table>
                    </v-row>
                    <v-row justify="space-between" class="w-100" v-if="getAdminLogs.length > page_view_logs">
                        <v-col cols="auto">
                            <v-btn color="#00000018" rounded="lg" elevation="0" class="btn-text" append-icon="mdi-reply"
                                @click="MinusPageLogs()">
                                Предыдущая страница
                            </v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <p> Страница: {{ page_view_logs }}</p>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn color="#00000018" rounded="lg" elevation="0" class="btn-text" append-icon="mdi-share"
                                @click="PlusPageLogs()">
                                Следующая страница
                            </v-btn>
                        </v-col>
                    </v-row>

                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDeleteAdmin.state" max-width="700">
                <v-card class="pa-6 py-md-10 text-center" rounded="lg">
                    <h2 class="mb-3">Вы уверены что хотите удалить админа ?</h2>
                    <v-row justify="center" class="ma-0">
                        <v-col cols="6" align-self="center">
                            <h2> {{ dialogDeleteAdmin.admin.username }}</h2>
                        </v-col>
                    </v-row>
                    <h4>Внимание: </h4>
                    <v-card-text class="pt-0">Это действие не подлежат возврату!</v-card-text>
                    <v-card-actions>
                        <v-row justify="center">
                            <v-col cols="6">
                                <v-btn color="#E05757" class="btn-text" variant="flat" flat rounded="lg" block
                                    text="Удалить навсегда" @click="DeleteAdmin(dialogDeleteAdmin.admin.ID)"></v-btn>
                            </v-col>
                            <v-col cols="6">
                                <v-btn color="#C6C6C6" class="btn-text" variant="flat" flat rounded="lg" block
                                    text="Подумать" @click="dialogDeleteAdmin.state = false"></v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                    <v-card-text>
                        <i>
                            При нажатии “Удалить навсегда” админ полносью исчезнет из списка.
                        </i>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
    <v-snackbar :timeout="2000" location="top right" :color="alert_snackbar.color" variant="flat"
        v-model="alert_snackbar.state">
        <strong>{{ alert_snackbar.text }}</strong>
    </v-snackbar>
</template>
<script>
import { defineComponent } from "vue";
import { mapGetters } from 'vuex'
import { useLocale } from "vuetify";
import { urlAPI } from "../../store/config";

export default defineComponent({
    name: "SettingsAdminsCRM",
    setup() {
        const { t } = useLocale();
        return {
            t,
        };
    },
    async updated() {
        await this.$store.dispatch('LoadAllAdmins');
    },
    data: () => ({
        urlAPI: urlAPI + '/',
        page_view: 1,
        limit_view: 10,
        page_view_logs: 1,
        limit_view_logs: 100,
        dialogDeleteAdmin: {
            state: false,
            admin: {}
        },
        dialogInfoAdmin: {
            state: false,
            admin: {}
        },
        alert_snackbar: {
            state: false,
            color: "",
            text: ""
        },
    }),
    computed: {
        ...mapGetters([
            'getAllAdmins',
            'getPriority',
            'getAdminLogs',
            /* 'getAdmin', */
        ]),
    },
    methods: {
        getInfoRealEstate(level) {
            switch (level) {
                case 1:
                    return "Создание, Редактирование, Удаление"
                case 2:
                    return "Создание, Редактирование, Удаление"
                case 3:
                    return "Создание, Редактирование,Удаление"
                case 4:
                    return "Нет"
                default:
                    return "Нет"
            }
        },
        getInfoLeads(level) {
            switch (level) {
                case 1:
                    return "Создание, Редактирование, Удаление"
                case 2:
                    return "Создание, Редактирование, Удаление"
                case 3:
                    return "Нет"
                case 4:
                    return "Создание, Редактирование,Удаление"
                default:
                    return "Нет"
            }
        },
        async AdminInfoOpenDialog(admin) {
            /* await this.$store.dispatch('LoadAdmin', admin.ID); */
            await this.$store.dispatch('LoadAdminLogs', admin.ID);
            this.dialogInfoAdmin.state = true;
            this.dialogInfoAdmin.admin = admin;
        },
        AdminOpenDialogDelete(admin) {
            this.dialogDeleteAdmin.state = true;
            this.dialogDeleteAdmin.admin = admin;
        },
        async DeleteAdmin(ID) {
            this.dialogDeleteAdmin.state = false;
            this.dialogDeleteAdmin.admin = {};
            let responce = await this.$store.dispatch('AdminDeleteById', ID);
            if (responce.success == true) {
                await this.$store.dispatch('LoadAllAdmins');
                this.alert_snackbar.state = true;
                this.alert_snackbar.text = "Админ был удалён с сайта";
                this.alert_snackbar.color = "success";
            } else {
                this.alert_snackbar.state = true;
                this.alert_snackbar.text = responce.error ?? responce.message + " " + Object.values(responce.data);
                this.alert_snackbar.color = "error";
            }
        },
        MinusPage() {
            if (this.page_view > 1) {
                this.page_view -= 1;
            }
        },
        PlusPage() {
            if (this.page_view * this.limit_view < this.getAllAdmins.length) {
                this.page_view += 1;
            }
        },
        MinusPageLogs() {
            if (this.page_view_logs > 1) {
                this.page_view_logs -= 1;
            }
        },
        PlusPageLogs() {
            if (this.page_view_logs * this.limit_view_logs < this.getAdminLogs.length) {
                this.page_view_logs += 1;
            }
        },
        GoToEditAdmin(ID) {
            this.$router.push({
                name: "edit-admin-crm",
                params: {
                    id: ID
                }
            })
        },
        GoToLogs() {
            this.$router.push({
                name: "logs-crm",
            })
        },
        GoToAddAdmin() {
            this.$router.push({
                name: "create-admin-crm",
            })
        },
        GoToFilters() {
            this.$router.push({
                name: "filters-settings-crm",
            })
        },
    },
    async mounted() {
        this.windowVue = window;
        await this.$store.dispatch('LoadAllAdmins');
    },
})
</script>
<style scoped>
.title_property_big {
    font-weight: 500;
    font-size: 1.4rem;
}

.title_property {
    font-weight: 500;
    font-size: 1.1rem;
}

.divider-bottom {
    border: 1px solid rgba(0, 0, 0, 0.2);
    opacity: 1;
}

.btn-text-2 {
    text-transform: inherit;
    letter-spacing: 0px;
    text-align: left;
}

.btn-text {
    text-transform: inherit;
}

.table {
    border: 1px solid rgba(0, 0, 0, 0.12);
    width: 100%;
    border-radius: 10px;
}

.input-search {
    min-width: 300px;
}

.select-search {
    min-width: 200px;
    max-width: 400px;
}

.col-header-gap {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 10px;
}

.w-100 {
    min-width: 100%;
}

@media screen and (max-width: 1400px) {
    .input-search {
        min-width: 250px;
    }
}
</style>

<style>
.input-search .v-field__input,
.select-search .v-field__input {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    min-height: 36px;
}
</style>