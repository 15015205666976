<template>
  <v-parallax src="../../assets/img/real_estate/Categories/bg.jpg" width="100%" :height="height_image_paralax"
    id="paralax-image-real-estate-single">
    <v-container class="fill-height px-sm-10 px-md-15 container-main"
      :fluid="$vuetify.display.mdAndDown ? true : false">

      <v-row class="row-line mh-90" justify="space-between">
        <v-col cols="auto" align-self="end">
          <v-expand-transition>
            <h1 class="title-big" v-show="textAnim.showTextTitle">
              {{ Categorie[`name_${$vuetify.locale.current}`] }}
            </h1>
          </v-expand-transition>
        </v-col>
        <v-col cols="auto" offset-md="1" align-self="center">
          <v-fade-transition>
            <h6 class="text-h6 title-small2" v-show="textAnim.showText">
              {{ t("$vuetify.real_estate_single.Parallax.subtitle_right") }}
            </h6>
          </v-fade-transition>
        </v-col>
      </v-row>

      <v-divider class="divider-paralax my-4 my-sm-10 my-lg-13" color="info"></v-divider>

      <v-row class="row-line mb-4">
        <v-col cols="12" align-self="center">
          <v-btn variant="outlined" class="btn-paralax rounded-pill my-2"
            :size="$vuetify.display.lgAndUp ? 'x-large' : 'large'" href="#MaibCalculator">
            {{ t("$vuetify.real_estate_single.Parallax.btn_1") }}
          </v-btn>
          <v-btn variant="outlined" class="btn-paralax rounded-pill mx-4 my-2"
            :size="$vuetify.display.lgAndUp ? 'x-large' : 'large'" href="#GetInTouch">
            {{ t("$vuetify.real_estate_single.Parallax.btn_2") }}
          </v-btn>
        </v-col>
      </v-row>
      <breadcrumbs name="real_estate" :Categorie="Categorie" :Product="Product" />
    </v-container>
  </v-parallax>
</template>

<script>
import { useLocale } from "vuetify";
import breadcrumbs from "../breadcrumbs.vue";

export default {
  name: "ProductParalax",
  props: {
    Product: Object,
    Categorie: Object,
  },
  components: {
    breadcrumbs
  },
  setup() {
    const { t } = useLocale();
    return {
      t,
    };
  },
  data: () => ({
    textAnim: {
      showTextTitle: false,
      showText: false,
    },

  }),
  mounted() {
    let vue = this;

    if (document.readyState == "complete") {
      setTimeout(() => {
        vue.textAnim.showTextTitle = true;
      }, 800);
      setTimeout(() => {
        vue.textAnim.showText = true;
      }, 1300);
    } else {
      document.addEventListener(
        "PreloaderFinish",
        () => {
          setTimeout(() => {
            vue.textAnim.showTextTitle = true;
          }, 800);
          setTimeout(() => {
            vue.textAnim.showText = true;
          }, 1300);
        },
        false
      );
    }
  },
  computed: {
    height_image_paralax() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return "100vh";
        case "lg":
          return "100vh";
        case "xl":
          return "75vh";
        case "xxl":
          return "75vh";
        default:
          return "100vh";
      }
    },
  },
};
</script>

<style scoped>
.mh-90 {
  min-height: 124px;
}

.container-main {
  padding-top: 25vh;
  align-content: center
}

.card-categories-img {
  align-items: center;
  aspect-ratio: 0.7;
}

.card-categories-text {
  color: #9A9A9B;
  font-size: 1.15rem;
  font-style: italic;
  font-weight: 400;
  letter-spacing: -0.01rem;
  transition: .4s;
}

.card-categories {
  padding: 0px;
  border-radius: 25px;
  background: linear-gradient(to bottom, #ffffff 0%, rgba(144, 142, 135, 0) 60%) border-box;
  border: 1px solid transparent;
  filter: saturate(0);
  transition: .4s;
}

.card-categories:hover {
  filter: saturate(1.1);
  transform: scale(1.05);
  cursor: pointer;
}

.card-categories:hover .card-categories-text {
  color: #F6F2E6;
}

.row-line {
  width: 100%;
}

.btn-paralax {
  color: rgba(246, 242, 230, 1);
  text-transform: inherit;
  font-style: italic;
  font-size: 1.4rem;
  letter-spacing: -0.03rem;
  transition: 0.7s;
}

.btn-paralax:hover {
  color: #111213;
  background: #f6f2e6;
}

.title-small2 {
  color: #c4c4c4;
  font-weight: 400;
  letter-spacing: -0.035rem !important;
}

.title-small {
  color: #c4c4c4;
  font-weight: 400;
  letter-spacing: -0.04rem !important;
  text-shadow: 8px 13px 35px rgba(0, 0, 0, 0.5);
}

.title-big {
  letter-spacing: -0.24rem !important;
  color: rgba(246, 242, 230, 1);
  text-shadow: rgba(0, 0, 0, 0.87) 0px 4px 30px;
  font-size: 6rem;
  font-weight: 300;
  line-height: 6rem;
}

.divider-paralax {
  border-width: 1px;
  border-color: rgba(154, 154, 155, 0.36);
  opacity: 1;
}

@media screen and (max-width: 600px) {
  .mh-90 {
    min-height: 155px;
  }

  .title-big {
    letter-spacing: -0.02rem !important;
    font-size: 3rem;
    font-weight: 300;
    line-height: 3rem;
  }

  .title-small,
  .title-small2 {
    font-size: 0.85rem !important;
    line-height: 1.1rem !important;
  }

  .row-line {
    height: unset !important;
  }

  .btn-paralax {
    zoom: 0.6;
  }
}

@media screen and (min-width: 600px) and (max-width: 960px) {
  .title-big {
    zoom: 0.8;
  }

  .title-small,
  .title-small2 {
    zoom: 0.8;
  }

  .btn-paralax {
    zoom: 0.9;
  }
}
</style>

<style>
#paralax-image-real-estate-single>.v-responsive__content {
  background: linear-gradient(rgba(17, 18, 19, 0.27) 40%,
      rgba(17, 18, 19, 1) 97%);
  bottom: -1px;
  position: relative;
}
</style>