import axios from "axios";

//https://api.gpartners.md
//https://apigpartners.amark.pro
let urlAPI = "https://apigpartners.amark.pro";
let url = "https://gpartners.md";
let axiosInstance = axios.create({
  baseURL: "https://apigpartners.amark.pro",
  headers: {
    Authorization: `Bearer ${window.localStorage.getItem("token")}`,
  },
});

export { urlAPI, url, axiosInstance };
