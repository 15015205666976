import axios from "axios";
import { urlAPI } from "./config";

export default {
  state: {
    Location: {},
    AllLocations: {},
  },
  mutations: {
    SetLocation(state, payload) {
      state.Location = payload;
    },
    SetAllLocations(state, payload) {
      state.AllLocations = payload;
    },
  },
  actions: {
    async LoadLocation({ commit }, payload) {
      let data = await axios.get(urlAPI + `/location/${payload}`);
      commit("SetLocation", data?.data?.data);
    },
    async LoadAllLocations({ commit }) {
      let data = await axios.get(urlAPI + `/location`);
      commit("SetAllLocations", data?.data?.data);
    },
  },
  getters: {
    getLocation: (state) => state.Location,
  },
};
