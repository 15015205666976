<template>
  <headerTop />
  <cookiesModal />
  <getConsultation />
  <v-main>
    <ProductParallax :Product="getProduct" :Categorie="getCategorie" />
    <ProductImages id="ProductImages" :Product="getProduct" />
    <ProductDetails :Product="getProduct" :Categorie="getCategorie" />
    <MaibCalculator id="MaibCalculator" class="mt-100" />
    <OtherProducts :Product="getProduct" :Categorie="getCategorie" class="mt-100" />
    <GetInTouch id="GetInTouch" class="mt-200" />
    <realEstateWorld class="mt-200" />
  </v-main>
  <footerComponent />
  <Preloader :is-loading="isLoading" />
</template>

<script>
import headerTop from "../components/header.vue";
import cookiesModal from "../components/cookiesModal.vue";
import getConsultation from "../components/getConsultation.vue";
import Preloader from "../components/Preloader.vue";
import footerComponent from "../components/footer.vue";
import ProductParallax from "../components/RealEstateSinglePage/1.ProductParallax.vue";
import ProductImages from "../components/RealEstateSinglePage/2.ProductImages.vue";
import ProductDetails from "../components/RealEstateSinglePage/3.ProductDetails.vue";
import MaibCalculator from "../components/RealEstatePage/3.MaibCalculator.vue";
import OtherProducts from "../components/RealEstateSinglePage/5.OtherProducts.vue";
import GetInTouch from "../components/RealEstatePage/4.GetInTouch.vue";
import realEstateWorld from "../components/realEstateWorld.vue";
import { defineComponent } from "vue";
import { useLocale } from "vuetify";
import { mapGetters } from 'vuex'

export default defineComponent({
  name: "RealEstateSingle",
  components: {
    headerTop,
    cookiesModal,
    getConsultation,
    footerComponent,
    Preloader,
    ProductParallax,
    ProductImages,
    ProductDetails,
    MaibCalculator,
    OtherProducts,
    GetInTouch,
    realEstateWorld,
  },
  setup() {
    const { t } = useLocale();
    return {
      t,
    };
  },
  metaInfo() {
    if (this.getProduct.ID) {
      if (!this.getProduct[`meta_title_${this.$vuetify.locale.current}`] || !this.getProduct[`meta_description_${this.$vuetify.locale.current}`]) return {}
      return {
        htmlAttrs: {
          lang: this.$vuetify.locale.current,
        },
        title: this.getProduct[`meta_title_${this.$vuetify.locale.current}`],
        description: this.getProduct[`meta_description_${this.$vuetify.locale.current}`],
        meta: [
          { vmid: 'description', name: 'description', content: this.getProduct[`meta_description_${this.$vuetify.locale.current}`] }
        ]
      }
    } else {
      return {}
    }
  },
  data: () => ({
    isLoading: true,
  }),
  async beforeCreate() {
    let responce = await this.$store.dispatch('LoadProduct', { type: "link_name", value: this.$route.params.propertyID });
    if (responce.error) {
      this.$router.push({
        name: "real-estate",
        params: {
          lang: this.$vuetify.locale.current
        }
      })
      return
    }
    await this.$store.dispatch('LoadCategorie', this.getProduct.ID_category);

    let vue = this;
    if (document.readyState == "complete") {
      vue.isLoading = false;
    } else {
      window.addEventListener("load", () => {
        vue.isLoading = false;
      });
    }
  },
  async updated() {
    await this.$store.dispatch('LoadProduct', { type: "link_name", value: this.$route.params.propertyID });
    await this.$store.dispatch('LoadCategorie', this.getProduct.ID_category);
  },
  computed: {
    ...mapGetters([
      'getCategorie',
      'getProduct',
    ]),
  },
});
</script>
<style scoped>
.mt-200 {
  margin-top: 200px;
}

.mt-100 {
  margin-top: 100px;
}

@media screen and (max-width: 768px) {
  .mt-200 {
    margin-top: 50px;
  }
}
</style>