import { locale } from "../locales/index.js";
import Home from "../views/Home.vue";
import About from "../views/About.vue";
import Learn from "../views/Learn.vue";
import Partners from "../views/Partners.vue";
import PartnerSingle from "../views/PartnerSingle.vue";
import Contact from "../views/Contact.vue";
import Legal from "../views/Legal.vue";
import RealEstate from "../views/RealEstate.vue";
import RealEstateSingle from "../views/RealEstateSingle.vue";
import RealEstateSingleGallary from "../views/RealEstateSingleGallery.vue";

const web_router = [
  {
    path: `/:lang?`,
    name: "home",
    component: Home,
    beforeEnter: (to, from, next) => {
      const lang = to.params.lang;
      if (Object.keys(locale.messages).includes(lang)) {
        locale.locale = lang;
        localStorage.setItem("language", lang.toLowerCase());
        next();
      } else {
        next("/en/");
      }
    },
  },
  {
    path: `/:lang?/about`,
    name: "about",
    component: About,
    beforeEnter: (to, from, next) => {
      const lang = to.params.lang;
      if (Object.keys(locale.messages).includes(lang)) {
        locale.locale = lang;
        localStorage.setItem("language", lang.toLowerCase());
        next();
      } else {
        next("/en/about");
      }
    },
  },
  {
    path: `/:lang?/real-estate/:categorie?`,
    name: "real-estate",
    component: RealEstate,
    beforeEnter: (to, from, next) => {
      const lang = to.params.lang;
      if (Object.keys(locale.messages).includes(lang)) {
        locale.locale = lang;
        localStorage.setItem("language", lang.toLowerCase());
        next();
      } else {
        next("/en/real-estate/");
      }
    },
  },
  {
    path: `/:lang?/property/:propertyID?`,
    name: "property",
    component: RealEstateSingle,
    beforeEnter: (to, from, next) => {
      const lang = to.params.lang;
      if (Object.keys(locale.messages).includes(lang) && to.params.propertyID) {
        locale.locale = lang;
        localStorage.setItem("language", lang.toLowerCase());
        next();
      } else {
        next("/en/real-estate/");
      }
    },
  },
  {
    path: `/:lang?/property/:propertyID/gallery`,
    name: "property_gallery",
    component: RealEstateSingleGallary,
    beforeEnter: (to, from, next) => {
      const lang = to.params.lang;
      if (Object.keys(locale.messages).includes(lang)) {
        locale.locale = lang;
        localStorage.setItem("language", lang.toLowerCase());
        next();
      } else {
        next("/en/real-estate/");
      }
    },
  },
  {
    path: `/:lang?/learn`,
    name: "learn",
    component: Learn,
    beforeEnter: (to, from, next) => {
      const lang = to.params.lang;
      if (Object.keys(locale.messages).includes(lang)) {
        locale.locale = lang;
        localStorage.setItem("language", lang.toLowerCase());
        next();
      } else {
        next("/en/learn");
      }
    },
  },
  {
    path: `/:lang?/partners`,
    name: "partners",
    component: Partners,
    beforeEnter: (to, from, next) => {
      const lang = to.params.lang;
      if (Object.keys(locale.messages).includes(lang)) {
        locale.locale = lang;
        localStorage.setItem("language", lang.toLowerCase());
        next();
      } else {
        next("/en/partners");
      }
    },
  },
  {
    path: `/:lang?/partners/:name`,
    name: "partner-single",
    component: PartnerSingle,
    beforeEnter: (to, from, next) => {
      const lang = to.params.lang;
      if (Object.keys(locale.messages).includes(lang)) {
        locale.locale = lang;
        localStorage.setItem("language", lang.toLowerCase());
        next();
      } else {
        next("/en/partners/:name");
      }
    },
  },
  {
    path: `/:lang?/contact`,
    name: "contact",
    component: Contact,
    beforeEnter: (to, from, next) => {
      const lang = to.params.lang;
      if (Object.keys(locale.messages).includes(lang)) {
        locale.locale = lang;
        localStorage.setItem("language", lang.toLowerCase());
        next();
      } else {
        next("/en/contact");
      }
    },
  },
  {
    path: `/:lang?/legal/:name?`,
    name: "legal",
    component: Legal,
    beforeEnter: (to, from, next) => {
      const lang = to.params.lang;
      if (Object.keys(locale.messages).includes(lang)) {
        locale.locale = lang;
        localStorage.setItem("language", lang.toLowerCase());
        next();
      } else {
        next("/en/legal");
      }
    },
  },
];

export default web_router;
