<template>
  <headerTop />
  <cookiesModal />
  <getConsultation />
  <v-main id="main">
    <HomeParallax />
    <HomeRealEstate class="mt-200" />
    <HelpWithYou class="mt-100" />
    <AboutG class="mt-100" />
    <OurMission class="mt-200" />
    <realEstateWorld class="mt-200" />
  </v-main>
  <footerComponent />
  <Preloader :is-loading="isLoading" />
</template>

<script>
import { defineComponent } from "vue";
import { useLocale } from "vuetify";
import headerTop from "../components/header.vue";
import Preloader from "../components/Preloader.vue";
import cookiesModal from "../components/cookiesModal.vue";
import getConsultation from "../components/getConsultation.vue";
import HomeParallax from "../components/HomePage/1.Parallax.vue";
import HomeRealEstate from "../components/HomePage/2.RealEstate.vue";
import HelpWithYou from "../components/HomePage/3.HelpWithYou.vue";
import AboutG from "../components/HomePage/4.AboutG.vue";
import OurMission from "../components/HomePage/5.OurMission.vue";
import realEstateWorld from "../components/realEstateWorld.vue";
import footerComponent from "../components/footer.vue";

export default defineComponent({
  name: "HomePage",
  components: {
    headerTop,
    HomeParallax,
    Preloader,
    cookiesModal,
    getConsultation,
    HomeRealEstate,
    HelpWithYou,
    AboutG,
    OurMission,
    realEstateWorld,
    footerComponent,
  },
  setup() {
    const { t } = useLocale();
    return {
      t,
    };
  },
  data: () => ({
    isLoading: true,
  }),
  mounted() {
    let vue = this;
    if (document.readyState == "complete") {
      vue.isLoading = false;
    } else {
      window.addEventListener("load", () => {
        vue.isLoading = false;
      });
    }
  },
});
</script>

<style scoped>
.mt-200 {
  margin-top: 200px;
}

.mt-100 {
  margin-top: 100px;
}

@media screen and (max-width: 768px) {
  .mt-200 {
    margin-top: 50px;
  }
}
</style>