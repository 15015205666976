<template>
  <v-parallax src="../../assets/img/learn/bg.jpg" width="100%" :height="height_image_paralax" id="paralax-image-learn">
    <v-container class="fill-height px-sm-10 px-md-15 container-main" style="align-content: center"
      :fluid="$vuetify.display.mdAndDown ? true : false">

      <v-row class="row-line mh-90" justify="space-between">
        <v-col cols="auto" align-self="end">
          <v-expand-transition>
            <h1 class="title-big" v-show="textAnim.showTextTitle">
              {{ t("$vuetify.learn_page.Parallax.title") }}
            </h1>
          </v-expand-transition>
        </v-col>
        <v-col cols="auto" md="5" align-self="center">
          <v-fade-transition>
            <h6 class="text-h6 title-small2" v-show="textAnim.showText">
              {{ t("$vuetify.learn_page.Parallax.subtitle_right") }}
            </h6>
          </v-fade-transition>
        </v-col>
      </v-row>

      <v-divider class="divider-paralax my-4 my-sm-10 my-lg-13" color="info"></v-divider>

      <breadcrumbs name="learn" />
    </v-container>
  </v-parallax>
</template>

<script>
import { useLocale } from "vuetify";
import breadcrumbs from "../breadcrumbs.vue";

export default {
  name: "AboutParallax",
  props: {
    Product: Object,
    Categorie: Object,
  },
  components: {
    breadcrumbs
  },
  setup() {
    const { t } = useLocale();
    return {
      t,
    };
  },
  data: () => ({
    textAnim: {
      showTextTitle: false,
      showText: false,
    },
    isLoading: true
  }),
  mounted() {
    let vue = this;
    if (document.readyState == "complete") {
      setTimeout(() => {
        vue.textAnim.showTextTitle = true;
      }, 800);
      setTimeout(() => {
        vue.textAnim.showText = true;
      }, 1300);
    } else {
      document.addEventListener(
        "PreloaderFinish",
        () => {
          setTimeout(() => {
            vue.textAnim.showTextTitle = true;
          }, 800);
          setTimeout(() => {
            vue.textAnim.showText = true;
          }, 1300);
        },
        false
      );
    }
  },
  computed: {
    height_image_paralax() {
      switch (this.$vuetify.display.name) {
        case "xs":
          return "auto";
        case "sm":
          return "auto";
        case "md":
          return "100vh";
        case "lg":
          return "100vh";
        case "xl":
          return "75vh";
        case "xxl":
          return "75vh";
        default:
          return "100vh";
      }
    },
  },
};
</script>

<style scoped>
.mh-90 {
  min-height: 124px;
}

.container-main {
  padding-top: 20vh;
}

.row-line {
  width: 100%;
}

.title-small2 {
  color: #c4c4c4;
  font-weight: 400;
  letter-spacing: -0.035rem !important;
}

.title-small {
  color: #c4c4c4;
  font-weight: 400;
  letter-spacing: -0.04rem !important;
  text-shadow: 8px 13px 35px rgba(0, 0, 0, 0.5);
}

.title-big {
  letter-spacing: -0.24rem !important;
  color: rgba(246, 242, 230, 1);
  text-shadow: rgba(0, 0, 0, 0.87) 0px 4px 30px;
  font-size: 6rem;
  font-weight: 300;
  line-height: 6rem;
}

.divider-paralax {
  border-width: 1px;
  border-color: rgba(154, 154, 155, 0.36);
  opacity: 1;
}

@media screen and (max-width: 600px) {
  .title-big {
    letter-spacing: -0.02rem !important;
    font-size: 3rem;
    font-weight: 300;
    line-height: 2rem;
  }

  .title-small,
  .title-small2 {
    font-size: 0.85rem !important;
    line-height: 1.1rem !important;
  }

  .row-line {
    height: unset !important;
  }


}

@media screen and (min-width: 600px) and (max-width: 960px) {
  .title-big {
    zoom: 0.8;
  }

  .title-small,
  .title-small2 {
    zoom: 0.8;
  }
}
</style>
<style>
#paralax-image-learn>.v-responsive__content {
  background: linear-gradient(rgba(17, 18, 19, 0.27) 0%,
      rgba(17, 18, 19, 1) 97%);
  bottom: -1px;
  position: relative;
}
</style>