// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
//locale
import { locale } from "../locales/index.js";
// Vuetify
import { createVuetify } from "vuetify";

export default createVuetify(
  {
    locale,
  }
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
);
