<template>
  <PdfPresentation :pdf="card.pdf_path" ref="OpenPdfModal" />
  <v-card color="black" class="card-realEstate" @click="GoToProduct(card)">
    <div>
      <v-btn @click.stop="OpenPDF(card)" icon size="x-small" class="card-pdf-btn" flat>
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20Z"
            fill="#F6F2E6" />
          <path
            d="M17.1101 24.7537C17.2725 24.656 17.452 24.5615 17.6452 24.4708C17.4661 24.74 17.2898 24.9826 17.1205 25.1903C16.7942 25.5906 16.5398 25.8031 16.3802 25.8701C16.362 25.8777 16.3484 25.882 16.3387 25.8845C16.3312 25.8742 16.3207 25.8577 16.3081 25.8321C16.2433 25.7002 16.246 25.5751 16.3549 25.4032C16.4788 25.2078 16.7275 24.9837 17.1101 24.7537Z"
            fill="#111213" />
          <path
            d="M19.9742 22.7982C19.836 22.8268 19.6974 22.8576 19.559 22.8905C19.671 22.6668 19.7801 22.4404 19.8853 22.2136C19.9731 22.0244 20.0587 21.8338 20.1414 21.6432C20.2298 21.8098 20.3214 21.9745 20.4154 22.136C20.5191 22.3141 20.6268 22.49 20.7374 22.6619C20.4839 22.6999 20.2283 22.7456 19.9742 22.7982Z"
            fill="#111213" />
          <path
            d="M22.9197 23.9133C22.7578 23.7832 22.5873 23.6188 22.4129 23.4268C22.6785 23.4314 22.9194 23.4522 23.127 23.4902C23.4969 23.558 23.6708 23.6644 23.7313 23.7378C23.7522 23.7632 23.7598 23.784 23.7609 23.8139C23.7622 23.8515 23.7528 23.9272 23.6921 24.0508C23.6448 24.1472 23.6029 24.1844 23.5819 24.1984C23.5646 24.2099 23.543 24.2187 23.5017 24.217C23.3954 24.2126 23.2003 24.1388 22.9197 23.9133Z"
            fill="#111213" />
          <path
            d="M20.3249 18.2778C20.2785 18.5668 20.1987 18.8992 20.0915 19.261C20.0505 19.1196 20.0157 18.9824 19.9877 18.8503C19.8986 18.431 19.8859 18.102 19.9336 17.8747C19.9777 17.664 20.0611 17.5798 20.1626 17.5385C20.2497 17.503 20.3036 17.4934 20.3318 17.4911C20.3461 17.5271 20.3645 17.5999 20.3691 17.7261C20.3744 17.8702 20.3607 18.0546 20.3249 18.2778Z"
            fill="#111213" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M15.3333 10H21.5084C21.8178 10 22.1145 10.1251 22.3333 10.3478L26.6583 14.75C26.8771 14.9727 27 15.2747 27 15.5897V26.625C27 27.9367 25.9553 29 24.6667 29H15.3333C14.0447 29 13 27.9367 13 26.625V12.375C13 11.0633 14.0447 10 15.3333 10ZM21.75 11.7812V14.1562C21.75 14.8121 22.2723 15.3438 22.9167 15.3438H25.25L21.75 11.7812ZM15.5255 26.2305C15.6309 26.445 15.7941 26.6377 16.0369 26.7279C16.2782 26.8176 16.5171 26.7759 16.7135 26.6935C17.0849 26.5378 17.4548 26.1751 17.7939 25.759C18.182 25.2827 18.5909 24.6586 18.9852 23.9661C19.3611 23.8522 19.7536 23.7527 20.1485 23.671C20.5476 23.5884 20.9411 23.5257 21.3148 23.4844C21.6645 23.9385 22.0267 24.3303 22.3774 24.6122C22.7035 24.8744 23.08 25.0911 23.4666 25.1069C23.6716 25.1153 23.8767 25.0665 24.061 24.9437C24.2416 24.8234 24.3762 24.6497 24.4747 24.4492C24.5802 24.2343 24.6434 24.0092 24.6353 23.7815C24.6269 23.546 24.5429 23.3369 24.4018 23.1656C24.1383 22.8456 23.7064 22.6914 23.2819 22.6137C22.8384 22.5325 22.302 22.5173 21.7246 22.5545C21.5346 22.2829 21.3471 21.9892 21.1682 21.6819C20.9527 21.3119 20.7542 20.9299 20.5815 20.5526C20.8728 19.7688 21.0912 19.027 21.1884 18.4215C21.23 18.1623 21.2516 17.9153 21.2435 17.693C21.2356 17.476 21.1983 17.249 21.0954 17.0537C20.9837 16.8417 20.7973 16.6762 20.5392 16.62C20.3033 16.5687 20.0611 16.6206 19.8376 16.7116C19.3975 16.8908 19.1658 17.2689 19.0778 17.689C18.9933 18.0925 19.0314 18.563 19.1324 19.0385C19.2347 19.5198 19.4099 20.0449 19.6334 20.5762C19.4722 20.9865 19.2904 21.4102 19.0941 21.8333C18.8729 22.3102 18.6364 22.7794 18.3948 23.2208C17.751 23.4356 17.1518 23.6943 16.6652 23.9868C16.2329 24.2467 15.8504 24.5563 15.6196 24.9204C15.3739 25.3081 15.2986 25.7687 15.5255 26.2305Z"
            fill="#111213" />
        </svg>
      </v-btn>
      <v-img :src="urlAPI + card.main_image_path" class="card-image-realEstate" aspect-ratio="1.21" cover></v-img>
      <v-card-item>
        <v-card-text class="cards-title px-0 py-3">
          {{ truncateString(card['property_' + $vuetify.locale.current], 70) }}
        </v-card-text>

        <v-row class="ma-0 my-sm-1" style="flex-wrap: nowrap;">
          <v-card-text class="card-area pa-0" v-if="card.area">
            {{ card.area + " м²" }}
          </v-card-text>


          <v-card-text class="card-number_of_rooms pa-0" v-if="card.number_of_rooms">
            <svg width="19" height="14" class="mr-1" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.8333 2.54545H1.26667V0.636364C1.26667 0.467589 1.19994 0.305728 1.08117 0.186387C0.962395 0.0670454 0.801304 0 0.633333 0C0.465363 0 0.304272 0.0670454 0.185499 0.186387C0.066726 0.305728 0 0.467589 0 0.636364V13.3636C0 13.5324 0.066726 13.6943 0.185499 13.8136C0.304272 13.933 0.465363 14 0.633333 14C0.801304 14 0.962395 13.933 1.08117 13.8136C1.19994 13.6943 1.26667 13.5324 1.26667 13.3636V10.8182H17.7333V13.3636C17.7333 13.5324 17.8001 13.6943 17.9188 13.8136C18.0376 13.933 18.1987 14 18.3667 14C18.5346 14 18.6957 13.933 18.8145 13.8136C18.9333 13.6943 19 13.5324 19 13.3636V5.72727C19 4.8834 18.6664 4.07409 18.0725 3.47739C17.4786 2.88068 16.6732 2.54545 15.8333 2.54545ZM1.26667 3.81818H6.96667V9.54545H1.26667V3.81818Z"
                fill="#F6F2E6" />
            </svg>
            {{ card.number_of_rooms == 4 ? card.number_of_rooms + "+" : card.number_of_rooms }}
          </v-card-text>
        </v-row>

        <v-row class="ma-0 mb-sm-1" style="flex-wrap: nowrap;" v-if="get_price_type.length > 0">
          <v-card-text class="card-price pa-0" v-if="card.ID_price_type != 2">{{ '€ ' +
            card.price.toLocaleString('ru-RU')
            }} <br />
            <span v-if="card.ID_price_type == 1" style="text-wrap: nowrap;">
              {{ get_price_type.filter(p => p.ID == 1)[0]['name_' + $vuetify.locale.current] }}
            </span>
          </v-card-text>

          <v-card-text class="card-price pa-0" v-if="card.ID_price_type == 2">
            <span>
              {{ get_price_type.filter(p => p.ID == 2)[0]['name_' + $vuetify.locale.current] }}
            </span>
          </v-card-text>

          <v-card-text class="card-location pa-0">
            {{ card['location_' + $vuetify.locale.current] }}
          </v-card-text>
        </v-row>
      </v-card-item>
    </div>
    <div class="px-4 pb-3">
      <v-btn @click="GoToProduct(card)" size="small" variant="flat" color="#F6F2E6" block
        class="card-learn rounded-pill py-4">
        {{ t("$vuetify.home.real_estate.btn_cards_action") }}
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import { useLocale } from "vuetify";
import { mapGetters } from 'vuex'
import PdfPresentation from "./Modals/PdfPresentation.vue";
import { urlAPI } from "../store/config";

export default {
  name: "CardRealEstate",
  setup() {
    const { t } = useLocale();
    return {
      t,
    };
  },
  data: () => ({
    urlAPI: urlAPI + '/',
  }),
  props: {
    card: Object,
  },
  components: {
    PdfPresentation
  },
  mounted() {
    this.$store.dispatch("Load_price_type");
  },
  computed: {
    ...mapGetters([
      'get_price_type',
    ]),
  },
  methods: {
    truncateString(str, num) {
      if (!str) return;
      if (str.length > num) {
        return str.slice(0, num) + "...";
      } else {
        return str;
      }
    },
    GoToProduct(card) {
      this.$router.push(
        {
          name: 'property',
          params: {
            lang: this.$route.params.lang,
            propertyID: card['link_' + this.$vuetify.locale.current],
          }
        }
      );
    },
    OpenPDF(card) {
      this.$refs.OpenPdfModal.dialog = true;
      this.$refs.OpenPdfModal.property = card;
    },
  },
};
</script>
<style scoped>
.card-number_of_rooms {
  text-wrap: nowrap;
  color: #F6F2E6;
  font-weight: 500;
  letter-spacing: -0.2px;
  font-size: 1rem;
  text-align: end;
}

.card-area {
  text-wrap: nowrap;
  color: #F6F2E6;
  font-weight: 500;
  letter-spacing: -0.2px;
  font-size: 1rem;
}

.card-pdf-btn {
  position: absolute;
  z-index: 2;
  top: 18px;
  right: 19px;
  width: 40px;
  height: 40px;
}

.cards-title {
  color: #f6f2e6;
  font-size: 1.15rem;
  overflow: hidden;
  min-height: 96px;
  line-height: 24px;
}

.card-learn {
  text-transform: inherit;
  color: #111213;
  font-weight: 400;
  font-size: 14px;
}

.card-price {
  /* min-height: 38px; */
  font-size: 1rem;
  text-wrap: nowrap;
  color: #9a9a9b;
  font-weight: 400;
  letter-spacing: -0.2px;
}

.card-location {
  text-wrap: balance;
  color: #9a9a9b;
  font-weight: 400;
  letter-spacing: -0.2px;
  font-size: 1rem;
  text-align: end;
}

.card-realEstate {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 40px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .cards-title {
    font-size: 12px;
    min-height: 80px;
    line-height: 16px;
  }

  .card-price {
    font-size: 11px;
    min-height: 25px;
  }

  .card-location {
    font-size: 11px;
  }

  .card-learn {
    zoom: 0.8;
  }
}

@media screen and (min-width: 600px) and (max-width: 960px) {
  .cards-title {
    font-size: 20px;
    min-height: 95px;
    line-height: 28px;
  }

  .card-price {
    font-size: 18px;
  }

  .card-location {
    font-size: 18px;
  }

  .card-learn {
    zoom: 1.1;
  }
}
</style>

<style>
.card-realEstate:hover .card-image-realEstate {
  filter: brightness(1.5);
}

.card-image-realEstate {
  transition: 0.5s;
}

.card-image-realEstate .v-responsive__sizer {
  background: linear-gradient(rgba(0, 0, 0, 0.62), rgba(0, 0, 0, 0.434));
}
</style>