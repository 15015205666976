<template>
  <v-container :fluid="$vuetify.display.mdAndDown ? true : false" style="position: relative">
    <v-row justify="center">
      <v-col cols="12" sm="11" md="11">
        <v-row justify="space-between">
          <v-col cols="8">
            <h2 class="block-title-text">
              {{ t("$vuetify.about_page.FAQs.title") }}
            </h2>
          </v-col>
          <v-col cols="2" align-self="center">
            <v-row justify="end" class="ma-0">
              <svg :width="$vuetify.display.smAndUp ? '46' : '30'" viewBox="0 0 46 46" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M20 0.505399L24 0.498276L24.0801 45.4982L20.0801 45.5053L20 0.505399Z" fill="#F6F2E6" />
                <path d="M45.0373 21.4608L45.0444 25.4608L0.0445209 25.541L0.0373977 21.541L45.0373 21.4608Z"
                  fill="#F6F2E6" />
                <path d="M36.8047 5.45155L39.5765 8.21356L7.81343 40.09L5.04157 37.328L36.8047 5.45155Z"
                  fill="#F6F2E6" />
                <path d="M39.6291 37.7273L36.8671 40.4992L4.99065 8.73611L7.75266 5.96425L39.6291 37.7273Z"
                  fill="#F6F2E6" />
              </svg>
            </v-row>
          </v-col>
        </v-row>
        <v-row justify="center" class="mt-14">
          <v-expansion-panels id="faq-expansion-panel" v-model="panel" multiple>
            <v-expansion-panel v-for="(item, i) in FAQs" :key="i" :value="i">
              <v-expansion-panel-title collapse-icon="mdi-close" expand-icon="mdi-plus" class="faq-title pa-10">
                {{ t(`$vuetify.about_page.FAQs.items.${item.title}`) }}
              </v-expansion-panel-title>
              <v-expansion-panel-text class="faq-text pa-4">
                {{ t(`$vuetify.about_page.FAQs.items.${item.description}`) }}
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useLocale } from "vuetify";

export default {
  name: "OurServices",
  setup() {
    const { t } = useLocale();
    return {
      t,
    };
  },
  data: () => ({
    FAQs: [
      {
        title: "item1.title",
        description: "item1.description",
      },
      {
        title: "item2.title",
        description: "item2.description",
      },
      {
        title: "item3.title",
        description: "item3.description",
      },
      {
        title: "item4.title",
        description: "item4.description",
      },
      {
        title: "item5.title",
        description: "item5.description",
      },
    ],
    panel: [0, 1]
  }),
  mounted() {
  },
  methods: {
  }
};
</script>

<style scoped>
.faq-text {
  color: rgba(154, 154, 155, 1);
  font-size: 1.2rem;
}

.block-title-text {
  color: rgba(154, 154, 155, 1);
  font-size: 3.5rem;
  font-weight: 400;
}

.faq-title {
  color: rgba(246, 242, 230, 1);
  font-size: 1.3rem;
  line-height: 1.8rem;
}

@media screen and (max-width: 600px) {
  .block-title-text {
    font-size: 2.2rem;
  }
}

@media screen and (max-width: 600px) and (max-width: 960px) {}
</style>
<style>
#faq-expansion-panel .v-expansion-panel {
  background: rgba(7, 10, 12, 1);
  margin-bottom: 14px;
  border: 1px solid rgba(38, 38, 43, 1);
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}

#faq-expansion-panel .v-expansion-panel-title:hover .v-expansion-panel-title__overlay {
  background-color: unset !important;
}
</style>
