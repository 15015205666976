import { axiosInstance } from "../config";

export default {
  state: {
    Admin: {},
    AllAdmins: [],
  },
  mutations: {
    SetAdmin(state, payload) {
      state.Admin = payload;
    },
    SetAllAdmins(state, payload) {
      state.AllAdmins = payload;
    },
  },
  actions: {
    async LoadAllAdmins({ commit }) {
      return await axiosInstance
        .get("/admin")
        .then(function (data) {
          commit("SetAllAdmins", data?.data?.data);
          return data?.data?.data;
        })
        .catch(function (error) {
          return {
            error: error.response.data.message,
          };
        });
    },
    async LoadAdmin({ commit }, payload) {
      return await axiosInstance
        .get(`/admin/${payload}`)
        .then(function (data) {
          commit("SetAdmin", data?.data?.data);
          return data?.data?.data;
        })
        .catch(function (error) {
          return {
            error: error.response.data.message,
          };
        });
    },
    LoadAdminEmpty({ commit }) {
      commit("SetAdmin", {});
    },
    async CreateAdmin(commit, payload) {
      const formData = new FormData();
      for (const key in payload) {
        if (
          Object.prototype.hasOwnProperty.call(payload, key) &&
          payload[key] !== undefined
        ) {
          formData.append(key, payload[key]);
        }
      }
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      return await axiosInstance
        .post("/admin/register", formData, config)
        .then(function (data) {
          return data?.data;
        })
        .catch(function (error) {
          return {
            error: error.response.data.message,
          };
        });
    },
    async UpdateAdmin(commit, payload) {
      const formData = new FormData();
      formData.append("_method", "PUT");
      for (const key in payload.data) {
        if (
          Object.prototype.hasOwnProperty.call(payload.data, key) &&
          payload.data[key] !== undefined
        ) {
          formData.append(key, payload.data[key]);
        }
      }
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      return await axiosInstance
        .post("/admin/" + payload.ID, formData, config)
        .then(function (data) {
          return data?.data;
        })
        .catch(function (error) {
          return {
            error: error.response.data.message,
          };
        });
    },
    async AdminDeleteById(commit, payload) {
      return await axiosInstance
        .delete("/admin/" + payload)
        .then(function (data) {
          return data?.data;
        })
        .catch(function (error) {
          return {
            error: error.response.data.message,
          };
        });
    },
  },
  getters: {
    getAdmin: (state) => state.Admin,
    getAllAdmins: (state) => state.AllAdmins,
  },
};
