<template>
    <v-container fluid class="pt-10 fill-height" v-if="getPriority <= 2">
        <v-row class="w-100" justify="center">
            <v-col cols="12">
                <h1>Настройки Фильтров</h1>
            </v-col>
        </v-row>
        <v-row justify="space-between" class="w-100" v-if="getPriority == 1">
            <v-col cols="auto">
                <v-btn color="#0255B7" rounded="lg" class="btn-text" @click="GoToLogs()">
                    Логи (история действий)
                </v-btn>
                <v-btn color="#0255B7" rounded="lg" class="btn-text ml-3" @click="GoToSettings()">
                    Список админов
                </v-btn>
            </v-col>
        </v-row>
        <v-row class="my-4 mx-0 w-100" justify="center">
            <v-row class="my-4 mx-0 w-100" justify="center">
                <v-table height="80vh" fixed-header class="table" hover>
                    <thead>
                        <tr>
                            <th class="text-left">
                                Название фильтра
                            </th>
                            <th class="text-left">
                                Переменные фильтра
                            </th>
                            <th class="text-left">
                                Действия
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="get_building_type">
                            <td>Тип объекта</td>
                            <td>{{ get_building_type.map(v => v.name_ru) }}</td>
                            <td style="text-wrap: nowrap;">
                                <v-col cols="12" style="flex-direction: column; display: flex;">
                                    <v-btn variant="text"
                                        @click="FilterEditOpenDialog(get_building_type, 'Тип объекта', 'building_type')"
                                        class="btn-text-2">
                                        <template v-slot:prepend>
                                            <v-icon>
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M17.37 6.18658C17.5433 6.35987 17.5433 6.64084 17.37 6.81413L16.4445 7.73968L14.6695 5.9647L15.595 5.03915C15.7683 4.86586 16.0493 4.86586 16.2226 5.03915L17.37 6.18658Z"
                                                        fill="#408ACB" />
                                                    <path
                                                        d="M15.8169 8.36723L14.042 6.59225L7.99548 12.6387C7.94677 12.6874 7.91007 12.7468 7.88828 12.8122L7.17424 14.9543C7.11642 15.1277 7.28144 15.2928 7.45489 15.2349L9.59701 14.5209C9.66236 14.4991 9.72175 14.4624 9.77046 14.4137L15.8169 8.36723Z"
                                                        fill="#408ACB" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M4.5 16.4466C4.5 17.1818 5.09601 17.7778 5.83124 17.7778H15.5936C16.3289 17.7778 16.9249 17.1818 16.9249 16.4466V11.1216C16.9249 10.8765 16.7262 10.6779 16.4811 10.6779C16.2361 10.6779 16.0374 10.8765 16.0374 11.1216V16.4466C16.0374 16.6916 15.8387 16.8903 15.5936 16.8903H5.83124C5.58616 16.8903 5.38749 16.6916 5.38749 16.4466V6.68416C5.38749 6.43909 5.58616 6.24042 5.83124 6.24042H11.5999C11.845 6.24042 12.0437 6.04174 12.0437 5.79667C12.0437 5.5516 11.845 5.35293 11.5999 5.35293H5.83124C5.09601 5.35293 4.5 5.94894 4.5 6.68416V16.4466Z"
                                                        fill="#408ACB" />
                                                </svg>
                                            </v-icon>
                                        </template>
                                        Редактировать
                                    </v-btn>
                                </v-col>
                            </td>
                        </tr>
                        <tr v-if="get_house_condition_type">
                            <td>Состояние объекта</td>
                            <td>{{ get_house_condition_type.map(v => v.name_ru) }}</td>
                            <td style="text-wrap: nowrap;">
                                <v-col cols="12" style="flex-direction: column; display: flex;">
                                    <v-btn variant="text"
                                        @click="FilterEditOpenDialog(get_house_condition_type, 'Состояние объекта', 'house_condition_type')"
                                        class="btn-text-2">
                                        <template v-slot:prepend>
                                            <v-icon>
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M17.37 6.18658C17.5433 6.35987 17.5433 6.64084 17.37 6.81413L16.4445 7.73968L14.6695 5.9647L15.595 5.03915C15.7683 4.86586 16.0493 4.86586 16.2226 5.03915L17.37 6.18658Z"
                                                        fill="#408ACB" />
                                                    <path
                                                        d="M15.8169 8.36723L14.042 6.59225L7.99548 12.6387C7.94677 12.6874 7.91007 12.7468 7.88828 12.8122L7.17424 14.9543C7.11642 15.1277 7.28144 15.2928 7.45489 15.2349L9.59701 14.5209C9.66236 14.4991 9.72175 14.4624 9.77046 14.4137L15.8169 8.36723Z"
                                                        fill="#408ACB" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M4.5 16.4466C4.5 17.1818 5.09601 17.7778 5.83124 17.7778H15.5936C16.3289 17.7778 16.9249 17.1818 16.9249 16.4466V11.1216C16.9249 10.8765 16.7262 10.6779 16.4811 10.6779C16.2361 10.6779 16.0374 10.8765 16.0374 11.1216V16.4466C16.0374 16.6916 15.8387 16.8903 15.5936 16.8903H5.83124C5.58616 16.8903 5.38749 16.6916 5.38749 16.4466V6.68416C5.38749 6.43909 5.58616 6.24042 5.83124 6.24042H11.5999C11.845 6.24042 12.0437 6.04174 12.0437 5.79667C12.0437 5.5516 11.845 5.35293 11.5999 5.35293H5.83124C5.09601 5.35293 4.5 5.94894 4.5 6.68416V16.4466Z"
                                                        fill="#408ACB" />
                                                </svg>
                                            </v-icon>
                                        </template>
                                        Редактировать
                                    </v-btn>
                                </v-col>
                            </td>
                        </tr>
                        <tr v-if="get_building_purpose_type">
                            <td>Предложение</td>
                            <td>{{ get_building_purpose_type.map(v => v.name_ru) }}</td>
                            <td style="text-wrap: nowrap;">
                                <v-col cols="12" style="flex-direction: column; display: flex;">
                                    <v-btn variant="text"
                                        @click="FilterEditOpenDialog(get_building_purpose_type, 'Предложение', 'building_purpose_type')"
                                        class="btn-text-2">
                                        <template v-slot:prepend>
                                            <v-icon>
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M17.37 6.18658C17.5433 6.35987 17.5433 6.64084 17.37 6.81413L16.4445 7.73968L14.6695 5.9647L15.595 5.03915C15.7683 4.86586 16.0493 4.86586 16.2226 5.03915L17.37 6.18658Z"
                                                        fill="#408ACB" />
                                                    <path
                                                        d="M15.8169 8.36723L14.042 6.59225L7.99548 12.6387C7.94677 12.6874 7.91007 12.7468 7.88828 12.8122L7.17424 14.9543C7.11642 15.1277 7.28144 15.2928 7.45489 15.2349L9.59701 14.5209C9.66236 14.4991 9.72175 14.4624 9.77046 14.4137L15.8169 8.36723Z"
                                                        fill="#408ACB" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M4.5 16.4466C4.5 17.1818 5.09601 17.7778 5.83124 17.7778H15.5936C16.3289 17.7778 16.9249 17.1818 16.9249 16.4466V11.1216C16.9249 10.8765 16.7262 10.6779 16.4811 10.6779C16.2361 10.6779 16.0374 10.8765 16.0374 11.1216V16.4466C16.0374 16.6916 15.8387 16.8903 15.5936 16.8903H5.83124C5.58616 16.8903 5.38749 16.6916 5.38749 16.4466V6.68416C5.38749 6.43909 5.58616 6.24042 5.83124 6.24042H11.5999C11.845 6.24042 12.0437 6.04174 12.0437 5.79667C12.0437 5.5516 11.845 5.35293 11.5999 5.35293H5.83124C5.09601 5.35293 4.5 5.94894 4.5 6.68416V16.4466Z"
                                                        fill="#408ACB" />
                                                </svg>
                                            </v-icon>
                                        </template>
                                        Редактировать
                                    </v-btn>
                                </v-col>
                            </td>
                        </tr>
                        <tr v-if="get_land_type">
                            <td>Коммерческое назначение</td>
                            <td>{{ get_land_type.map(v => v.name_ru) }}</td>
                            <td style="text-wrap: nowrap;">
                                <v-col cols="12" style="flex-direction: column; display: flex;">
                                    <v-btn variant="text"
                                        @click="FilterEditOpenDialog(get_land_type, 'Коммерческое назначение', 'land_type')"
                                        class="btn-text-2">
                                        <template v-slot:prepend>
                                            <v-icon>
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M17.37 6.18658C17.5433 6.35987 17.5433 6.64084 17.37 6.81413L16.4445 7.73968L14.6695 5.9647L15.595 5.03915C15.7683 4.86586 16.0493 4.86586 16.2226 5.03915L17.37 6.18658Z"
                                                        fill="#408ACB" />
                                                    <path
                                                        d="M15.8169 8.36723L14.042 6.59225L7.99548 12.6387C7.94677 12.6874 7.91007 12.7468 7.88828 12.8122L7.17424 14.9543C7.11642 15.1277 7.28144 15.2928 7.45489 15.2349L9.59701 14.5209C9.66236 14.4991 9.72175 14.4624 9.77046 14.4137L15.8169 8.36723Z"
                                                        fill="#408ACB" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M4.5 16.4466C4.5 17.1818 5.09601 17.7778 5.83124 17.7778H15.5936C16.3289 17.7778 16.9249 17.1818 16.9249 16.4466V11.1216C16.9249 10.8765 16.7262 10.6779 16.4811 10.6779C16.2361 10.6779 16.0374 10.8765 16.0374 11.1216V16.4466C16.0374 16.6916 15.8387 16.8903 15.5936 16.8903H5.83124C5.58616 16.8903 5.38749 16.6916 5.38749 16.4466V6.68416C5.38749 6.43909 5.58616 6.24042 5.83124 6.24042H11.5999C11.845 6.24042 12.0437 6.04174 12.0437 5.79667C12.0437 5.5516 11.845 5.35293 11.5999 5.35293H5.83124C5.09601 5.35293 4.5 5.94894 4.5 6.68416V16.4466Z"
                                                        fill="#408ACB" />
                                                </svg>
                                            </v-icon>
                                        </template>
                                        Редактировать
                                    </v-btn>
                                </v-col>
                            </td>
                        </tr>
                        <tr v-if="get_industrial_purpose_type">
                            <td>Индустриальное назначение</td>
                            <td>{{ get_industrial_purpose_type.map(v => v.name_ru) }}</td>
                            <td style="text-wrap: nowrap;">
                                <v-col cols="12" style="flex-direction: column; display: flex;">
                                    <v-btn variant="text"
                                        @click="FilterEditOpenDialog(get_industrial_purpose_type, 'Индустриальное назначение', 'industrial_purpose_type')"
                                        class="btn-text-2">
                                        <template v-slot:prepend>
                                            <v-icon>
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M17.37 6.18658C17.5433 6.35987 17.5433 6.64084 17.37 6.81413L16.4445 7.73968L14.6695 5.9647L15.595 5.03915C15.7683 4.86586 16.0493 4.86586 16.2226 5.03915L17.37 6.18658Z"
                                                        fill="#408ACB" />
                                                    <path
                                                        d="M15.8169 8.36723L14.042 6.59225L7.99548 12.6387C7.94677 12.6874 7.91007 12.7468 7.88828 12.8122L7.17424 14.9543C7.11642 15.1277 7.28144 15.2928 7.45489 15.2349L9.59701 14.5209C9.66236 14.4991 9.72175 14.4624 9.77046 14.4137L15.8169 8.36723Z"
                                                        fill="#408ACB" />
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M4.5 16.4466C4.5 17.1818 5.09601 17.7778 5.83124 17.7778H15.5936C16.3289 17.7778 16.9249 17.1818 16.9249 16.4466V11.1216C16.9249 10.8765 16.7262 10.6779 16.4811 10.6779C16.2361 10.6779 16.0374 10.8765 16.0374 11.1216V16.4466C16.0374 16.6916 15.8387 16.8903 15.5936 16.8903H5.83124C5.58616 16.8903 5.38749 16.6916 5.38749 16.4466V6.68416C5.38749 6.43909 5.58616 6.24042 5.83124 6.24042H11.5999C11.845 6.24042 12.0437 6.04174 12.0437 5.79667C12.0437 5.5516 11.845 5.35293 11.5999 5.35293H5.83124C5.09601 5.35293 4.5 5.94894 4.5 6.68416V16.4466Z"
                                                        fill="#408ACB" />
                                                </svg>
                                            </v-icon>
                                        </template>
                                        Редактировать
                                    </v-btn>
                                </v-col>
                            </td>
                        </tr>
                    </tbody>
                </v-table>
            </v-row>
        </v-row>
    </v-container>
    <v-dialog v-model="dialogEditFilter.state" max-width="900">
        <v-card class="pa-6 pb-md-10 text-center" rounded="lg">
            <v-row justify="end">
                <v-col cols="6" sm="4">
                    <v-btn color="#6C757D" flat variant="text" text="Закрыть" prepend-icon="mdi-close"
                        @click="dialogEditFilter.state = false"></v-btn>
                </v-col>
            </v-row>
            <h2 class="mb-6">Изменить Фильтр "<b>{{ dialogEditFilter.title }}</b>"</h2>
            <v-form v-model="valid2" ref="formEditLead">


                <v-table height="400px" fixed-header class="table" hover>
                    <thead>
                        <tr>
                            <th class="text-center">
                                ID
                            </th>
                            <th class="text-center">
                                Name En
                            </th>
                            <th class="text-center">
                                Name Ro
                            </th>
                            <th class="text-center">
                                Name Ru
                            </th>
                            <th class="text-center">
                                Действия
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in dialogEditFilter.filter" :key="item.ID">
                            <td>{{ item.ID }}</td>
                            <td>
                                <v-text-field v-model="item.name_en" variant="outlined" density="compact" hide-details
                                    @change="item.changed_en = true">
                                </v-text-field>
                            </td>
                            <td>
                                <v-text-field v-model="item.name_ro" variant="outlined" density="compact" hide-details
                                    @change="item.changed_ro = true">
                                </v-text-field>
                            </td>
                            <td>
                                <v-text-field v-model="item.name_ru" variant="outlined" density="compact" hide-details
                                    @change="item.changed_ru = true">
                                </v-text-field>
                            </td>
                            <td style="text-wrap: nowrap;">
                                <v-col cols="auto">
                                    <v-icon @click="RemoveFilterItem(item, index)">
                                        <svg width="13" height="16" viewBox="0 0 13 16" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M1 1.78884C0.447715 1.78884 0 2.21213 0 2.73429V3.67975C0 4.20191 0.447715 4.6252 1 4.6252H1.5V13.1343C1.5 14.1786 2.39543 15.0252 3.5 15.0252H9.5C10.6046 15.0252 11.5 14.1786 11.5 13.1343V4.6252H12C12.5523 4.6252 13 4.20191 13 3.67975V2.73429C13 2.21213 12.5523 1.78884 12 1.78884H8.5C8.5 1.26668 8.05229 0.843384 7.5 0.843384H5.5C4.94772 0.843384 4.5 1.26668 4.5 1.78884H1ZM4 5.57066C4.27614 5.57066 4.5 5.7823 4.5 6.04338V12.6616C4.5 12.9226 4.27614 13.1343 4 13.1343C3.72386 13.1343 3.5 12.9226 3.5 12.6616L3.5 6.04338C3.5 5.7823 3.72386 5.57066 4 5.57066ZM6.5 5.57066C6.77614 5.57066 7 5.7823 7 6.04338V12.6616C7 12.9226 6.77614 13.1343 6.5 13.1343C6.22386 13.1343 6 12.9226 6 12.6616V6.04338C6 5.7823 6.22386 5.57066 6.5 5.57066ZM9.5 6.04338V12.6616C9.5 12.9226 9.27614 13.1343 9 13.1343C8.72386 13.1343 8.5 12.9226 8.5 12.6616V6.04338C8.5 5.7823 8.72386 5.57066 9 5.57066C9.27614 5.57066 9.5 5.7823 9.5 6.04338Z"
                                                fill="#E05757" />
                                        </svg>
                                    </v-icon>
                                </v-col>
                            </td>
                        </tr>
                        <tr>
                            <td>Новый</td>
                            <td>
                                <v-text-field v-model="new_name_en" variant="outlined" density="compact" hide-details
                                    label="Name En">
                                </v-text-field>
                            </td>
                            <td>
                                <v-text-field v-model="new_name_ro" variant="outlined" density="compact" hide-details
                                    label="Name Ro">
                                </v-text-field>
                            </td>
                            <td>
                                <v-text-field v-model="new_name_ru" variant="outlined" density="compact" hide-details
                                    label="Name Ru">
                                </v-text-field>
                            </td>
                            <td>
                                <v-btn class="btn-text" color="#0255B7" variant="flat"
                                    :disabled="!new_name_ru || !new_name_ro || !new_name_en"
                                    @click="AddFilter()">Сохранить</v-btn>
                            </td>
                        </tr>
                    </tbody>
                </v-table>

            </v-form>

            <v-row class="mt-3">
                <v-btn color="#C4C4C4" class="mb-3 btn-text" variant="flat" flat rounded="lg" block
                    text="Отменить редактирование" @click="dialogEditFilter.state = false"></v-btn>
            </v-row>
            <v-row>
                <v-btn color="#0255B7" size="large" flat block variant="flat" rounded="lg" text="Сохранить"
                    class="btn-text"
                    @click="SaveEditFilter(dialogEditFilter.filter, dialogEditFilter.type, dialogEditFilter.deleted)"></v-btn>
            </v-row>
        </v-card>
    </v-dialog>
    <v-snackbar :timeout="2000" location="top right" :color="alert_snackbar.color" variant="flat"
        v-model="alert_snackbar.state">
        <strong>{{ alert_snackbar.text }}</strong>
    </v-snackbar>
</template>
<script>
import { defineComponent } from "vue";
import { useLocale } from "vuetify";
import { mapGetters } from 'vuex'
import { urlAPI } from "../../store/config";

export default defineComponent({
    name: "SettingsFilterCRM",
    setup() {
        const { t } = useLocale();
        return {
            t,
        };
    },
    data: () => ({
        urlAPI: urlAPI + '/',
        dialogEditFilter: {
            state: false,
            filter: [],
            title: "",
            type: "",
            deleted: [],
        },
        new_name_en: "",
        new_name_ro: "",
        new_name_ru: "",
        alert_snackbar: {
            state: false,
            color: "",
            text: ""
        },
    }),
    computed: {
        ...mapGetters([
            'get_house_condition_type',
            'get_price_type',
            'get_building_purpose_type',
            'get_building_type',
            'get_industrial_purpose_type',
            'get_land_type',
            'get_location',
            'get_property_developer',
            'getPriority'
        ])
    },
    methods: {
        GoToLogs() {
            this.$router.push({
                name: "logs-crm",
            })
        },
        GoToSettings() {
            this.$router.push({
                name: "admin-settings-crm",
            })
        },
        RemoveFilterItem(item, index) {
            this.dialogEditFilter.filter.splice(index, 1);
            if (item.ID) {
                this.dialogEditFilter.deleted.push(item.ID);
            }
        },
        AddFilter() {
            this.dialogEditFilter.filter.push({
                ID: null,
                name_en: this.new_name_en,
                name_ro: this.new_name_ro,
                name_ru: this.new_name_ru,
                type: "added",
            });
            this.new_name_en = "";
            this.new_name_ro = "";
            this.new_name_ru = "";
        },
        FilterEditOpenDialog(filter, title, type) {
            this.dialogEditFilter.state = true;
            this.dialogEditFilter.filter = JSON.parse(JSON.stringify(filter));
            this.dialogEditFilter.title = title;
            this.dialogEditFilter.type = type;
            this.dialogEditFilter.deleted = [];
            this.new_name_en = "";
            this.new_name_ro = "";
            this.new_name_ru = "";
        },
        async SaveEditFilter(filter, type, deleted) {
            try {
                let added = filter.filter(f => f.type == "added");
                for (const item_d of deleted) {
                    await this.$store.dispatch('FilterDelete', { ID: item_d, type: type });
                }
                for (const item_a of added) {
                    let data = {
                        name_en: item_a.name_en,
                        name_ro: item_a.name_ro,
                        name_ru: item_a.name_ru,
                    }
                    await this.$store.dispatch('FilterAdd', { type: type, data: data });
                }
                let old_filters = filter.filter(f => f.type != "added");
                for (const item of old_filters) {
                    let data = {
                        name_en: item.name_en,
                        name_ro: item.name_ro,
                        name_ru: item.name_ru,
                    }
                    if (item.changed_en || item.changed_ro || item.changed_ru) {
                        await this.$store.dispatch('FilterEdit', { type: type, ID: item.ID, data: data });
                    }
                }

                this.alert_snackbar.state = true;
                this.alert_snackbar.text = "Фильтр был сохранён";
                this.alert_snackbar.color = "success";
                this.dialogEditFilter.state = false;
                this.dialogEditFilter.filter = [];
                this.dialogEditFilter.title = "";
                this.dialogEditFilter.type = "";
                this.dialogEditFilter.deleted = [];
                this.new_name_en = "";
                this.new_name_ro = "";
                this.new_name_ru = "";
            } catch (error) {
                this.alert_snackbar.state = true;
                this.alert_snackbar.text = error;
                this.alert_snackbar.color = "error";
            }
        },
    },
    mounted() {
        this.$store.dispatch("Load_building_type");
        this.$store.dispatch("Load_building_purpose_type");
        this.$store.dispatch("Load_house_condition_type");
        this.$store.dispatch("Load_price_type");
        this.$store.dispatch("Load_land_type");
        this.$store.dispatch("Load_industrial_purpose_type");
        this.$store.dispatch("Load_location");
    },
    updated() {
        this.$store.dispatch("Load_building_type");
        this.$store.dispatch("Load_building_purpose_type");
        this.$store.dispatch("Load_house_condition_type");
        this.$store.dispatch("Load_price_type");
        this.$store.dispatch("Load_land_type");
        this.$store.dispatch("Load_industrial_purpose_type");
        this.$store.dispatch("Load_location");
    }
})
</script>
<style scoped>
.title_property_big {
    font-weight: 500;
    font-size: 1.4rem;
}

.title_property {
    font-weight: 500;
    font-size: 1.1rem;
}

.divider-bottom {
    border: 1px solid rgba(0, 0, 0, 0.2);
    opacity: 1;
}

.btn-text-2 {
    text-transform: inherit;
    letter-spacing: 0px;
    text-align: left;
}

.btn-text {
    text-transform: inherit;
}

.table {
    border: 1px solid rgba(0, 0, 0, 0.12);
    width: 100%;
    border-radius: 10px;
}

.input-search {
    min-width: 300px;
}

.select-search {
    min-width: 200px;
    max-width: 400px;
}

.col-header-gap {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 10px;
}

.w-100 {
    min-width: 100%;
}

@media screen and (max-width: 1400px) {}
</style>
