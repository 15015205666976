<template>
    <v-main id="sign_in_page">
        <v-container class="fill-height">
            <v-row justify="center">
                <v-col cols="11" sm="9" md="7" lg="5">
                    <v-card class="px-12 py-8 card_main" color="#FEFEFE">
                        <v-row justify="center">
                            <v-col cols="4">
                                <v-img width="100%" alt="logo_auth" src="../../assets/img/crm/logo_auth.png"></v-img>
                            </v-col>
                        </v-row>
                        <v-form v-model="form" @submit.prevent="onSubmit" class="mt-8">
                            <v-text-field v-model="username" density="compact" :readonly="loading"
                                :rules="[required, minValue]" variant="outlined" label="Логин" clearable
                                rounded="lg"></v-text-field>
                            <v-text-field v-model="password" type="password" density="compact" :readonly="loading"
                                :rules="[required, minValue]" variant="outlined" label="Пароль" clearable class="mt-4"
                                rounded="lg"></v-text-field>
                            <v-alert border="top" type="warning" variant="outlined" prominent v-show="error">
                                {{ error }}
                            </v-alert>
                            <v-btn class="mt-2 mb-4 btn-login" color="#111213" type="submit" block :disabled="!form"
                                :loading="loading">Log
                                in</v-btn>
                        </v-form>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-main>
</template>
<script>
import { defineComponent } from "vue";
import { useLocale } from "vuetify";

export default defineComponent({
    name: "CRM_SignIn",
    setup() {
        const { t } = useLocale();
        return {
            t,
        };
    },
    data: () => ({
        username: null,
        password: null,
        form: false,
        loading: false,
        error: "",
    }),
    methods: {
        async onSubmit() {
            if (!this.form) return
            this.loading = true;

            let data = {
                username: this.username,
                password: this.password
            }

            let responce = await this.$store.dispatch("SignIn", data);
            if (responce.success == true) {
                this.error = "";
                window.location.reload();
                this.$router.push({
                    name: "dashboard",
                })
            } else {
                this.error = Object.values(responce).join(",   ");
            }
            this.loading = false

        },
        required(v) {
            return !!v || 'Это поле обьязательное'
        },
        minValue(v) {
            return v.length > 4 || 'Поле должно иметь минимум 5 символов'
        },
    }
})
</script>
<style scoped>
.btn-login {
    background-color: #111213;
    text-transform: inherit;
    color: #F2F2F2;
    border-radius: 10px;
}

.card_main {
    border: 1px solid #D8D8D8;
    box-shadow: 0px 9px 35px rgba(0, 0, 0, 0.13);
    border-radius: 26px;
}

#sign_in_page {
    background-color: #F4F4F4;
}
</style>