<template>
  <v-container :fluid="$vuetify.display.mdAndDown ? true : false" style="position: relative">
    <v-row justify="center">
      <v-col cols="11" md="11" lg="10">
        <v-row class="">
          <v-row justify="center" justify-md="space-between">
            <v-col cols="12" sm="11" md="7" lg="6" align-self="center">
              <p class="text-top">
                {{ t("$vuetify.learn_page.our_mission.text_subtitle") }}
              </p>
              <h2 class="title-text">
                {{ t("$vuetify.learn_page.our_mission.text_title") }}
              </h2>
              <h5 class="content-text mt-4 mt-md-12">
                <b>
                  {{ t("$vuetify.learn_page.our_mission.text_content_part_1_bold") }}
                </b>
              </h5>
              <p class="content-text my-6">
                {{ t("$vuetify.learn_page.our_mission.text_content_part_2") }}
              </p>
              <h5 class="content-text">
                <b>
                  {{ t("$vuetify.learn_page.our_mission.text_content_part_3_bold") }}
                </b>
              </h5>
              <p class="content-text my-6">
                {{ t("$vuetify.learn_page.our_mission.text_content_part_4") }}
              </p>
              <p class="content-text">
                {{ t("$vuetify.learn_page.our_mission.text_content_part_5") }}
              </p>
            </v-col>
            <v-col cols="12" sm="8" md="5" align-self="center">
              <v-img src="../../assets/img/learn/ourMission.png" width="100%"></v-img>
            </v-col>
          </v-row>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { useLocale } from "vuetify";

export default {
  name: "LearnOurMission",
  setup() {
    const { t } = useLocale();
    return {
      t,
    };
  },
  data: () => ({
  }),
  mounted() {
  },
  computed: {
  },
};
</script>
<style scoped>
.content-text {
  color: #9a9a9b;
  font-size: 20px;
}

.text-top {
  color: #9a9a9b;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.01rem;
}

.title-text {
  font-size: 56px;
  font-weight: 400;
  color: #f6f2e6;
}

@media screen and (max-width: 600px) {
  .content-text {
    font-size: 14px;
  }

  .title-text {
    font-size: 36px;
  }
}

@media screen and (min-width: 600px) and (max-width: 960px) {}
</style>