<template>
    <v-container fluid class="pt-10 fill-height" v-if="getPriority == 1">
        <v-row class="w-100" justify="center">
            <v-col cols="12">
                <h1>Настройки</h1>
            </v-col>
        </v-row>
        <v-row justify="space-between" class="w-100">
            <v-col cols="12">
                <v-card width="100%" class="pa-4">
                    <h2 class="">
                        {{ $route.name == 'edit-admin-crm' ? 'Редактировать администратора' :
                            'Новый Администратор'
                        }}
                    </h2>

                    <h2 class="title_property_big mt-6">
                        Основные данные
                    </h2>

                    <!-- Login -->
                    <div class="my-3" v-if="$route.name == 'edit-admin-crm'">
                        <v-card-text class="title_property">
                            <small>Логин Администратора</small> <b>{{ username }}</b>
                        </v-card-text>
                    </div>
                    <div class="my-3" v-if="$route.name == 'create-admin-crm'">
                        <v-card-text class="title_property">
                            Логин Администратора
                        </v-card-text>
                        <v-text-field v-model="username" variant="outlined" placeholder="Логин Администратора"
                            density="comfortable" label="Этот логин будет использоваться при авторизации в систему.">
                        </v-text-field>
                    </div>
                    <div class="my-3">
                        <v-card-text class="title_property">
                            Пароль
                        </v-card-text>
                        <v-text-field v-model="password" variant="outlined" placeholder="Пароль" density="comfortable"
                            :rules="[required, minValue]" label="Пароль не должен быть короче 8 символов ">
                        </v-text-field>
                    </div>

                    <h1 class="title_property_big mt-10">
                        Права доступа Администратора
                    </h1>

                    <v-item-group selected-class="level-selected" v-model="admin_level" class="mt-6">
                        <v-container>
                            <v-row>
                                <v-col cols="12" md="10">
                                    <v-item v-slot="{ isSelected, selectedClass, toggle }">
                                        <v-card :class="[selectedClass, !isSelected ? 'level' : '']" @click="toggle"
                                            flat class="pa-4">
                                            <v-radio-group v-model="admin_level" readonly>
                                                <v-radio label="Супер Администратор (Уровень 1)" :value="0"></v-radio>
                                            </v-radio-group>
                                            <v-row class="mx-0">
                                                <v-col cols="auto" class="text-center">
                                                    <svg width="12px" viewBox="0 0 10 40" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.84615 9.64286V7.13904C3.84615 6.96429 4.03846 6.78571 4.23077 6.78571H5.76923C5.96154 6.78571 6.15385 6.96429 6.15385 7.14286V9.64286C6.15385 9.8401 6.32604 10 6.53846 10H9.61539C9.8278 10 10 9.8401 10 9.64286V4.64286C10 4.54814 9.95948 4.4573 9.88735 4.39032L8.84615 3.4235V1.07143C8.84615 0.874184 8.67396 0.714286 8.46154 0.714286H7.69231C7.47989 0.714286 7.30769 0.874184 7.30769 1.07143V1.99492L5.27196 0.104605C5.12176 -0.0348682 4.87824 -0.0348682 4.72804 0.104605L0.112651 4.39032C0.0405219 4.4573 0 4.54814 0 4.64286V9.64286C0 9.8401 0.172198 10 0.384615 10H3.46154C3.67396 10 3.84615 9.8401 3.84615 9.64286Z"
                                                            fill="#53CA40" />
                                                        <path
                                                            d="M4.375 23.75C4.375 23.75 3.75 23.75 3.75 23.125C3.75 22.5 4.375 20.625 6.875 20.625C9.375 20.625 10 22.5 10 23.125C10 23.75 9.375 23.75 9.375 23.75H4.375Z"
                                                            fill="#53CA40" />
                                                        <path
                                                            d="M6.875 20C7.91053 20 8.75 19.1605 8.75 18.125C8.75 17.0895 7.91053 16.25 6.875 16.25C5.83947 16.25 5 17.0895 5 18.125C5 19.1605 5.83947 20 6.875 20Z"
                                                            fill="#53CA40" />
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M3.26023 23.75C3.17223 23.5724 3.125 23.362 3.125 23.125C3.125 22.2778 3.54941 21.4066 4.33496 20.8C3.99131 20.6901 3.59068 20.625 3.125 20.625C0.625 20.625 0 22.5 0 23.125C0 23.75 0.625 23.75 0.625 23.75H3.26023Z"
                                                            fill="#53CA40" />
                                                        <path
                                                            d="M2.8125 20C3.67544 20 4.375 19.3004 4.375 18.4375C4.375 17.5746 3.67544 16.875 2.8125 16.875C1.94956 16.875 1.25 17.5746 1.25 18.4375C1.25 19.3004 1.94956 20 2.8125 20Z"
                                                            fill="#53CA40" />
                                                        <path
                                                            d="M5.87791 30.6565C5.61991 29.7812 4.38009 29.7812 4.12209 30.6565L4.05952 30.8688C3.89467 31.4281 3.25585 31.6927 2.7438 31.4138L2.54946 31.3079C1.74808 30.8714 0.871386 31.7481 1.3079 32.5495L1.41376 32.7438C1.69267 33.2558 1.42806 33.8947 0.868769 34.0595L0.656491 34.1221C-0.218831 34.3801 -0.21883 35.6199 0.656492 35.8779L0.868769 35.9405C1.42806 36.1053 1.69267 36.7442 1.41376 37.2562L1.3079 37.4505C0.871386 38.2519 1.74808 39.1286 2.54946 38.6921L2.7438 38.5862C3.25585 38.3073 3.89467 38.5719 4.05952 39.1312L4.12209 39.3435C4.38009 40.2188 5.61992 40.2188 5.87791 39.3435L5.94048 39.1312C6.10533 38.5719 6.74415 38.3073 7.2562 38.5862L7.45055 38.6921C8.25192 39.1286 9.12862 38.2519 8.6921 37.4505L8.58624 37.2562C8.30733 36.7442 8.57194 36.1053 9.13123 35.9405L9.34351 35.8779C10.2188 35.6199 10.2188 34.3801 9.34351 34.1221L9.13123 34.0595C8.57194 33.8947 8.30733 33.2558 8.58624 32.7438L8.6921 32.5495C9.12862 31.7481 8.25192 30.8714 7.45054 31.3079L7.2562 31.4138C6.74415 31.6927 6.10533 31.4281 5.94048 30.8688L5.87791 30.6565ZM5 36.8305C3.98904 36.8305 3.16949 36.011 3.16949 35C3.16949 33.989 3.98904 33.1695 5 33.1695C6.01096 33.1695 6.83051 33.989 6.83051 35C6.83051 36.011 6.01096 36.8305 5 36.8305Z"
                                                            fill="#53CA40" />
                                                    </svg>
                                                </v-col>
                                                <v-col cols="10" align-self="center">
                                                    Полные права во всех модулях
                                                </v-col>
                                            </v-row>
                                            <v-row class="mx-0" v-for="text in admin1" :key="text">
                                                <v-col cols="auto" align-self="center" class="py-1">
                                                    <svg width="13" viewBox="0 0 10 10" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M10 5C10 7.76142 7.76142 10 5 10C2.23858 10 0 7.76142 0 5C0 2.23858 2.23858 0 5 0C7.76142 0 10 2.23858 10 5ZM7.51895 3.10604C7.33589 2.92299 7.0391 2.92299 6.85604 3.10604C6.85162 3.11046 6.84747 3.11515 6.84361 3.12007L4.67337 5.88546L3.36492 4.57701C3.18186 4.39396 2.88507 4.39396 2.70201 4.57701C2.51895 4.76007 2.51895 5.05687 2.70201 5.23993L4.35604 6.89396C4.5391 7.07701 4.83589 7.07701 5.01895 6.89396C5.02303 6.88988 5.02688 6.88558 5.03048 6.88108L7.52561 3.76217C7.70198 3.57864 7.69977 3.28686 7.51895 3.10604Z"
                                                            fill="#0D73EB" />
                                                    </svg>
                                                </v-col>
                                                <v-col cols="11" align-self="center" class="py-1">
                                                    {{ text }}
                                                </v-col>
                                            </v-row>
                                        </v-card>
                                    </v-item>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" md="10">
                                    <v-item v-slot="{ isSelected, selectedClass, toggle }">
                                        <v-card :class="[selectedClass, !isSelected ? 'level' : '']" @click="toggle"
                                            flat class="pa-4">
                                            <v-radio-group v-model="admin_level" readonly>
                                                <v-radio
                                                    label="Управление недвижимостью, партнерами, категориями, лидами, настройка фильтров. (Уровень 2)"
                                                    :value="1"></v-radio>
                                            </v-radio-group>
                                            <v-row class="mx-0">
                                                <v-col cols="auto" class="text-center">
                                                    <svg width="12px" viewBox="0 0 10 40" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.84615 9.64286V7.13904C3.84615 6.96429 4.03846 6.78571 4.23077 6.78571H5.76923C5.96154 6.78571 6.15385 6.96429 6.15385 7.14286V9.64286C6.15385 9.8401 6.32604 10 6.53846 10H9.61539C9.8278 10 10 9.8401 10 9.64286V4.64286C10 4.54814 9.95948 4.4573 9.88735 4.39032L8.84615 3.4235V1.07143C8.84615 0.874184 8.67396 0.714286 8.46154 0.714286H7.69231C7.47989 0.714286 7.30769 0.874184 7.30769 1.07143V1.99492L5.27196 0.104605C5.12176 -0.0348682 4.87824 -0.0348682 4.72804 0.104605L0.112651 4.39032C0.0405219 4.4573 0 4.54814 0 4.64286V9.64286C0 9.8401 0.172198 10 0.384615 10H3.46154C3.67396 10 3.84615 9.8401 3.84615 9.64286Z"
                                                            fill="#53CA40" />
                                                        <path
                                                            d="M4.375 23.75C4.375 23.75 3.75 23.75 3.75 23.125C3.75 22.5 4.375 20.625 6.875 20.625C9.375 20.625 10 22.5 10 23.125C10 23.75 9.375 23.75 9.375 23.75H4.375Z"
                                                            fill="#53CA40" />
                                                        <path
                                                            d="M6.875 20C7.91053 20 8.75 19.1605 8.75 18.125C8.75 17.0895 7.91053 16.25 6.875 16.25C5.83947 16.25 5 17.0895 5 18.125C5 19.1605 5.83947 20 6.875 20Z"
                                                            fill="#53CA40" />
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M3.26023 23.75C3.17223 23.5724 3.125 23.362 3.125 23.125C3.125 22.2778 3.54941 21.4066 4.33496 20.8C3.99131 20.6901 3.59068 20.625 3.125 20.625C0.625 20.625 0 22.5 0 23.125C0 23.75 0.625 23.75 0.625 23.75H3.26023Z"
                                                            fill="#53CA40" />
                                                        <path
                                                            d="M2.8125 20C3.67544 20 4.375 19.3004 4.375 18.4375C4.375 17.5746 3.67544 16.875 2.8125 16.875C1.94956 16.875 1.25 17.5746 1.25 18.4375C1.25 19.3004 1.94956 20 2.8125 20Z"
                                                            fill="#53CA40" />
                                                        <path
                                                            d="M5.87791 30.6565C5.61991 29.7812 4.38009 29.7812 4.12209 30.6565L4.05952 30.8688C3.89467 31.4281 3.25585 31.6927 2.7438 31.4138L2.54946 31.3079C1.74808 30.8714 0.871386 31.7481 1.3079 32.5495L1.41376 32.7438C1.69267 33.2558 1.42806 33.8947 0.868769 34.0595L0.656491 34.1221C-0.218831 34.3801 -0.21883 35.6199 0.656492 35.8779L0.868769 35.9405C1.42806 36.1053 1.69267 36.7442 1.41376 37.2562L1.3079 37.4505C0.871386 38.2519 1.74808 39.1286 2.54946 38.6921L2.7438 38.5862C3.25585 38.3073 3.89467 38.5719 4.05952 39.1312L4.12209 39.3435C4.38009 40.2188 5.61992 40.2188 5.87791 39.3435L5.94048 39.1312C6.10533 38.5719 6.74415 38.3073 7.2562 38.5862L7.45055 38.6921C8.25192 39.1286 9.12862 38.2519 8.6921 37.4505L8.58624 37.2562C8.30733 36.7442 8.57194 36.1053 9.13123 35.9405L9.34351 35.8779C10.2188 35.6199 10.2188 34.3801 9.34351 34.1221L9.13123 34.0595C8.57194 33.8947 8.30733 33.2558 8.58624 32.7438L8.6921 32.5495C9.12862 31.7481 8.25192 30.8714 7.45054 31.3079L7.2562 31.4138C6.74415 31.6927 6.10533 31.4281 5.94048 30.8688L5.87791 30.6565ZM5 36.8305C3.98904 36.8305 3.16949 36.011 3.16949 35C3.16949 33.989 3.98904 33.1695 5 33.1695C6.01096 33.1695 6.83051 33.989 6.83051 35C6.83051 36.011 6.01096 36.8305 5 36.8305Z"
                                                            fill="#53CA40" />
                                                    </svg>
                                                </v-col>
                                                <v-col cols="10" align-self="center">
                                                    Полные права во всех модулях кроме просмотра логов и создания новых
                                                    пользователей.
                                                </v-col>
                                            </v-row>
                                            <v-row class="mx-0" v-for="text in admin2" :key="text">
                                                <v-col cols="auto" align-self="center" class="py-1">
                                                    <svg width="13" viewBox="0 0 10 10" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M10 5C10 7.76142 7.76142 10 5 10C2.23858 10 0 7.76142 0 5C0 2.23858 2.23858 0 5 0C7.76142 0 10 2.23858 10 5ZM7.51895 3.10604C7.33589 2.92299 7.0391 2.92299 6.85604 3.10604C6.85162 3.11046 6.84747 3.11515 6.84361 3.12007L4.67337 5.88546L3.36492 4.57701C3.18186 4.39396 2.88507 4.39396 2.70201 4.57701C2.51895 4.76007 2.51895 5.05687 2.70201 5.23993L4.35604 6.89396C4.5391 7.07701 4.83589 7.07701 5.01895 6.89396C5.02303 6.88988 5.02688 6.88558 5.03048 6.88108L7.52561 3.76217C7.70198 3.57864 7.69977 3.28686 7.51895 3.10604Z"
                                                            fill="#0D73EB" />
                                                    </svg>
                                                </v-col>
                                                <v-col cols="11" align-self="center" class="py-1">
                                                    {{ text }}
                                                </v-col>
                                            </v-row>
                                        </v-card>
                                    </v-item>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" md="10">
                                    <v-item v-slot="{ isSelected, selectedClass, toggle }">
                                        <v-card :class="[selectedClass, !isSelected ? 'level' : '']" @click="toggle"
                                            flat class="pa-4">
                                            <v-radio-group v-model="admin_level" readonly>
                                                <v-radio
                                                    label="Управление недвижимостью, категориями, партнерами. (Уровень 3)"
                                                    :value="2"></v-radio>
                                            </v-radio-group>
                                            <v-row class="mx-0">
                                                <v-col cols="auto" class="text-center">
                                                    <svg width="12px" viewBox="0 0 10 40" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.84615 9.64286V7.13904C3.84615 6.96429 4.03846 6.78571 4.23077 6.78571H5.76923C5.96154 6.78571 6.15385 6.96429 6.15385 7.14286V9.64286C6.15385 9.8401 6.32604 10 6.53846 10H9.61539C9.8278 10 10 9.8401 10 9.64286V4.64286C10 4.54814 9.95948 4.4573 9.88735 4.39032L8.84615 3.4235V1.07143C8.84615 0.874184 8.67396 0.714286 8.46154 0.714286H7.69231C7.47989 0.714286 7.30769 0.874184 7.30769 1.07143V1.99492L5.27196 0.104605C5.12176 -0.0348682 4.87824 -0.0348682 4.72804 0.104605L0.112651 4.39032C0.0405219 4.4573 0 4.54814 0 4.64286V9.64286C0 9.8401 0.172198 10 0.384615 10H3.46154C3.67396 10 3.84615 9.8401 3.84615 9.64286Z"
                                                            fill="#53CA40" />
                                                        <path
                                                            d="M4.375 23.75C4.375 23.75 3.75 23.75 3.75 23.125C3.75 22.5 4.375 20.625 6.875 20.625C9.375 20.625 10 22.5 10 23.125C10 23.75 9.375 23.75 9.375 23.75H4.375Z"
                                                            fill="#53CA40" />
                                                        <path
                                                            d="M6.875 20C7.91053 20 8.75 19.1605 8.75 18.125C8.75 17.0895 7.91053 16.25 6.875 16.25C5.83947 16.25 5 17.0895 5 18.125C5 19.1605 5.83947 20 6.875 20Z"
                                                            fill="#53CA40" />
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M3.26023 23.75C3.17223 23.5724 3.125 23.362 3.125 23.125C3.125 22.2778 3.54941 21.4066 4.33496 20.8C3.99131 20.6901 3.59068 20.625 3.125 20.625C0.625 20.625 0 22.5 0 23.125C0 23.75 0.625 23.75 0.625 23.75H3.26023Z"
                                                            fill="#53CA40" />
                                                        <path
                                                            d="M2.8125 20C3.67544 20 4.375 19.3004 4.375 18.4375C4.375 17.5746 3.67544 16.875 2.8125 16.875C1.94956 16.875 1.25 17.5746 1.25 18.4375C1.25 19.3004 1.94956 20 2.8125 20Z"
                                                            fill="#53CA40" />
                                                        <path
                                                            d="M5.87791 30.6565C5.61991 29.7812 4.38009 29.7812 4.12209 30.6565L4.05952 30.8688C3.89467 31.4281 3.25585 31.6927 2.7438 31.4138L2.54946 31.3079C1.74808 30.8714 0.871386 31.7481 1.3079 32.5495L1.41376 32.7438C1.69267 33.2558 1.42806 33.8947 0.868769 34.0595L0.656491 34.1221C-0.218831 34.3801 -0.21883 35.6199 0.656492 35.8779L0.868769 35.9405C1.42806 36.1053 1.69267 36.7442 1.41376 37.2562L1.3079 37.4505C0.871386 38.2519 1.74808 39.1286 2.54946 38.6921L2.7438 38.5862C3.25585 38.3073 3.89467 38.5719 4.05952 39.1312L4.12209 39.3435C4.38009 40.2188 5.61992 40.2188 5.87791 39.3435L5.94048 39.1312C6.10533 38.5719 6.74415 38.3073 7.2562 38.5862L7.45055 38.6921C8.25192 39.1286 9.12862 38.2519 8.6921 37.4505L8.58624 37.2562C8.30733 36.7442 8.57194 36.1053 9.13123 35.9405L9.34351 35.8779C10.2188 35.6199 10.2188 34.3801 9.34351 34.1221L9.13123 34.0595C8.57194 33.8947 8.30733 33.2558 8.58624 32.7438L8.6921 32.5495C9.12862 31.7481 8.25192 30.8714 7.45054 31.3079L7.2562 31.4138C6.74415 31.6927 6.10533 31.4281 5.94048 30.8688L5.87791 30.6565ZM5 36.8305C3.98904 36.8305 3.16949 36.011 3.16949 35C3.16949 33.989 3.98904 33.1695 5 33.1695C6.01096 33.1695 6.83051 33.989 6.83051 35C6.83051 36.011 6.01096 36.8305 5 36.8305Z"
                                                            fill="#53CA40" />
                                                    </svg>
                                                </v-col>
                                                <v-col cols="10" align-self="center">
                                                    Полные права в работе с недвижимостью, категориями, партнерами.
                                                </v-col>
                                            </v-row>
                                            <v-row class="mx-0" v-for="text in admin3" :key="text">
                                                <v-col cols="auto" align-self="center" class="py-1">
                                                    <svg width="13" viewBox="0 0 10 10" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M10 5C10 7.76142 7.76142 10 5 10C2.23858 10 0 7.76142 0 5C0 2.23858 2.23858 0 5 0C7.76142 0 10 2.23858 10 5ZM7.51895 3.10604C7.33589 2.92299 7.0391 2.92299 6.85604 3.10604C6.85162 3.11046 6.84747 3.11515 6.84361 3.12007L4.67337 5.88546L3.36492 4.57701C3.18186 4.39396 2.88507 4.39396 2.70201 4.57701C2.51895 4.76007 2.51895 5.05687 2.70201 5.23993L4.35604 6.89396C4.5391 7.07701 4.83589 7.07701 5.01895 6.89396C5.02303 6.88988 5.02688 6.88558 5.03048 6.88108L7.52561 3.76217C7.70198 3.57864 7.69977 3.28686 7.51895 3.10604Z"
                                                            fill="#0D73EB" />
                                                    </svg>
                                                </v-col>
                                                <v-col cols="11" align-self="center" class="py-1">
                                                    {{ text }}
                                                </v-col>
                                            </v-row>
                                        </v-card>
                                    </v-item>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" md="10">
                                    <v-item v-slot="{ isSelected, selectedClass, toggle }">
                                        <v-card :class="[selectedClass, !isSelected ? 'level' : '']" @click="toggle"
                                            flat class="pa-4">
                                            <v-radio-group v-model="admin_level" readonly>
                                                <v-radio label="Управление лидами. (Уровень 4)"
                                                    :value="3"></v-radio>
                                            </v-radio-group>
                                            <v-row class="mx-0">
                                                <v-col cols="auto" class="text-center">
                                                    <svg width="12px" viewBox="0 0 10 40" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M3.84615 9.64286V7.13904C3.84615 6.96429 4.03846 6.78571 4.23077 6.78571H5.76923C5.96154 6.78571 6.15385 6.96429 6.15385 7.14286V9.64286C6.15385 9.8401 6.32604 10 6.53846 10H9.61539C9.8278 10 10 9.8401 10 9.64286V4.64286C10 4.54814 9.95948 4.4573 9.88735 4.39032L8.84615 3.4235V1.07143C8.84615 0.874184 8.67396 0.714286 8.46154 0.714286H7.69231C7.47989 0.714286 7.30769 0.874184 7.30769 1.07143V1.99492L5.27196 0.104605C5.12176 -0.0348682 4.87824 -0.0348682 4.72804 0.104605L0.112651 4.39032C0.0405219 4.4573 0 4.54814 0 4.64286V9.64286C0 9.8401 0.172198 10 0.384615 10H3.46154C3.67396 10 3.84615 9.8401 3.84615 9.64286Z"
                                                            fill="#53CA40" />
                                                        <path
                                                            d="M4.375 23.75C4.375 23.75 3.75 23.75 3.75 23.125C3.75 22.5 4.375 20.625 6.875 20.625C9.375 20.625 10 22.5 10 23.125C10 23.75 9.375 23.75 9.375 23.75H4.375Z"
                                                            fill="#53CA40" />
                                                        <path
                                                            d="M6.875 20C7.91053 20 8.75 19.1605 8.75 18.125C8.75 17.0895 7.91053 16.25 6.875 16.25C5.83947 16.25 5 17.0895 5 18.125C5 19.1605 5.83947 20 6.875 20Z"
                                                            fill="#53CA40" />
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M3.26023 23.75C3.17223 23.5724 3.125 23.362 3.125 23.125C3.125 22.2778 3.54941 21.4066 4.33496 20.8C3.99131 20.6901 3.59068 20.625 3.125 20.625C0.625 20.625 0 22.5 0 23.125C0 23.75 0.625 23.75 0.625 23.75H3.26023Z"
                                                            fill="#53CA40" />
                                                        <path
                                                            d="M2.8125 20C3.67544 20 4.375 19.3004 4.375 18.4375C4.375 17.5746 3.67544 16.875 2.8125 16.875C1.94956 16.875 1.25 17.5746 1.25 18.4375C1.25 19.3004 1.94956 20 2.8125 20Z"
                                                            fill="#53CA40" />
                                                        <path
                                                            d="M5.87791 30.6565C5.61991 29.7812 4.38009 29.7812 4.12209 30.6565L4.05952 30.8688C3.89467 31.4281 3.25585 31.6927 2.7438 31.4138L2.54946 31.3079C1.74808 30.8714 0.871386 31.7481 1.3079 32.5495L1.41376 32.7438C1.69267 33.2558 1.42806 33.8947 0.868769 34.0595L0.656491 34.1221C-0.218831 34.3801 -0.21883 35.6199 0.656492 35.8779L0.868769 35.9405C1.42806 36.1053 1.69267 36.7442 1.41376 37.2562L1.3079 37.4505C0.871386 38.2519 1.74808 39.1286 2.54946 38.6921L2.7438 38.5862C3.25585 38.3073 3.89467 38.5719 4.05952 39.1312L4.12209 39.3435C4.38009 40.2188 5.61992 40.2188 5.87791 39.3435L5.94048 39.1312C6.10533 38.5719 6.74415 38.3073 7.2562 38.5862L7.45055 38.6921C8.25192 39.1286 9.12862 38.2519 8.6921 37.4505L8.58624 37.2562C8.30733 36.7442 8.57194 36.1053 9.13123 35.9405L9.34351 35.8779C10.2188 35.6199 10.2188 34.3801 9.34351 34.1221L9.13123 34.0595C8.57194 33.8947 8.30733 33.2558 8.58624 32.7438L8.6921 32.5495C9.12862 31.7481 8.25192 30.8714 7.45054 31.3079L7.2562 31.4138C6.74415 31.6927 6.10533 31.4281 5.94048 30.8688L5.87791 30.6565ZM5 36.8305C3.98904 36.8305 3.16949 36.011 3.16949 35C3.16949 33.989 3.98904 33.1695 5 33.1695C6.01096 33.1695 6.83051 33.989 6.83051 35C6.83051 36.011 6.01096 36.8305 5 36.8305Z"
                                                            fill="#53CA40" />
                                                    </svg>
                                                </v-col>
                                                <v-col cols="10" align-self="center">
                                                    Полные права в работе с лидами.
                                                </v-col>
                                            </v-row>
                                            <v-row class="mx-0" v-for="text in admin4" :key="text">
                                                <v-col cols="auto" align-self="center" class="py-1">
                                                    <svg width="13" viewBox="0 0 10 10" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M10 5C10 7.76142 7.76142 10 5 10C2.23858 10 0 7.76142 0 5C0 2.23858 2.23858 0 5 0C7.76142 0 10 2.23858 10 5ZM7.51895 3.10604C7.33589 2.92299 7.0391 2.92299 6.85604 3.10604C6.85162 3.11046 6.84747 3.11515 6.84361 3.12007L4.67337 5.88546L3.36492 4.57701C3.18186 4.39396 2.88507 4.39396 2.70201 4.57701C2.51895 4.76007 2.51895 5.05687 2.70201 5.23993L4.35604 6.89396C4.5391 7.07701 4.83589 7.07701 5.01895 6.89396C5.02303 6.88988 5.02688 6.88558 5.03048 6.88108L7.52561 3.76217C7.70198 3.57864 7.69977 3.28686 7.51895 3.10604Z"
                                                            fill="#0D73EB" />
                                                    </svg>
                                                </v-col>
                                                <v-col cols="11" align-self="center" class="py-1">
                                                    {{ text }}
                                                </v-col>
                                            </v-row>
                                        </v-card>
                                    </v-item>
                                </v-col>
                            </v-row>

                        </v-container>
                    </v-item-group>

                    <v-row justify="center" class="my-15">
                        <v-col cols="12" sm="8" md="6">
                            <v-btn color="#0255B7" block flat class="btn-text" rounded="lg" size="large"
                                :disabled="admin_level === null || !username"
                                @click="CreateAdmin()">
                                {{ $route.name == 'edit-categories-crm'
                                    ? 'Сохранить изменения администратора' :
                                    'Сохранить нового администратора' }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-snackbar :timeout="2000" location="top right" :color="alert_snackbar.color" variant="flat"
            v-model="alert_snackbar.state">
            <strong>{{ alert_snackbar.text }}</strong>
        </v-snackbar>
    </v-container>
</template>
<script>
import { defineComponent } from "vue";
import { useLocale } from "vuetify";
import { mapGetters } from 'vuex'
import { urlAPI } from "../../store/config";

export default defineComponent({
    name: "CreateOrEditAdmin",
    setup() {
        const { t } = useLocale();
        return {
            t,
        };
    },
    data: () => ({
        urlAPI: urlAPI + '/',
        alert_snackbar: {
            state: false,
            color: "",
            text: ""
        },
        username: null,
        password: null,
        admin_level: null,
        windowVue: null,
        admin1: [
            "Переназначить все объекты недвижимости от одного агента к другому (безвозвратное действие)",
            "Добавить партнера.",
            "Редактировать данные партнера. ",
            "Удаление партнеров.",
            "Добавить, изменить, удалить категорию; переназначить все объекты недвижимости между категориями (безвозвратное действие).",
            "Добавить, изменить, удалить, архивировать объект недвижимости.",
            "Добавить, удалить из top property.",
            "Добавить, обновить, удалить SEO блок.",
            "Просматривать, архивировать, обновлять, удалять лидов. ",
            "Просматривать, обновлять, удалять источники лидов (!без удаления!).",
            "Добавлять, изменять, удалять значения для каждого фильтра в отдельности. ",
            "Экспортировать лиды по датам.",
            "Просматривать логи.",
            "Создавать, редактировать права?, удалять новых пользователей.",
        ],
        admin2: [
            "Переназначить все объекты недвижимости от одного агента к другому (безвозвратное действие)",
            "Добавить партнера.",
            "Редактировать данные партнера. ",
            "Удаление партнеров.",
            "Добавить, изменить, удалить категорию; переназначить все объекты недвижимости между категориями (безвозвратное действие).",
            "Добавить, изменить, удалить, архивировать объект недвижимости.",
            "Добавить, удалить из top property.",
            "Добавить, обновить, удалить SEO блок.",
            "Просматривать, архивировать, обновлять, удалять лидов. ",
            "Просматривать, обновлять, удалять источники лидов (!без удаления!).",
            "Добавлять, изменять, удалять значения для каждого фильтра в отдельности. ",
        ],
        admin3: [
            "Добавить, изменить, удалить, архивировать объект недвижимости",
            "Добавить, удалить из top property",
            "Добавить, изменить, удалить категорию; переназначить все объекты недвижимости между категориями (безвозвратное действие)",
            "Переназначить все объекты недвижимости от одного агента к другому (безвозвратное действие) ",
            "Добавить партнера",
            "Редактировать данные партнера ",
            "Удаление партнеров",
            "Добавить, обновить, удалить SEO блок.",
        ],
        admin4: [
            "Просматривать, архивировать, обновлять, удалять лидов",
            "Экспортировать лиды по датам",
            "Просматривать, обновлять, удалять источники лидов (!без удаления!)",
        ],
    }),
    computed: {
        ...mapGetters([
            'getAdmin',
            'getPriority'
        ]),
    },
    methods: {
        async CreateAdmin() {
            let vue = this;
            try {
                if (this.$route.name == 'edit-admin-crm') {
                    let data = {
                        username: this.username,
                        ID_access_level: this.admin_level + 1,
                    };
                    if(this.password){
                        data.password = this.password;
                        data.password_confirmation = this.password;
                    }
                    let responce = await this.$store.dispatch('UpdateAdmin', { ID: this.getAdmin.ID, data: data });
                    if (responce.success == true) {
                        this.alert_snackbar.state = true;
                        this.alert_snackbar.text = "Админ успешно обновлён";
                        this.alert_snackbar.color = "success";
                    } else {
                        this.alert_snackbar.state = true;
                        this.alert_snackbar.text = responce.error ?? responce.message + " " + Object.values(responce.data);
                        this.alert_snackbar.color = "error";
                    }

                } else {
                    let data = {
                        username: this.username,
                        password: this.password,
                        password_confirmation: this.password,
                        ID_access_level: this.admin_level + 1,
                    };

                    let responce = await this.$store.dispatch('CreateAdmin', data);
                    if (responce.success == true) {
                        this.alert_snackbar.state = true;
                        this.alert_snackbar.text = "Админ успешно создан";
                        this.alert_snackbar.color = "success";

                        setTimeout(function () {
                            vue.$router.push({
                                name: "admin-settings-crm",
                            })
                        }, 1500);
                    } else {
                        this.alert_snackbar.state = true;
                        this.alert_snackbar.text = responce.error ?? responce.message + " " + Object.values(responce.data);
                        this.alert_snackbar.color = "error";
                    }
                }
            } catch (error) {
                console.log(error);
                this.alert_snackbar.state = true;
                this.alert_snackbar.text = error.message;
                this.alert_snackbar.color = "error";
            }
        },
        required(v) {
            return !!v || 'Это поле обьязательное'
        },
        minValue(v) {
            return v.length > 7 || 'Поле должно иметь минимум 8 символов'
        },
    },
    async beforeCreate() {
        if (this.$route.params.id) {
            await this.$store.dispatch('LoadAdmin', this.$route.params.id);
            this.username = this.getAdmin.username;
            this.admin_level = this.getAdmin.ID_access_level - 1;

        } else {
            this.$store.dispatch('LoadAdminEmpty');
        }
    },
    mounted() {
        this.windowVue = window;
    }
})
</script>
<style scoped>
.level-selected {
    background-color: rgba(2, 87, 183, 0.2);
    border-radius: 12px;
    border: 1px solid rgba(2, 87, 183, 0.8);
}

.level {
    background-color: #F7F7F7;
    border-radius: 12px;
    border: 1px solid #DEDEDE;
}

.title_property_big {
    font-weight: 500;
    font-size: 1.4rem;
}

.title_property {
    font-weight: 500;
    font-size: 1.1rem;
}

.divider-bottom {
    border: 1px solid rgba(0, 0, 0, 0.2);
    opacity: 1;
}

.btn-text {
    text-transform: inherit;
}

.input-search {
    min-width: 300px;
}

.select-search {
    min-width: 200px;
    max-width: 400px;
}

.col-header-gap {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 10px;
}

.w-100 {
    min-width: 100%;
}

@media screen and (max-width: 1400px) {
    .input-search {
        min-width: 140px;
    }
}
</style>

<style>
.input-search .v-field__input,
.select-search .v-field__input {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
    min-height: 36px;
}
</style>